import * as React from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { TextField } from '@fluentui/react/lib/TextField';
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    ContextualMenu,
    Toggle,
    Modal,
    IDragOptions,
    IIconProps,
    Stack,
    IStackProps,
} from '@fluentui/react';
import { DefaultButton, PrimaryButton, IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { repeat } from 'lodash';
import { RegionStatus } from '../../../../models/alerting';
import { useState } from 'react';
import { CustomerImpactChoices } from './CustomerImpactChoices';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';

interface ITableModalProps {
    isModalOpen: boolean,
    hideModal: () => void,
    item?: RegionStatus,
    save: (rs?: RegionStatus) => void,
}

export const RegionStatusTableModal: React.FunctionComponent<ITableModalProps> = (props) => {
    const { isModalOpen, hideModal, item } = props;
    /*    const [item, setItem] = useState<RegionStatus>(props.item == undefined ? undefined : props.item);*/
    const [rootCause, setRootCause] = useState(item?.rootCause == undefined ? "" : item.rootCause);
    const [mitigation, setMitigation] = useState(item?.mitigationStrategy == undefined ? "" : item.mitigationStrategy);
    const [projectRecoveryDate, setProjectRecoverDate] = useState(item?.projectRecoveryDate == undefined ? "" : item.projectRecoveryDate);
    const [customerImpact, setCustomerImpact] = useState(item?.customerImpact == undefined ? "" : item.customerImpact);
/*    const [regionItem, setRegionItem] = useState<RegionStatus>(item == undefined ? null : item);*/

    const onRootCauseChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
        setRootCause(value == undefined ? "" : value);
        if (item != undefined) {
            item.rootCause = value;
        }
    };

    const onMitigationChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
        setMitigation(value == undefined ? "" : value);
        if (item != undefined) {
            item.mitigationStrategy = value;
        }
    };

    const onProjectRecoveryDateChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
        setProjectRecoverDate(value == undefined ? "" : value);
        if (item != undefined) {
            item.projectRecoveryDate = value;
        }
    };

    const onCustomerImpactChange = (ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption): void => {
        setCustomerImpact(option.key);
        if (item != undefined) {
            item.customerImpact = option.key;
        }
    };

    // Use useId() to ensure that the IDs are unique on the page.
    // (It's also okay to use plain strings and manually ensure uniqueness.)
    const titleId = useId('title');

    return (
        <div>
            <Modal
                titleAriaId={titleId}
                isOpen={isModalOpen}
                onDismiss={hideModal}
                isBlocking={false}
                containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header}>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={hideModal}
                    />
                </div>
                <div>
                    <div style={{ width: "600px", height: "650px" }}>
                            <p style={{ marginLeft: "20px" }}><strong>Live Region:</strong> {item?.region} </p>
                            <p style={{ marginLeft: "20px" }}><strong>Environment:</strong> {item?.environment} </p>
                            <p style={{ marginLeft: "20px" }}><strong>Server Min TTL in next 12m:</strong> {item?.minTTL} </p>
                            <p style={{ marginLeft: "20px" }}><strong>Restricted Resource:</strong> {item?.restrictedRes} </p>
                            <p style={{ marginLeft: "20px" }}><strong>Server Min TTL date in next 12m:</strong> {item?.minTTLDate} </p>
                            <p style={{ marginLeft: "20px" }}><strong>Server Today TTL:</strong> {item?.todayTTL} </p>
                            <p style={{ marginLeft: "20px" }}><strong>Server TTL at 6m:</strong> {item?.sixMonthsTTL} </p>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}><TextField label="Root Cause:" value={rootCause} onChange={onRootCauseChange} underlined /></div>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}><TextField label="Mitigation Strategy:" value={mitigation} onChange={onMitigationChange} underlined /></div>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}><TextField label="Project Recovery Date:" value={projectRecoveryDate} onChange={onProjectRecoveryDateChange} underlined /></div>
                        <div style={{ marginLeft: "21px", marginTop: "20px" }}><CustomerImpactChoices value={customerImpact} changeValue={onCustomerImpactChange} /></div>
                        <div style={{ marginLeft: "260px", marginTop: "20px" }}>   <PrimaryButton text="Submit" onClick={() => props.save(item)} /></div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const stackProps: Partial<IStackProps> = {
    horizontal: true,
    tokens: { childrenGap: 40 },
    styles: { root: { marginBottom: 20 } },
};
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};