import * as React from "react";
import PageHeader from '../../common/pageheader/PageHeader';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import {
    MessageBar,
    MessageBarType,
    Link
} from '@fluentui/react';


export const HLCReport: React.FC = () => {

    return (
        <div>
            <PageHeader
                title="Hardware Lifecycle"
                description="The report contains the historical and future supply telemetry for CPU/IOPS/HDD/SSD/Memory of Substrate Backend"
            />
            <div>
                <BodyPage />
            </div>
        </div>

    )
}

const BodyPage: React.FC = () => {
    return (
        <div>
            <MessageBar>
                Please go to
                <Link href="https://aka.ms/tesseract/m2cp/hlc" inline>
                    aka.ms/tesseract/m2cp/hlc
                </Link>{' '}
                to check original report
            </MessageBar>
            <iframe title="DERA_0606E"
                style={{ width: "100%", height: "900px", marginTop: '20px' }}
                src="https://msit.powerbi.com/reportEmbed?reportId=fb376fec-2b59-4737-9fe8-1752ebc5528d&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&pageName=ReportSection"
                frameBorder={0}
                allowFullScreen={true}></iframe>
        </div>
    );
}