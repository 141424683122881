import { IComboBoxOption, IDropdownOption } from "@fluentui/react";
import { UserPermission } from "../models/permission";

export function distinct<T=any>(arr: Array<T>): Array<T> {
    return Array.from(new Set(arr));
}

export function asDropdownOption(name: string) {
    return {
        key: name,
        text: name
    } as IDropdownOption;
}

export function asComboBoxOption(name: string) {
    return {
        key: name,
        text: name
    } as IComboBoxOption;
}

export function convertDate(point: ITimeSeriesDataPoint) {
    return {
        date: parseDate(point.date.toString()),
        value: point.value
    } as ITimeSeriesDataPoint
}

export function parseDate(dateStr: string) {
    let d = Date.parse(dateStr);
    return d? new Date(d) : undefined;
}

export function formatDate(date: Date) {
    return date.toISOString().slice(0, 10);
}

export function intersect(a: any[], b: any[]) {
    var setB = new Set(b);
    return [...new Set(a)].filter(x => setB.has(x));
}

export function checkUserPermission(userpermissions: UserPermission[], tool: string, permission: string) {
    var p = userpermissions.filter(p => p.tool == tool)
    if (p.length > 0) {
        return p[0].permission.includes(permission);
    }
    return false;
}
