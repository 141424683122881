import React from 'react'
import { IRecommendation, ISelectionPlan } from "../../../../models/DCselectionPlan";

interface ISimulationPlan {
    simulationResult: ISelectionPlan
}

export const DCSelectionSimulationGroup: React.FC<ISimulationPlan> = ({ simulationResult }) => {
    const withoutBorder = {
        marginTop: "1rem",
        textAlign: "center",
    }
    const firstPriority = getFirstPriority()
    return (
        <div className="ms-Grid-row" style={withoutBorder}>
            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{simulationResult.stampName}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{simulationResult.region}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{simulationResult.capacityGeoCode}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{simulationResult.reservationStatus}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{simulationResult.fiscalYear}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{simulationResult.planIntent}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{firstPriority}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{getHostingCost()}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{getNetworkingCost()}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{simulationResult.dagCnt}</div>
        </div>
    )

    function getFirstPriority() {
        if (simulationResult != null && simulationResult.recommendation != undefined &&
            simulationResult.recommendation.length != 0) {
            var dcItem: IRecommendation = simulationResult.recommendation[0];
            console.log("First item: " + dcItem);
            return dcItem.dcs.reduce((pre, cur) => pre + cur.name + "  ", "");
        }
        console.log("Empty item");
        return "";
    }

    function unitConverter (x: number) {
        return (x / 1000).toFixed(2) + "K"
    }

    function getHostingCost() {
        try {
            return unitConverter(simulationResult.recommendation[0].hosting);
        } catch (ex) {
            return "";
        }
    }

    function getNetworkingCost() {
        try {
            return unitConverter(simulationResult.recommendation[0].networking);
        } catch (ex) {
            return "";
        }
    }
}
