
// template for a new component


import { DetailsList, PrimaryButton, TextField } from "@fluentui/react";
import * as React from "react";
// import { IHardwareRoadmapEntry } from "../../../../models/DemandPlanGenerationModel";
import { fetchDefaultSellableProfile, fetchHardwareRoadmapDetail, fetchSellableProfileDetail, saveSellableProfile } from "../../../../services/demandPlanService";
import { IHardwareRoadmapEntry } from "../../../../models/DemandPlanGenerationModel";

export interface IHardwareRoadmapViewerProps {
    profileId: string
}
export const HardwareRoadmapViewer: React.FC<IHardwareRoadmapViewerProps> = ({profileId}) => {
    const [version, setVersion] = React.useState<number>(0);
    const [profileName, setProfileName] = React.useState<string>("");
    const [profileDescription, setProfileDescription] = React.useState<string>("");

    const [hardwareRoadmapEntries, setHardwareRoadmapEntries] = React.useState<IHardwareRoadmapEntry[]>([]);
    React.useEffect(() => {
        fetchHardwareRoadmapDetail(profileId).then(response => {
            console.log(response);
            setHardwareRoadmapEntries(response)
        });
    }, [])

    return (
        <div key={version} className="ms-Grid" dir="ltr" style={{padding: "0 2rem"}}>
            <DetailsList
                items={hardwareRoadmapEntries}
            />
        </div>
    );

}