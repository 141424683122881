import { Dictionary, min } from "lodash";
import { RegionEnvironmentStatus, RegionStatus } from "../models/alerting";
import { ICostSavingResult, ITtl, IDeraStamp, IHLCStamp, ITotalDagCount, IDemandData, IAIStamp, IRegionGeoMapping, ISummaryStamp } from "../models/CostSaving";
import { ApiServiceBase, getJson, post, postJson, postMethod } from "../utils/apiServiceBase";

export function fetchEnvironments() {
    return ["Multitenant"];
    //return api.getJson<string[]>("costsaving/costsaving_environments")
}

export function fetchRegions() {
    return ["United States"];
    //return api.getJson<string[]>("costsaving/costsaving_regions")
}

export function fetchForests() {
    return ["NEWCAPACITY", "NAMPRD05.PROD.OUTLOOK.COM", "PROD.EXCHANGELABS.COM"];
    //return api.getJson<string[]>("costsaving/costsaving_forests")
}

export function fetchThresholds() {
    var thresholds = [];
    for (var i = 1; i <= 20; i++) {
        thresholds.push(i.toString());
    }
    return thresholds;
}

export function fetchRanges() {
    var ranges = [];
    for (var i = 0; i <= 36; i++) {
        ranges.push(i.toString());
    }
    return ranges;
}


const monthDays: Dictionary<number> = {
    1: 31,
    2: 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31
}

export async function fetchRegionGeoMapping() {
    return await getJson<IRegionGeoMapping[]>(`costsaving/region_geocode_mapping`);
}

export async function fetchDeraStamps(region: string) {
    if (region === "") {
        region = "BRA";
    }
    return await getJson<IDeraStamp[]>(`costsaving/dera_stamps?region=${region}`);
}

export async function fetchHLCStamps(region: string, environment: string) {
    if (region === "") {
        region = "BRA";
    } 
    if (environment === "") {
        environment = "Go Local"
    }
    
    return await getJson<IHLCStamp[]>(`costsaving/hlc_stamps?region=${region}&&environment=${environment}`);
}

export async function fetchAIRecStamps(region: string, environment: string) {
    return await getJson<IAIStamp[]>(`costsaving/ai_stamps?region=${region}&&environment=${environment}`);
}

export async function fetchGrowthRates(region: string, environment: string) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }
    return await getJson<Dictionary<number>>(`costsaving/growth_rates?region=${region}&&environment=${environment}`);
}

export async function fetchTotalDagCounts(liveDate: string) {
    return await getJson<ITotalDagCount[]>(`costsaving/total_dagcounts?liveDate=${liveDate}`);
}

export async function fetchCPUDemandData(region: string, environment: string) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }
    return await getJson<IDemandData[]>(`costsaving/min_cpu_ttl_data?region=${region}&&environment=${environment}`);
}

export async function fetchIOPSDemandData(region: string, environment: string) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }
    return await getJson<IDemandData[]>(`costsaving/min_iops_ttl_data?region=${region}&&environment=${environment}`);
}

export async function fetchHDDDemandData(region: string, environment: string) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }
    return await getJson<IDemandData[]>(`costsaving/min_hdd_ttl_data?region=${region}&&environment=${environment}`);
}

export async function fetchSSDDemandData(region: string, environment: string) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }
    return await getJson<IDemandData[]>(`costsaving/min_ssd_ttl_data?region=${region}&&environment=${environment}`);
}

export async function computeNewHDDTTL(region: string, environment: string, minHDDData: IDemandData[], deselectedStamps: IHLCStamp[]) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }

    var reqBody = {
        'deselectedStamps': deselectedStamps,
        'minTTLHDDDemand': minHDDData
    };

    return await postJson<IDemandData[]>(`costsaving/new_hdd_ttl?region=${region}&&environment=${environment}`, reqBody);
}

export async function computeNewCPUTTL(region: string, environment: string, minCPUData: IDemandData[], deselectedStamps: IHLCStamp[]) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }

    var reqBody = {
        'deselectedStamps': deselectedStamps,
        'minTTLCPUDemand': minCPUData
    };

    return await postJson<IDemandData[]>(`costsaving/new_cpu_ttl?region=${region}&&environment=${environment}`, reqBody);
}

export async function computeNewIOPSTTL(region: string, environment: string, minIOPSData: IDemandData[], deselectedStamps: IHLCStamp[]) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }

    var reqBody = {
        'deselectedStamps': deselectedStamps,
        'minTTLIOPSDemand': minIOPSData
    };

    return await postJson<IDemandData[]>(`costsaving/new_iops_ttl?region=${region}&&environment=${environment}`, reqBody);
}

export async function computeNewSSDTTL(region: string, environment: string, minSSDData: IDemandData[], deselectedStamps: IHLCStamp[]) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }

    var reqBody = {
        'deselectedStamps': deselectedStamps,
        'minTTLSSDDemand': minSSDData
    };

    return await postJson<IDemandData[]>(`costsaving/new_ssd_ttl?region=${region}&&environment=${environment}`, reqBody);
}


export async function computeAICPUTTL(region: string, environment: string, hlcStamps: IHLCStamp[], aiStamps: IAIStamp[], baseCPUData: IDemandData[]) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }

    var reqBody = {
        'hlcStamps': hlcStamps,
        'aiStamps': aiStamps,
        'baseTTLCPUDemand': baseCPUData
    };

    return await postJson<IDemandData[]>(`costsaving/ai_cpu_ttl?region=${region}&&environment=${environment}`, reqBody);
}

export async function computeAIHDDTTL(region: string, environment: string, hlcStamps: IHLCStamp[], aiStamps: IAIStamp[], baseHDDData: IDemandData[]) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }

    var reqBody = {
        'hlcStamps': hlcStamps,
        'aiStamps': aiStamps,
        'baseTTLHDDDemand': baseHDDData
    };

    return await postJson<IDemandData[]>(`costsaving/ai_hdd_ttl?region=${region}&&environment=${environment}`, reqBody);
}

export async function computeAIIOPSTTL(region: string, environment: string, hlcStamps: IHLCStamp[], aiStamps: IAIStamp[], baseIOPSData: IDemandData[]) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }

    var reqBody = {
        'hlcStamps': hlcStamps,
        'aiStamps': aiStamps,
        'baseTTLIOPSDemand': baseIOPSData
    };

    return await postJson<IDemandData[]>(`costsaving/ai_iops_ttl?region=${region}&&environment=${environment}`, reqBody);
}

export async function computeAISSDTTL(region: string, environment: string, hlcStamps: IHLCStamp[], aiStamps: IAIStamp[], baseSSDData: IDemandData[]) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local"
    }

    var reqBody = {
        'hlcStamps': hlcStamps,
        'aiStamps': aiStamps,
        'baseTTLSSDDemand': baseSSDData
    };

    return await postJson<IDemandData[]>(`costsaving/ai_ssd_ttl?region=${region}&&environment=${environment}`, reqBody);
}

export async function fetchThreshold(region: string, environment: string) {
    if (region === "") {
        region = "BRA";
    }
    if (environment === "") {
        environment = "Go Local";
    }

    return await getJson<number>(`costsaving/reg_env_threshold?region=${region}&&environment=${environment}`);
}


export async function downloadStampSummary(summaryStamps: ISummaryStamp[]) {

    var reqBody = {
        'summaryStamps': summaryStamps
    };

    return await post(`costsaving/ExportCSPSummary`, reqBody);
}


export async function checkIsColdRegEnv(geocode: string, environment: string) {

    return await getJson<boolean>(`costsaving/is_cold_reg_env?geocode=${geocode}&&environment=${environment}`);
}



export async function fetchAllRegStatus() {

    return await getJson<RegionEnvironmentStatus[]>(`costsaving/all_reg_status`);
}
