import * as React from "react";
import img from "../../pics/m2cp_home_page_figure.png";
import PageHeader from '../../common/pageheader/PageHeader';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import { useBoolean, useId } from '@fluentui/react-hooks';


export const Home: React.FC = () => {
    const buttonId = useId('targetButton');
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(true);

    return (
        <div >
            <div id={buttonId}></div>
                <PageHeader
                    title="Welcome to Capacity Portal"
                    description="Multi-metric capacity planning (M2CP) of Substrate backend provides tools and reports for End-to-End capacity planning based on the holistic views of its computes' (CPU/IOPS/HDD/SSD/Memory) supply and demand signals."
            />

            { DEPLOYMENT_ENV == 'prod' && teachingBubbleVisible && (
                <TeachingBubble
                    calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                    target={`#${buttonId}`}
                    isWide={true}
                    hasCloseButton={true}
                    closeButtonAriaLabel="Close"
                    onDismiss={toggleTeachingBubbleVisible}
                    headline="Capacity portal is available"
                >
                    Please note that aka.ms/tesseract/m2cp is pointing to Capacity portal. For M2CP TTL powerBI report, please check the Multi-Metrics Report under Time-to-Live (TTL) section.
                </TeachingBubble>
            )}

            <div style={{ textAlign: "center", marginTop: "50px" }}>
                <img src={img} height={"90%"} width={"80%"} alt={"m2cp flow"} aria-label={"m2cpflow"} aria-labelledby={"m2cpflow"} role={"presentation"} title={"m2cp flow"} />
            </div>
        </div>
    );
}