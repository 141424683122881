import * as React from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

interface IStatusFilterProp {
    onStatusFilter: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void,
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
};

const options: IDropdownOption[] = [
    { key: 'Approved', text: 'Approved' },
    { key: 'Sent To Tesseract', text: 'Sent To Tesseract' },
    { key: 'Rejected', text: 'Rejected' },
    { key: 'Pending', text: 'Pending' }
];

const stackTokens: IStackTokens = { childrenGap: 20 };

export const StatusFilter: React.FunctionComponent<IStatusFilterProp> = (props) => {

    return (
        <Stack tokens={stackTokens}>
            <Dropdown
                placeholder="Select options"
                label="Select Status"
                defaultSelectedKeys={['Approved','Rejected','Pending','Sent To Tesseract']}
                multiSelect
                options={options}
                styles={dropdownStyles}
                onChange={props.onStatusFilter}
            />
        </Stack>
    );
};
