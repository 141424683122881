import * as React from "react"
import { ActionTypes } from "../../../actions/ActionTypes"
import { ICakeData, cakeReducer, makeBuyCakeAction } from "../../../reducers/cakeReducerExample"
import { useDispatch, useSelector } from "react-redux"
import { IAppState } from "../../../store"
import { IBuyCakeAction } from "../../../actions/CakeActionsExample"

export const CakeExample: React.FC = () => {
    const cakes = useSelector<IAppState, ICakeData>(state => state.cakes)
    const dispatch = useDispatch()
    const {
        numberOfCakes
    } = cakes

    const buyCakes = () => {
        dispatch(makeBuyCakeAction())
    }

    return (
        <div>
            The shop has {numberOfCakes} cakes total
            <button onClick={buyCakes} > customer buy cakes </button>
        </div>
        )
}