import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { IAppState } from "../store";

import { Home } from "./pages/home/Home";
import { M2CPReport } from "./pages/businssinsightsreport/m2cp";
import { PrivateReport } from "./pages/businssinsightsreport/PrivateReports";
import { PCMReport } from "./pages/businssinsightsreport/pcm";
import { CloudCacheReport } from "./pages/businssinsightsreport/cloudcache";
import { ThresholdConfig } from "./pages/alerting/ThresholdConfig";
import { AlertingReport } from "./pages/alerting/AlertingReport";
import { BufferGeneration } from "./pages/alerting/BufferGeneration";
import { StampLeadTimeReport } from "./pages/businssinsightsreport/stampleadtime";
import { DeraHistoryReport} from "./pages/businssinsightsreport/dera"
import PageLayout from "./common/layout/PageLayout"
import { CakeExample } from "./pages/example/CakeTest"
import { HLCReport } from "./pages/businssinsightsreport/hlc"
import { CopsTesting } from "./pages/example/CopsTesting"
import { AllocationOverridePage } from "./pages/allocation/AllocationOverride/AllocationOverridePage";
import { AllocationOverviewPage } from "./pages/allocation/AllocationOverview/AllocationOverviewPage";
import { AllocationSimulationPage } from "./pages/allocation/AllocationSimulation/AllocationSimulationPage";
import { DemandPlanSimulationPage } from "./pages/demandplan/DemandPlanSimulationPage";
import { DemandPlanGenerationPage } from "./pages/demandplan/DemandPlanGenerationPage";
import { CapacityPlanningReport } from "./pages/businssinsightsreport/cpreport";
import { DCSelectionOverviewPage } from "./pages/dcselection/DCSelectionOverview/DCSelectionOverviewPage"
import { DCSelectionSimulationPage } from "./pages/dcselection/DCSelectionSimulation/DCSelectionSimulationPage";
import { DCSelectionComparisonPage } from "./pages/dcselection/DCSelectionComparison/DCSelectionComparisonPage";
import { ForecastAccuracyReport } from "./pages/businssinsightsreport/forecastaccuracy";
import { COGSCalculatorRequestsPage } from "./pages/pcm/COGSCalculatorRequests/COGSCalculatorRequestsPage"
import { CapacityPlanGeneratorScenarioPage } from "./pages/demandplan/CapacityPlanGeneratorScenarioPage";
import { CapacityPlanScenarioPage } from "./pages/demandplan/CapacityPlanScenarioPage";
import { CostSavingOverviewPage } from "./pages/costsaving/CostSavingOverviewPage";
import { CapacityPlanGeneratorScenarioIndex } from "./pages/demandplan/CapacityPlanGeneratorScenarioIndex";
import { CapacitySellableEditor } from "./pages/demandplan/input/CapacitySellableEditor";
import { CapacitySellableIndexPage } from "./pages/demandplan/input/CapacitySellableIndexPage";
import { CapacityGenerationParameterIndexPage } from "./pages/demandplan/input/CapacityGenerationParameterIndexPage";
import { HardwareRoadmapIndexPage } from "./pages/demandplan/input/HardwareRoadmapIndexPage";
import { EfficiencyUploadPage } from "../components/pages/pcm/EfficiencyTracker/EfficiencyUploadPage";
import { GoLocalsReport } from "./pages/businssinsightsreport/golocals";

const App: React.FC = () => {  
    return (
        <Switch>
            <Route exact path="/Home">
                <PageLayout>
                    <Home />
                </PageLayout>
            </Route>
            <Route exact path="/BusinessInsights/MultiMetrics">
                <PageLayout>
                    <M2CPReport />
                </PageLayout>
            </Route>
            <Route exact path="/BusinessInsights/PrivateReport">
                <PageLayout>
                    <PrivateReport />
                </PageLayout>
            </Route>
            <Route exact path="/BusinessInsights/CapacityPlanningReport">
                <PageLayout>
                    <CapacityPlanningReport />
                </PageLayout>
            </Route>
            <Route exact path="/BusinessInsights/DeraHistoryReport">
                <PageLayout>
                    <DeraHistoryReport />
                </PageLayout>
            </Route>
            <Route exact path="/BusinessInsights/HLCReport">
                <PageLayout>
                    <HLCReport />
                </ PageLayout>
            </ Route>
            <Route exact path="/BusinessInsights/PCMReport">
                <PageLayout>
                    <PCMReport />
                </ PageLayout>
            </ Route>
            <Route exact path="/BusinessInsights/GoLocalsReport">
                <PageLayout>
                    <GoLocalsReport />
                </ PageLayout>
            </ Route>
            <Route exact path="/BusinessInsights/CloudCacheReport">
                <PageLayout>
                    <CloudCacheReport />
                </ PageLayout>
            </ Route>
            <Route exact path="/BusinessInsights/DemandForecastAccuracyReport">
                <PageLayout>
                    <ForecastAccuracyReport />
                </ PageLayout>
            </ Route>
            <Route exact path="/BusinessInsights/LeadTimeReport">
                <PageLayout>
                    <StampLeadTimeReport />
                </ PageLayout>
            </ Route>
            <Route exact path="/Alerting/Report">
                <PageLayout>
                    <AlertingReport />
                </PageLayout>
            </Route>
            <Route exact path="/Alerting/Config">
                <PageLayout>
                    <ThresholdConfig />
                </PageLayout>
            </Route>
            <Route exact path="/Alerting/BufferGeneration">
                <PageLayout>
                    <BufferGeneration />
                </PageLayout>
            </Route>
            <Route exact path="/Test">
                <PageLayout>
                    <CakeExample />
                </ PageLayout>
            </ Route>
            <Route exact path="/cops">
                <PageLayout>
                    <CopsTesting />
                </ PageLayout>
            </ Route>
            {/*           <Route exact path="/demo">
                <PageLayout selectedItem=''>
                    <PowerBiComp />
                </ PageLayout>
            </ Route>*/}
            <Route exact path="/Allocation/Override">
                <PageLayout>
                    <AllocationOverridePage />
                </PageLayout>
            </Route>
            <Route exact path='/Allocation/Overview'>
                <PageLayout>
                    <AllocationOverviewPage />
                </PageLayout>
            </Route>
            <Route exact path='/Allocation/Simulate'>
                <PageLayout>
                    <AllocationSimulationPage />
                </PageLayout>
            </Route>
            <Route exact path='/DCSelection/Overview'>
                <PageLayout>
                    <DCSelectionOverviewPage />
                </PageLayout>
            </Route>
            <Route exact path='/DCSelection/Simulation'>
                <PageLayout active='/DCSelection/Simulation'>
                    <DCSelectionSimulationPage />
                </PageLayout>
            </Route>
            <Route exact path='/DCSelection/Comparison'>
                <PageLayout active='/DCSelection/Comparison'>
                    <DCSelectionComparisonPage />
                </PageLayout>
            </Route>
            <Route exact path='/InorganicDemand/AppsOnboardingRequest'>
                <PageLayout>
                    <COGSCalculatorRequestsPage />
                </PageLayout>
            </Route>
            <Route exact path='/InorganicSignal/EfficiencyTracker'>
                <PageLayout>
                    <EfficiencyUploadPage />
                </PageLayout>
            </Route>
            <Route exact path="/CostSaving/Overview">
                <PageLayout>
                    <CostSavingOverviewPage />
                </ PageLayout>
            </ Route>
            <Route exact path="/DemandPlan/Simulate">
                <PageLayout>
                    <DemandPlanSimulationPage />
                </ PageLayout>
            </ Route>
            <Route exact path="/DemandPlan/Generate">
                <PageLayout>
                    <DemandPlanGenerationPage />
                </ PageLayout>
            </ Route>
            <Route exact path="/DemandPlan/Scenario/Index">
                <PageLayout>
                    <CapacityPlanGeneratorScenarioIndex />
                </ PageLayout>
            </ Route>
            <Route exact path="/DemandPlan/Scenario/input/sellables">
                <PageLayout>
                    <CapacitySellableIndexPage />
                </ PageLayout>
            </ Route>
            <Route exact path="/DemandPlan/Scenario/input/parameters">
                <PageLayout>
                    <CapacityGenerationParameterIndexPage />
                </ PageLayout>
            </ Route>
            <Route exact path="/DemandPlan/Scenario/input/hardwareRoadmap">
                <PageLayout>
                    <HardwareRoadmapIndexPage />
                </ PageLayout>
            </ Route>
            <Route exact path="/DemandPlan/Scenario">
                <PageLayout>
                    <CapacityPlanGeneratorScenarioPage />
                </ PageLayout>
            </ Route>
            <Route exact path="/">
                <Redirect to='/Home' />
            </Route>
        </Switch>
    );
}

export default App;