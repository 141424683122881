export interface ISelectionPlan {
    id?: string,
    stampName?: string,
    region?: string,
    capacityGeoCode?: string,
    reservationStatus?: string,
    fiscalYear?: number,
    planIntent?: string,
    recommendation: IRecommendation[],
    dagCnt?: number,
    rackCnt?: number,
    serverCnt?: number,
    sku?: string,
    jobType?: "Model" | "WhatIf"
    dcCostSnapshotDate?: string
}

export interface IRecommendation {
    priority: number,
    hosting: number,
    networking: number,
    dcs: IDCInformation[],
}

export interface IDCInformation {
    name: string,
    AZ: string,
    AR: string,
    hosting: number,
    requiredKW: number
}

export var EmptyISelectionPlan: ISelectionPlan = {
    stampName: "",
    region: "",
    capacityGeoCode: "",
    reservationStatus: "",
    fiscalYear: 2000,
    planIntent: "",
    recommendation: [],
    dagCnt: 0,
    sku: "",
    jobType: "WhatIf"
};

export interface IStampInfo {
    stampName: string,
    region: string,
    sku: string,
    layout: string,
    dagCount: number,
    sellableTBPerDag: number,
    dcCount: number
};

export var EmptyStampInfo: IStampInfo = {
    stampName: "",
    region: "",
    sku: "",
    layout: "",
    dagCount: 0,
    sellableTBPerDag: 0,
    dcCount: 0
};