import { useMsal } from "@azure/msal-react";
import { DefaultPalette, Icon, IconButton, IIconProps, IStackItemStyles, IStackStyles, IStackTokens, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import React from "react";
import { useId } from "@fluentui/react-hooks";
import styles from "./AppBanner.less";

const stackStyles: IStackStyles = {
    root: {
        backgroundColor: "#484644",
        height: "48px",
        color: DefaultPalette.white,
        padding: "0 24px"
    }
};

const innerStackStyles: IStackStyles = {
    root: {
        alignItems: "center",

    }
}

const innerStackTokens: IStackTokens = { childrenGap: 5 };

const mailIconProps: IIconProps = { iconName: "Mail" };
const openInNewWindowProps: IIconProps = { iconName: "OpenInNewWindow" };

const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: "center",
        color: DefaultPalette.white,
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
    },
};

const AppBanner: React.FC = () => {
    const { instance } = useMsal();
    const feedbackTooltipId = useId("feedback-tooltip");
    const perfCollectorId = useId("perf-collector-tooltip");
    const account = instance.getActiveAccount();
    return (
        <Stack horizontal horizontalAlign="space-between" styles={stackStyles}>
            <Stack.Item styles={stackItemStyles}>
                <Icon iconName="CubeShape" className={styles.logo} />
                <h2><a href="/" style={{ color: "white", textDecoration: "none" }}>{DEPLOYMENT_ENV == 'prod' ? "Capacity Portal" : "Capacity Portal PPE"}</a></h2>
            </Stack.Item>
            <Stack.Item styles={stackItemStyles}>
                <Stack horizontal tokens={innerStackTokens} styles={innerStackStyles}>
                    <TooltipHost
                        content="Send feedback"
                        id={feedbackTooltipId}
                        delay={TooltipDelay.zero}
                    >
                        <IconButton title="Got " className={styles.iconButton} iconProps={mailIconProps} href="mailto:capsensesupport@microsoft.com" />
                    </TooltipHost>
                    {/*                    <TooltipHost
                        content="Perf collector"
                        id={perfCollectorId}
                        delay={TooltipDelay.zero}
                    >
                        <IconButton className={styles.iconButton} iconProps={openInNewWindowProps} href="https://jawsportal.azurewebsites.net/PerfCollector" target="_blank" />
                    </TooltipHost>*/}
                    {account?.username}
                </Stack>
            </Stack.Item>
        </Stack>
    );
};

export default AppBanner;