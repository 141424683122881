// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IPffpCulHraMxGtmUrgl {
  background-color: #C8C8C8;
  margin-bottom: 20px;
  height: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/alerting/RegionTTL.less"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;EACA,WAAA;AACJ","sourcesContent":[".separator {\n    background-color: #C8C8C8;\n    margin-bottom: 20px;\n    height: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": `IPffpCulHraMxGtmUrgl`
};
export default ___CSS_LOADER_EXPORT___;
