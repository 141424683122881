import * as React from 'react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';

interface ICustomerImpactlProps {
    value: string,
    changeValue: (ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption) => void
}

const options: IChoiceGroupOption[] = [
    { key: 'none', text: 'N/A'  },
    { key: 'red', text: 'Red'  },
    { key: 'yellow', text: 'Yellow' },
    { key: 'blue', text: 'Blue' },
];

export const CustomerImpactChoices: React.FunctionComponent<ICustomerImpactlProps> = (props) => {
    return (
        <>
            <ChoiceGroup label="Customer Impact:"
                defaultSelectedKey={props.value}
                options={options}
                onChange={props.changeValue}
                styles={{ flexContainer: { display: "flex", gap: 10 } }} />
        </>
    );
};