import { ActionButton, IButtonStyles, IStackTokens, Stack, Link } from "@fluentui/react";
import * as React from "react";
import { ICOGSCalculatorRequest } from "../../../../models/PCM";
import { round } from 'lodash';

interface ICOGSCalculatorRequestGroupProps {
    request: ICOGSCalculatorRequest,
    edit: (request: ICOGSCalculatorRequest) => void,
    permission: boolean,
    onDelete: (request: ICOGSCalculatorRequest) => void
}

export const COGSCalculatorRequestGroup: React.FC<ICOGSCalculatorRequestGroupProps> = ({request, edit, permission, onDelete}) => {
    const withBorder = {
        marginTop: "1rem",
        borderBottom: "ridge",
        borderColor: "#faf9f8",
    }

    const buttonStyles: IButtonStyles = {
        root : {
            marginTop: 0,
            fontSize: 13,
            width: "100%",
        },
        icon: {
            fontSize: 13
        },
        flexContainer: {
            alignItems: "baseline",
        }
    }

    const onEditClicked = () => {
        edit(request);
    }

    const onDeleteClicked = () => {
        onDelete(request);
    }

    return (
        <div className="ms-Grid-row" style={withBorder}>
            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7" style={{wordWrap: 'break-word'}}>{request.requestId}</div>
                    <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
                        <Link href={"https://o365exchange.visualstudio.com/O365 Core/_workitems/edit/"+request.workItemId} target="_blank">
                            {request.workItemId}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">{round(request.additionalGcycles).toLocaleString()}</div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">{round(request.additionalIOPS).toLocaleString()}</div>
                </div>
            </div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{formatDate(request.tentativeStartDate)}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{request.frequency}</div>
            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4" style={{wordWrap: 'break-word'}}>{request.capacityReviewSubmittedBy}</div>
                    <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">{formatDate(request.capacityReviewSubmittedTime)}</div>
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{color: setColor(request.approveStatus), wordWrap: 'break-word'}}>{request.approveStatus}</div>
                </div>
            </div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        <ActionButton styles={buttonStyles} iconProps={{iconName: "ChevronDown"}} onClick={onEditClicked}>{permission ? 'Edit' : 'Details'}</ActionButton>
                    </div>
                    {permission &&
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <ActionButton styles={buttonStyles} iconProps={{iconName: "Delete"}} onClick={onDeleteClicked}>Delete</ActionButton>
                        </div>
                    }
                </div>
            </div>
        </div>
    )

    function setColor(status: string) {
        switch(status) {
            case 'Approved':
                return "green";
            case 'Sent To Tesseract':
                    return "green";
            case 'Rejected':
                return "red";
            default:
                return "orange";
            }
    }

    function formatDate(date: Date) {
        function pad(s: number) { return (s < 10) ? '0' + s : s; }
        var d = new Date(date)
        return [pad(d.getMonth()+1), pad(d.getDate()), d.getFullYear()].join('/')
    }
};
