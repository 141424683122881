import React from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { fetchPlanIntent, fetchCapacityGeoCode, fetchDCSelectionPlans, fetchTotalNumPage, fetchFiscalYear } from '../../../../../services/dcSelectionService'
import { forEach } from 'lodash';
interface IFilter {
    defaultOptions: string[],
    onFilter: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void,
}
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
};

const stackTokens: IStackTokens = { childrenGap: 20 };

export const CapacityGeoCodeFilter: React.FunctionComponent<IFilter> = (props) => {
    React.useEffect(() => {
        getCapacityGeoCode();
    }, [])
    const [capacityGeoCode, setCapacityGeoCode] = React.useState<IDropdownOption[]>([]);

    const getCapacityGeoCode = () => {
        fetchCapacityGeoCode().then(response => {
            const capacityGeoCode = response.map(item => {return {"key": item, "text": item}});
            setCapacityGeoCode(capacityGeoCode);
        })
    }
    return (
        <Stack tokens={stackTokens}>
            <Dropdown 
                placeholder='select capacity Geo Code' 
                label='Capacity Geo Code'
                defaultSelectedKeys={props.defaultOptions}
                multiSelect
                options={capacityGeoCode}
                styles={dropdownStyles}
                onChange={props.onFilter}
            />
        </Stack>
    )
}

// const ReservationOptions: IDropdownOption[] = [
//     { key: 'Reservation Success', text: 'Success' },
//     { key: 'Reservation Not Started', text: 'Not Started' },
//     { key: 'Reservation Wait', text: 'Wait' },
//     { key: 'Reservation Failed', text: 'Failed' },
//     { key: 'N/A', text: 'N/A' }
// ];

// export const ReservationStatusFilter: React.FunctionComponent<IFilter> = (props) => {
//     return (
//         <Stack tokens={stackTokens}>
//             <Dropdown 
//                 placeholder='select reservation status' 
//                 label='Reservation Status'
//                 defaultSelectedKeys={props.defaultOptions}
//                 multiSelect
//                 options={ReservationOptions}
//                 styles={dropdownStyles}
//                 onChange={props.onFilter}
//             />
//         </Stack>

//     )
// }

export const FiscalYearFilter: React.FunctionComponent<IFilter> = (props) => {
    React.useEffect(() => {
        getFiscalYear();
    }, [])
    
    const [FiscalYear, setFiscalYear] = React.useState<IDropdownOption[]>([]);

    const getFiscalYear = () => {
        fetchFiscalYear().then(response => {
            const fiscalYear = response.map(item => {return {"key": item, "text": item}});
            setFiscalYear(fiscalYear);
        });
    }

    return (
        <Stack tokens={stackTokens}>
            <Dropdown 
                placeholder='select fiscal year' 
                label='Fiscal Year'
                defaultSelectedKeys={props.defaultOptions}
                multiSelect
                options={FiscalYear}
                styles={dropdownStyles}
                onChange={props.onFilter}
            />
        </Stack>
    )
}

export const PlanIntentFilter: React.FunctionComponent<IFilter> = (props) => {
    React.useEffect(() => {
        getPlanIntent();
    }, [])
    const [PlanIntent, setPlanIntent] = React.useState<IDropdownOption[]>([]);

    const getPlanIntent = () => {
        fetchPlanIntent().then(response => {
            const planIntent = response.map(item => {return {"key": item, "text": item}});
            setPlanIntent(planIntent);
        });
    }
    return (
        <Stack tokens={stackTokens}>
            <Dropdown 
                placeholder='select plan intent' 
                label='Plan Intent'
                defaultSelectedKeys={props.defaultOptions}
                multiSelect
                options={PlanIntent}
                styles={dropdownStyles}
                onChange={props.onFilter}
            />
        </Stack>
    )
}

// const JobType: IDropdownOption[] = [
//     { key: 'Updated', text: 'Model' },
//     { key: 'WhatIf', text: 'WhatIf' }
// ];

// export const JobTypeFilter: React.FunctionComponent<IFilter> = (props) => {
//     return (
//         <Stack tokens={stackTokens}>
//             <Dropdown 
//                 placeholder='select job type' 
//                 label='Job Type'
//                 defaultSelectedKeys={props.defaultOptions}
//                 multiSelect
//                 options={JobType}
//                 styles={dropdownStyles}
//                 onChange={props.onFilter}
//             />
//         </Stack>
//     )
// }