import * as React from "react"

import {
    TextField,
    Spinner,
    SpinnerSize,
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    PrimaryButton
} from '@fluentui/react';


//import { IAllocationPlan } from "../../../../models/AllocationPlan";
import {fetchAIRecStamps, fetchCPUDemandData, fetchEnvironments, fetchForests, fetchHDDDemandData, fetchHLCStamps, fetchIOPSDemandData, fetchRanges, fetchRegions, fetchSSDDemandData, fetchThreshold, fetchThresholds } from "../../../services/costsavingservice";
import PageHeader from '../../common/pageheader/PageHeader';
import { IAIStamp, IDemandData, IFutureSupply, IHLCStamp } from "../../../models/CostSaving";
import { useLocation } from 'react-router-dom'
import { TTLDeltasChart } from "./TTLDeltasChart";
import { AITimeline } from "./timeline/AITimeline";
import { SimulationTimeline } from "./timeline/SimulationTimeline";
import { Dictionary } from "lodash";

const monthNumberToName: Dictionary<string> = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
}

export const CostSavingOverviewPage: React.FC = () => {
    const location = useLocation()
    const { state } = location
    const [endRange, setEndRange] = React.useState<string>("36");

    function getMetricsDemandData() {
        var tempCPUDemandData = 36;
        var tempIOPSDemandData = 36;
        var tempHDDDemandData = 36;
        var tempSSDDemandData = 36;

        fetchCPUDemandData(region, environment).then(
            response1 => {
                console.log("cpu from overview", response1);
                tempCPUDemandData = response1.length;
                fetchIOPSDemandData(region, environment).then(
                    response2 => {
                        console.log("iops from overview", response2);
                        tempIOPSDemandData = response2.length;
                        fetchHDDDemandData(region, environment).then(
                            response3 => {
                                console.log("hdd from overview", response3);
                                tempHDDDemandData = response3.length;
                                fetchSSDDemandData(region, environment).then(
                                    response4 => {
                                        console.log("ssd from overview", response4);
                                        tempSSDDemandData = response4.length;
                                        setEndRange(Math.min(tempCPUDemandData, tempIOPSDemandData, tempHDDDemandData, tempSSDDemandData).toString());
                                    }
                                );
                            }
                        );
                    }
                );
            },
            ex => {
                console.log("Error fetching demand data");
                setEndRange("36");
            }
        );
    }

    React.useEffect(() => {
        getThreshold();
        getHLCStamps();
        getEnvironments();
        getRegions();
        getForests();
        getAIRecStamps();
        getRanges();
        getMetricsDemandData()
    }, [])

    const [paramsError, setParamsError] = React.useState<string>("");
    
    const [environment, setEnvironment] = React.useState<string>(state ? state.frsEnv : "");
    const [environments, setEnvironments] = React.useState<string[]>([]);

    const [region, setRegion] = React.useState<string>(state ? state.frsRegion : "");
    const [regions, setRegions] = React.useState<string[]>([]);

    const [forest, setForest] = React.useState<string>("");
    const [forests, setForests] = React.useState<string[]>([]);

    const [deselectedStamps, setDeselectedStamps] = React.useState<IHLCStamp[]>([]);
    let [selectedBools, setSelectedBools] = React.useState<boolean[]>([]);
    let [hlcStamps, setHlcStamps] = React.useState<IHLCStamp[]>([]);
    let [aiStamps, setAIStamps] = React.useState<IAIStamp[]>([]);

    const [threshold, setThreshold] = React.useState<string>("10");

    const [startRange, setStartRange] = React.useState<string>("0");
    const [startRanges, setStartRanges] = React.useState<string[]>([]);

    
    const [endRanges, setEndRanges] = React.useState<string[]>([]);

    const title = `Cost Saving - ${region} / ${environment}`;

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: "100%" },
    };

    // helper functions
    const asOptionsStartRange = (options: string[]) => {
        if (!options || options.length == 0) {
            return []
        }

        return options.filter(e => (+e < +endRange)).map(e => ({ key: e, text: monthFormat(+e) }))
    }

    const asOptionsEndRange = (options: string[]) => {
        if (!options || options.length == 0) {
            return []
        }
        return options.filter(e => (+e > +startRange)).map(e => ({ key: e, text: monthFormat(+e) }))
    }

    // Parameter input functions
    const _onEnvironmentSelected = (event: React.FormEvent, option?: IDropdownOption) => {
        setEnvironment(option?.key as string || "");
    }

    const _onRegionSelected = (event: React.FormEvent, option?: IDropdownOption) => {
        setRegion(option?.key as string || "");
    }

    const _onForestSelected = (event: React.FormEvent, option?: IDropdownOption) => {
        setForest(option?.key as string || "");
    }

    const _onStartRangeSelected = (event: React.FormEvent, option?: IDropdownOption) => {
        setStartRange(option?.key as string || "");
    }

    const _onEndRangeSelected = (event: React.FormEvent, option?: IDropdownOption) => {
        setEndRange(option?.key as string || "");
    }

    // get functions
    // should only get the selected envoironments from health page
    function getEnvironments() {
        console.log(fetchEnvironments());
        setEnvironments(fetchEnvironments());
        /*fetchEnvironments().then(response => {
            console.log(response);
            setEnvironments(response);
        })*/
    }
    // should only get the selected regions from health page
    function getRegions() {
        console.log(fetchRegions());
        setRegions(fetchRegions());
        /*fetchRegions().then(response => {
            console.log(response);
            setRegions(response);
        })*/
    }
    // should only get the selected forests from health page
    function getForests() {
        console.log(fetchForests());
        setForests(fetchForests());
        /*fetchForests().then(response => {
            console.log(response);
            setForests(response);
        })*/
    }

    function getRanges() {
        console.log(fetchRanges());
        //setRanges(fetchRanges());
        setStartRanges(fetchRanges());
        setEndRanges(fetchRanges());
    }

    function getThreshold() {
        fetchThreshold(region, environment).then(
            response => {
                console.log("fetching reg env thresh: ", response);
                setThreshold(response.toString());
            },
            ex => {
                console.log("Failed to get threshold");
            }
        )
    }

    function compareDates(date1: Date, date2: Date) {
        if (date1 < date2) {
            return -1;
        } else if (date1 > date2) {
            return 1;
        }
        return 0;
    }

    function createSelectedBoolsList(response: IHLCStamp[]) {
        var boolList = [];
        for (var i = 0; i < response.length; i++) {
            boolList.push(true);
        }
        console.log("Created bool list: ", boolList);
        setSelectedBools(boolList);
    }

    function getHLCStamps() {
        fetchHLCStamps(region, environment).then(
            response => {
                response.sort((d1, d2) => { return compareDates(d1.goliveETA, d2.goliveETA) });
                console.log("hlc", response);
                setHlcStamps(response);
                createSelectedBoolsList(response);
            },
            ex => {
                console.log("Failed to get HLC stamps");
            })
    }

    function getAIRecStamps() {
        fetchAIRecStamps(region, environment).then(
            response => {
                response.sort((d1, d2) => { return compareDates(d1.recommended_date, d2.recommended_date) });
                console.log("ai", response);
                setAIStamps(response);
            },
            ex => {
                console.log("Failed to get AI stamps");
            }
        )
    }

    function formatDate(date: Date) {
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        var monthString = monthNumberToName[month];

        return monthString + " " + year.toString();
    }

    function monthFormat(monthCount: number) {
        var newStartDate: Date = new Date();
        newStartDate.setDate(1);
        newStartDate.setMonth(newStartDate.getMonth() + monthCount);
        //console.log("New start date: ", newStartDate);
        return formatDate(newStartDate);
    }
 

    return (
        <div>
            <PageHeader
                title={title}
                description="You can examine and modify the cost-saving decision process for Substrate backend"
            />

            <div className="ms-Grid" style={{ margin: "" }} dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                        <Dropdown
                            disabled
                            label="TTL Threshold"
                            selectedKey={threshold}
                            placeholder={threshold}
                            styles={dropdownStyles}
                            options={[]} />
                    </div>
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                        <Dropdown
                            label="Start Month"
                            selectedKey={monthFormat(+startRange)}
                            placeholder={monthFormat(+startRange)}
                            options={asOptionsStartRange(startRanges)}
                            styles={dropdownStyles}
                            onChange={_onStartRangeSelected}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                        <Dropdown
                            label="End Month"
                            selectedKey={monthFormat(+endRange)}
                            placeholder={monthFormat(+endRange)}
                            options={asOptionsEndRange(endRanges)}
                            styles={dropdownStyles}
                            onChange={_onEndRangeSelected}
                        />
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />

            <h2>AI recommendation with new Go Live ETA stamp dates</h2>
            <br />
            <AITimeline region={region} environment={environment} aiStamps={aiStamps} hlcStamps={hlcStamps} startRange={+startRange} endRange={+endRange} threshold={+threshold} />

            <br />
            <br />
            <br />
            <br />

            <h2>Current HLC GoLiveETA User Simulation</h2>
            <br />
            <SimulationTimeline region={region} environment={environment} startRange={+startRange} endRange={+endRange} hlcStamps={hlcStamps} deselectedStamps={deselectedStamps} selectedBools={selectedBools}/>
            
            <br />
            <br />
            <br />
            <br />
            
            <TTLDeltasChart environment={environment} region={region} deselectedStamps={deselectedStamps} threshold={+threshold} startRange={+startRange} endRange={+endRange} />
        </div>
    );
};