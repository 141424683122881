import * as React from "react";
import PageHeader from '../../common/pageheader/PageHeader';
import {
    MessageBar,
    MessageBarType,
    Link
} from '@fluentui/react';

export const PCMReport: React.FC = (props) => {
    return (
        <div>
            <PageHeader
                title="PCM"
                description="The reports contains usage trend and growth rate at service and app level."
            />
            <MessageBar>
                Please go to
                <Link href="https://aka.ms/tesseract/m2cp/pcm" inline>
                    aka.ms/tesseract/m2cp/pcm
                </Link>{' '}
                to check original report
            </MessageBar>
            <iframe title="M2CP"
                style={{ width: '100%', height: '850px', marginTop: '20px' }}
                src="https://msit.powerbi.com/reportEmbed?reportId=41bd9dfe-f86f-49d4-8412-8672ff779bb0&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47"
                frameBorder={0}
                allowFullScreen={true}></iframe>
        </div>

    )
}