import * as React from "react";
import ReactEcharts from "echarts-for-react"; 
import { IRecommendation } from "../../../../models/DCselectionPlan";


interface IGraphCostOnTopNProps {
    title?: string,
    stamp: IRecommendation[],
    getSpecificInfo: (name: string) => void,
}


const titleStyle = {
    fontFamily: 'Helvetica',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 'normal',
}


const createGraphOption = (title: string, plans: IRecommendation[]) => {
    const hostingCost = plans.map(plan => plan.hosting / 1000);
    const networkingCost = plans.map(plan => plan.networking / 1000);
    const topN = plans.map(plan => plan.priority);
    const option = {
        title: {
            text: title,
            textStyle: titleStyle
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            inverse: true,
            // data: forests.filter(forest=>!forest.toLowerCase().includes("sparecapacity"))
            data: topN
        },
        series: [
        {
            name: 'Hosting Cost',
            type: 'bar',
            stack: 'total',
            label: {
                show: true
            },
            emphasis: {
                focus: 'series'
            },
            // data: capacityToTtl(extras).map(roundTwo)
            data: hostingCost.map(roundTwo)
        },
        {
            name: 'Networking Cost',
            type: 'bar',
            stack: 'total',
            label: {
                show: true 
            },
            emphasis: {
                focus: 'series'
            },
            // data: capacityToTtl(forestNewCapacity).map(roundTwo)
            data: networkingCost.map(roundTwo)
        },
        ]
    }

    return option
}

function roundTwo(x: number) {
    return Math.round(x*100) / 100;
}

export const GraphCostOnTopN: React.FC<IGraphCostOnTopNProps> = ({title, stamp, getSpecificInfo}) => {

    const onclick = {
        'click': (param: any) => {getSpecificInfo(param.name)}
    }
    return (
        <div>
            <ReactEcharts option={createGraphOption(title||"", stamp)} onEvents={onclick}/>
        </div>
    )
}