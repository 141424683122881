import React from "react";
import { DefaultButton, IButtonStyles, IconButton, IStackTokens, Label, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { ISelectionPlan } from "../../../../models/DCselectionPlan";
import styles from "../../../common/pageheader/PageHeader.less"
import { GraphCostOnTopN } from "./GraphCostOnTopN";
import { DCSelectionPlanDetailGroup, DCSelectionPlanDCDetailGroup } from "./DCSelectionPlanDetailGroup"

interface IDCSelectionPlanDetailedPageProps {
    stamp: ISelectionPlan,
    onBack?: () => void
}
export const DCSelectionPlanDetailedPage: React.FC<IDCSelectionPlanDetailedPageProps> = ({stamp, onBack}) => {
    const paraStyle = {
        marginTop: "2rem",
    }

    const [selectedItem, setSelectedItem] = React.useState<string>("");
    
    const selectOneItem = (name: string) => {
        setSelectedItem(name)
    }
    const backAllInfo = () => {
        setSelectedItem("")
    }
    const itemIndex: number = +selectedItem

    return (
        <div className="ms-Fabric ms-font-su" style={{margin: ""}}>
            <div className="ms-font-su ms-fontColor-themePrimary">Stamp: {stamp.stampName}</div>
            <div className={styles.separator}></div>
            <div className="ms-Grid-row" style={paraStyle} >
                <div className="ms-Grid-col ms-sm12 ms-md9 ms-lg4">
                    <Stack horizontal tokens={{childrenGap: 20}}>
                        <DefaultButton style={{width: "7rem"}} text="Go Back" onClick={onBack}/> 
                    </Stack>
                </div>
            </div>
            <div style={{marginTop: "50px"}}></div>
            <div className="ms-Grid-row" dir="ltr" style={paraStyle}>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                    <h1>Top N Total Cost</h1>
                    <GraphCostOnTopN title="" stamp={stamp.recommendation} getSpecificInfo={selectOneItem} />
                </div>

                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"></div>
                <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7">
                    {
                        !selectedItem ?
                        <>
                            <h1>All recommended results</h1>
                            <div className="ms-Grid ms-font-s" dir="ltr">
                            <div className="ms-Grid-row" style={{fontWeight: "bold", padding: "0.8rem 0.2rem", textAlign: "center"}}>
                                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Priority</div>
                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">DCs</div>
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">Hosting ($/yr)</div>
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">Networking ($/yr)</div>
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">Total Cost ($/yr)</div>
                                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">RequiredKW</div>
                            </div>
                            {
                                stamp.recommendation.map((re, index) => {
                                    return <DCSelectionPlanDetailGroup key={index} plan={re}/>
                                })
                            }
                            </div>
                        </>
                    :
                        <>
                            <Stack horizontal horizontalAlign="space-between">
                                <Stack.Item align="start">
                                    <h1>Priority {selectedItem} </h1>
                                </Stack.Item>
                                <Stack.Item align="center">
                                    <PrimaryButton onClick={backAllInfo}>Show All</PrimaryButton>
                                </Stack.Item>
                            </Stack>
                            <div className="ms-Grid ms-font-s" dir="ltr">
                            <div className="ms-Grid-row" style={{fontWeight: "bold", padding: "0.8rem 0.2rem", textAlign: "center"}}>
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">DCs</div>
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">AZ</div>
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">AR</div>
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">Hosting ($/yr)</div>
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">RequiredKW</div>
                            </div>
                            {
                                stamp.recommendation[itemIndex-1].dcs.map((dc, index) => {
                                    return <DCSelectionPlanDCDetailGroup key={index} dcInfo={dc}/>
                                })
                            }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )

}