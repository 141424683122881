/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpMethods } from "./HttpConstants";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalInstance as defaultMsalInstance } from "./msalInstance";
import { loginRequest } from "../authConfig";
import axios from "axios";

export class ApiServiceBase {
    private readonly baseUrl: string;

    private readonly msalInstance: PublicClientApplication;

    constructor(baseUrl: string, msalInstance?: PublicClientApplication) {
        this.baseUrl = baseUrl;
        this.msalInstance = msalInstance ?? defaultMsalInstance;
    }

    private async getAuthHeader(additionalHeaders? : Map<string, string>) {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await this.msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });

        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json; charset=utf-8");
        additionalHeaders?.forEach((v, k) => headers.append(k, v));
        return headers;
    }

    /*    public async getAccessToken() {
            const account = this.msalInstance.getActiveAccount();
            if (!account) {
                throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            }
    
            const response = await this.msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account
            });
            return response.accessToken;
        }*/

    public async get(url: string) : Promise<Response> {
        const options: RequestInit = {
            method: HttpMethods.GET,
            headers: await this.getAuthHeader(),
        };
        const response = await fetch(`${this.baseUrl}/${url}`, options);
        return response;
    }

    public async post(url: string, body: any, additionalHeaders? : Map<string, string>): Promise<Response> {
        const options: RequestInit = {
            method: HttpMethods.POST,
            headers: await this.getAuthHeader(additionalHeaders),
            body: JSON.stringify(body)
        };
        const response = await fetch(`${this.baseUrl}/${url}`, options);
        return response;
    }

    public async getJson<T>(url: string): Promise<T> {
        const response = await this.get(url);
        return response.json();
    }

    public async delete(url: string): Promise<Response> {
        const requestOption: RequestInit = {
            method: HttpMethods.DELETE,
            headers: await this.getAuthHeader(),
        }

        const response = await fetch(`${this.baseUrl}/${url}`, requestOption);
        return response;
    }

    public async postJson<T>(url: string, body: any, additionalHeaders? : Map<string, string>): Promise<T> {
        const response = await this.post(url, body, additionalHeaders);
        return response.json();
    }

    public async postMethod<T>(url: string, withbody: T): Promise<Response> {
        const options: RequestInit = {
            method: HttpMethods.POST,
            headers: await this.getAuthHeader(),
            body: JSON.stringify(withbody)
        }

        const response = await fetch(`${this.baseUrl}/${url}`, options);
        return response;
    }

    public async postForm(url: string, body: FormData, additionalHeaders? : Map<string, string>): Promise<Response> {
        const headers = await this.getAuthHeader(additionalHeaders);
        return axios.post(`${this.baseUrl}/${url}`, body, {headers: {'Authorization': headers.get('Authorization')!}})
    }
}

export const defaultApiServiceBase = new ApiServiceBase(SERVICE_ENDPOINT); // TODO: make the base URL configurable

export function get(url: string): Promise<Response> {
    return defaultApiServiceBase.get(url);
}

export function getJson<T>(url: string): Promise<T> {
    return defaultApiServiceBase.getJson(url);
}

export function post(url: string, body: any): Promise<Response> {
    return defaultApiServiceBase.post(url, body);
}

export function postJson<T>(url: string, body: any): Promise<T> {
    return defaultApiServiceBase.postJson(url, body);
}

export function deleteItem(url: string): Promise<Response> {
    return defaultApiServiceBase.delete(url);
}

export function postMethod<T>(url: string, withbody: T): Promise<Response> {
    return defaultApiServiceBase.postMethod(url, withbody);
}

export function postForm(url: string, body: FormData): Promise<Response> {
    return defaultApiServiceBase.postForm(url, body);
}
