import React from 'react'
import {
    TextField,
    Spinner,
    SpinnerSize,
} from '@fluentui/react';
import PageHeader from '../../../common/pageheader/PageHeader';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { useBoolean } from '@fluentui/react-hooks';
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";

import { fetchCOGSCalculatorRequests, updateCOGSCalculatorRequest, deleteCOGSCalculatorRequest } from '../../../../services/pcmService';
import { ICOGSCalculatorRequest } from '../../../../models/PCM';
import { COGSCalculatorRequestGroup } from './COGSCalculatorRequestGroup';
import { StatusFilter } from './StatusFilter';
import { RequestEditModal } from './RequestEditModal';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { checkUserPermission } from "../../../../utils/utils"
import { IAppState } from "../../../../store";
import { IUserPermissionData } from "../../../../reducers/userPermissionReducer";
import { ToolType } from "../../../../utils/ToolConstants"

export const COGSCalculatorRequestsPage: React.FC = () => {
    
    React.useEffect(() => {
        getCOGSCalculatorRequests();
    }, [])

    const { instance } = useMsal();
    const { userpermissions } = useSelector<IAppState, IUserPermissionData>(state => state.userPermissions);
    const [keyword, setKeyword] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [isModalOpen, { setTrue: openModal, setFalse: hideModal }] = useBoolean(false);
    const [isDeleteModalOpen, { setTrue: openDeleteModal, setFalse: hideDeleteModal }] = useBoolean(false);
    const [selectedItem, setSelectedItem] = React.useState<ICOGSCalculatorRequest>();
    const [selectedDeleteItem, setSelectedDeleteItem] = React.useState<ICOGSCalculatorRequest>();

    const [cogsCalculatorRequests, setCOGSCalculatorRequests] = React.useState<ICOGSCalculatorRequest[]>([]);
    const [statusKeys, setStatusKeys] = React.useState(['Approved','Rejected','Pending','Sent To Tesseract']);

    let requestsToShow = cogsCalculatorRequests
    .filter(
        request => request.requestId && includesIgnoreCase(request.requestId, keyword) ||
        request.workItemId && includesIgnoreCase(request.workItemId, keyword) ||
        request.capacityReviewSubmittedBy && includesIgnoreCase(request.capacityReviewSubmittedBy, keyword)
    )
    .filter(
        request => statusKeys.includes(request.approveStatus)
    );


    const onEditClicked = (request: ICOGSCalculatorRequest) => {
        setSelectedItem(request);
        openModal();
    }

    const onDeleteClicked = (request: ICOGSCalculatorRequest) => {
        setSelectedDeleteItem(request);
        openDeleteModal();
    }

    const onRequestSave = (request: ICOGSCalculatorRequest) => {
        if (checkUserPermission(userpermissions, ToolType.PCM, 'edit')) {
            hideModal();
            request.modifiedDateTime = new Date();
            request.modifiedBy = instance.getActiveAccount()!.username;
            updateCOGSCalculatorRequest(request);
        }
        else{
            alert("You are not allowed to edit the requests!")
        }
    }

    const onRequestDelete = (request: ICOGSCalculatorRequest) => {
        if (checkUserPermission(userpermissions, ToolType.PCM, 'edit')) {
            hideDeleteModal();
            deleteCOGSCalculatorRequest(request.requestId)
            setCOGSCalculatorRequests(cogsCalculatorRequests.filter(x => x.requestId != request.requestId))
        }
        else{
            alert("You are not allowed to delete the requests!")
        }
    }

    const onStatusFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                setStatusKeys([...statusKeys, option.key.toString()])
            }
            else {
                const arr_index = statusKeys.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = statusKeys.filter(key => key != option.key.toString());
                    setStatusKeys(new_arr);  
                }
            }
        }
    }

    return (
            <div className="ms-Fabric ms-font-su" style={{margin: ""}}>
                <PageHeader 
                title='Apps Onboarding Request'
                description="Capacity request from COGS calculator."
                />
                <div className="ms-Grid" style={{marginTop:"2rem", marginBottom: "1rem"}}>
                    <TextField placeholder="Search by request id, workitem id and submitter." onChange={onKeywordInput} />
                </div>

                <div className="ms-Grid" dir="ltr" style={{marginTop: '20px'}}>
                    <div className="ms-Grid-row">                    
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"><StatusFilter onStatusFilter={onStatusFilter} /></div>
                    </div>
                </div>

                {isLoading?
                    <div style={{margin: "10rem"}}>
                        <Spinner label="Fetching requests data..." size={SpinnerSize.large} />
                    </div> 
                :
                    <div className="ms-Grid" dir="ltr" style={{marginTop: '20px'}}>
                        <div className="ms-Grid-row" style={{fontWeight: "bold", padding: "0.8rem 0.2rem", textAlign: "left"}}>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7">Request ID</div>
                                    <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">WorkItem ID</div>
                                </div>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">Additional CPU (Gcycles)</div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">Additional IO (IOPS)</div>
                                </div>
                            </div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Start Date</div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Frequency</div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">Submitter</div>
                                    <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">Submitted Date</div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">Status</div>
                                </div>
                            </div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Actions</div>
                        </div>
                        {Array.from(requestsToShow).map((request, index) => {
                                return <COGSCalculatorRequestGroup key={index} request={request} edit={onEditClicked} onDelete={onDeleteClicked} permission={checkUserPermission(userpermissions, ToolType.PCM, 'edit')}/>
                            }
                        )}
                        {isModalOpen && <RequestEditModal isModalOpen={isModalOpen} hideModal={hideModal} request={selectedItem!} save={onRequestSave} permission={checkUserPermission(userpermissions, ToolType.PCM, 'edit')}/>}
                        {isDeleteModalOpen && <DeleteConfirmationModal isModalOpen={isDeleteModalOpen} hideModal={hideDeleteModal} request={selectedDeleteItem!} delete={onRequestDelete} />}
                    </div>
                }
            </div>
    );

    function onKeywordInput(event: React.FormEvent, newValue? : string) {
        setKeyword(newValue ||  "");
    }
    function includesIgnoreCase(str: string, substr: string) {
        return str.toLowerCase().includes(substr.toLowerCase());
    }

    function getCOGSCalculatorRequests() {
        fetchCOGSCalculatorRequests().then(response => {
            setCOGSCalculatorRequests(response);
            setIsLoading(false)
        })
    }
}
