import * as React from "react"
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/Dropdown";

interface IStatusDropDownProps {
    selection? : string
    onChange? :  (event:React.FormEvent, selectedStatusKey?: string) => void
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 120 },
};

const options: IDropdownOption[] = [
    { key: 'pending', text: 'Pending' },
    { key: 'created', text: 'Created' },
    { key: 'completed', text: 'Completed' },
    { key: 'history', text: 'History' },
    { key: 'excluded', text: 'Excluded'},
];


export const StatusDropDown: React.FC<IStatusDropDownProps> = ({selection, onChange}) => {
    const onDropdownChange = (event:React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        onChange?.(event, item?.key.toString())

    }

    return (
        <Dropdown 
            placeholder="Select status"
            defaultSelectedKey={selection}
            onChange={onDropdownChange}
            options={options}
            styles={dropdownStyles}
        />
    )
}