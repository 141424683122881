// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dlsMSJ9dm36_i6JL36Pd {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 4px;
}
.NqfUMXJ7vj04DCZ98OyS {
  background-color: #C8C8C8;
  margin: 0 16px;
  height: 1px;
}
.jylEfQMYVSmAekkHGks6 {
  height: 48px;
  line-height: 48px;
  margin-left: 16px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/layout/Navigation.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,8BAAA;EACA,iBAAA;AACJ;AAEA;EACI,yBAAA;EACA,cAAA;EACA,WAAA;AAAJ;AAGA;EACI,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;AADJ","sourcesContent":[".navBar {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    padding-left: 4px;\n}\n\n.separator {\n    background-color: #C8C8C8;\n    margin: 0 16px;\n    height: 1px;\n}\n\n.groupHeader {\n    height: 48px;\n    line-height: 48px;\n    margin-left: 16px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBar": `dlsMSJ9dm36_i6JL36Pd`,
	"separator": `NqfUMXJ7vj04DCZ98OyS`,
	"groupHeader": `jylEfQMYVSmAekkHGks6`
};
export default ___CSS_LOADER_EXPORT___;
