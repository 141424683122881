import { getJson, post } from "../utils/apiServiceBase";
import { AlertingConfig, RegionStatus, AlertingConfigNew, CopsQC, RegionStatusTrend, Buffer } from "../models/alerting"

export async function getConfigs(type: string): Promise<AlertingConfig[]> {
    return await getJson<AlertingConfig[]>("api/Alerting/getconfigs?type=" + type);
}

export async function getConfigsNew(type: string): Promise<AlertingConfigNew[]> {
    return await getJson<AlertingConfigNew[]>("api/Alerting/getconfigs_new?type=" + type);
}

export async function updateConfigs(configs: AlertingConfig[]): Promise<Response> {
    return await post("api/Alerting/updateconfigs", configs);
}

export async function updateConfigsNew(configs: AlertingConfigNew): Promise<Response> {
    return await post("api/Alerting/updateconfigs_new", configs);
}

export async function getRegionStatus(maxEDB: string): Promise<RegionStatus[]> {
    return await getJson<RegionStatus[]>("api/Alerting/getregionstatus?maxedb=" + maxEDB);
}

export async function getCopsQC(): Promise<CopsQC> {
    return await getJson<CopsQC>("api/Alerting/getcopsqc");
}

export async function getRegionStatusTrend(): Promise<RegionStatusTrend[]> {
    return await getJson<RegionStatusTrend[]>("api/Alerting/getstatustrend");
}

export async function saveRegionStatus(regionStatus: RegionStatus): Promise<Response> {
    return await post("api/Alerting/saveregionstatus", regionStatus);
}

export async function getBuffer(regions: string, envs: string, fiscalYear: number, percentile: number): Promise<Buffer[]> {
    return await getJson<Buffer[]>("api/Alerting/generatebuffer?regions=" + regions + "&envs=" + envs + "&fiscalyear=" + fiscalYear + "&percentile=" + percentile);
}

export async function saveBuffer(buffer: AlertingConfigNew[]): Promise<Response> {
    return await post("api/Alerting/savebuffer", buffer);
}