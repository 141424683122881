import * as React from "react";
import axios from "axios";
import { fetchForestSnd, fetchRegionSnd, fetchRnf } from "../../../services/demandPlanService";
import { setDefaultTarget } from "@fluentui/react/lib/components/Layer/Layer.notification";
import { DatePicker, Dropdown, IDropdownOption, PrimaryButton, TextField } from "@fluentui/react";
import { dataSanitizeString } from "@microsoft/applicationinsights-common";
import { Chart, registerables } from "chart.js";
import { chart } from "highcharts";
import { fetchForestCapacityMetrics } from "../../../services/allocationService";
import { merge } from "lodash";
import { useMsal } from "@azure/msal-react";
import { ApiId } from "@azure/msal-browser";
import { intersect } from "../../../utils/utils";
import { IDemandPlanEntry, IForestSupplyAndDemand, ITimeSeriesDataPoint } from "../../../models/DemandPlanGenerationModel";
import PageHeader from '../../common/pageheader/PageHeader';

interface IDemandPlanSimuationPageProps {
    defaultRegion?: string,
    defaultForest?: string,
}
export const DemandPlanSimulationPage: React.FC<IDemandPlanSimuationPageProps> = ({defaultRegion, defaultForest}) => {

    const chartInstance = React.useRef<Chart>();

    const _hddSupply = React.useRef<ITimeSeriesDataPoint[]>([]);
    const _hddDemand = React.useRef<ITimeSeriesDataPoint[]>([]);
    const _cpuSupply = React.useRef<ITimeSeriesDataPoint[]>([]);
    const _cpuDemand = React.useRef<ITimeSeriesDataPoint[]>([]);
    const _iopsSupply = React.useRef<ITimeSeriesDataPoint[]>([]);
    const _iopsDemand = React.useRef<ITimeSeriesDataPoint[]>([]);
    const _ssdSupply = React.useRef<ITimeSeriesDataPoint[]>([]);
    const _ssdDemand = React.useRef<ITimeSeriesDataPoint[]>([]);

    const _demandPlans = React.useRef<IDemandPlanEntry[]>([]);

    const _numShiftDays = React.useRef<number>(45);
    const _orderFrequency = React.useRef<number>(1);
    const _capacitySellable = React.useRef<number>(500);
    const _leadingTime = React.useRef<number>(6);
    const _supplyCutoffDate = React.useRef<Date>();

    const [selectedRegion, setSelectedRegion] = React.useState<string>(defaultRegion || "");
    const [selectedForest, setSelectedForest] = React.useState<string>(defaultForest || "");
    const [selectedCapacityMetric, setSelectedCapacityMetric] = React.useState<string>();

    const [regionForests, setRegionForests] = React.useState<Map<string, string[]>>();
    const [regions, setRegions] = React.useState<string[]>([]);

    const username = useMsal().instance.getActiveAccount()?.username;

    const getSelectedCapacitySellable = (capacityMetric: string) => {
        switch (capacityMetric) {
            case "HDD":
                return 700;
            case "IOPS":
                return 110000;
            case "CPU":
                return 5000;
            case "SSD":
                return 700000; 
            default:
                return 1;
        }
    }

    const refreshChart = () => {
        switch (selectedCapacityMetric) {
            case "HDD":
                createSndChart(_hddSupply.current, _hddDemand.current);
                setData(_hddSupply.current, _hddDemand.current);
                break;
            case "CPU":
                createSndChart(_cpuSupply.current, _cpuDemand.current);
                setData(_cpuSupply.current, _cpuDemand.current);
                break;
            case "IOPS":
                createSndChart(_iopsSupply.current, _iopsDemand.current);
                setData(_iopsSupply.current, _iopsDemand.current);
                break;
            case "SSD":
                createSndChart(_ssdSupply.current, _ssdDemand.current);
                setData(_ssdSupply.current, _ssdDemand.current);
                break;
        }

    }
    const updateData = () => {

        if (!selectedRegion || !selectedForest) {
            return;
        }
        if (selectedForest == "(Region)") {
            fetchRegionSnd(selectedRegion).then(response => {
                let snd: IForestSupplyAndDemand = response;
                console.log(snd.hddSupply);
                console.log(snd.hddDemand);

                _hddSupply.current = snd.hddSupply.map(convertDate);
                _hddDemand.current = snd.hddDemand.map(convertDate);
                _cpuSupply.current = snd.cpuSupply.map(convertDate);
                _cpuDemand.current = snd.cpuDemand.map(convertDate);
                _iopsSupply.current = snd.iopsSupply.map(convertDate);
                _iopsDemand.current = snd.iopsDemand.map(convertDate);
                _ssdSupply.current = snd.ssdSupply.map(convertDate);
                _ssdDemand.current = snd.ssdDemand.map(convertDate);
            })
        } else {
            fetchForestSnd(selectedForest).then(response => {
                let snd: IForestSupplyAndDemand = response;
                console.log(snd.hddSupply);
                console.log(snd.hddDemand);

                _hddSupply.current = snd.hddSupply.map(convertDate);
                _hddDemand.current = snd.hddDemand.map(convertDate);
                _cpuSupply.current = snd.cpuSupply.map(convertDate);
                _cpuDemand.current = snd.cpuDemand.map(convertDate);
                _iopsSupply.current = snd.iopsSupply.map(convertDate);
                _iopsDemand.current = snd.iopsDemand.map(convertDate);
                _ssdSupply.current = snd.ssdSupply.map(convertDate);
                _ssdDemand.current = snd.ssdDemand.map(convertDate);

            })
            refreshChart();

        }
    }

    React.useEffect(() => {
        Chart.register(...registerables);
        
        fetchRnf().then(
            response => {
                console.log("fetch rnf then")
                response.json().then(body => {
                    let regionForests: Map<string, string[]> = new Map(Object.entries(body));
                    console.log(regionForests);

                    let regions = []
                    for (let o of regionForests.keys()) {
                        regions.push(o);
                    }
                    regions.sort();
                    setRegions(regions);
                    setRegionForests(regionForests);
                });
            }
        );
        updateData();
    }, [])

    React.useEffect(() => {
        console.log("using effect for dropdown change");
        if (!selectedRegion || !selectedForest) {
            console.log(selectedRegion);
            console.log(selectedForest);
            return;
        }
        console.log("updating data");

        updateData();

    }, [selectedRegion, selectedForest, selectedCapacityMetric]);


    const _calculateSupplyWithDemandPlans = () => {
        const dates: string[] = chartInstance.current!.data.labels as string[];
        const originalSupply: number[] = chartInstance.current!.data.datasets[0].data as number[];
        const supplyWithDemandPlans: number[] = [];

        let j = -1;
        
        for (let i = 0; i < dates.length; i++) {
            if (parseDate(dates[i])! >= _demandPlans.current[j+1].date) {
                j++;
            }
            if (j != -1) {
                supplyWithDemandPlans.push(originalSupply[i] + _demandPlans.current[j].numUnits * getSelectedCapacitySellable(selectedCapacityMetric||""));//_demandPlans.current[j].unitCapacity);
            } else {
                supplyWithDemandPlans.push(originalSupply[i]);
            }

        }
        return supplyWithDemandPlans;
    }

    const _calculateDemandPlans = (dateStrs: string[], supply: number[], safeSupply: number[], decidingFactor: string) => {

        console.log(dateStrs);

        console.log(supply);
        console.log(safeSupply);

        let i = 0;
        while (dateStrs[i] < formatDate(new Date(Date.now()))) {
            i++;
        }
        while (i < dateStrs?.length &&  supply[i]! > safeSupply[i]!) {
            i++;
        }
        if (i >= dateStrs?.length) {
            return [];
        }
        let supplyNeededDate = parseDate(dateStrs[i])!;
        const lastDate = parseDate(dateStrs[dateStrs.length-1])!;

        console.log(dateStrs[i]);
        console.log(supplyNeededDate);
        supplyNeededDate.setDate(1);

        const demandPlans: IDemandPlanEntry[] = [];
        while (supplyNeededDate < lastDate) {
            let nextSupplyDate = new Date(supplyNeededDate);
            nextSupplyDate.setMonth(nextSupplyDate.getMonth() + _orderFrequency.current);
            
            const targetCapacity = safeSupply[dateStrs.indexOf(formatDate(nextSupplyDate))];
            const currentCapcity = supply[dateStrs.indexOf(formatDate(nextSupplyDate))];
            const numDags = Math.ceil((targetCapacity - currentCapcity) / getSelectedCapacitySellable(decidingFactor));//_capacitySellable.current);
            // console.log(nextSupplyDate);
            // console.log(currentCapcity);
            // console.log(targetCapacity);
            // console.log(numDags);
            supplyNeededDate = nextSupplyDate;
            demandPlans.push({
                date: nextSupplyDate,
                numUnits: numDags,
                unitCapacity: getSelectedCapacitySellable(decidingFactor),//_capacitySellable.current,
                notes: decidingFactor
            });
            //monthlyDemandPlan.push()
        }
        
        return demandPlans;
        //console.log(formatDate(supplyNeededDate));
    }

    const mergeDemandPlans = (demandPlans: IDemandPlanEntry[][]) : IDemandPlanEntry[] => {
        const merge = (dp1: IDemandPlanEntry[], dp2: IDemandPlanEntry[]): IDemandPlanEntry[] => {
            if (dp1.length == 0) {
                return dp2;
            }
            if (dp2.length == 0) {
                return dp1;
            }
            let i = 0;
            let j = 0;
            const mergedDemandPlans: IDemandPlanEntry[] = [];
            if (formatDate(dp1[i].date) < formatDate(dp2[j].date)) {
                console.log(formatDate(dp1[i].date));
                console.log(formatDate(dp2[j].date));
                mergedDemandPlans.push(dp1[i]);
                i++;
            }

            if (formatDate(dp2[j].date) < formatDate(dp1[i].date)) {
                mergedDemandPlans.push(dp2[j]);
                j++;
            }
            console.log(dp1);
            console.log(dp2);
            while (1) {
                i++; 
                j++;

                if (i >= dp1.length) {
                    dp2.slice(j, dp2.length).forEach(plan => mergedDemandPlans.push(plan));
                    break;
                }
                if (j >= dp2.length) {
                    dp1.slice(i, dp1.length).forEach(plan => mergedDemandPlans.push(plan));
                    break;
                }

                if (dp1[i].numUnits < dp2[j].numUnits) {
                    mergedDemandPlans.push(dp2[j]);
                } else {
                    mergedDemandPlans.push(dp1[i]);
                }
            }

            return mergedDemandPlans;
        }

        if (demandPlans.length == 1) {
            return demandPlans[0];
        }
        return mergeDemandPlans(
            [
                merge(demandPlans[0], demandPlans[1]), 
                ...demandPlans.slice(2, demandPlans.length)
            ]);
    }

    const _updateDemandPlans = () => {
        let dateStrs = chartInstance.current!.data.labels! as string[];
        let data = chartInstance.current!.data;

        let supply = data.datasets[0].data as number[];
        let safeSupply = data.datasets[2].data as number[];

        console.log("====================================")
        console.log(_hddSupply.current);
        console.log(_hddDemand.current);
        const [hddDateStrs, hddSupply, hddDemand, hddSafeSupply] = extractAsArrays(_hddSupply.current, _hddDemand.current);
        const [cpuDateStrs, cpuSupply, cpuDemand, cpuSafeSupply] = extractAsArrays(_cpuSupply.current, _cpuDemand.current);
        const [iopsDateStrs, iopsSupply, iopsDemand, iopsSafeSupply] = extractAsArrays(_iopsSupply.current, _iopsDemand.current);
        const [ssdDateStrs, ssdSupply, ssdDemand, ssdSafeSupply] = extractAsArrays(_ssdSupply.current, _ssdDemand.current);
        const hddDemandPlans = _calculateDemandPlans(hddDateStrs, hddSupply, hddSafeSupply, "HDD");
        const cpuDemandPlans = _calculateDemandPlans(cpuDateStrs, cpuSupply, cpuSafeSupply, "CPU");
        const iopsDemandPlans = _calculateDemandPlans(iopsDateStrs, iopsSupply, iopsSafeSupply, "IOPS");
        const ssdDemandPlans = _calculateDemandPlans(ssdDateStrs, ssdSupply, ssdSafeSupply, "SSD");

        //console.log(iopsDemandPlans);

        //const demandPlans = mergeDemandPlans([hddDemandPlans, cpuDemandPlans, iopsDemandPlans, ssdDemandPlans])
        const demandPlans = mergeDemandPlans([hddDemandPlans, iopsDemandPlans]);

        _demandPlans.current = demandPlans

        const withNewDemand = {
            label: "With new demand",
            data: _calculateSupplyWithDemandPlans()
        }
        if (chartInstance.current!.data.datasets.length <= 3) {
            chartInstance.current!.data.datasets.push(withNewDemand);
        } else {
            chartInstance.current!.data.datasets[3] = withNewDemand;
        }
        chartInstance.current!.update();
    }

    const _onTargetTtlChange = (event: React.FormEvent, newValue?: string) => {
        _numShiftDays.current = (parseFloat(newValue || "") * 30 + _orderFrequency.current * 30) || _numShiftDays.current;
        // setData(_hddSupply.current, _hddDemand.current);
        refreshChart();
    }

    const _onOrderFrequencyChange = (event: React.FormEvent, newValue?: string) => {
        _orderFrequency.current = parseInt(newValue || "") || _orderFrequency.current;
    }

    const _onCapacitySellableChange = (event: React.FormEvent, newValue?: string) => {
        _capacitySellable.current = parseFloat(newValue || "") || _capacitySellable.current;
    }

    const _onLeadingTimeChange = (event: React.FormEvent, newValue?: string) => {
        _leadingTime.current = parseFloat(newValue || "") || _leadingTime.current;
    }

    const _onSupplyCutoffDateChange = (event: React.FormEvent, newValue?: string) => {
    }

    const _onSelectedRegionChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setSelectedRegion(option?.text);
        setSelectedForest(undefined);
    }

    const _onSelectedForestChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setSelectedForest(option?.text);
    }

    const _onSelectedCapacityMetricChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setSelectedCapacityMetric(option?.text);
    }


    const asOption = (name: string) => {
        return {
            key: name,
            text: name
        } as IDropdownOption;
    }
    const capacityMetrics = ["HDD", "CPU", "IOPS", "SSD"];

    const getForests = (region: string | undefined) => {
        let forests = ["(Region)", ...(regionForests?.get(region||"")?.sort() || [])];
        return forests.map(asOption);
    }

    return (
        <div>
            <PageHeader
                title="Demand Plan Simulation"
                description="The page is to simulate demand plan generation"
            />
            <div>
                <Dropdown
                    label="Region"
                    selectedKey={selectedRegion}
                    options={regions.map(asOption)}
                    onChange={_onSelectedRegionChange}
                />
                <Dropdown
                    label="Forest"
                    selectedKey={selectedForest}
                    options={getForests(selectedRegion)}
                    onChange={_onSelectedForestChange}
                />
                <Dropdown
                    label="Capacity Metric"
                    selectedKey={selectedCapacityMetric}
                    options={capacityMetrics.map(asOption)}
                    onChange={_onSelectedCapacityMetricChange}
                />
            </div>
            <canvas id="snd-hdd"/>
            <div>
                <TextField 
                    label="Target TTL (months)" 
                    onChange={_onTargetTtlChange} 
                />
            </div>
            <div>
                <TextField 
                    label="Order Frequency (every (x) months)" 
                    onChange={_onOrderFrequencyChange} 
                />
            </div>
            <div>
                <TextField 
                    label="Capacity Sellable" 
                    onChange={_onCapacitySellableChange} 
                />
            </div>
            <div>
                <TextField 
                    label="Supply Chain Leading Time (months)" 
                    onChange={_onLeadingTimeChange} 
                />
            </div>
            <div>
                <DatePicker 
                    label="Supply Cut-off Date" 
                    onChange={_onSupplyCutoffDateChange} 
                />
            </div>
            <div>
                <PrimaryButton onClick={_updateDemandPlans}>
                    Generate Demand Plan
                </PrimaryButton>
            </div>
        </div>
    )

    function createSndChart(supplyDataPoints: ITimeSeriesDataPoint[], demandDataPoints: ITimeSeriesDataPoint[]) {
        const ctx = document.getElementById("snd-hdd") as HTMLCanvasElement;

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const chart: Chart = new Chart(ctx!, {
            type: 'line',
            data: generateData(supplyDataPoints, demandDataPoints),
        })
        chartInstance.current = chart;
    }

    function generateData (supplyDataPoints: ITimeSeriesDataPoint[], demandDataPoints: ITimeSeriesDataPoint[]) {
        const labels = 
        intersect(
            supplyDataPoints.map(p => p.date.toISOString().slice(0, 10)), 
            demandDataPoints.map(p => p.date.toISOString().slice(0, 10)));

        const data =  {
                labels: labels,
                datasets: [{
                    label: 'Actual Supply',
                    data: labels.map(_ => 0),
                    // borderWidth: 1,
                    // pointHitRadius: 25,
                    // fill: true,
                    // pointBackgroundColor: function(context: any) {
                    //     var index = context.dataIndex;
                    //     var value = context.dataset.data[index];
                    //     return index > forecastStartingIndex ? "blue" : "green"
                    // }
                },
                {
                    label: 'Consumption',
                    data: labels.map(_ => 0),
                },
                {
                    label: 'Safety supply',
                    data: labels.map(_ => 0),
                },
            
            ]
        }
        return data;
    }

    function convertDate(point: ITimeSeriesDataPoint) {
        return {
            date: parseDate(point.date.toString()),
            value: point.value
        } as ITimeSeriesDataPoint
    }

    function parseDate(dateStr: string) {
        let d = Date.parse(dateStr);
        return d? new Date(d) : undefined;
    }

    function formatDate(date: Date) {
        return date.toISOString().slice(0, 10);
    }

    function extractAsArrays (supplyDataPoints: ITimeSeriesDataPoint[], demandDataPoints: ITimeSeriesDataPoint[]) {
        const dateStrs = intersect(
            supplyDataPoints.map(p => p.date.toISOString().slice(0, 10)), 
            demandDataPoints.map(p => p.date.toISOString().slice(0, 10)));

        const supply: number[] = supplyDataPoints
            .filter(d => dateStrs.includes(formatDate(d.date)))
            .map(d => d.value);

        const demand: number[] = demandDataPoints
            .filter(d => dateStrs.includes(formatDate(d.date)))
            .map(d => d.value);

        const safeSupply = demandDataPoints
            .filter(d => {
                let shiftedDate = new Date(d.date);
                shiftedDate.setDate(shiftedDate.getDate() - _numShiftDays.current);
                return dateStrs.includes(formatDate(shiftedDate))
            })
            .map(d => d.value)
        return [dateStrs, supply, demand, safeSupply];
    }

    function setData(supplyDataPoints: ITimeSeriesDataPoint[], demandDataPoints: ITimeSeriesDataPoint[]) {
        const [dateStrs, supply, demand, safeSupply] = extractAsArrays(supplyDataPoints, demandDataPoints);
        chartInstance.current!.data.datasets[0].data = supply
        // supplyDataPoints
        //     .filter(d => chartInstance.current!.data.labels!.includes(formatDate(d.date)))
        //     .map(d => d.value);
        chartInstance.current!.data.datasets[1].data = demand
        // demandDataPoints
        //     .filter(d => chartInstance.current!.data.labels!.includes(formatDate(d.date)))
        //     .map(d => d.value);
        chartInstance.current!.data.datasets[2].data = safeSupply
        // demandDataPoints
        //     .filter(d => {
        //         let shiftedDate = new Date(d.date);
        //         shiftedDate.setDate(shiftedDate.getDate() - _numShiftDays.current);
        //         return chartInstance.current!.data.labels!.includes(formatDate(shiftedDate))
        //     })
        //     .map(d => d.value)
        chartInstance.current!.update();
    }
};