import React from "react";

import { Chart, registerables } from "chart.js";
import { IDropdownOption } from "@fluentui/react";

interface IDemandPlanSimuationChartProps {
    dateStrs: string[]
    supplyData: number[],
    demandData: number[],
    safeSupplyData: number[],
    supplyWithNewDemandData: number[]
}
export const DemandPlanSimulationChart: React.FC<IDemandPlanSimuationChartProps> = ({dateStrs, supplyData, demandData, safeSupplyData, supplyWithNewDemandData}) => {

    const _chartInstance = React.useRef<Chart>();

    React.useEffect(() => {
        Chart.register(...registerables);
    }, []);

    React.useEffect(
        createSndChart, 
        [dateStrs, supplyData, demandData, safeSupplyData, supplyWithNewDemandData]
    );


    function createSndChart() {
        console.log("creating chart")
        console.log(supplyData)
        console.log(demandData)
        const ctx = document.getElementById("demand-plan-simulation-chart") as HTMLCanvasElement;
        console.log(ctx);

        if (_chartInstance.current) {
            _chartInstance.current.destroy();
        }

        const chart: Chart = new Chart(ctx!, {
            type: 'line',
            data: generateData(),
        })
        console.log(chart);
        _chartInstance.current = chart;
    }

    function generateData () {

        const data =  {
                labels: dateStrs,
                datasets: [{
                    label: 'Actual Supply',
                    data: supplyData,
                    // borderWidth: 1,
                    // pointHitRadius: 25,
                    // fill: true,
                    // pointBackgroundColor: function(context: any) {
                    //     var index = context.dataIndex;
                    //     var value = context.dataset.data[index];
                    //     return index > forecastStartingIndex ? "blue" : "green"
                    // }
                    backgroundColor: "#005CAF"
                },
                {
                    label: 'Consumption',
                    data: demandData,
                    backgroundColor: "#CB4042"
                },
                {
                    label: 'Safety supply',
                    data: safeSupplyData,
                    backgroundColor: "#577C8A"
                },
                {
                    label: 'With new demand',
                    data: supplyWithNewDemandData,
                    backgroundColor: "#51A8DD"
                },
            
            ]
        }
        return data;
    }

    return (
        <div>
            <canvas id="demand-plan-simulation-chart" />

        </div>
    );
}

