import * as React from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

interface IEnvFilterProp {
    defaultOptions: string,
    onFilter: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void,
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
};

const options: IDropdownOption[] = [
    { key: '78', text: '0.78' },
    { key: '79', text: '0.79' },
    { key: '80', text: '0.8' },
    { key: '81', text: '0.81' },
    { key: '82', text: '0.82' },
    { key: '83', text: '0.83' },
    { key: '84', text: '0.84' },
    { key: '85', text: '0.85' },
    { key: '86', text: '0.86' },
    { key: '87', text: '0.87' },
    { key: '88', text: '0.88' },
    { key: '89', text: '0.89' },
    { key: '90', text: '0.90' },
    { key: '91', text: '0.91' },
    { key: '92', text: '0.92' },
    { key: '93', text: '0.93' },
    { key: '94', text: '0.94' },
];

const stackTokens: IStackTokens = { childrenGap: 20 };

export const MaxEDBSelection: React.FunctionComponent<IEnvFilterProp> = (props) => {

    return (
        <Stack tokens={stackTokens}>
            <Dropdown
                placeholder="Select options"
                label="Select MaxEDB"
                defaultSelectedKey={props.defaultOptions}
                options={options}
                styles={dropdownStyles}
                onChange={props.onFilter}
            />
        </Stack>
    );
};
