import * as React from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

interface IEnvFilterProp {
    defaultOptions: string,
    onFilter: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void,
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
};

const options: IDropdownOption[] = [
    { key: 'Approved', text: 'Approve' },
    { key: 'Sent To Tesseract', text: 'Approve & Send To Tesseract' },
    { key: 'Rejected', text: 'Reject' },
];

const stackTokens: IStackTokens = { childrenGap: 20 };

export const StatusSelection: React.FunctionComponent<IEnvFilterProp> = (props) => {

    return (
        <Stack tokens={stackTokens}>
            <Dropdown
                placeholder={props.defaultOptions}
                label="Review result"
                defaultSelectedKey={props.defaultOptions}
                options={options}
                styles={dropdownStyles}
                onChange={props.onFilter}
            />
        </Stack>
    );
};
