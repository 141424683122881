import React from 'react'
import {
    TextField,
    Spinner,
    SpinnerSize,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import { Pagination } from "@uifabric/experiments"
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from '@fluentui/react/lib/Stack';
import PageHeader from '../../../common/pageheader/PageHeader';
import { ISelectionPlan } from '../../../../models/DCselectionPlan';
import { DCSelectionPlanGroup } from './DCSelectionPlanGroup';
import { DCSelectionPlanDetailedPage } from './DCSelectionPlanDetailPage';
import { fetchDCSelectionPlans, fetchFilterNumPage, fetchTotalNumPage, postFilterParams } from '../../../../services/dcSelectionService';
import { CapacityGeoCodeFilter, FiscalYearFilter, PlanIntentFilter} from './modules/Filters';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { useBoolean } from '@fluentui/react-hooks';
import { PlanEditModal } from './modules/PlanEditModal';
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { checkUserPermission } from "../../../../utils/utils"
import { IAppState } from "../../../../store";
import { IUserPermissionData } from "../../../../reducers/userPermissionReducer";
import { ToolType } from "../../../../utils/ToolConstants"

const IPaginationStyle = {
    pageNumber : {
        marginBottom: "0.6rem"
    }
}
export const DCSelectionOverviewPage: React.FC = () => {
    
    React.useEffect(() => {
        getTotalNumPage();
        getDCSelectionPlans(0);
    }, [])

    const { instance } = useMsal();
    const { userpermissions } = useSelector<IAppState, IUserPermissionData>(state => state.userPermissions);
    const [detailedStamp, setDetailedStamp] = React.useState<string>("");
    const [keyword, setKeyword] = React.useState<string>("");
    
    const defaultSelectedGeoCode: string[] = [];
    // const defaultSelectedReservation: string[] = [];
    const defaultSelectedFiscalYear: string[] = [];
    const defaultSelectedPlanIntent: string[] = [];
    // const defaultSelectedJobType: string[] = [];
    const [dcSelectionPlans, setDCSelectionPlans] = React.useState<ISelectionPlan[]>([]);
    const [goToSimulate, setGoToSimulate] = React.useState<boolean>(false);
    const [selectedGeoCode, setSelectedGeoCode] = React.useState(defaultSelectedGeoCode);
    //const [selectedReservation, setSelectedReservation] = React.useState(defaultSelectedReservation);
    const [selectedFiscalYear, setSelectedFiscalYear] = React.useState(defaultSelectedFiscalYear);
    const [selectedPlanIntent, setSelectedPlanIntent] = React.useState(defaultSelectedPlanIntent);
    // const [selectedJobType, setSelectedJobType] = React.useState(defaultSelectedJobType);
    const [isModalOpen, { setTrue: openModal, setFalse: hideModal }] = useBoolean(false);
    const [selectedItem, setSelectedItem] = React.useState<ISelectionPlan>();
    const [currentPage, setCurrentPage] = React.useState<number>(0);
    const [filterArgs, setFilterArgs] = React.useState<object>({});
    const [totalPage, setTotalPage] = React.useState<number>(0);
    const [errMsg, setErrMsg] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    let plansToShow = dcSelectionPlans.filter(
        plan => plan.stampName && includesIgnoreCase(plan.stampName, keyword) ||
        plan.planIntent && includesIgnoreCase(plan.planIntent, keyword) ||
        plan.capacityGeoCode && includesIgnoreCase(plan.capacityGeoCode, keyword) ||
        plan.region && includesIgnoreCase(plan.region, keyword) ||
        plan.planIntent && includesIgnoreCase(plan.planIntent, keyword) ||
        plan.recommendation[0].dcs.map(a => a.name).join(" ") && includesIgnoreCase(plan.recommendation[0].dcs.map(a => a.name).join(" "), keyword)
    );
    const stamp = plansToShow.filter(stamp => stamp.id! == detailedStamp)[0];

    const onDetailClicked = (stampClicked: string) => {
        setDetailedStamp(stampClicked);
    }
    const onGoToSimulateClicked = () => {
        setGoToSimulate(true)
    }
    const onEditClicked = (plan: ISelectionPlan) => {
        setSelectedItem(plan);
        openModal();
    }
    const _onCapacityGeoCodeFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                selectedGeoCode.push(option.key.toString());
                setSelectedGeoCode(selectedGeoCode);
            }
            if (option.selected == false) {
                const arr_index = selectedGeoCode.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = selectedGeoCode.filter(key => key != option.key.toString());
                    setSelectedGeoCode(new_arr);
                }
            }
        }
    }

    const _onFiscalYear = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                selectedFiscalYear.push(option.key.toString());
                setSelectedFiscalYear(selectedFiscalYear);
            }
            if (option.selected == false) {
                const arr_index = selectedFiscalYear.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = selectedFiscalYear.filter(key => key != option.key.toString());
                    setSelectedFiscalYear(new_arr);
                }
            }
        }
    }

    // const _onReservationFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
    //     if (option != null) {
    //         if (option.selected == true) {
    //             selectedReservation.push(option.key.toString());
    //             setSelectedReservation(selectedReservation);
    //         }
    //         if (option.selected == false) {
    //             const arr_index = selectedReservation.indexOf(option.key.toString());
    //             if (arr_index > -1) {
    //                 var new_arr = selectedReservation.filter(key => key != option.key.toString());
    //                 setSelectedReservation(new_arr);
    //             }
    //         }
    //     }
    // }

    const _onPlanIntentFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                selectedPlanIntent.push(option.key.toString());
                setSelectedPlanIntent(selectedPlanIntent);
            }
            if (option.selected == false) {
                const arr_index = selectedPlanIntent.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = selectedPlanIntent.filter(key => key != option.key.toString());
                    setSelectedPlanIntent(new_arr);
                }
            }
        }
    }

    // const _onJobTypeFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
    //     if (option != null) {
    //         if (option.selected == true) {
    //             selectedJobType.push(option.key.toString());
    //             setSelectedJobType(selectedJobType);
    //         }
    //         if (option.selected == false) {
    //             const arr_index = selectedJobType.indexOf(option.key.toString());
    //             if (arr_index > -1) {
    //                 var new_arr = selectedJobType.filter(key => key != option.key.toString());
    //                 setSelectedJobType(new_arr);
    //             }
    //         }
    //     }
    // }
    
    const _onPageChange = (index: number) => {
        //console.log("_onPageChange called")
        setDCSelectionPlans([]);
        setCurrentPage(index);
        if (Object.keys(filterArgs).length !== 0){
            fetchFilterDCSelectionPlans(filterArgs, index);
        }
        else{
            getDCSelectionPlans(index);
        }
    }

    const _onSearchClicked = () => {
        //console.log("_onSearchClicked called")
        const filterParams = {
            "search": keyword,
            "capacityGeoCode": selectedGeoCode,
            "reservationStatus": [''],
            "fiscalYear": selectedFiscalYear,
            "planIntent": selectedPlanIntent,
            "jobType": ["Updated"]
        }
        setDCSelectionPlans([]);
        setCurrentPage(0);
        if (filterParams.search != "" || filterParams.capacityGeoCode.length != 0 || filterParams.reservationStatus.length != 0
            || filterParams.fiscalYear.length != 0 || filterParams.planIntent.length != 0 || filterParams.jobType.length != 0){
            getFilterNumPage(filterParams);
            fetchFilterDCSelectionPlans(filterParams, 0);
            setFilterArgs(filterParams);
        }
        else{
            getTotalNumPage();
            setFilterArgs({});
            getDCSelectionPlans(currentPage);
        }
    }
    return (
        detailedStamp.toLocaleLowerCase() == "" ? 
            <div className="ms-Fabric ms-font-su" style={{margin: ""}}>
                <PageHeader 
                title='DC Selection overview'
                description="Showing the detail output of each stamp from DC selection model."
                />
                <div className="ms-Grid" style={{marginTop:"2rem", marginBottom: "1rem"}}>
                    <TextField placeholder="You could search by stamp name, capacity geo code, plan intent, region and dc's name." onChange={onKeywordInput} />
                </div>
                <div style={{marginBottom: "2rem"}} className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                    <Stack horizontal>
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"><CapacityGeoCodeFilter defaultOptions={selectedGeoCode} onFilter={_onCapacityGeoCodeFilter} /></div>
                        {/* <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"><ReservationStatusFilter defaultOptions={selectedReservation} onFilter={_onReservationFilter} /></div> */}
                        {/*<div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"><FiscalYearFilter defaultOptions={selectedFiscalYear} onFilter={_onFiscalYear} /></div>*/}
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"><PlanIntentFilter defaultOptions={selectedPlanIntent} onFilter={_onPlanIntentFilter} /></div>
                        {/* <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"><JobTypeFilter defaultOptions={selectedJobType} onFilter={_onJobTypeFilter} /></div> */}
                        <Stack.Item align="end">
                            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                <PrimaryButton onClick={_onSearchClicked}>Search</PrimaryButton>
                            </div>
                        </Stack.Item>
                        </Stack>
                    </div>
                    <div className="ms-Grid-col">
                        <div>
                            {errMsg && <MessageBar messageBarType={MessageBarType.error}> {errMsg}  </MessageBar>}
                        </div>
                    </div>
                </div>

                {isLoading?
                    <div style={{margin: "10rem"}}>
                        <Spinner label="Fetching stamp data..." size={SpinnerSize.large} />
                    </div> 
                :
                    <div className="ms-Grid ms-font-s" dir="ltr">
                        <div className="ms-Grid-row" style={{fontWeight: "bold", padding: "0.8rem 0.2rem", textAlign: "center"}}>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">StampName</div>
                            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">Region</div>
                                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">Fiscal Year</div>
                                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">Capacity Geo Code</div>
                                </div>
                            </div>
                            {/* <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Reservation Status</div> */}
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">DAG Cnt</div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">Rack Cnt</div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">Server Cnt</div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">Plan Intent</div>
                                </div>
                            </div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">DCs (Priority1)</div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Hosting Cost ($/yr)</div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Networking Cost ($/yr)</div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Cost Snapshot Date</div>
                            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">Actions</div>
                        </div>
                        {Array.from(plansToShow).map((grops, index) => {
                                const plans = [grops]
                                return <DCSelectionPlanGroup key={index} selectionPlans={plans} editPlan={onEditClicked} getDetail={onDetailClicked} goToSimulate={onGoToSimulateClicked} hasEditPermission={checkUserPermission(userpermissions, ToolType.DCSelection, 'edit')}/>
                            }
                        )}
                        <div style={{marginTop:"1rem", textAlign: "right"}}>
                            <Pagination
                            selectedPageIndex={currentPage}
                            pageCount={totalPage}
                            onPageChange={_onPageChange}
                            format={"buttons"}
                            styles={IPaginationStyle}
                            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
                            previousPageIconProps={{ iconName: 'ChevronLeft' }}
                            nextPageIconProps={{ iconName: 'ChevronRight' }}
                            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
                            />
                        </div>
                        {isModalOpen && <PlanEditModal isModalOpen={isModalOpen} hideModal={hideModal} stamp={selectedItem!} />}
                    </div>
                }
            </div>
            :
            <DCSelectionPlanDetailedPage 
                stamp={stamp}
                onBack={backToOverview}
            />
    );

    function getDCSelectionPlans(pagenum: number) {
        fetchDCSelectionPlans(pagenum).then(
            response => {
                setDCSelectionPlans(response);
                setErrMsg("");
                setIsLoading(false);
            },
            ex => {
                setDCSelectionPlans([]);
                setErrMsg("Error retrieving DC selection plans.");
            }   
        );
    }

    function getTotalNumPage() {
        fetchTotalNumPage().then(
            res => {
                setTotalPage(res);
                setErrMsg("");
            },
            ex => {
                setDCSelectionPlans([]);
                setErrMsg("Error retrieving total number pages.");
            }      
        );
    }
    function getFilterNumPage(filterParams: object) {
        fetchFilterNumPage(filterParams).then(
            res => {
                setTotalPage(res);
                setErrMsg("");
            },
            ex => {
                setDCSelectionPlans([]);
                setErrMsg("Error retrieving filtered number pages.");
            } 
        );
    }
    function fetchFilterDCSelectionPlans(params: object, index: number) {
        postFilterParams(params, index).then(response => {
            if (!response.length) {
                setDCSelectionPlans([]);
                setErrMsg("We can not find any stamp. Please check if filter parameter is right.");
            }
            else {
                setDCSelectionPlans(response);
                setErrMsg("");
            }
            setIsLoading(false);
        });
    }
    function onKeywordInput(event: React.FormEvent, newValue? : string) {
        setKeyword(newValue ||  "");
    }
    function includesIgnoreCase(str: string, substr: string) {
        return str.toLowerCase().includes(substr.toLowerCase());
    }
    function backToOverview() {
        setKeyword("");
        setDetailedStamp("");
        setErrMsg("");
    }
}
