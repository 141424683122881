import React from 'react'
import {IRecommendation, IDCInformation} from "../../../../models/DCselectionPlan"

interface IDCSelectionPlanDetailGroup{
    plan: IRecommendation
}

interface IDCSelectionPlanDCDetailGroup{
    dcInfo: IDCInformation
}

const withBorder = {
    marginTop: "2rem",
    borderBottom: "ridge",
    borderColor: "#faf9f8",
    textAlign: "center",
}

export const DCSelectionPlanDetailGroup: React.FC<IDCSelectionPlanDetailGroup> = 
({plan}) => {
    
    const firstPriority = getFirstPriority(plan)
    const totalRequiredKW = getTotalRequiredKW(plan)
    return (
        <div className="ms-Grid-row" style={withBorder}>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{plan.priority}</div>
            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">{firstPriority}</div>
            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{unitConverter(plan.hosting)}</div>
            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{unitConverter(plan.networking)}</div>
            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{unitConverter(plan.hosting + plan.networking)}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{totalRequiredKW.toFixed(2)}</div>
        </div>
    )
    function getFirstPriority(dcItem: IRecommendation){
        return  dcItem.dcs.reduce((pre, cur) => pre+cur.name+"  ", "")
    }

    function getTotalRequiredKW(dcItem: IRecommendation){
        return dcItem.dcs.reduce((pre, cur) => pre+cur.requiredKW, 0)
    }
}

export const DCSelectionPlanDCDetailGroup: React.FC<IDCSelectionPlanDCDetailGroup> = 
({dcInfo}) => {
    return (
        <div className="ms-Grid-row" style={withBorder}>
            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{dcInfo.name}</div>
            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{dcInfo.AZ}</div>
            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{dcInfo.AR}</div>
            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{unitConverter(dcInfo.hosting)}</div>
            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{dcInfo.requiredKW.toFixed(2)}</div>
        </div>
    )
}

export const unitConverter = (x: number) => {
    return (x/1000).toFixed(2) + "K"
}