import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IDropdownOption, IDropdownStyles, PrimaryButton, Spinner, TextField } from "@fluentui/react";
import * as React from "react"
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { saveHardwareRoadmapProfile } from "../../../../services/demandPlanService";

export const HardwareRoadmapUploader: React.FC = () => {
    const [selectedFile, setSelectedFile] = React.useState<File>();
    const [profileName, setProfileName] = React.useState<string>("");
    const [profileDescription, setProfileDescription] = React.useState<string>("");
    
    const _submitFile = () => {
        if (!selectedFile) {
            return;
        }

        const formData = new FormData();
        formData.append(
            "file",
            selectedFile,
            selectedFile.name,
        )

        formData.append(
            "profileName",
            profileName
        );
        formData.append(
            "profileDescription",
            profileDescription
        );
        saveHardwareRoadmapProfile(formData).then(response => {
            if (response.status === 200) {
                window.alert("Upload successfully");
            }
        }
        )
    }


    const _onFileSelected = (event: React.FormEvent) => {
        setSelectedFile((event.target as HTMLInputElement).files![0]);
    }

    const _onProfileNameChanged = (e: React.FormEvent, newValue?: string) => {
        setProfileName(newValue || "");
    }

    const _onProfileDescriptionChanged = (e: React.FormEvent, newValue?: string) => {
        setProfileDescription(newValue || "");
    }


    return (
        <div>
            <input type="file" name="file" onChange={_onFileSelected} />
            <DefaultButton href={"https://microsoftapc-my.sharepoint.com/:x:/g/personal/fej_microsoft_com/EYoOIENgnDBKljMoL1E1i-EBmWpQwziYqkqL3M7smENOnw?e=EoguQV"}> Download Template </DefaultButton>
            <TextField label="ProfileName" onChange={_onProfileNameChanged} />
            <TextField label="ProfileDescription" onChange={_onProfileDescriptionChanged} />
            <div style={{marginTop: "0.5rem"}}>
                <PrimaryButton onClick = {_submitFile}> Submit </PrimaryButton>
            </div>
        </div>
    )
}

 