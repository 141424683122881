import * as React from "react"
import { ITextFieldStyles, TextField} from '@fluentui/react/lib/TextField';
import { Stack, IStackProps, IStackStyles, IStackTokens } from "@fluentui/react/lib/Stack";
import { IAllocationOverrideEntry } from "../../../../models/AllocationOverride";
import { UsageTypeDropdown } from "./UsageTypeDropdown";
import { IconButton, Label } from "@fluentui/react";
import { fetchBeStamps } from "../../../../services/allocationService";

interface IAllocationOverrideTableRowProps {
    rowNum: number,
    stamps: IStamp[],
    data: IAllocationOverrideEntry,
    onRemoved: (rowNum: number) => void,
};

const AllocationOverrideTableRow: React.FC<IAllocationOverrideTableRowProps> = ({rowNum, data, stamps, onRemoved}) => {
    const demandIdStyles: Partial<ITextFieldStyles> = {
        root: {
            width: 100
        }
    }
    const forestStyles: Partial<ITextFieldStyles> = {
        root: {
            width: 400
        }
    }
    const unitsStyles: Partial<ITextFieldStyles> = {
        root: {
            width: 50
        }
    }
    const stackTokens: IStackTokens = { childrenGap: 40 };


    const onDemandIdChange = (event:React.FormEvent, newValue?: string) => {
        if (newValue)
        {
            data.demandId = parseInt(newValue);
        }
    }

    const onForestChange = (event:React.FormEvent, newValue?: string) => {
        if (newValue)
        {
            data.forest = newValue;
        }
    }

    const onUnitsChange = (event:React.FormEvent, newValue?: string) => {
        if (newValue)
        {
            console.log(newValue)
            data.default = parseInt(newValue);
        }
    }

    const onUsageTypeChange = (event:React.FormEvent, newValue?: string) => {
        if (newValue)
        {
            data.usageType = newValue;
        }
    }

    const removeClicked = () => {
        onRemoved(rowNum);
    }

    return (
        <div>
            <Stack horizontal tokens={stackTokens}>
                <TextField styles={demandIdStyles} defaultValue={data?.demandId?.toString()} onChange={onDemandIdChange}/>
                <TextField styles={forestStyles} defaultValue={data?.forest?.toString()} onChange={onForestChange}/>
                <TextField styles={unitsStyles} defaultValue={data?.default?.toString()} onChange={onUnitsChange}/>
                <Label>EtaRtegDate</Label>
                <UsageTypeDropdown selection={data.usageType} onChange={onUsageTypeChange}/>
                <IconButton iconProps={{ iconName: 'Remove' }} title="Remove" ariaLabel="Remove" onClick={removeClicked}/>
            </Stack>
        </div>
    )
                //<Label>{stamps.filter(stamp => stamp.demandId == data?.demandId!)[0].deploymentGroupIdName || ""}</Label>
} 

interface IAllocationOverrideTableProps<T=any> {
    data: IAllocationOverrideEntry[],
    stamps: IStamp[],
    triggerRefresh: () => void,
};

export const AllocationOverrideTable: React.FC<IAllocationOverrideTableProps> = ({data, stamps, triggerRefresh}) => {
    const onRowRemoved = (rowNum: number) => {
        data.splice(rowNum, 1);
        triggerRefresh();
    }

    return (
        <div>
            {data.map((rowData, idx) => <AllocationOverrideTableRow rowNum={idx} stamps={stamps} data={rowData} onRemoved={onRowRemoved}/>)}
        </div>
    );
}
