import { ComboBox, DefaultButton, DetailsList, DetailsRow, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IComboBox, IComboBoxOption, IconButton, IDetailsListProps, IDetailsRowStyles, IDropdownOption, IDropdownStyles, IGroup, ITooltipHostStyles, Label, PrimaryButton, Selection, SelectionMode, TextField, TooltipHost } from "@fluentui/react";
import * as React from "react";
import {Chart, registerables} from "chart.js";
import axios from "axios";
import PageHeader from "../../common/pageheader/PageHeader";
import { useMsal } from "@azure/msal-react";
import { useId } from '@fluentui/react-hooks'
import { CapacityPlanGeneratorScenarioPage } from "./CapacityPlanGeneratorScenarioPage";
import { fetchScenarios } from "../../../services/demandPlanService";
import { CapacityPlanScenarioPage } from "./CapacityPlanScenarioPage";

interface IScenario {
    scenarioId: string,
    scenarioName: string,
    scenarioDescription: string,
    // Creator: string,
    // Timestamp: string
}

export const CapacityPlanGeneratorScenarioIndex: React.FC = () => {
    const [scenarios, setScenarios] = React.useState<IScenario[]>([]);
    const columns = [
        {key:"ScenarioId", name: "Scenario Id", fieldName: "scenarioId", minWidth: 5, maxWidth: 300},
        {key:"ScenarioName", name: "Scenario Name", fieldName: "scenarioName", minWidth: 5, maxWidth: 200},
        {key:"ScenarioDescription", name: "Scenario Description", fieldName: "scenarioDescription", minWidth: 5, maxWidth: 1000}
    ]

    const [edittingScenario, setEdittingScenario] = React.useState<string>();
    const [comparingScenarios, setComparingScenarios] = React.useState<boolean>(false);
    const [selectedScenarios, setSelectedScenarios] = React.useState<string[]>([]);
    const capacityType = React.useState<string>("be");

    const _onScenarioSelected = (item?: IScenario, index?: number, ev?: Event) => {
        setEdittingScenario(item?.scenarioId);
    }

    const _onNewClicked = () => {
        setEdittingScenario("new");
    }

    const _onCompareClicked = () => {
        setComparingScenarios(true);
    }

    const _onSelectedScenariosChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option != null) {
            if (option.selected == true) {
                selectedScenarios.push(option.key.toString());
                setSelectedScenarios(selectedScenarios);
            } else {
                setSelectedScenarios (selectedScenarios.filter(key => key != option.key.toString()));
            }
        }
    }
    const selection = new Selection({
        onSelectionChanged: () => {
          const selectedItems = selection.getSelection().map(item => (item as unknown as IScenario).scenarioId);
          setSelectedScenarios(selectedItems);
        }
      });

    React.useEffect(() => {
        fetchScenarios().then(
            response => {
                console.log(response);
                setScenarios(response);
            }
        );
    },[]);
    
    return (
        <div>
        {!edittingScenario &&
            <div>
                <h1>Scenarios</h1>
                <Label style={{marginTop: "2rem"}}>Double click on the scenario to edit</Label>
                <DetailsList
                    items={scenarios}
                    columns={columns}
                    selectionMode = {SelectionMode.multiple}
                    selection={selection}
                    onItemInvoked={_onScenarioSelected}
                />

                <div className="ms-Grid-col ms-lg8 ms-md12 ms-sm12" style= {{marginTop:"2rem"}}>
                    <PrimaryButton 
                        style={{marginRight: "2rem"}}
                        text="New"
                        onClick={_onNewClicked}
                    />
                    <DefaultButton 
                        style={{marginRight: "2rem"}}
                        text="Compare"
                        onClick={_onCompareClicked}
                    />
                </div>
            </div>}
        {edittingScenario &&
            <div >
                <DefaultButton 
                    style={{margin: "1rem 5rem" , marginLeft: "6rem"}}
                    onClick={() => {setEdittingScenario(undefined); setComparingScenarios(false)}}
                > 
                    Back 
                </DefaultButton> 
                <CapacityPlanScenarioPage id={edittingScenario} />
            </div>
        }
        </div>
    )
}