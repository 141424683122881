import { ActionTypes, RequestStatus } from "../actions/ActionTypes";
import { IGetSideBarAction } from "../actions/SideBarAction"
import { Reducer } from "redux"
import { ThunkDispatch } from "redux-thunk";
import { GetStateFunction } from "../store";
import { getSidebarsBySG } from "../services/sidebarservice";
import { INavLinkGroup } from "@fluentui/react";

export interface ISideBarData {
    sidebars: INavLinkGroup[];
    isLoading: boolean;
}

const initState = {
    sidebars: [],
    isLoading: true
}

export const sideBarReducer: Reducer<ISideBarData, IGetSideBarAction> = (state: ISideBarData = initState, action: IGetSideBarAction): ISideBarData => {
    switch (action.type) {
        case ActionTypes.GetSideBarAction: {
            switch (action.status) {
                case RequestStatus.Requested: return {
                    ...state,
                    isLoading: true
                }
                case RequestStatus.Succeed: return {
                    ...state,
                    sidebars: action.sidebars,
                    isLoading: false
                }
                case RequestStatus.Failed: return {
                    ...state,
                    sidebars: [],
                    isLoading: false
                }
            }
        }
        default: return state
    }
}

export function loadSideBarsIfNeeded(username?: string) {
    return async function (dispatch: ThunkDispatch<IGetSideBarAction, void, IGetSideBarAction>, getState: GetStateFunction): Promise<void> {
        const sideBarData = getState().sideBars;
        if (sideBarData.sidebars.length > 0) {
            return Promise.resolve();
        }

        dispatch({ type: ActionTypes.GetSideBarAction, status: RequestStatus.Requested, sidebars: [] });
        getSidebarsBySG(username)
            .then(data => dispatch({ type: ActionTypes.GetSideBarAction, status: RequestStatus.Succeed, sidebars: data }))
            .catch(() => dispatch({ type: ActionTypes.GetSideBarAction, status: RequestStatus.Failed, sidebars: [] }));
    }
}