import * as React from "react";
import PageHeader from '../../common/pageheader/PageHeader';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import {
    MessageBar,
    MessageBarType,
    Link
} from '@fluentui/react';


export const CapacityPlanningReport: React.FC = () => {

    return (
        <div>
            <PageHeader
                title="M365 Capacity Execution"
                description="This report contains the information for Substrate BE capacity planning."
            />
            <div>
                <BodyPage />
            </div>
        </div>

    )
}

const BodyPage: React.FC = () => {
    return (
        <div>
            <MessageBar>
                Please go to
                <Link href="https://aka.ms/tesseract/m2cp/capacity" inline>
                    aka.ms/tesseract/m2cp/capacity
                </Link>{' '}
                to check original report
            </MessageBar>
            <iframe title="O365 Capacity Plan"
                style={{ width: "100%", height: "900px", marginTop: '20px' }}
                src="https://aka.ms/tesseract/m2cp/capacity-embeded"
                frameBorder={0}
                allowFullScreen={true}></iframe>
        </div>
    );
}