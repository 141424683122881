import { IAllocationOverrideEntry } from "../models/AllocationOverride";
import { IAllocationPlan } from "../models/AllocationPlan";
import { ApiServiceBase, getJson } from "../utils/apiServiceBase";

const api = new ApiServiceBase(SERVICE_ENDPOINT);

export function fetchAllocationPlans() {
    return api.getJson<IAllocationPlan[]>("allocation/allocation_plans")
}

export function fetchBeStamps() {
    return api.getJson<IStamp[]>("allocation/be_stamps")
}

export function fetchAllocationOverrides() {
    return api.getJson<IAllocationOverrideEntry[]>("allocation/allocation_override")
}

export function fetchForestCapacityMetrics(region: string, d: Date) {
    return api.getJson<IForestCapacityMetrics[]>(
        "allocation/forest_capacity_metrics?region=" + region 
        + "&date=" + d.toISOString())
}

export function updateAllocationPlans(allocationPlans: IAllocationPlan[]) {
    return api.postJson(
        "allocation/allocation_plans",
        allocationPlans
    )
}

export function fetchAllocationPermissions(username: string) {
    return api.getJson<Object>(
        "allocation/allocation_permissions?username="+username
    )
}
