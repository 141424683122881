import * as React from "react"

import {
    TextField,
    Spinner,
    SpinnerSize,
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    PrimaryButton,
    ITooltipProps,
    ITooltipHostStyles,
    TooltipHost,
    TooltipDelay,
    DirectionalHint,
    Label,
    Button,
    IIconProps,
    IconButton
} from '@fluentui/react';
import { useBoolean, useId } from "@fluentui/react-hooks";
import { Dictionary } from "highcharts";
import { fetchDeraStamps } from "../../../../services/costsavingservice";
import { IDeraStamp, IHLCStamp } from "../../../../models/CostSaving";
import { Bool } from "reselect/es/types";

const TICKS_PER_MONTH = 4;

interface ITimelineProps {
    region: string
    environment: string
    startRange: number
    endRange: number
    hlcStamps: IHLCStamp[]
    deselectedStamps: IHLCStamp[]
    selectedBools: boolean[]
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', width: '1vw' } };
const calloutProps = { gapSpace: 0 };

const lineStyleTextDateTicks = {
    width: '96%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '2%',
    marginTop: '-1%'
}

const lineStyleTextLabels = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}

const circularButton = {
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent'
}

const circularButtonLowOpacity = {
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    opacity: 0.3
}

const circleIcon: IIconProps = { iconName: 'CircleFill' };

const monthNumberToName: Dictionary<string> = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
}

export const SimulationTimeline: React.FC<ITimelineProps> = ({ region, environment, startRange, endRange, hlcStamps, deselectedStamps, selectedBools }) => {
    const START_DATE = new Date();
    START_DATE.setMonth(START_DATE.getMonth() + startRange);
    START_DATE.setDate(1);
    const END_DATE = new Date();
    END_DATE.setMonth(END_DATE.getMonth() + endRange);
    END_DATE.setDate(2);
    const TOTAL_MONTHS = 36;

    function computeNumberOfTicks(): number {
        return (TOTAL_MONTHS - (TOTAL_MONTHS - endRange) - startRange) * TICKS_PER_MONTH + 1;
    }

    function roundSmallNumber(num: number) {
        var numString = num.toString();
        var numStringAfterDecimalPoint = numString.substring(2,);
        var i = 0;
        var zeroCount = 0;
        while (i < numStringAfterDecimalPoint.length && numStringAfterDecimalPoint[i] == "0") {
            zeroCount += 1;
            i += 1;
        }

        var numDecimalPlaces = 3;
        var newNum = num * Math.pow(10, zeroCount + numDecimalPlaces);
        var roundedNum = Math.round(newNum);
        return roundedNum / Math.pow(10, zeroCount + numDecimalPlaces);
    }

    function getGridStyle() {
        console.log("Number of ticks: ", computeNumberOfTicks());
        console.log("Number of boxes: ", computeNumberOfTicks() - 1);
        // Find the box size and scale it accordingly to timeline space we have
        var autoColSize = 1 / (computeNumberOfTicks() - 1) / 0.96;
        var roundedAutoColSize = roundSmallNumber(autoColSize);
        var autoColSizeText = roundedAutoColSize + "fr";
        console.log("Col size of box: ", autoColSize, " / Col size of box rounded: ", autoColSizeText);

        // Find the width size of the grid to support adding 1 more box
        var widthSize = +((0.96 / (1 - roundedAutoColSize)) * 100).toFixed(2);
        var widthSizeString = widthSize + "%";

        // Find the margin left value to align center of left most box with beginning of timeline
        var marginLeftSizeString = (2 - ((widthSize - 96) / 2)) + "%";

        const lineStyleText = {
            width: widthSizeString,
            display: 'grid',
            gridAutoColumns: autoColSizeText,
            gridAutoRows: '0.1fr',
            marginLeft: marginLeftSizeString,
        }

        return lineStyleText;
    }

    let [deraStamps, setDeraStamps] = React.useState<IDeraStamp[]>([]);
    
    const [selected, { toggle: setSelected }] = useBoolean(true);

    

    function getDeraStamps() {
        fetchDeraStamps(region).then(
            response => {
                console.log("dera", response);
                setDeraStamps(response);
            },
            ex => {
                console.log("Failed to get DERA stamps");
            }
        )
    }

    React.useEffect(() => {
        getDeraStamps();
    }, []);

    function getDeraStamp(stampName: string) {
        var deraStamp: IDeraStamp = deraStamps.filter(stamp => (stamp.deploymentGroupIDName === stampName)).pop()!;
        if (deraStamp === undefined) {
            return null;
        }
        return deraStamp;
    }

    function getTooltipProps(hlcStamp: IHLCStamp | null, goLiveETA: Date) {
        var stampName = hlcStamp == null ? "" : hlcStamp.deploymentGroupIDName;
        var forest = hlcStamp == null ? "" : hlcStamp.forest;
        var deraStamp: IDeraStamp | null = getDeraStamp(stampName!);
        var mdmids = deraStamp == null ? "" : deraStamp.mdmid!;
        var planIntent = hlcStamp == null ? "" : hlcStamp.planIntentName!;
        var cpu = (hlcStamp == null || hlcStamp.gCyclesLimit_Effi == null) ? "" : ("" + Math.round(hlcStamp.gCyclesLimit_Effi!));
        var iops = (hlcStamp == null || hlcStamp.iopsLimit_Effi == null) ? "" : ("" + Math.round(hlcStamp.iopsLimit_Effi!));
        var hdd = (hlcStamp == null || hlcStamp.maxEDBLimit == null) ? "" : ("" + Math.round(hlcStamp.maxEDBLimit!));
        var ssd = (hlcStamp == null || hlcStamp.ssdLimit == null) ? "" : ("" + Math.round(hlcStamp.ssdLimit!));

        var planDockDate = (deraStamp == null || deraStamp.planDockDate == null) ? "" : formatFullDate(deraStamp.planDockDate!);
        var etaDockDate = (deraStamp == null || deraStamp.etaDockDate == null) ? "" : formatFullDate(deraStamp.etaDockDate!);
        var actualDockDate = (deraStamp == null || deraStamp.act_Dock == null) ? "" : formatFullDate(deraStamp.act_Dock!);
        var planRTEGDate = (deraStamp == null || deraStamp.planRTEGDate == null) ? "" : formatFullDate(deraStamp.planRTEGDate!);
        var etaRTEGDate = (deraStamp == null || deraStamp.etartegDate == null) ? "" : formatFullDate(deraStamp.etartegDate!);
        var actualRTEGDate = (deraStamp == null || deraStamp.act_RTEG == null) ? "" : formatFullDate(deraStamp.act_RTEG!);

        const tooltipProps: ITooltipProps = {
            onRenderContent: () => (
                
                <div style={{ textAlign: 'center' }}>
                    <h2>{stampName}</h2>
                    <p>Go Live ETA: {formatFullDate(goLiveETA)}</p>
                    <p>Forest: {forest}</p>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>MDMIDS: {mdmids}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>Plan Intent: {planIntent}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>Plan Dock Date: {planDockDate}</p>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>CPU: {cpu}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>IOPS: {iops}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>HDD: {hdd}</p>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>SSD: {ssd}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>ETA Dock Date: {etaDockDate}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>Actual Dock Date: {actualDockDate}</p>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>Plan RTEG Date: {planRTEGDate}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>ETA RTEG Date: {etaRTEGDate}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>Actual RTEG Date: {actualRTEGDate}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        };
        return tooltipProps;
    }

    function dateDiff(date1: Date, date2: Date) {
        var diff = Math.abs(new Date(date1).getTime() - date2.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        return diffDays;
    }

    function convertDateToTickLocation(date: Date) {
        var totalTicks: number = computeNumberOfTicks();
        var numDaysIn3Years = dateDiff(START_DATE, END_DATE);
        var diffDays = dateDiff(date, START_DATE);
        //console.log("Computing date diff for ", new Date(date), " and ", START_DATE, ": ", diffDays);
        var tick = (diffDays / numDaysIn3Years) * totalTicks; // proportion
        return tick;
    }

    function convertTickToFlexSize(stampTick: number, prevStampTick: number) {
        return stampTick - prevStampTick;
    }

    function convertTextSizeToTickAmount() {
        var totalTicks: number = computeNumberOfTicks();
        return totalTicks * 0.0525;
    }

    function dateComparison(date1: Date, date2: Date) {
        if (date1 == date2) {
            return 0;
        } else if (date1 < date2) {
            return -1;
        }
        return 1;
    }

    function formatDate(date: Date) {
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        var monthString = monthNumberToName[month];

        return monthString + " " + year.toString();
    }

    function formatFullDate(date: Date) {
        var dateString = date.toString();
        dateString = dateString.substring(0, dateString.indexOf("T"));
        var dateArr = dateString.split("-");
        
        var day = +dateArr[2];
        var month = +dateArr[1];
        var year = dateArr[0];

        var dayString = day < 10 ? "0" + day.toString() : day.toString();
        var monthString = monthNumberToName[month];

        return monthString + " " + dayString + ", " + year;
    }

    function reverseBool(stamp: IHLCStamp) {
        setSelected();
        const stampIndex: number = hlcStamps.findIndex(s => { return (s.deploymentGroupIDName == stamp.deploymentGroupIDName && s.forest == stamp.forest) });
        selectedBools[stampIndex] = !selectedBools[stampIndex];
        //console.log("Deselecting at: ", stampIndex, " and becomes ", selectedBools[stampIndex]);
    }

    function onDeselectStamp(stamp: IHLCStamp) {
        console.log("Before: ", deselectedStamps);
        const stampIndex: number = deselectedStamps.findIndex(s => { return (s.deploymentGroupIDName == stamp.deploymentGroupIDName && s.forest == stamp.forest) });
        if (stampIndex !== -1) {
            console.log("removing: ", stamp.deploymentGroupIDName);
            deselectedStamps.splice(stampIndex, 1);
        } else {
            console.log("adding: ", stamp.deploymentGroupIDName);
            deselectedStamps.push(stamp);
        }
        console.log("After: ", deselectedStamps);
        reverseBool(stamp);
    }

    function getIconButtonStyle(stamp: IHLCStamp) {
        const stampIndex: number = hlcStamps.findIndex(s => { return (s.deploymentGroupIDName == stamp.deploymentGroupIDName && s.forest == stamp.forest) });
        return selectedBools[stampIndex] ? circularButton : circularButtonLowOpacity;
    }
    
    function generateTimelineDots() {
        //console.log("generating stamp dots");
        var items = [];

        var prevStampTick = 0;

        var row = 1;
        var i = 0;
        hlcStamps.forEach(stamp => {
            var goLiveETA: Date = stamp.goliveETA;
            if (dateComparison(START_DATE, new Date(goLiveETA)) < 1 && dateComparison(new Date(goLiveETA), END_DATE) < 1) {
                var stampTick = Math.floor(convertDateToTickLocation(goLiveETA)) + 1;
                var flexSize = convertTickToFlexSize(stampTick, prevStampTick);

                if (flexSize == 0) {
                    row += 1;
                } else {
                    row = 1;
                }

                items.push(
                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', gridRow: row, gridColumn: stampTick, margin: 0 }}>
                        <TooltipHost
                            tooltipProps={getTooltipProps(stamp, goLiveETA)}
                            calloutProps={calloutProps}
                            delay={TooltipDelay.zero}
                            directionalHint={DirectionalHint.topCenter}
                            styles={hostStyles}
                        >
                            <IconButton toggle checked={selectedBools[i]} iconProps={circleIcon} onClick={() => onDeselectStamp(stamp)} style={getIconButtonStyle(stamp)} />
                        </TooltipHost>

                    </div>);
                prevStampTick = stampTick;
                i++;
            }
        });

        return items;
    }
   
    function generateTimelineLabels() {
        var items = [];
        var dateTicks = 1.6; // average pixel proportion size of a date converted into ticks

        // add the starting date
        items.push(<Label style={{ textAlign: 'center' }}>{formatDate(START_DATE)}</Label>);

        // add the ending line 
        var totalTicks: number = computeNumberOfTicks();
        var endLineTicks = convertTickToFlexSize(totalTicks, dateTicks);
        endLineTicks -= dateTicks;
        items.push(<div style={{ flex: endLineTicks, height: '0px', backgroundColor: 'black' }} />);

        // add the ending date
        items.push(<Label style={{ textAlign: 'center' }}>{formatDate(END_DATE)}</Label>);

        return items;
    }

    function generateTimelineDateTicks() {
        var items = [];
        var monthFlex = 4;

        // add the starting tick
        items.push(<div style={{ borderLeft: '4px solid', height: '2vh', backgroundColor: 'black' }} />);

        // add month ticks
        for (var i = 0; i < (TOTAL_MONTHS - (TOTAL_MONTHS - endRange) - startRange); i++) {
            if (i != 0) {
                items.push(<div style={{ borderLeft: '4px solid', height: '1vh', backgroundColor: 'black' }} />);
            }

            items.push(<div style={{ flex: monthFlex, height: '4px', backgroundColor: 'black' }} />);
        }

        // add the ending tick
        items.push(<div style={{ borderLeft: '4px solid', height: '2vh', backgroundColor: 'black' }} />);

        return items;
    }


    return (
        <div>
            {
                (hlcStamps.length == 0) ?
                    <div style={{ margin: "2rem" }}>
                        <Spinner label="Generating current timeline. This might take a while..." size={SpinnerSize.large} />
                    </div>
                    :
                    <div>
                        <div style={lineStyleTextDateTicks}>
                            {generateTimelineDateTicks()}
                        </div>
                        <div style={lineStyleTextLabels}>
                            {generateTimelineLabels()}
                        </div>
                        <div style={getGridStyle()}>
                            {generateTimelineDots()}
                        </div>
                    </div>
            }
        </div>
    );
};