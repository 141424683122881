import React from 'react'
import { ActionButton, IButtonStyles, IStackTokens, Stack } from "@fluentui/react";
import { Link, useHistory } from 'react-router-dom';

import { ISelectionPlan, IRecommendation } from "../../../../models/DCselectionPlan";
import {unitConverter} from "./DCSelectionPlanDetailGroup"
interface ISelectionPlanGroupProps {
    selectionPlans: ISelectionPlan[],
    editPlan: (stamp: ISelectionPlan) => void,
    getDetail: (id: string) => void,
    goToSimulate: () => void,
    hasEditPermission: boolean
}
export const DCSelectionPlanGroup: React.FC<ISelectionPlanGroupProps> = ({selectionPlans, editPlan, getDetail, goToSimulate, hasEditPermission}) => {
    const withBorder = {
        marginTop: "1rem",
        borderBottom: "ridge",
        borderColor: "#faf9f8",
        textAlign: "center",
    }
    const plan = selectionPlans[0]
    const { recommendation: recom } = plan
    const history = useHistory()
    const buttonStyles: IButtonStyles = {
        root : {
            marginTop: 0,
            fontSize: 10,
            width: "100%",
        },
        icon: {
            fontSize: 10
        },
        flexContainer: {
            alignItems: "baseline",
        }
    }

    const onEditClicked = () => {
        editPlan(plan);
    }
    const onDetailClicked = () => {
        getDetail(plan.id!);
    } 
    const onGoToSimulation = () => {
        history.push({pathname: '/DCSelection/Simulation', state:{plan: plan}})
        goToSimulate();
    } 

    const firstPriority = getFirstPriority(plan.recommendation[0])
    return (
        <div className="ms-Grid-row" style={withBorder}>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{plan.stampName}</div>
            <div className="ms-Grid-col ms-sm2 ms-m2 ms-lg2">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">{plan.region}</div>
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">{plan.fiscalYear}</div>
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">{plan.capacityGeoCode}</div>

                </div>
            </div>
            {/* <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{plan.reservationStatus || "N/A"}</div> */}
            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">{plan.dagCnt}</div>
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">{plan.rackCnt}</div>
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">{plan.serverCnt}</div>
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">{plan.planIntent}</div>
                </div>
            </div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{firstPriority}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{unitConverter(recom[0].hosting)}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{unitConverter(recom[0].networking)}</div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{plan.dcCostSnapshotDate}</div>
            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm0 ms-md0 ms-lg1"/>
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                        { hasEditPermission ? <ActionButton styles={buttonStyles} iconProps={{iconName: "Edit"}} onClick={onEditClicked}> 
                            Edit
                        </ActionButton> : <div/>}
                    </div>
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                        <ActionButton styles={buttonStyles} iconProps={{iconName: "Info"}} onClick={onDetailClicked}> 
                            Details
                        </ActionButton>
                    </div>
                    <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
                        { hasEditPermission ? <ActionButton styles={buttonStyles} iconProps={{iconName: "Puzzle"}} onClick={onGoToSimulation}>
                            Go to simulation
                        </ActionButton> : <div/>}
                    </div>
                </div>
            </div>
        </div>
    )

    function getFirstPriority(dcItem: IRecommendation){
        return  dcItem.dcs.reduce((pre, cur) => pre+cur.name+"  ", "")
    }
}
