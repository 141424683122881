import * as React from "react";;
import {
    Checkbox,
    DirectionalHint,
    getTheme,
    HoverCard,
    IDetailsColumnStyles,
    IDetailsRowProps,
    IDetailsRowStyles,
    IExpandingCardProps,
    IScrollablePaneStyles,
    ITooltipHostStyles,
    ITooltipProps,
    mergeStyleSets,
    ScrollablePane,
    Spinner,
    SpinnerSize,
    TooltipDelay,
    TooltipHost,
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    SelectionMode,
    IColumn,
    IDetailsListProps,
    DetailsRow,
    PrimaryButton,
    IDropdownOption
} from '@fluentui/react';

import { useId } from '@fluentui/react-hooks';
import { computeNewCPUTTL, computeNewHDDTTL, computeNewIOPSTTL, computeNewSSDTTL, fetchCPUDemandData, fetchHDDDemandData, fetchIOPSDemandData, fetchSSDDemandData } from '../../../services/costsavingservice';
import { IDemandData, IHLCStamp, ITTLResultsData } from "../../../models/CostSaving";
import { Dictionary, forEach } from "lodash";
import { ColumnSelection } from "./modules/ColumnSelection";

interface ITTLDeltasChart {
    environment: string
    region: string
    deselectedStamps: IHLCStamp[]
    threshold: number
    startRange: number
    endRange: number
}

const withBorder = {
    marginTop: "2rem",
    borderBottom: "ridge",
    borderColor: "#faf9f8",
    textAlign: "center",
}

const headerStyle: Partial<IDetailsColumnStyles> = {
    cellTitle: {
        color: 'white',
        backgroundColor: 'black',
        marginLeft: '1px'
    }
}

const theme = getTheme();
/*const classNames = mergeStyleSets({
    pane: {
        maxWidth: '90%',
        border: '1px solid ' + theme.palette.neutralLight,
    }

});*/
//const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNames.pane };


const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', width: '90%' } };

const scrollable = {
    overflowY: 'scroll',
    whiteSpace: 'nowrap',
    margin: '20px',
    textAlign: 'justify',
    padding: '20px',
    height: '100%',

}

const fixedHeaders = {
    position: 'sticky',
    top: '0',
    margin: '0',
    backgroundColor: 'white'
}

const monthNames: Dictionary<string> = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
}

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});
const controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '300px',
    },
};




export const TTLDeltasChart: React.FC<ITTLDeltasChart> = ({ environment, region, deselectedStamps, threshold, startRange, endRange }) => {


    let [cpuDemandData, setCpuDemandData] = React.useState<IDemandData[]>([]);
    let [iopsDemandData, setIopsDemandData] = React.useState<IDemandData[]>([]);
    let [hddDemandData, setHddDemandData] = React.useState<IDemandData[]>([]);
    let [ssdDemandData, setSsdDemandData] = React.useState<IDemandData[]>([]);

    let [newCpuDemandData, setNewCpuDemandData] = React.useState<IDemandData[]>([]);
    let [newIopsDemandData, setNewIopsDemandData] = React.useState<IDemandData[]>([]);
    let [newHddDemandData, setNewHddDemandData] = React.useState<IDemandData[]>([]);
    let [newSsdDemandData, setNewSsdDemandData] = React.useState<IDemandData[]>([]);

    const [isLoadingCPU, setIsLoadingCPU] = React.useState<boolean>(true);
    const [isLoadingIOPS, setIsLoadingIOPS] = React.useState<boolean>(true);
    const [isLoadingHDD, setIsLoadingHDD] = React.useState<boolean>(true);
    const [isLoadingSSD, setIsLoadingSSD] = React.useState<boolean>(true);

    const defaultColumnKeys = ['column0', 'column1', 'column2', 'column3', 'column4', 'column5', 'column6', 'column7', 'column8', 'column12', 'column16', 'column20', 'column24'];
    const [columnKeys, setColumnKeys] = React.useState(defaultColumnKeys);
    

   
    function getCPUDemandData() {
        fetchCPUDemandData(region, environment).then(
            response => {
                console.log("cpu", response);
                setCpuDemandData(response);
                setIsLoadingCPU(false);
            },
            ex => {
                console.log("Error fetching CPU demand data");
                setCpuDemandData([]);
                setIsLoadingCPU(false);
                //setParamsError("Error retrieving stamp information");
            }
        )
    }

    function getIOPSDemandData() {
        fetchIOPSDemandData(region, environment).then(
            response => {
                console.log("iops", response);
                setIopsDemandData(response);
                setIsLoadingIOPS(false);
            },
            ex => {
                console.log("Error fetching IOPS demand data");
                setIopsDemandData([]);
                setIsLoadingIOPS(false);
                //setParamsError("Error retrieving stamp information");
            }
        )
    }

    function getHDDDemandData() {
        fetchHDDDemandData(region, environment).then(
            response => {
                console.log("hdd", response);
                setHddDemandData(response);
                setIsLoadingHDD(false);
            },
            ex => {
                console.log("Error fetching HDD demand data");
                setHddDemandData([]);
                setIsLoadingHDD(false);
                //setParamsError("Error retrieving stamp information");
            }
        )
    }

    function getSSDDemandData() {
        console.log(region, environment);
        fetchSSDDemandData(region, environment).then(
            response => {
                console.log("ssd", response);
                setSsdDemandData(response);
                setIsLoadingSSD(false);
            },
            ex => {
                console.log("Error fetching SSD demand data");
                setSsdDemandData([]);
                setIsLoadingSSD(false);
                //setParamsError("Error retrieving stamp information");
            }
        )
    }

    React.useEffect(() => {
        (async () => {
            try {
                await Promise.all([getCPUDemandData(), getIOPSDemandData(), getHDDDemandData(), getSSDDemandData()]);
            } catch (err) {
                console.log('Error occured when fetching demand data for TTL metrics');
            }
        })();
    }, []);

   /* function getColor(delta: number) {
        if (delta <= 0) {
            return { "backgroundColor": "green", width: "135px", padding: '15px', margin: '15px' };
        } else {
            return { "backgroundColor": "red", width: "135px", padding: '15px', margin: '15px' };
        }
    }*/

    function genDateHeaders() {
        let today = new Date();
        //console.log("today date", today);
        let todayMonth = today.getMonth() + 1;
        //console.log("today month", todayMonth);
        let todayYear = today.getFullYear();
        let headers = [];
        let numDates = Math.min(cpuDemandData.length, iopsDemandData.length, hddDemandData.length, ssdDemandData.length, endRange);
        for (let i = 0; i < numDates; i++) {
            let newMonth = (todayMonth + i) % 12;
            if (newMonth == 0) {
                newMonth = 12;
            }
            if (newMonth == 1) {
                todayYear += 1;
            }
            let newDateHeader = monthNames[newMonth] + " " + todayYear;
            //console.log(i, newDateHeader);
            headers.push(newDateHeader);
        }
        return headers;
    }

    /*function genColumns() {
        const columns: IColumn[] = [];
        const dateHeaders: string[] = genDateHeaders();
        for (let i = 0; i < dateHeaders.length; i++) {
            const col = {
                key: 'column' + (i + 1),
                name: dateHeaders[i],
                fieldName: 'month header',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            };
            
        }
    }*/

    function roundTwoDecimals(num: number) {
        //console.log(num);
        num = Math.abs(num);
        const rounded = (Math.round(num * 100) / 100).toFixed(2);
        //console.log(rounded);
        if (num >= 100000000) {
            const numLen = rounded.indexOf('.');
            const numZeros = 6;
            const numDigitsInFront = numLen - numZeros;
            const wholeNumber = rounded.substring(0, numDigitsInFront);
            const decimal = rounded.substring(numDigitsInFront, numDigitsInFront + 2);
            const newRep = wholeNumber + "." + decimal + "M";
            return newRep;
        }

        if (num >= 100000) {
            const numLen = rounded.indexOf('.');
            const numZeros = 3;
            const numDigitsInFront = numLen - numZeros;
            const wholeNumber = rounded.substring(0, numDigitsInFront);
            const decimal = rounded.substring(numDigitsInFront, numDigitsInFront + 2);
            const newRep = wholeNumber + "." + decimal + "K";
            return newRep;
        }

        return (Math.round(num * 100) / 100).toFixed(2);
    }

    function fetchNewHDDTTL() {
        computeNewHDDTTL(region, environment, hddDemandData, deselectedStamps).then(
            response => {
                console.log("new ttl hdd", response);
                setNewHddDemandData(response);
                setIsLoadingHDD(false);
            },
            ex => {
                setNewHddDemandData([]);
                setIsLoadingHDD(false);
            }
        )
    }

    function fetchNewCPUTTL() {
        computeNewCPUTTL(region, environment, cpuDemandData, deselectedStamps).then(
            response => {
                console.log("new ttl cpu", response);
                setNewCpuDemandData(response);
                setIsLoadingCPU(false);
            },
            ex => {
                setNewCpuDemandData([]);
                setIsLoadingCPU(false);
            }
        )
    }

    function fetchNewIOPSTTL() {
        computeNewIOPSTTL(region, environment, iopsDemandData, deselectedStamps).then(
            response => {
                console.log("new ttl iops", response);
                setNewIopsDemandData(response);
                setIsLoadingIOPS(false);
            },
            ex => {
                setNewIopsDemandData([]);
                setIsLoadingIOPS(false);
            }
        )
    }

    function fetchNewSSDTTL() {
        computeNewSSDTTL(region, environment, ssdDemandData, deselectedStamps).then(
            response => {
                console.log("new ttl ssd", response);
                setNewSsdDemandData(response);
                setIsLoadingSSD(false);
            },
            ex => {
                setNewSsdDemandData([]);
                setIsLoadingSSD(false);
            }
        )
    }

    async function fetchNewTTL() {
        //console.log(deselectedStamps);
        try {
            setIsLoadingCPU(true);
            setIsLoadingIOPS(true);
            setIsLoadingHDD(true);
            setIsLoadingSSD(true);

            await Promise.all([fetchNewCPUTTL(), fetchNewIOPSTTL(), fetchNewHDDTTL(), fetchNewSSDTTL()]);
        } catch (err) {
            console.log('Error occured when fetching new results for TTL metrics');
            //setIsLoading(false);
        }
    }


    function genDemandRow(metricData: IDemandData[]) {
        const demand: string[] = [];
        metricData.forEach(data => { demand.push(roundTwoDecimals(data.demand)) });
        return demand;
    }

    function genCapacityBeforeRow(metricData: IDemandData[]) {
        const capBefore: string[] = [];
        metricData.forEach(data => { capBefore.push(roundTwoDecimals(data.supply)) });
        return capBefore;
    }

    function genCapacityAfterRow(metricData: IDemandData[]) {
        const capAfter: string[] = [];
        metricData.forEach(data => { capAfter.push(roundTwoDecimals(data.supply)) });
        return capAfter;
    }

    function genTTLBeforeRow(metricData: IDemandData[]) {
        const ttlBefore: string[] = [];
        metricData.forEach(data => { ttlBefore.push(roundTwoDecimals(data.ttl)) });
        return ttlBefore;
    }

    function genTTLAfterRow(metricData: IDemandData[]) {
        const ttlAfter: string[] = [];
        metricData.forEach(data => { ttlAfter.push(roundTwoDecimals(data.ttl)) });
        return ttlAfter;
    }

    function genDemandDelta(metricData: IDemandData[]) {
        const demandDiff: number[] = [0];
        for (var i = 1; i < metricData.length; i++) {
            var prevDemand = metricData[i - 1].demand;
            var currDemand = metricData[i].demand;

            var diff = currDemand - prevDemand;
            demandDiff.push(diff);
        }
        const demandDiffCol: string[] = [];
        demandDiff.forEach(data => { demandDiffCol.push("+" + roundTwoDecimals(data)) });
        demandDiffCol[0] = "";
        return demandDiffCol;
    }

    function genDemandDeltaPercent(metricData: IDemandData[]) {
        const demandDiff: number[] = [0];
        for (var i = 1; i < metricData.length; i++) {
            var prevDemand = metricData[i - 1].demand;
            var currDemand = metricData[i].demand;

            var diff = currDemand - prevDemand;
            var percent = (diff / prevDemand) * 100;
            demandDiff.push(percent);
        }

        const demandDiffCol: string[] = [];
        demandDiff.forEach(data => { demandDiffCol.push("+" + roundTwoDecimals(data) + "%") });
        demandDiffCol[0] = "";
        return demandDiffCol;
    }

    function genSupplyDelta(oldMetricData: IDemandData[], newMetricData: IDemandData[]) { 
        const supplyDiff: number[] = [];
        const minLength = Math.min(oldMetricData.length, newMetricData.length);
        for (var i = 0; i < minLength; i++) {
            var oldSupply = oldMetricData[i].supply;
            var newSupply = newMetricData[i].supply;

            var diff = newSupply - oldSupply;
            supplyDiff.push(diff);
        }

        const supplyDiffCol: string[] = [];
        supplyDiff.forEach(data => {supplyDiffCol.push("-" + roundTwoDecimals(data))});

        return supplyDiffCol;
    }

    function genSupplyDeltaPercent(oldMetricData: IDemandData[], newMetricData: IDemandData[]) {
        const supplyDiffPercent: number[] = [];
        const minLength = Math.min(oldMetricData.length, newMetricData.length);
        for (var i = 0; i < minLength; i++) {
            var oldSupply = oldMetricData[i].supply;
            var newSupply = newMetricData[i].supply;

            var diff = newSupply - oldSupply;
            var percent = (diff / oldSupply) * 100;
            supplyDiffPercent.push(percent);
        }
        const supplyDiffPercentCol: string[] = [];
        supplyDiffPercent.forEach(data => { supplyDiffPercentCol.push("-" + roundTwoDecimals(data) + "%") });

        return supplyDiffPercentCol;
    }

    function genTableData() {
        var items: ITTLResultsData[] = [];
        const dateHeaders: string[] = genDateHeaders();
        const demandCPURow: string[] = genDemandRow(cpuDemandData);
        const demandIOPSRow: string[] = genDemandRow(iopsDemandData);
        const demandHDDRow: string[] = genDemandRow(hddDemandData);
        const demandSSDRow: string[] = genDemandRow(ssdDemandData);
        const demandCPUDeltaRow: string[] = genDemandDelta(cpuDemandData);
        const demandIOPSDeltaRow: string[] = genDemandDelta(iopsDemandData);
        const demandHDDDeltaRow: string[] = genDemandDelta(hddDemandData);
        const demandSSDDeltaRow: string[] = genDemandDelta(ssdDemandData);
        const demandCPUDeltaPercentRow: string[] = genDemandDeltaPercent(cpuDemandData);
        const demandIOPSDeltaPercentRow: string[] = genDemandDeltaPercent(iopsDemandData);
        const demandHDDDeltaPercentRow: string[] = genDemandDeltaPercent(hddDemandData);
        const demandSSDDeltaPercentRow: string[] = genDemandDeltaPercent(ssdDemandData);
        const capBeforeCPURow: string[] = genCapacityBeforeRow(cpuDemandData);
        const capBeforeIOPSRow: string[] = genCapacityBeforeRow(iopsDemandData);
        const capBeforeHDDRow: string[] = genCapacityBeforeRow(hddDemandData);
        const capBeforeSSDRow: string[] = genCapacityBeforeRow(ssdDemandData);
        const capAfterCPURow: string[] = genCapacityAfterRow(newCpuDemandData);
        const capAfterIOPSRow: string[] = genCapacityAfterRow(newIopsDemandData);
        const capAfterHDDRow: string[] = genCapacityAfterRow(newHddDemandData);
        const capAfterSSDRow: string[] = genCapacityAfterRow(newSsdDemandData);
        const ttlBeforeCPURow: string[] = genTTLBeforeRow(cpuDemandData);
        const ttlBeforeIOPSRow: string[] = genTTLBeforeRow(iopsDemandData);
        const ttlBeforeHDDRow: string[] = genTTLBeforeRow(hddDemandData);
        const ttlBeforeSSDRow: string[] = genTTLBeforeRow(ssdDemandData);
        const ttlAfterCPURow: string[] = genTTLAfterRow(newCpuDemandData);
        const ttlAfterIOPSRow: string[] = genTTLAfterRow(newIopsDemandData);
        const ttlAfterHDDRow: string[] = genTTLAfterRow(newHddDemandData);
        const ttlAfterSSDRow: string[] = genTTLAfterRow(newSsdDemandData);
        const capCPUDeltaRow: string[] = genSupplyDelta(cpuDemandData, newCpuDemandData);
        const capIOPSDeltaRow: string[] = genSupplyDelta(iopsDemandData, newIopsDemandData);
        const capHDDDeltaRow: string[] = genSupplyDelta(hddDemandData, newHddDemandData);
        const capSSDDeltaRow: string[] = genSupplyDelta(ssdDemandData, newSsdDemandData);
        const capCPUDeltaPercentRow: string[] = genSupplyDeltaPercent(cpuDemandData, newCpuDemandData);
        const capIOPSDeltaPercentRow: string[] = genSupplyDeltaPercent(iopsDemandData, newIopsDemandData);
        const capHDDDeltaPercentRow: string[] = genSupplyDeltaPercent(hddDemandData, newHddDemandData);
        const capSSDDeltaPercentRow: string[] = genSupplyDeltaPercent(ssdDemandData, newSsdDemandData);

        const minEndRange = Math.min(dateHeaders.length, endRange);
        for (var i = startRange; i < minEndRange; i++) {
            const dateVal = dateHeaders[i];

            var cpuDemandVal = "";
            if (i < demandCPURow.length) {
                cpuDemandVal = demandCPURow[i];
            }

            var iopsDemandVal = "";
            if (i < demandIOPSRow.length) {
                iopsDemandVal = demandIOPSRow[i];
            }

            var hddDemandVal = "";
            if (i < demandHDDRow.length) {
                hddDemandVal = demandHDDRow[i];
            }

            var ssdDemandVal = "";
            if (i < demandSSDRow.length) {
                ssdDemandVal = demandSSDRow[i];
            }

            var cpuDemandDeltaVal = "";
            if (i < demandCPUDeltaRow.length) {
                cpuDemandDeltaVal = demandCPUDeltaRow[i];
            }

            var iopsDemandDeltaVal = "";
            if (i < demandIOPSDeltaRow.length) {
                iopsDemandDeltaVal = demandIOPSDeltaRow[i];
            }

            var hddDemandDeltaVal = "";
            if (i < demandHDDDeltaRow.length) {
                hddDemandDeltaVal = demandHDDDeltaRow[i];
            }

            var ssdDemandDeltaVal = "";
            if (i < demandSSDDeltaRow.length) {
                ssdDemandDeltaVal = demandSSDDeltaRow[i];
            }

            var cpuDemandDeltaPercentVal = "";
            if (i < demandCPUDeltaPercentRow.length) {
                cpuDemandDeltaPercentVal = demandCPUDeltaPercentRow[i];
            }

            var iopsDemandDeltaPercentVal = "";
            if (i < demandIOPSDeltaPercentRow.length) {
                iopsDemandDeltaPercentVal = demandIOPSDeltaPercentRow[i];
            }

            var hddDemandDeltaPercentVal = "";
            if (i < demandHDDDeltaPercentRow.length) {
                hddDemandDeltaPercentVal = demandHDDDeltaPercentRow[i];
            }

            var ssdDemandDeltaPercentVal = "";
            if (i < demandSSDDeltaPercentRow.length) {
                ssdDemandDeltaPercentVal = demandSSDDeltaPercentRow[i];
            }

            var cpuCapBeforeVal = "";
            if (i < capBeforeCPURow.length) {
                cpuCapBeforeVal = capBeforeCPURow[i];
            }

            var iopsCapBeforeVal = "";
            if (i < capBeforeIOPSRow.length) {
                iopsCapBeforeVal = capBeforeIOPSRow[i];
            }

            var hddCapBeforeVal = "";
            if (i < capBeforeHDDRow.length) {
                hddCapBeforeVal = capBeforeHDDRow[i];
            }

            var ssdCapBeforeVal = "";
            if (i < capBeforeSSDRow.length) {
                ssdCapBeforeVal = capBeforeSSDRow[i];
            }

            var cpuCapAfterVal = "";
            if (i < capAfterCPURow.length) {
                cpuCapAfterVal = capAfterCPURow[i];
            }

            var iopsCapAfterVal = "";
            if (i < capAfterIOPSRow.length) {
                iopsCapAfterVal = capAfterIOPSRow[i];
            }

            var hddCapAfterVal = "";
            if (i < capAfterHDDRow.length) {
                hddCapAfterVal = capAfterHDDRow[i];
            }

            var ssdCapAfterVal = "";
            if (i < capAfterSSDRow.length) {
                ssdCapAfterVal = capAfterSSDRow[i];
            }

            var cpuTTLBeforeVal = "";
            if (i < ttlBeforeCPURow.length && ttlBeforeCPURow[i] !== '0.00') {
                cpuTTLBeforeVal = ttlBeforeCPURow[i];
            }

            var iopsTTLBeforeVal = "";
            if (i < ttlBeforeIOPSRow.length && ttlBeforeIOPSRow[i] !== '0.00') {
                iopsTTLBeforeVal = ttlBeforeIOPSRow[i];
            }

            var hddTTLBeforeVal = "";
            if (i < ttlBeforeHDDRow.length && ttlBeforeHDDRow[i] !== '0.00') {
                hddTTLBeforeVal = ttlBeforeHDDRow[i];
            }

            var ssdTTLBeforeVal = "";
            if (i < ttlBeforeSSDRow.length && ttlBeforeSSDRow[i] !== '0.00') {
                ssdTTLBeforeVal = ttlBeforeSSDRow[i];
            }

            var cpuTTLAfterVal = "";
            if (i < ttlAfterCPURow.length && ttlAfterCPURow[i] !== '0.00') {
                cpuTTLAfterVal = ttlAfterCPURow[i];
            }

            var iopsTTLAfterVal = "";
            if (i < ttlAfterIOPSRow.length && ttlAfterIOPSRow[i] !== '0.00') {
                iopsTTLAfterVal = ttlAfterIOPSRow[i];
            }

            var hddTTLAfterVal = "";
            if (i < ttlAfterHDDRow.length && ttlAfterHDDRow[i] !== '0.00') {
                hddTTLAfterVal = ttlAfterHDDRow[i];
            }

            var ssdTTLAfterVal = "";
            if (i < ttlAfterSSDRow.length && ttlAfterSSDRow[i] !== '0.00') {
                ssdTTLAfterVal = ttlAfterSSDRow[i];
            }

            var cpuCapDeltaVal = "";
            if (i < capCPUDeltaRow.length && capCPUDeltaRow[i] !== '-0.00') {
                cpuCapDeltaVal = capCPUDeltaRow[i];
            }

            var iopsCapDeltaVal = "";
            if (i < capIOPSDeltaRow.length && capIOPSDeltaRow[i] !== '-0.00') {
                iopsCapDeltaVal = capIOPSDeltaRow[i];
            }

            var hddCapDeltaVal = "";
            if (i < capHDDDeltaRow.length && capHDDDeltaRow[i] !== '-0.00') {
                hddCapDeltaVal = capHDDDeltaRow[i];
            }

            var ssdCapDeltaVal = "";
            if (i < capSSDDeltaRow.length && capSSDDeltaRow[i] !== '-0.00') {
                ssdCapDeltaVal = capSSDDeltaRow[i];
            }

            var cpuCapDeltaPercentVal = "";
            if (i < capCPUDeltaPercentRow.length && capCPUDeltaPercentRow[i] !== '-0.00%') {
                cpuCapDeltaPercentVal = capCPUDeltaPercentRow[i];
            }

            var iopsCapDeltaPercentVal = "";
            if (i < capIOPSDeltaPercentRow.length && capSSDDeltaPercentRow[i] !== '-0.00%') {
                iopsCapDeltaPercentVal = capIOPSDeltaPercentRow[i];
            }

            var hddCapDeltaPercentVal = "";
            if (i < capHDDDeltaPercentRow.length && capSSDDeltaPercentRow[i] !== '-0.00%') {
                hddCapDeltaPercentVal = capHDDDeltaPercentRow[i];
            }

            var ssdCapDeltaPercentVal = "";
            if (i < capSSDDeltaPercentRow.length && capSSDDeltaPercentRow[i] !== '-0.00%') {
                ssdCapDeltaPercentVal = capSSDDeltaPercentRow[i];
            }

            var ttlItem: ITTLResultsData = {
                date: dateVal,
                cpuDemand: cpuDemandVal,
                iopsDemand: iopsDemandVal,
                hddDemand: hddDemandVal,
                ssdDemand: ssdDemandVal,
                cpuCapBefore: cpuCapBeforeVal,
                iopsCapBefore: iopsCapBeforeVal,
                hddCapBefore: hddCapBeforeVal,
                ssdCapBefore: ssdCapBeforeVal,
                cpuCapAfter: cpuCapAfterVal,
                iopsCapAfter: iopsCapAfterVal,
                hddCapAfter: hddCapAfterVal,
                ssdCapAfter: ssdCapAfterVal,
                cpuTTLBefore: cpuTTLBeforeVal,
                iopsTTLBefore: iopsTTLBeforeVal,
                hddTTLBefore: hddTTLBeforeVal,
                ssdTTLBefore: ssdTTLBeforeVal,
                cpuTTLAfter: cpuTTLAfterVal,
                iopsTTLAfter: iopsTTLAfterVal,
                hddTTLAfter: hddTTLAfterVal,
                ssdTTLAfter: ssdTTLAfterVal,
                cpuDemandDelta: cpuDemandDeltaVal,
                iopsDemandDelta: iopsDemandDeltaVal,
                hddDemandDelta: hddDemandDeltaVal,
                ssdDemandDelta: ssdDemandDeltaVal,
                cpuDemandDeltaPercent: cpuDemandDeltaPercentVal,
                iopsDemandDeltaPercent: iopsDemandDeltaPercentVal,
                hddDemandDeltaPercent: hddDemandDeltaPercentVal,
                ssdDemandDeltaPercent: ssdDemandDeltaPercentVal,
                cpuCapDelta: cpuCapDeltaVal,
                iopsCapDelta: iopsCapDeltaVal,
                hddCapDelta: hddCapDeltaVal,
                ssdCapDelta: ssdCapDeltaVal,
                cpuCapDeltaPercent: cpuCapDeltaPercentVal,
                iopsCapDeltaPercent: iopsCapDeltaPercentVal,
                hddCapDeltaPercent: hddCapDeltaPercentVal,
                ssdCapDeltaPercent: ssdCapDeltaPercentVal
            };

            items.push(ttlItem);
        }

        return items;
    }

    const columns: IColumn[] = [
        {
            styles: headerStyle,
            key: 'column0',
            name: 'Date',
            fieldName: 'date',
            minWidth: 60,
            maxWidth: 70,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.date}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column1',
            name: 'CPU TTL Current',
            fieldName: 'cpuTTLBefore',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.cpuTTLBefore < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.cpuTTLBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column2',
            name: 'CPU TTL With Recomendation',
            fieldName: 'cpuTTLAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.cpuTTLAfter < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.cpuTTLAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column3',
            name: 'IOPS TTL Current',
            fieldName: 'iopsTTLBefore',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.iopsTTLBefore < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.iopsTTLBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column4',
            name: 'IOPS TTL With Recomendation',
            fieldName: 'iopsTTLAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.iopsTTLAfter < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.iopsTTLAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column5',
            name: 'HDD TTL Current',
            fieldName: 'hhdTTLBefore',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.hddTTLBefore < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.hddTTLBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column6',
            name: 'HDD TTL With Recomendation',
            fieldName: 'hhdTTLAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.hddTTLAfter < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.hddTTLAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column7',
            name: 'SSD TTL Current',
            fieldName: 'ssdTTLBefore',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.ssdTTLBefore < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.ssdTTLBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column8',
            name: 'SSD TTL With Recomendation',
            fieldName: 'ssdTTLAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.ssdTTLAfter < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.ssdTTLAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column9',
            name: 'CPU Capacity Current',
            fieldName: 'cpuCapBefore',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.cpuCapBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column10',
            name: 'CPU Capacity With Recomendation',
            fieldName: 'cpuCapAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.cpuCapAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column11',
            name: 'CPU Capacity Delta (Current-Recommendation)',
            fieldName: 'cpuCapDelta',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'red' }}>{item.cpuCapDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column12',
            name: 'CPU Capacity Delta % (Current-Recommendation)',
            fieldName: 'cpuCapDeltaPercent',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'red' }}>{item.cpuCapDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column13',
            name: 'IOPS Capacity Current',
            fieldName: 'iopsCapBefore',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.iopsCapBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column14',
            name: 'IOPS Capacity With Recomendation',
            fieldName: 'iopsCapAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.iopsCapAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column15',
            name: 'IOPS Capacity Delta (Current-Recommendation)',
            fieldName: 'iopsCapDelta',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'red' }}>{item.iopsCapDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column16',
            name: 'IOPS Capacity Delta % (Current-Recommendation)',
            fieldName: 'iopsCapDeltaPercent',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'red' }}>{item.iopsCapDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column17',
            name: 'HDD Capacity Current',
            fieldName: 'hhdCapBefore',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.hddCapBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column18',
            name: 'HDD Capacity With Recomendation',
            fieldName: 'hhdCapAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.hddCapAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column19',
            name: 'HDD Capacity Delta (Current-Recommendation)',
            fieldName: 'hddCapDelta',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'red' }}>{item.hddCapDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column20',
            name: 'HDD Capacity Delta % (Current-Recommendation)',
            fieldName: 'hddCapDeltaPercent',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'red' }}>{item.hddCapDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column21',
            name: 'SSD Capacity Current',
            fieldName: 'ssdCapBefore',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.ssdCapBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column22',
            name: 'SSD Capacity With Recomendation',
            fieldName: 'ssdCapAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.ssdCapAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column23',
            name: 'SSD Capacity Delta (Current-Recommendation)',
            fieldName: 'ssdCapDelta',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'red' }}>{item.ssdCapDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column24',
            name: 'SSD Capacity Delta % (Current-Recommendation)',
            fieldName: 'ssdCapDeltaPercent',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'red' }}>{item.ssdCapDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column25',
            name: 'CPU Demand',
            fieldName: 'cpuDemand',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.cpuDemand}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column26',
            name: 'CPU Demand Delta',
            fieldName: 'cpuDemandDelta',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.cpuDemandDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column27',
            name: 'CPU Demand Delta %',
            fieldName: 'cpuDemandDeltaPercent',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.cpuDemandDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column28',
            name: 'IOPS Demand',
            fieldName: 'iopsDemand',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.iopsDemand}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column29',
            name: 'IOPS Demand Delta',
            fieldName: 'iopsDemandDelta',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.iopsDemandDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column30',
            name: 'IOPS Demand Delta %',
            fieldName: 'iopsDemandDeltaPercent',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.iopsDemandDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column31',
            name: 'HDD Demand',
            fieldName: 'hddDemand',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.hddDemand}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column32',
            name: 'HDD Demand Delta',
            fieldName: 'hddDemandDelta',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.hddDemandDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column33',
            name: 'HDD Demand Delta %',
            fieldName: 'hddDemandDeltaPercent',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.hddDemandDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column34',
            name: 'SSD Demand',
            fieldName: 'ssdDemand',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.ssdDemand}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column35',
            name: 'SSD Demand Delta',
            fieldName: 'ssdDemandDelta',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.ssdDemandDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column36',
            name: 'SSD Demand Delta %',
            fieldName: 'ssdDemandDeltaPercent',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.ssdDemandDeltaPercent}</span>;
            },
            isPadded: true,
        },
    ];

    const options: IDropdownOption[] = [
        { key: 'column0', text: 'Date' },
        { key: 'column1', text: 'CPU TTL Current' },
        { key: 'column2', text: 'CPU TTL With Recomendation' },
        { key: 'column3', text: 'IOPS TTL Current' },
        { key: 'column4', text: 'IOPS TTL With Recomendation' },
        { key: 'column5', text: 'HDD TTL Current' },
        { key: 'column6', text: 'HDD TTL With Recomendation' },
        { key: 'column7', text: 'SSD TTL Current' },
        { key: 'column8', text: 'SSD TTL With Recomendation' },
        { key: 'column9', text: 'CPU Capacity Current' },
        { key: 'column10', text: 'CPU Capacity With Recomendation' },
        { key: 'column11', text: 'CPU Capacity Delta (Current-Recommendation)' },
        { key: 'column12', text: 'CPU Capacity Delta % (Current-Recommendation)' },
        { key: 'column13', text: 'IOPS Capacity Current' },
        { key: 'column14', text: 'IOPS Capacity With Recomendation' },
        { key: 'column15', text: 'IOPS Capacity Delta (Current-Recommendation)' },
        { key: 'column16', text: 'IOPS Capacity Delta % (Current-Recommendation)' },
        { key: 'column17', text: 'HDD Capacity Current' },
        { key: 'column18', text: 'HDD Capacity With Recomendation' },
        { key: 'column19', text: 'HDD Capacity Delta (Current-Recommendation)' },
        { key: 'column20', text: 'HDD Capacity Delta % (Current-Recommendation)' },
        { key: 'column21', text: 'SSD Capacity Current' },
        { key: 'column22', text: 'SSD Capacity With Recomendation' },
        { key: 'column23', text: 'SSD Capacity Delta (Current-Recommendation)' },
        { key: 'column24', text: 'SSD Capacity Delta % (Current-Recommendation)' },
        { key: 'column25', text: 'CPU Demand' },
        { key: 'column26', text: 'CPU Demand Delta' },
        { key: 'column27', text: 'CPU Demand Delta %' },
        { key: 'column28', text: 'IOPS Demand' },
        { key: 'column29', text: 'IOPS Demand Delta' },
        { key: 'column30', text: 'IOPS Demand Delta %' },
        { key: 'column31', text: 'HDD Demand' },
        { key: 'column32', text: 'HDD Demand Delta' },
        { key: 'column33', text: 'HDD Demand Delta %' },
        { key: 'column34', text: 'SSD Demand' },
        { key: 'column35', text: 'SSD Demand Delta' },
        { key: 'column36', text: 'SSD Demand Delta %' },
    ];

    const _onRenderRow = (props: IDetailsRowProps, index: number) => {
        const rowStyles: Partial<IDetailsRowStyles> = {
            root: {
                background: props.itemIndex % 2 == 0 ? '#e3e3e3' : '#cacaca',
                color: 'black',
                marginBottom: '1px'
            },
        }
        return <DetailsRow {...props} styles={rowStyles} />;
    }

    function _getKey(item: any, index ?: number): string {
        return item.key;
    }

    const [detailColumns, setDetailColumns] = React.useState<IColumn[]>(columns.filter(col => defaultColumnKeys.includes(col.key)));

    const _onColumnFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                columnKeys.push(option.key.toString());
                setColumnKeys(columnKeys);
                setDetailColumns(columns.filter(col => columnKeys.includes(col.key)))
            }
            if (option.selected == false) {
                const arr_index = columnKeys.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = columnKeys.filter(key => key != option.key.toString());
                    setColumnKeys(new_arr);
                    setDetailColumns(columns.filter(col => new_arr.includes(col.key)));
                }
            }
        }
    }

    return (
        <div>
            {
                (isLoadingCPU && isLoadingIOPS && isLoadingHDD && isLoadingSSD) ?
                    <div style={{ margin: "2rem" }}>
                        <Spinner label="Fetching TTL metric information. This might take a while..." size={SpinnerSize.large} />
                    </div>
                    :
                    <div style={{ textAlign: "center" }}>
                        <PrimaryButton onClick={fetchNewTTL}>Compute new ttl</PrimaryButton>
                        <br />
                        <br />
                        <br />
                        <div style={{ width: '20%', marginLeft: '40%' }}>
                            <ColumnSelection options={ options} defaultOptions={columnKeys} onColFilter={_onColumnFilter} />
                        </div>
                        <br />
                        <DetailsList
                            items={genTableData()}
                            compact={true}
                            columns={detailColumns}
                            selectionMode={SelectionMode.none}
                            getKey={_getKey}
                            setKey="none"
                            onRenderRow={_onRenderRow}
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                        />
                        
                    </div>
            }
        </div>
    )
}
