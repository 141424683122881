import * as React from "react"
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/Dropdown";

interface IUsageTypeDropdownProps {
    selection? : string
    onChange? :  (event:React.FormEvent, selectedUsageTypeKey?: string) => void
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 120 },
};

const options: IDropdownOption[] = [
    { key: 'growth', text: 'Growth' },
    { key: 'upsku', text: 'UpSKU' },
    { key: 'upskuv2', text: 'UpSKU V2'},
];


export const UsageTypeDropdown: React.FC<IUsageTypeDropdownProps> = ({selection, onChange}) => {
    const onDropdownChange = (event:React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        onChange?.(event, item?.key.toString())
    }
    return (
        <Dropdown 
            placeholder="Select usage type"
            defaultSelectedKey={selection}
            onChange={onDropdownChange}
            options={options}
            styles={dropdownStyles}
        />
    )
}