import * as React from "react";
import { IAllocationPlan } from "../../../../models/AllocationPlan";

interface IAllocationPlanGroupProps {
    allocationPlans: IAllocationPlan[]
}
export const AllocationPlanGroupDetail: React.FC<IAllocationPlanGroupProps> = ({allocationPlans}) => {
    const plan = allocationPlans[0];

    const headerStyle = {
        borderRadius: "8px 8px 0 0",
        backgroundColor: "#0078d4",
        color: "white"
    }
    const rowStyle = {
        borderRadius: "0 0 8px 8px",
        backgroundColor: "#f3f2f1",
    }

    const contentStyle = {
        paddingLeft: "20px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingRight: "20px"
    }
    return (
        <div className="ms-Grid ms-font-s" dir="ltr">
            <div style={headerStyle}>
                <div className="ms-Grid-row" style={contentStyle}> 
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Region</div>
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">Forest</div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Growth</div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">UpSKU</div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">UpSKU V2</div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Status</div>

                </div>
            </div>
            <div style={rowStyle}>
                {allocationPlans.map((plan, index) => (
                    <div className="ms-Grid-row" key={index} style={contentStyle}>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{plan.region}</div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">{plan.forest?.split(".")[0]}</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{plan.growth}</div>
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{plan.upSku}</div>
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{plan.upSkuV2}</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{plan.status}</div>
                    </div>
                ))}
            </div>
        </div>
    )

};