import * as React from "react";
import { cops } from "../../../models/copsdata";
import { getCOPSMultitenants } from "../../../services/ttlservice";
import { useEffect, useState } from "react";

export const CopsTesting: React.FC = () => {

    const [copsdata, setCopsData] = useState<cops[]>([]);

    useEffect(() => {
        getCOPSMultitenants()
            .then(res => setCopsData(res))
            .catch(err => alert(err));
    }, [])

    return (
        <div>
            {copsdata.map(cops => <li>{cops.dataDate} -- {cops.forest} -- {cops.dagName} -- {cops.dagCountOfLiveDatabases}</li>)}
            </div>
    )
} 
