import { Configuration, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "2e2c338f-6162-45ab-988e-76766f0c3cad",
        authority: "https://login.microsoftonline.com/microsoft.onmicrosoft.com",
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    }
};

export const loginRequest: RedirectRequest = {
    scopes: ["api://44f1e23f-a26a-4433-afdd-90e2e19a7d63/ReadWrite"]
};