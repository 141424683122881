import * as React from "react";
import PageHeader from '../../common/pageheader/PageHeader';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import {
    MessageBar,
    MessageBarType,
    Link
} from '@fluentui/react';


export const StampLeadTimeReport: React.FC = () => {

    return (
        <div>
            <PageHeader
                title="M365 BackEnd Lead Time"
                description="This report measures the lead time for a backend stamp from order to live."
            />
            <div>
                <BodyPage />
            </div>
        </div>

    )
}

const BodyPage: React.FC = () => {
    return (
        <div>
            <MessageBar>
                Please go to
                <Link href="https://aka.ms/tesseract/m2cp/backendleadtime" inline>
                    aka.ms/tesseract/m2cp/backendleadtime
                </Link>{' '}
                to check original report
            </MessageBar>
            <iframe title="M365 BackEnd Lead Time"
                style={{ width: "100%", height: "900px", marginTop: '20px' }}
                src="https://aka.ms/tesseract/m2cp/backendleadtime-embeded"
                frameBorder={0}
                allowFullScreen={true}></iframe>
        </div>
    );
}