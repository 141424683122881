// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KAExej9McJwBBDqRjTia {
  background-color: #C8C8C8;
  margin: 0 16px;
  height: 1px;
}
.p8w3Oz0YZnVOY3haUUjt {
  overflow-y: auto;
  padding: 40px 48px;
  flex: 1 1 80%;
  overflow-x: hidden;
  background-color: #ffffff;
}
.UzHMSVUS6rXzSwufXqfy {
  flex: 0 0 10%;
  background-color: #E9E9E9;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/layout/PageLayout.less"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,cAAA;EACA,WAAA;AACJ;AAEA;EACI,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;AAAJ;AAGA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AADJ","sourcesContent":[".separator {\n    background-color: #C8C8C8;\n    margin: 0 16px;\n    height: 1px;\n}\n\n.pageContainer {\n    overflow-y: auto;\n    padding: 40px 48px;\n    flex: 1 1 80%;\n    overflow-x: hidden;\n    background-color: #ffffff\n}\n\n.leftPanel {\n    flex: 0 0 10%;\n    background-color: #E9E9E9;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": `KAExej9McJwBBDqRjTia`,
	"pageContainer": `p8w3Oz0YZnVOY3haUUjt`,
	"leftPanel": `UzHMSVUS6rXzSwufXqfy`
};
export default ___CSS_LOADER_EXPORT___;
