import * as React from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { TextField } from '@fluentui/react/lib/TextField';
import { Label } from '@fluentui/react/lib/Label';
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    Modal,
    IIconProps,
    Spinner,
    SpinnerSize,
    ITag,
} from '@fluentui/react';
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { useState } from 'react';
import { DefaultButton, PrimaryButton, IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { ISelectionPlan, IRecommendation } from "../../../../../models/DCselectionPlan";
import { updateDCSelectionPlan, getSimulatedPlans } from "../../../../../services/dcSelectionService"
import { number } from 'echarts';


interface IPlanModalProps {
    isModalOpen: boolean,
    hideModal: () => void,
    stamp: ISelectionPlan
}

export const PlanEditModal: React.FunctionComponent<IPlanModalProps> = (props) => {
    const [dropdownControlledOptions, setDropdownControlledOptions] = React.useState<IDropdownOption[]>([]);
    const { isModalOpen, hideModal } = props;
    let { stamp } = props;
    const [simulatedStamps, setSimulatedStamps] = React.useState<ISelectionPlan[]>([]);
    const [selectedSimulatedStamp, setSelectedSimulatedStamp] = React.useState<ISelectionPlan>();
    const [selectedCombination, setSelectedCombination] = React.useState<string>();
    const [priority, setPriority] = useState("");
    const [showAdd, setShowAdd] = React.useState<boolean>(true);
    const titleId = useId('title');
    const newstamp = JSON.parse(JSON.stringify(stamp));
    const [tmpStamps, setTmpStamps] = React.useState<ISelectionPlan>(newstamp);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    let selectedSimulatedId: string = "";
    const submitClicked = () => {
        for (var i: number = 0; i < stamp.recommendation.length; i++)
        {
            stamp.recommendation[i].priority = tmpStamps.recommendation[i].priority;
        }
        if (selectedCombination)
        {
            stamp.recommendation.push(selectedSimulatedStamp!.recommendation[0]);
            selectedSimulatedId = selectedSimulatedStamp!.id!;
        }
        saveDCSelectionPlan(stamp, selectedSimulatedId);
        hideModal();
    };

    const onRowRemoved = (rowNum: number) => {
        tmpStamps.recommendation = tmpStamps.recommendation.filter((re, id) => id!==rowNum)
        setTmpStamps(Object.assign({}, tmpStamps));
    };

    const closeOrUnfocusModal = () => {
        setTmpStamps({...stamp});
        hideModal();
    };

    const onChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setPriority("0");
        setSelectedCombination(item?.key.toString());
        const selectedStamp = simulatedStamps.filter(plan => plan.id! == item?.key)[0];
        setSelectedSimulatedStamp(selectedStamp);
    };

    const onPriorityChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
        setPriority(value == undefined ? "" : value);
        selectedSimulatedStamp!.recommendation[0].priority = value == undefined ? 0 : +value;
    }

    return (
    <div>
        <Modal
            titleAriaId={titleId}
            isOpen={isModalOpen}
            onDismiss={closeOrUnfocusModal}
            isBlocking={false}
            containerClassName={contentStyles.container}
        >
        <div className={contentStyles.header}>
        <span id={titleId}>{stamp.stampName}</span>
        <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={closeOrUnfocusModal}
        />
        </div>
        <div style={{marginTop: "2rem"}}></div>
        <div className="ms-Grid ms-font-s" dir="ltr" style={{ width: "600px", height: "650px"}}>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"></div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"><strong>Priority</strong></div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"></div>
                <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8"><strong>Recommended DC</strong></div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    {showAdd && <IconButton iconProps={{ iconName: 'Add'}} title="Add" ariaLabel="Add" onClick={addAllocationOverrideRow}/>}
                </div>
            </div>
            {
                isLoading?
                <div style={{margin: "1rem"}}>
                    <Spinner label="Fetching simulated stamp data..." size={SpinnerSize.large} />
                </div> 
                :
                !showAdd && 
                <div className="ms-Grid-row" style={{marginTop: "1rem", textAlign: "justify"}}>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"></div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"><TextField value={priority} onChange={onPriorityChange}/></div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"></div>
                    <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
                    <Dropdown
                        onChange={onChange}
                        placeholder="Select a combination"
                        options={dropdownControlledOptions}
                        styles={dropdownStyles}
                    />
                </div>
            </div>
            }
            {
                tmpStamps.recommendation.map((plan, index) => {
                    const group = [plan]
                    return <ModalListGroup key={index} rowNum={index} plan={group} onRemoved={onRowRemoved}/>
                })
            }
            <div className="ms-Grid-row" style={{marginTop: "2rem"}}>
                <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9"></div>
                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{textAlign: "right"}}>
                    <PrimaryButton text="Submit" onClick={submitClicked}/>
                </div>
            </div>
        </div>
        </Modal>
    </div>
);
    function addAllocationOverrideRow() {
        setIsLoading(true);
        getSimulatedPlans(stamp.stampName!).then(
            response => {
                setIsLoading(false);
                if (response.length == 0) {
                    window.alert("We cannot find this stamp in simulated results. Please check if this stamp info has been simulated.");
                } else {
                    setSimulatedStamps(response);
                    const dropdownControlledOptions = response.map(plan => {return {"key": plan.id!, "text": converDCListToString(plan.recommendation[0])}});
                    setDropdownControlledOptions(dropdownControlledOptions);
                    setShowAdd(!showAdd);
                }
            });
    }

    function converDCListToString(dcItem: IRecommendation){
        return  dcItem.dcs.reduce((pre, cur) => pre+cur.name+" ", "")
    }

    function saveDCSelectionPlan(changedPlan: ISelectionPlan, selectedId: string) {
        updateDCSelectionPlan(changedPlan, selectedId).then(response => {
            console.log(response.status);
            if (response.status == 200) {
                window.alert("Update successfully!");
            }
            else {window.alert("Fail to edit.")}});
    }
};


const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },    
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

interface IModalListGroup {
    rowNum: number,
    plan: IRecommendation[],
    onRemoved: (key: number) => void,
}
const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

const ModalListGroup: React.FC<IModalListGroup> = ({rowNum, plan, onRemoved}) => {
    const [priority, setPriority] = useState<string>(plan[0].priority.toString());

    const defaultSelectedDC: string = plan[0].dcs.reduce((pre, cur) => pre+cur.name+" ", "");
    const filterPromise = (dcsToReturn: ITag[]): ITag[] | Promise<ITag[]> => {
        return dcsToReturn
    };
    const onPriorityChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
        plan[0].priority = value == undefined ? 0 : +value;
        setPriority(value == undefined ? "" : value);
    }
    const getTextFromItem = (item: ITag) => item.name;
    const removeClicked = () => {
        onRemoved(rowNum);
    }

    return (
        <div className="ms-Grid-row" style={{marginTop: "1rem", textAlign: "justify"}}>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"></div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"><TextField value={priority.toString()} onChange={onPriorityChange} /></div>
            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"></div>
            <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
                <Label style={{"wordSpacing": "20px"}}>{defaultSelectedDC}</Label>
            </div>
        </div>
    )
    function removeDuplicates(dcs: ITag[], possibleDupes: ITag[]) {
        return dcs.filter(dc => !listContainsDC(dc, possibleDupes));
    }
    function listContainsDC(dc: ITag, dcs: ITag[]) {
        if (!dcs || !dcs.length || dcs.length === 0) {
            return false;
        }
        return dcs.filter(item => item.name === dc.name).length > 0;  
    }
    function doesTextStartWith(text: string, filterText: string): boolean {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
    }
}

