import * as React from "react"

import {
    TextField,
    Spinner,
    SpinnerSize,
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    PrimaryButton,
    ITooltipProps,
    ITooltipHostStyles,
    TooltipHost,
    TooltipDelay,
    DirectionalHint,
    Label,
    IIconProps,
    IconButton,
    Toggle,
    Link,
    Icon,
    mergeStyleSets,
    ActivityItem,
    IButtonStyles,
    MessageBar,
    MessageBarType
} from '@fluentui/react';
import { useId } from "@fluentui/react-hooks";
import { Dictionary } from "highcharts";
import { downloadStampSummary, fetchDeraStamps, fetchHLCStamps } from "../../../../services/costsavingservice";
import { IAIStamp, IDeraStamp, IHLCStamp, ISummaryStamp } from "../../../../models/CostSaving";
import { AITTLDeltasChart } from "../AITTLDeltasChart";
import FileDownLoad from 'js-file-download';

const TICKS_PER_MONTH = 4;

interface ITimelineProps {
    region: string
    environment: string
    startRange: number
    endRange: number
    aiStamps: IAIStamp[]
    hlcStamps: IHLCStamp[]
    threshold: number
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', width: '1vw', margin: '1px' } };
const calloutProps = { gapSpace: 0 };


const lineStyleTextDateTicks = {
    width: '96%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '2%',
    marginTop: '-1%'
}

const lineStyleTextLabels = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}

const circularButton = {
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent'
}

const scrollable = {
    overflowY: 'scroll',
    whiteSpace: 'nowrap',
    marginTop: '0px',
    marginLeft: '20px',
    marginRight: '20px',
    textAlign: 'justify',
    padding: '20px',
    height: '25vh',

}

const classNames = mergeStyleSets({
    exampleRoot: {
        marginTop: '20px',
    },
    nameText: {
        fontWeight: 'bold',
    },
});

const circleIcon: IIconProps = { iconName: 'CircleFill' };

const excelButton: Partial<IButtonStyles> = {
    background: "green",
    marginLeft: '1.9vw'
};

const monthNumberToName: Dictionary<string> = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
}

export const AITimeline: React.FC<ITimelineProps> = ({ region, environment, startRange, endRange, aiStamps, hlcStamps, threshold }) => {
  
    const START_DATE = new Date();
    START_DATE.setMonth(START_DATE.getMonth() + startRange);
    START_DATE.setDate(1);
    const END_DATE = new Date();
    END_DATE.setMonth(END_DATE.getMonth() + endRange);
    const TOTAL_MONTHS = 36;
    END_DATE.setDate(2);
    const GoLiveETA = new Date();

    function computeNumberOfTicks(): number {
        return (TOTAL_MONTHS - (TOTAL_MONTHS - endRange) - startRange) * TICKS_PER_MONTH + 1;
    }

    function roundSmallNumber(num: number) {
        var numString = num.toString();
        var numStringAfterDecimalPoint = numString.substring(2,);
        var i = 0;
        var zeroCount = 0;
        while (i < numStringAfterDecimalPoint.length && numStringAfterDecimalPoint[i] == "0") {
            zeroCount += 1;
            i += 1;
        }

        var numDecimalPlaces = 3;
        var newNum = num * Math.pow(10, zeroCount + numDecimalPlaces);
        var roundedNum = Math.round(newNum);
        return roundedNum / Math.pow(10, zeroCount + numDecimalPlaces);
    }

    function getGridStyle() {
        console.log("Number of ticks: ", computeNumberOfTicks());
        console.log("Number of boxes: ", computeNumberOfTicks() - 1);
        // Find the box size and scale it accordingly to timeline space we have
        var autoColSize = 1 / (computeNumberOfTicks() - 1) / 0.96;
        var roundedAutoColSize = roundSmallNumber(autoColSize);
        var autoColSizeText = roundedAutoColSize + "fr";
        console.log("Col size of box: ", autoColSize, " / Col size of box rounded: ", autoColSizeText);

        // Find the width size of the grid to support adding 1 more box
        var widthSize = +((0.96 / (1 - roundedAutoColSize)) * 100).toFixed(2);
        var widthSizeString = widthSize + "%";

        // Find the margin left value to align center of left most box with beginning of timeline
        var marginLeftSizeString = (2 - ((widthSize - 96) / 2)) + "%";

        const lineStyleText = {
            width: widthSizeString,
            display: 'grid',
            gridAutoColumns: autoColSizeText,
            gridAutoRows: '0.1fr',
            marginLeft: marginLeftSizeString,
        }

        return lineStyleText;
    }
    

    let [deraStamps, setDeraStamps] = React.useState<IDeraStamp[]>([]);
    let [showTTL, setShowTTL] = React.useState<boolean>(false);
    let [showDECOM, setShowDECOM] = React.useState<boolean>(false);

    function getDeraStamps() {
        fetchDeraStamps(region).then(
            response => {
                console.log("dera", response);
                setDeraStamps(response);
            },
            ex => {
                console.log("Failed to get DERA stamps");
            }
        )
    }

    React.useEffect(() => {
        getDeraStamps();
    }, []);

    function getDeraStamp(stampName: string) {
        var deraStamp: IDeraStamp = deraStamps.filter(stamp => stamp.deploymentGroupIDName === stampName).pop()!;
        if (deraStamp === undefined) {
            return null;
        }
        return deraStamp;
    }

    function getHLCStamp(stampName: string) {
        var hlcStamp: IHLCStamp = hlcStamps.filter(stamp => stamp.deploymentGroupIDName === stampName).pop()!;
        if (hlcStamp === undefined) {
            return null;
        }
        return hlcStamp;
    }

    function getTooltipProps(stampName: string, stampForest: string, goLiveETA: Date) {
/*        console.log("Tooltip stamp name: ", stampName);
        console.log("Tooltip go live eta: ", goLiveETA);*/
        var hlcStamp: IHLCStamp  | null = getHLCStamp(stampName);
        var deraStamp: IDeraStamp | null = getDeraStamp(stampName);
        var mdmids = deraStamp == null ? "" : deraStamp.mdmid!;
        var planIntent = hlcStamp == null ? "" : hlcStamp.planIntentName!;
        var cpu = (hlcStamp == null || hlcStamp.gCyclesLimit_Effi == null) ? "" : ("" + Math.round(hlcStamp.gCyclesLimit_Effi!));
        var iops = (hlcStamp == null || hlcStamp.iopsLimit_Effi == null) ? "" : ("" + Math.round(hlcStamp.iopsLimit_Effi!));
        var hdd = (hlcStamp == null || hlcStamp.maxEDBLimit == null) ? "" : ("" + Math.round(hlcStamp.maxEDBLimit!));
        var ssd = (hlcStamp == null || hlcStamp.ssdLimit == null) ? "" : ("" + Math.round(hlcStamp.ssdLimit!));

        var planDockDate = (deraStamp == null || deraStamp.planDockDate == null) ? "" : formatFullDate(deraStamp.planDockDate!);
        var etaDockDate = (deraStamp == null || deraStamp.etaDockDate == null) ? "" : formatFullDate(deraStamp.etaDockDate!);
        var actualDockDate = (deraStamp == null || deraStamp.act_Dock == null) ? "" : formatFullDate(deraStamp.act_Dock!);
        var planRTEGDate = (deraStamp == null || deraStamp.planRTEGDate == null) ? "" : formatFullDate(deraStamp.planRTEGDate!);
        var etaRTEGDate = (deraStamp == null || deraStamp.etartegDate == null) ? "" : formatFullDate(deraStamp.etartegDate!);
        var actualRTEGDate = (deraStamp == null || deraStamp.act_RTEG == null) ? "" : formatFullDate(deraStamp.act_RTEG!);
        var goLiveETAFormatted = formatFullDate(goLiveETA);

        const tooltipProps: ITooltipProps = {
            onRenderContent: () => (
                
                <div style={{ textAlign: 'center' }}>
                    <h2>{stampName}</h2>
                    <p>Go Live ETA: {goLiveETAFormatted}</p>
                    <p>Forest: {stampForest}</p>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>MDMIDS: {mdmids}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>Plan Intent: {planIntent}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>Plan Dock Date: {planDockDate}</p>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>CPU: {cpu}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>IOPS: {iops}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>HDD: {hdd}</p>
                            </div>

                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>SSD: {ssd}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>ETA Dock Date: {etaDockDate}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>Actual Dock Date: {actualDockDate}</p>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>Plan RTEG Date: {planRTEGDate}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>ETA RTEG Date: {etaRTEGDate}</p>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                <p>Actual RTEG Date: {actualRTEGDate}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        };
        return tooltipProps;
    }

    function dateDiff(date1: Date, date2: Date) {
        var diff = Math.abs(date1.getTime() - date2.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        //console.log("AI computing date diff for ", date1, " and ", date2, ": ", diffDays);
        return diffDays;
    }

    function convertDateToTickLocation(date: Date) {
        var totalTicks: number = computeNumberOfTicks();
        //console.log("Total ticks: ", totalTicks);
        var numDaysIn3Years = dateDiff(START_DATE, END_DATE);
//            (TOTAL_MONTHS - (TOTAL_MONTHS - endRange) - startRange) * 30.5; // Change this to num days in the range
        //console.log("Num days between start date", START_DATE, "and end date", END_DATE, ": ", numDaysIn3Years);
        var diffDays = dateDiff(date, START_DATE);
        //console.log("Num days between ", date, " and start date ", START_DATE, ": ", diffDays);
        var tick = (diffDays / numDaysIn3Years) * totalTicks; // proportion
        //console.log("Proportioned tick: ", tick);
        return tick;
    }

    function convertTickToFlexSize(stampTick: number, prevStampTick: number) {
        return (stampTick - prevStampTick);
    }

    /*function convertTextSizeToTickAmount() {
        var totalTicks: number = computeNumberOfTicks();
        return totalTicks * 0.0525;
    }*/

    function formatDate(date: Date) {
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        var monthString = monthNumberToName[month];

        return monthString + " " + year.toString();
    }

    function formatFullDate(date: Date) {
        if (date == undefined || date == null) {
            return "";
        }
        var dateString = date.toString();
        //console.log("Formatting full date: ", date);
        var objIndicatorIndex = dateString.indexOf("Time");
        
        var day = 0;
        var month = 0;
        var year = 0;
        var dayString = "";
        var monthString = "";
        if (objIndicatorIndex == -1) {
            //console.log("Time indicator method");
            var timeIndicatorIndex = dateString.indexOf("T");
            dateString = dateString.substring(0, timeIndicatorIndex);
            var dateArr = dateString.split("-");
            //console.log(dateArr);

            day = +dateArr[2];
            month = +dateArr[1];
            year = +dateArr[0];
            dayString = day < 10 ? "0" + day.toString() : day.toString();
            monthString = monthNumberToName[month];
        } else {
            //console.log("Date object method");
            dateString = dateString.substring(4, 15);
            var dateArr = dateString.split(" ");
            //console.log(dateArr);

            dayString = dateArr[1];
            monthString = dateArr[0];
            year = +dateArr[2];
        }

        //console.log(monthString + " " + dayString + ", " + year);
        return monthString + " " + dayString + ", " + year;
    }

    function dateComparison(date1: Date, date2: Date) {
        if (date1 == date2) {
            return 0;
        } else if (date1 < date2) {
            return -1;
        }
        return 1;
    }

    function getAllDecomAIStamps() {
        var decomStamps: IAIStamp[] = [];
        hlcStamps.forEach(stamp => { 
            var stampName: string = stamp.deploymentGroupIDName!;
            var stampForest: string = stamp.forest!;
            var planIntent = stamp.planIntentName;
            if (planIntent == "DecommPair") {
                //console.log("converting", stampName, "into AI stamp");
                var newAIStamp: IAIStamp = {
                    write_time: new Date(),
                    region: region,
                    environment: environment,
                    recommended_date: stamp.goliveETA,
                    deploymentGroupIDName: stampName,
                    forest: stampForest
                }
                //console.log("Converted stamp: ", newAIStamp);
                decomStamps.push(newAIStamp);
            }
        })

        return decomStamps;
    }

    function generateTimelineDots() {
        //console.log("generating stamp dots");
        var items = [];

        var prevStampTick = 0;

        var decomAIStamps: IAIStamp[] = getAllDecomAIStamps();

        var combinedAIStamps = decomAIStamps.concat(aiStamps);
        combinedAIStamps.sort((a, b) => dateComparison(a.recommended_date, b.recommended_date));

        var row = 1;
        combinedAIStamps.forEach(stamp => {
            if ((decomAIStamps.includes(stamp) && showDECOM) || aiStamps.includes(stamp)) {
                var stampName: string = stamp.deploymentGroupIDName;
                var stampForest: string = stamp.forest;
                var sgoLiveETA: Date = stamp.recommended_date;
                var dateString = sgoLiveETA.toString().split("T")[0];
                //console.log("stamp info: ", stampName, "on", dateString);
                var dateComponents = dateString.split("-");
                var year = +dateComponents[0];
                var month = +dateComponents[1] - 1;
                var day = +dateComponents[2];
                GoLiveETA.setFullYear(year);
                GoLiveETA.setMonth(month);
                GoLiveETA.setDate(day);
                //console.log("comparing", GoLiveETA.toString(), "with", END_DATE.toString(), "is: ", dateComparison(GoLiveETA, END_DATE));
                if (dateComparison(START_DATE, GoLiveETA) < 1 && dateComparison(GoLiveETA, END_DATE) < 1) {
                    var stampTick = Math.floor(convertDateToTickLocation(GoLiveETA)) + 1;
                    var flexSize = convertTickToFlexSize(stampTick, prevStampTick);
                    //console.log("stamp tick (column): ", stampTick);
                    //console.log("flex size: ", flexSize);
                    if (flexSize == 0) {
                        row += 1;
                    } else {
                        row = 1;
                    }
                    //console.log("row: ", row);

                    items.push(
                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', gridRow: row, gridColumn: stampTick, margin: 0 }}>
                            <TooltipHost
                                tooltipProps={getTooltipProps(stampName, stampForest, GoLiveETA)}
                                calloutProps={calloutProps}
                                delay={TooltipDelay.zero}
                                directionalHint={DirectionalHint.topCenter}
                                styles={hostStyles}
                            >
                                <IconButton iconProps={circleIcon} disabled style={circularButton} />
                            </TooltipHost>

                        </div>);
                    prevStampTick = stampTick;
                }
            }

        });

        return items;
    }

    function generateTimelineLabels() {
        var items = [];
        var dateTicks = 1.6; // average pixel proportion size of a date converted into ticks+

        // add the starting date
        items.push(<Label style={{ textAlign: 'center' }}>{formatDate(START_DATE)}</Label>);

        // add the ending line 
        var totalTicks: number = computeNumberOfTicks();
        var endLineTicks = convertTickToFlexSize(totalTicks, dateTicks);
        endLineTicks -= dateTicks;
        items.push(<div style={{ flex: endLineTicks, height: '0px', backgroundColor: 'black' }} />);

        // add the ending date
        items.push(<Label style={{ textAlign: 'center' }}>{formatDate(END_DATE)}</Label>);

        return items;
    }

    function generateTimelineDateTicks() {
        var items = [];
        var monthFlex = 4;
        var sixMonthLimit = 6;
        var firstWeek = 7;
        var secondWeek = 15;
        var thirdWeek = 23;
        var fourthWeek = 31;

        // add the starting tick
        items.push(<div style={{ borderLeft: '4px solid', height: '2vh', backgroundColor: 'black' }} />);

        for (var i = 0; i < (TOTAL_MONTHS - (TOTAL_MONTHS - endRange) - startRange); i++) {
            if (i != 0) {
                if (i == (sixMonthLimit - startRange)) {
                    var today = new Date();
                    var todayDay = today.getDate();
                    var adjustedFlex = 0;
                    if (todayDay <= firstWeek) {
                        adjustedFlex = 1;
                    } else if (todayDay <= secondWeek) {
                        adjustedFlex = 2;
                    } else if (todayDay <= thirdWeek) {
                        adjustedFlex = 3;
                    } else {
                        adjustedFlex = 4;
                    }

                    items.push(<div style={{ borderLeft: '4px solid', height: '1vh', backgroundColor: 'black' }} />);
                    items.push(<div style={{ flex: adjustedFlex, height: '4px', backgroundColor: 'black' }} />);
                    items.push(<div style={{ borderLeft: '4px solid', height: '2vh', color: 'orange' }} />);
                    items.push(<div style={{ flex: (monthFlex - adjustedFlex), height: '4px', backgroundColor: 'black' }} />);

                } else {
                    items.push(<div style={{ borderLeft: '4px solid', height: '1vh', backgroundColor: 'black' }} />);
                    items.push(<div style={{ flex: monthFlex, height: '4px', backgroundColor: 'black' }} />);
                }
            } else {
                items.push(<div style={{ flex: monthFlex, height: '4px', backgroundColor: 'black' }} />);
            }

            
        }

        // add the ending tick
        items.push(<div style={{ borderLeft: '4px solid', height: '2vh', backgroundColor: 'black' }} />);

        return items;
    }

    // Flex works proportionally to all other flex elements in the div

    function generateSummary() {
        //console.log("Generating summary");
        var items = [];
        var i = 0;
        var totalRemovedDagCount = 0;
        hlcStamps.forEach(stamp => {
            var foundDecom = false;
            var stampName: string = stamp.deploymentGroupIDName!;
            var planIntent = stamp.planIntentName;
            var currentGoLiveETA: Date = stamp.goliveETA;
            //console.log("Generating summary action for ", stampName);
            if (planIntent == "DecommPair") {
                //console.log("Stamp is DECOM");
                if (dateComparison(START_DATE, new Date(currentGoLiveETA)) < 1 &&
                    dateComparison(new Date(currentGoLiveETA), END_DATE) < 1) {
                    var actItem = (
                        {
                            key: { i },
                            activityDescription: [
                                <Link
                                    key={1}
                                    className={classNames.nameText}
                                >
                                    AI
                                </Link>,
                                <span key={2}> keeps DECOM stamp </span>,
                                <span key={3} className={classNames.nameText}>
                                    {stampName}
                                </span>,
                            ],
                            comments: [
                                <span key={1} >Go Live ETA Date of </span>,
                                <span key={2} className={classNames.nameText}> {formatFullDate(currentGoLiveETA)} </span>,
                                <span key={3} > remains the same </span>,
                            ],
                            activityIcon: <Icon iconName={'RenewalCurrent'} />,

                        }
                    );
                    items.push(<ActivityItem {...actItem} key={actItem.key} className={classNames.exampleRoot} />);
                }
                foundDecom = true;
            }
            

            if (!foundDecom) {
                var stampIndex: number = aiStamps.findIndex(s => {
                    return s.deploymentGroupIDName == stampName;
                });

                //console.log("Stamp index is ", stampIndex);

                if (stampIndex == -1) {
                    //console.log("Stamp not found in AI stamps so must be removed");
                    if (dateComparison(START_DATE, new Date(currentGoLiveETA)) < 1 &&
                        dateComparison(new Date(currentGoLiveETA), END_DATE) < 1) {
                        var dagCount: number = stamp.dagCount!;
                        var actItem = (
                            {
                                key: { i },
                                activityDescription: [
                                    <Link
                                        key={1}
                                        className={classNames.nameText}
                                    >
                                        AI
                                    </Link>,
                                    <span key={2}> removes </span>,
                                    <span key={3} className={classNames.nameText}>
                                        {stampName}
                                    </span>,
                                ],
                                comments: [
                                    <span key={1} >Removing a total of </span>,
                                    <span key={2} className={classNames.nameText}> {dagCount} </span>,
                                    <span key={3} >DAGs </span>,
                                ],
                                activityIcon: <Icon iconName={'Trash'} />,

                            }
                        );
                        items.push(<ActivityItem {...actItem} key={actItem.key} className={classNames.exampleRoot} />);
                        totalRemovedDagCount += dagCount;
                    }
                } else {
                    var aiStamp: IAIStamp = aiStamps[stampIndex];
                    var newGoLiveETA: Date = aiStamp.recommended_date;
                    if (dateComparison(START_DATE, new Date(newGoLiveETA)) < 1 &&
                        dateComparison(new Date(newGoLiveETA), END_DATE) < 1) {
                        if (dateComparison(currentGoLiveETA, newGoLiveETA) == 0) {
                            //console.log("Stamp is found but go live eta date remained the same");
                            var actItem = (
                                {
                                    key: { i },
                                    activityDescription: [
                                        <Link
                                            key={1}
                                            className={classNames.nameText}
                                        >
                                            AI
                                        </Link>,
                                        <span key={2}> keeps </span>,
                                        <span key={3} className={classNames.nameText}>
                                            {stampName}
                                        </span>,
                                    ],
                                    comments: [
                                        <span key={1} >Go Live ETA Date of </span>,
                                        <span key={2} className={classNames.nameText}> {formatFullDate(currentGoLiveETA)} </span>,
                                        <span key={3} > remains the same </span>,
                                    ],
                                    activityIcon: <Icon iconName={'RenewalCurrent'} />,

                                }
                            );
                            items.push(<ActivityItem {...actItem} key={actItem.key} className={classNames.exampleRoot} />);
                            //items.push(<li style={{ padding: 5 }}>- Recommending maintaining <span style={{ fontWeight: 700 }}>{stampName}</span>'s Go Live ETA of <span style={{ fontWeight: 700 }}>{formatFullDate(currentGoLiveETA)}</span></li>);
                        } else {
                            //console.log("Stamp is found but go live eta date changed");
                            var actItem = (
                                {
                                    key: { i },
                                    activityDescription: [
                                        <Link
                                            key={1}
                                            className={classNames.nameText}
                                        >
                                            AI
                                        </Link>,
                                        <span key={2}> changes </span>,
                                        <span key={3} className={classNames.nameText}>
                                            {stampName}
                                        </span>,
                                    ],
                                    comments: [
                                        <span key={1} >Go Live ETA date of </span>,
                                        <span key={2} className={classNames.nameText}> {formatFullDate(currentGoLiveETA)} </span>,
                                        <span key={3} > changed to </span>,
                                        <span key={4} className={classNames.nameText}> {formatFullDate(newGoLiveETA)} </span>,
                                    ],
                                    activityIcon: <Icon iconName={'Edit'} />,

                                }
                            );
                            items.push(<ActivityItem {...actItem} key={actItem.key} className={classNames.exampleRoot} />);
                            //items.push(<li style={{ padding: 5 }}>- Recommending update of <span style={{ fontWeight: 700 }}>{stampName}</span>'s Go Live ETA from <span style={{ fontWeight: 700 }}>{formatFullDate(currentGoLiveETA)}</span> to <span style={{ fontWeight: 700 }}>{formatFullDate(newGoLiveETA)}</span></li>);
                        }
                    }
                }
            }

            i++;
        })
        return items;
    }
    function onClickDownload() {
        var summaryStamps: ISummaryStamp[] = [];
        hlcStamps.forEach(hlcStamp => {
            var foundDecom = false;
            var stampName: string = hlcStamp.deploymentGroupIDName!;
            var deraStamp: IDeraStamp | null = getDeraStamp(stampName);

            var mdmidsString = "";
            var actionString = "";
            var newRecDateString = "";

            var planIntent = hlcStamp.planIntentName;
            if (planIntent == "DecommPair") {
                actionString = "Keep";
                foundDecom = true;
            }

            if (deraStamp != null) {
                mdmidsString = deraStamp.mdmid!;
            }

            if (!foundDecom) {
                var stampIndex: number = aiStamps.findIndex(s => {
                    return s.deploymentGroupIDName == stampName;
                });

                if (stampIndex == -1) {
                    actionString = "Remove";
                } else {
                    actionString = "Update";
                    var currentGoLiveETA: Date = hlcStamp.goliveETA;
                    var aiStamp: IAIStamp = aiStamps[stampIndex];
                    var newGoLiveETA: Date = aiStamp.recommended_date;
                    console.log("Old date: ", currentGoLiveETA);
                    console.log("Updated date: ", newGoLiveETA);
                    console.log(dateComparison(currentGoLiveETA, newGoLiveETA));
                    if (dateComparison(currentGoLiveETA, newGoLiveETA) != 0) {
                        console.log("Diff dates");
                        newRecDateString = formatFullDate(newGoLiveETA);
                        console.log(newRecDateString);
                    }
                }
            }

            var summaryStamp: ISummaryStamp = {
                region: hlcStamp.region!,
                environment: hlcStamp.environment!,
                stampName: stampName,
                sku: hlcStamp.sku!,
                forest: hlcStamp.forest!,
                mdmids: mdmidsString,
                dagCount: hlcStamp.dagCount!,
                goliveETA: formatFullDate(hlcStamp.goliveETA),
                action: actionString,
                newlyRecommendedDate: newRecDateString
            };
            summaryStamps.push(summaryStamp);
        });

        let today = new Date();
        var todayDay = today.getDate() < 10 ? "0" + today.getDate().toString() : today.getDate().toString();
        var todayMonth = (today.getMonth() + 1) < 10 ? "0" + (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString();
        var todayYear = today.getFullYear();
        var workbookName = "Cost-Saving-Summary" + "-" + todayYear + "-" + todayMonth + "-" + todayDay + ".xlsx";
        console.log(workbookName);

        downloadStampSummary(summaryStamps).then(
            response => {
                response.blob().then(blobResponse => {
                    FileDownLoad(blobResponse, workbookName);
                    console.log("Successfully downloaded results");
                })
                
            },
            ex => {
                console.log("Failed to download results: " + ex.message);
            }
        );
    }
    function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
        setShowTTL(checked!);
    }

    function _onChangeDecomToggle(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
        setShowDECOM(checked!);
    }

    return (

        <div>
            
            {
                (hlcStamps.length == 0 || deraStamps.length == 0) ?
                    <div style={{ margin: "2rem" }}>
                        <Spinner label="Generating recommendation timeline & summary. This might take a while..." size={SpinnerSize.large} />
                    </div>
                    :
                    <div>
                        <MessageBar messageBarType={MessageBarType.warning}>
                            Please note that the orange line indicates the 6 month period from todays date in which no stamp before this date is changed.
                        </MessageBar>
                        <br />
                        <br />
                        <div>
                            <Toggle label="Show DECOM stamps" inlineLabel onChange={_onChangeDecomToggle} />
                        </div>
                        <br />
                        <div style={lineStyleTextDateTicks}>
                            {generateTimelineDateTicks()}
                        </div>
                        <div style={lineStyleTextLabels}>
                            {generateTimelineLabels()}
                        </div>
                        <div style={getGridStyle()}>
                            {generateTimelineDots()}
                        </div>
                        <br />
                        <br />
                        <br />
                        <PrimaryButton style={excelButton} onClick={onClickDownload}>Download summary to excel</PrimaryButton>
                        <div style={scrollable}>
                            {generateSummary()}
                        </div>
                        <br />
                        <br />
                        
                        <div>
                            <Toggle label="Show AI recommendation TTL" inlineLabel onChange={_onChange} />
                        </div>
                        {showTTL &&
                            <div>
                                <MessageBar messageBarType={MessageBarType.warning}>
                                    Please note that the before TTL values here represent the case where all non-decom stamps and
                                    those with go live eta 6 months after todays date are "deselected" (orders are temporarily removed). This 
                                    is to show the TTL change after the stamps are placed in their new corresponding dates.
                                </MessageBar>
                                <br />
                                <AITTLDeltasChart environment={environment} region={region} hlcStamps={hlcStamps} aiStamps={aiStamps} deraStamps={deraStamps} threshold={threshold} startRange={startRange} endRange={endRange} />
                            </div>
                        }
                    </div>
            }
            
        </div>
    );
};