import { ISelectionPlan, IStampInfo } from "../models/DCselectionPlan";
import { ApiServiceBase, getJson, postJson, post } from "../utils/apiServiceBase";


const api = new ApiServiceBase("https://intelligentplacement.azurewebsites.net");

export async function fetchTotalNumPage() {
    return await getJson<number>("dcselection/total_pages");
}

export async function fetchFilterNumPage(filterParams: object) {
    return await postJson<number>(`dcselection/filter_total_pages`, filterParams)
}

export async function fetchDCSelectionPlans(pagenum: number) {
    return await getJson<ISelectionPlan[]>(`dcselection/dcSelection_plan?index=${pagenum}`)
}

export async function fetchDCSelectionPermissions(username: string) {
    return await getJson<Object>(
        "dcselection/dcselection_permissions?username="+username
    )
}


export function fetchSimulationParams(requestParam: object) {
    const jsonResult = postJson<ISelectionPlan>(
        "dcselection/simulate_dcselection",
        requestParam
    )
    return jsonResult
}

export async function fetchPlanIntent() {
    return await getJson<string[]>("dcselection/plan_intent");
}

export async function fetchCapacityGeoCode() {
    return await getJson<string[]>("dcselection/capacity_geo_code");
}

export async function fetchFiscalYear() {
    return await getJson<string[]>("dcselection/fiscal_year");
}
export async function updateDCSelectionPlan(dcPlan: ISelectionPlan, simulatedId: string) {
    return await post(
        `dcselection/update_dc_plan?simulatedId=${simulatedId}`,
        dcPlan
    )
}

export async function postFilterParams(filterParams: object, index: number) {
    return await postJson<ISelectionPlan[]>(`dcselection/filter_params?index=${index}`, filterParams)
}

export async function getSimulatedPlans(stampName: string) {
    return await getJson<ISelectionPlan[]>("dcselection/simulated_plans?stampName=" + stampName);
}

export async function fetchDCNames(dcCostSnapshotDate : Date) {
    return await getJson<string[]>(`dcselection/dc_names?dcCostSnapshotDate=${dcCostSnapshotDate}`);
}

export async function fetchStampInfo(stampName: string) {
    return await getJson<IStampInfo>("dcselection/stamp_info?stampName=" + stampName);
}