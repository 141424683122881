// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tH2Davfzv78FkqF2mMLg {
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 20px;
}
.D36I4fW7NDS4AmuygvoU {
  color: white;
}
.D36I4fW7NDS4AmuygvoU:hover {
  background-color: unset;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/banner/AppBanner.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAEA;EACI,YAAA;AAAJ;AAEI;EACI,uBAAA;AAAR","sourcesContent":[".logo {\n    font-size: 1.5em;\n    font-weight: bold;\n    margin-right: 20px;\n}\n\n.iconButton {\n    color: white;\n\n    &:hover {\n        background-color: unset;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `tH2Davfzv78FkqF2mMLg`,
	"iconButton": `D36I4fW7NDS4AmuygvoU`
};
export default ___CSS_LOADER_EXPORT___;
