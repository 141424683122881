import * as React from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

interface IEnvFilterProp {
    defaultOptions: string[],
    onColFilter: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void,
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
};

const options: IDropdownOption[] = [
    { key: 'region', text: 'Region' },
    { key: 'env', text: 'Environment' },
    { key: 'todayttlssd', text: 'SSD TTL (Today)' },
    { key: 'todayttlssdmcdb', text: 'SSD MCDB TTL (Today)' },
    { key: 'todayttlssdphoenix', text: 'SSD Phoenix TTL (Today)' },
    { key: 'todayttlcpu', text: 'CPU TTL (Today)' },
    { key: 'todayttliops', text: 'IOPS TTL (Today)' },
    { key: 'todayttlhdd', text: 'HDD TTL (Today)' },  
    { key: 'todayttlmin', text: 'TTL min (Today)' },
    { key: '6mttl', text: 'TTL min (at 6m)' },
    { key: '12mttl', text: 'TTL min (at 12m)' },
    { key: 'minttl', text: 'TTL min (next 12m)' },
    { key: 'currentfyttl', text: 'End of FY' + (new Date().getMonth() + 1 > 7 ? new Date().getFullYear() + 1 : new Date().getFullYear()) + ' TTL', },
    { key: 'nextfyttl', text: 'End of FY' + (new Date().getMonth() + 1 > 7 ? new Date().getFullYear() + 2 : new Date().getFullYear() + 1) + ' TTL', },
    { key: 'minttldate', text: 'Date of TTL min (next 12m)' },
    { key: 'servertoday', text: 'Server Status (today)' },
    { key: 'server', text: 'Server Status (next 12m)' },
    { key: 'activeuserratio', text: 'Active User Ratio' },
    { key: 'livedag', text: 'Live Dags' },
    { key: 'dagratio', text: 'Dag Ratio' },
    { key: 'newdags', text: 'New Dags Till FY' + (new Date().getMonth() + 1 >= 7 ? new Date().getFullYear() + 1 : new Date().getFullYear()) },
    { key: 'hdddemand', text: 'HDD Demand Total (Today)' },
    { key: 'hddenterprisedemand', text: 'HDD Demand Enterprise (Today)' },
    { key: 'hddconsumerdemand', text: 'HDD Demand Consumer (Today)' },
    { key: 'hddenterpriseratio', text: 'HDD Demand Enterprise % (Today)' },
    { key: 'hddconsumerratio', text: 'HDD Demand Consumer % (Today)' },
    { key: 'rootcause', text: 'Root Cause' },
    { key: 'mitigation', text: 'Mitigation Strategy' },
    { key: 'recovery', text: 'Recovery Date' },
    { key: 'impact', text: 'Customer Impact' },
];

const stackTokens: IStackTokens = { childrenGap: 20 };

export const ColumnSelection: React.FunctionComponent<IEnvFilterProp> = (props) => {

    return (
        <Stack tokens={stackTokens}>
            <Dropdown
                placeholder="Select options"
                label="Select Columns"
                defaultSelectedKeys={props.defaultOptions}
                multiSelect
                options={options}
                styles={dropdownStyles}
                onChange={props.onColFilter}
            />
        </Stack>
    );
};
