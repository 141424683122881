import * as React from "react";
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { IStyleSet, Label, ILabelStyles, Pivot, PivotItem } from '@fluentui/react';
import PageHeader from '../../common/pageheader/PageHeader';
import {
    MessageBar,
    MessageBarType,
    Link
} from '@fluentui/react';

const HDD_LINK = "https://msit.powerbi.com/reportEmbed?reportId=856594b6-6715-41dc-9c75-0da650e5f734&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D";
const CPU_LINK = "https://msit.powerbi.com/reportEmbed?reportId=798910e9-6f17-4502-9f57-396a7b41866b&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D";
const IOPS_LINK = "https://msit.powerbi.com/reportEmbed?reportId=150fe2eb-1102-4b9c-bdb7-2e18c14960ec&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D";
const SSD_LINK = "https://msit.powerbi.com/reportEmbed?reportId=e9adbed5-8dc1-4b46-9a5a-b8e79924f1ad&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D";


export const PrivateReport: React.FC = () => {
    return (
        <div>
            <PageHeader
                title="Private Reports"
                description="The reports are created by each project owners"
            />
            <BodyPage />
        </div>

    )
}

const BodyPage: React.FC = () => {
    /*if (props.securityGroups.includes("special groups")) {*/
        return (
            <div>
                <Pivot aria-label="Basic Pivot Example" linkFormat="tabs">
                    <PivotItem
                        headerText="  HDD  "
                    >
                        <MessageBar>
                            Please go to
                            <Link href={HDD_LINK} inline>
                                HDD link
                            </Link>{' '}
                            to check original report
                        </MessageBar>
                        <iframe title="HDD"
                            style={{ width: '100%', height: '1000px', marginTop: "20px", borderTopStyle: "groove" }}
                            src={HDD_LINK}
                            frameBorder={0}
                            allowFullScreen={true}></iframe>
                    </PivotItem>
                    <PivotItem headerText="CPU">
                        <MessageBar>
                            Please go to
                            <Link href={CPU_LINK} inline>
                                CPU link
                            </Link>{' '}
                            to check original report
                        </MessageBar>
                        <iframe title="CPU"
                            style={{ width: '100%', height: '1000px', marginTop: "20px", borderTopStyle: "groove" }}
                            src={CPU_LINK}
                            frameBorder={0}
                            allowFullScreen={true}></iframe>
                    </PivotItem>
                    <PivotItem headerText="IOPS">
                        <MessageBar>
                            Please go to
                            <Link href={IOPS_LINK} inline>
                                IOPS link
                            </Link>{' '}
                            to check original report
                        </MessageBar>
                        <iframe title="IOPS"
                            style={{ width: '100%', height: '1000px', marginTop: "20px", borderTopStyle: "groove" }}
                            src={IOPS_LINK}
                            frameBorder={0}
                            allowFullScreen={true}></iframe>
                    </PivotItem>
                    <PivotItem headerText="SSD">
                        <MessageBar>
                            Please go to
                            <Link href={SSD_LINK} inline>
                                SSD link
                            </Link>{' '}
                            to check original report
                        </MessageBar>
                        <iframe title="SSD"
                            style={{ width: '100%', height: '1000px', marginTop: "20px", borderTopStyle: "groove" }}
                            src={SSD_LINK}
                            frameBorder={0}
                            allowFullScreen={true}></iframe>
                    </PivotItem>
                    {/*<PivotItem headerText="Memory">
                        <iframe title="Memory"
                            style={{ width: '100%', height: '1000px', marginTop: "30px", borderTopStyle: "groove" }}
                            src="https://msit.powerbi.com/reportEmbed?reportId=04d95e5c-4f80-423a-b05b-f07a024e784d&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
                            frameBorder={0}
                            allowFullScreen={true}></iframe>
                    </PivotItem>*/}
                </Pivot>
                
            </div>
        );
    /*} else {
        return (
            <div>
                Oooops, you don't have authorization to view the page, please go to IDWeb to request access to group blabla..
            </div>
        )
    }*/
}