
import axios from "axios";
import { IAllocationOverrideEntry } from "../models/AllocationOverride";
import { IAllocationPlan } from "../models/AllocationPlan";
import { ICapEx, ICapacityGenerationProfileEntry, IDecomPlan, IDemandPlanEntry, IForestSupplyAndDemand, IHardwareRoadmapEntry, IProfile, ISellableProfileEntry } from "../models/DemandPlanGenerationModel";
import { ApiServiceBase, getJson } from "../utils/apiServiceBase";

const api = new ApiServiceBase(SERVICE_ENDPOINT);


export function fetchForestSnd(forestAlias: string) {
    return api.getJson<IForestSupplyAndDemand>(`DemandPlanGeneration/snd/all/${forestAlias}`);
    //return api.get('DemandPlanGeneration/test')
}

export function fetchRegionSnd(region: string, sndVersion: string) {
    return api.getJson<IForestSupplyAndDemand>(`DemandPlanGeneration/sndregion/all/${region}/${sndVersion}`);
    //return api.get('DemandPlanGeneration/test')
}

export function fetchRnf() {
    return api.get('DemandPlanGeneration/rnf');
}

export function fetchDecomPlans(region: string) {
    return api.getJson<IDecomPlan[]>(`DemandPlanGeneration/decom/${region}`);
}

export function fetchDcSelectionToken() {
    return api.get('DemandPlanGeneration/dcselectiontoken');
}
export function generateDecomDemandPlans() {
    console.log("generating Decom Demand Plans");
    api.getJson<IDecomPlan[]>('DemandPlanGeneration/decom/nam').then((response) => {
    });
}

export function fetchScenarios() {
    return api.getJson<IProfile[]>('DemandPlanGeneration/GetScenarios?capacityType=Backend');
}

export function fetchScenarioDetail(scenarioId: string) {
    return api.getJson(`DemandPlanGeneration/GetScenarioDetail?scenarioId=${scenarioId}`);
}

export function saveScenarioDetail(scenarioPayload: any) {
    return api.post('DemandPlanGeneration/CreateScenario', scenarioPayload);
}

export function fetchCapacityGenerationProfiles() {
    return api.getJson<IProfile[]>('DemandPlanGeneration/GetCapacityGenerationProfiles');
}

export function fetchCapacityGenerationProfileDetail(profileId: string) {
    return api.getJson<ICapacityGenerationProfileEntry[]>(`DemandPlanGeneration/GetCapacityGenerationProfileDetail?profileId=${profileId}`);
}

export function saveCapacityGenerationProfileDetail(profilePayload: any) {
    return api.post('DemandPlanGeneration/CreateCapacityGenerationProfile', profilePayload);
}

export function fetchSellableProfiles() {
    return api.getJson<IProfile[]>('DemandPlanGeneration/GetSellableProfiles');
}

export function fetchSellableProfileDetail(profileId: string) {
    return api.getJson<ISellableProfileEntry[]>(`DemandPlanGeneration/GetSellableProfileDetail?profileId=${profileId}`);
}

export function fetchDefaultSellableProfile() {
    return fetchSellableProfileDetail("0");
}

export function saveSellableProfile(profilePayload: any) {
    return api.post('DemandPlanGeneration/CreateSellableProfile', profilePayload); 
}

export function fetchHardwareRoadmapProfiles() {
    return api.getJson<IProfile[]>('DemandPlanGeneration/GetHWRoadmaps');
}

export function saveHardwareRoadmapProfile(formData: any) {
    return api.postForm("DemandPlanGeneration/CreateHWRoadmap", formData); 
    // return axios.post(SERVICE_ENDPOINT + "/DemandPlanGeneration/CreateHWRoadmap", profilePayload);
}

export function fetchHardwareRoadmapDetail(profileId: string) {
    return api.getJson<IHardwareRoadmapEntry[]>(`DemandPlanGeneration/GetHWRoadmapDetail?profileId=${profileId}`);
}

export function fetchCapEx(region: string, skuFamily: string) {
    return api.getJson<ICapEx[]>(`DemandPlanGeneration/Getcapex?region=${region}&skuFamily=${skuFamily}`);
}

export function fetchForecastVersions() {
    return api.getJson<string[]>('DemandPlanGeneration/GetForecastVersions');
}