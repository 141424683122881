import React from 'react'
import * as XLSX from 'xlsx';
import {
    TextField,
    Spinner,
    SpinnerSize,
    PrimaryButton,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import PageHeader from '../../../common/pageheader/PageHeader';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { useBoolean } from '@fluentui/react-hooks';
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { EfficiencyTracker } from "../../../../models/PCM"
import { uploadEfficiencyProjects } from "../../../../services/pcmService"

export const EfficiencyUploadPage: React.FC = () => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const [upLoading, setUpLoading] = React.useState(false);
    const [upLoadMsg, setUpLoadMsg] = React.useState("");
    const [upLoadMsgType, setUpLoadMsgType] = React.useState(MessageBarType.success);
    const [rawExcel, setRawExcel] = React.useState([]);
    const [projects, setProjects] = React.useState<EfficiencyTracker[]>([]);

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array", cellDates: true });
                const sheetName = "Data To Submit";
                const worksheet = workbook.Sheets[sheetName];
                const efficiencyProjects = XLSX.utils.sheet_to_json(worksheet);                
                setRawExcel(efficiencyProjects)
                updateEfficiencyProjects(efficiencyProjects)            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const updateEfficiencyProjects = (rawProjects) => {
        const updatedProjects: EfficiencyTracker[] = rawProjects.map(p => {
            const d: EfficiencyTracker = {
                writeTime: new Date(),
                affects: p["Affects"],
                confidenceLevel: p["Confidence Level"],
                createdBy: account?.username,
                efficiencyName: p["Efficiency Name"],
                owner: p["Owner"],
                projectId: p["Project ID"],
                qualityForMap: p["Qualify For MAP"],
                region: p["Region"],
                resource: p["Resource"],
                submissionDate: new Date(p["Submission Date"]),
                submissionStatus: p["Submission Status"],
                valueMagnitud: p["Value Magnitud"],
                valueMonth: new Date(p["Value Month"]),
                valueType: p["Value Type"],
                resourceUOM: p["Resource_UOM"]                
            }
            return d;
        });
        console.log(rawProjects);
        setProjects(updatedProjects);
    }

    const submitProjects = () => {
        if (projects.length == 0) {
            setUpLoadMsgType(MessageBarType.error);
            setUpLoadMsg("Please upload excel before submitting.");
        } else {
            setUpLoading(true);
            setUpLoadMsg("");
            uploadEfficiencyProjects(projects)
                .then(response => {
                    if (response.status == 200) {
                        setUpLoading(false);
                        setUpLoadMsg("Succeeded to upload!");
                        setUpLoadMsgType(MessageBarType.success);
                        setProjects([]);
                    } else {
                        setUpLoading(false);
                        if (response.status == 400) {
                            setUpLoadMsg("Failed to upload! Failed status is " + response.status + ", excel contains data with unexpected format");
                        } else {
                            setUpLoadMsg("Failed to upload! Failed status is " + response.status);
                        }
                        
                        setUpLoadMsgType(MessageBarType.error);
                        setProjects([]);
                    }
                    
                })
                .catch(err => { setUpLoading(false); setUpLoadMsg("Failed to upload due to " + err); setUpLoadMsgType(MessageBarType.error) });
        }      
    }

    return (
            <div className="ms-Fabric ms-font-su" style={{margin: ""}}>
                <PageHeader 
                title='Efficiency Tracker'
                description="Track the efficiency projects."
            />

            <MessageBar messageBarType={MessageBarType.warning}>Please make sure the data is stored in sheet <b>"Data To Submit"</b> of the excel. </MessageBar>
            <div className="ms-Grid" style={{ marginTop: "2rem", marginBottom: "1rem" }}>                
                <div className="ms-Grid-row">
                    <input
                        type="file"
                        name="upload"
                        id="upload"
                        onChange={readUploadFile} />
                </div>
                <div className="ms-Grid-row" style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                    <PrimaryButton onClick={submitProjects }>Submit</PrimaryButton>
                </div>
                <div className="ms-Grid-row" style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                    {(upLoading) && <Spinner label="Upload..." size={SpinnerSize.large} style={{ marginTop: '300px' }} />}
                    {(!upLoading && upLoadMsg != "") && <MessageBar messageBarType={upLoadMsgType}> {upLoadMsg}</MessageBar>}
                </div>
            </div>
            </div>
    );
}
