import { sum, values } from "lodash";
import { calculatePrecision, INavLink, INavLinkGroup, INavStyles, IRenderGroupHeaderProps, Nav, Stack, IStackStyles, Pivot } from "@fluentui/react";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import styles from "./Navigation.less";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ISideBarData, loadSideBarsIfNeeded } from "../../../reducers/sideBarReducer";
import { IAppState } from "../../../store";
import { useMsal } from "@azure/msal-react";

interface INavigationProps {
    links: INavLinkGroup[],
    active?: string
}

const outerStackStyles: IStackStyles = {
    root: {
        flex: "0 0 10%",
        height: "100%",
        boxSizing: "border-box",
        border: "1px solid #E1E1E1",
        overflowY: "auto",
        minWidth: "205px",
        background: "white"
    }
};

const navStyles: Partial<INavStyles> = {
    groupContent: {
        marginBottom: 12
    },
    linkText: {
        left: "48px",
        top: calculatePrecision(50% - '20px'/2),
        height: "20px",
        width: "184px",
        fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
    },
    link: {
        selectors: {
            "&:after": { 
              'border-left': "4px solid",
              'border-left-color': "#0078D4",
            },
        },
        '.ms-Icon': {
            'font-size': "14px",
            color: "#323130",
            width: 16
        },
        '.ms-Button-flexContainer': {
            'margin-left': "12px"
        },
    },
};

const expertInsightsStyles: Partial<INavStyles> = {
    groupContent: {
        marginBottom: 12
    },
    linkText: {
        left: "48px",
        top: calculatePrecision(50% - '20px'/2),
        height: "20px",
        width: "184px",
        fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
    },
    link: {
        selectors: {
            "&:after": { 
              'border-left': "4px solid",
              'border-left-color': "#0078D4",
            },
        },
        '.ms-Icon': {
            'font-size': "14px",
            color: "#323130",
            width: 16
        },
        '.ms-Button-flexContainer': {
            'margin-left': "12px"
        },
    },
};

const quickLinkStyles: Partial<INavStyles> = {
    groupContent: {
        marginBottom: 12
    },
    linkText: {
        left: "48px",
        top: calculatePrecision(50% - '20px'/2),
        height: "20px",
        width: "184px",
        fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
    },
    link: {
        selectors: {
            "&:after": { 
              'border-left': "4px solid",
              'border-left-color': "#0078D4",
            },
        },
        '.ms-Icon': {
            'font-size': "14px",
            color: "#323130",
            width: 16
        },
        '.ms-Button-flexContainer': {
            'margin-left': "12px"
        },
    },
};

const ttlLinks: INavLinkGroup[] = [
    {
        links: [
            {
                name: "Home",
                key: "/Home",
                url: "/Home",
                icon: "Home"
            },
        ]
    },
    {
      name: 'Time-to-Live (TTL)',
      links: [
        {
            name: "Multi-metrics Report",
            key: "/TTLReport/MultiMetrics",
            url: "/TTLReport/MultiMetrics",
              title: "The report includes demand, supply, and the corresponding time-to-live (TTL) for CPU/IOPS/HDD/SSD/Memory of Substrate Backend",
              icon: "AnalyticsView"
        },
        {
            name: "Private Reports",
            key: "/TTLReport/PrivateReport",
            url: "/TTLReport/PrivateReport",
            title: "The reports are created by each project owners, only for team member review",
            icon: "AreaChart"
        }
      ],
    },
    {
        name: 'Hardware LifeCycle',
        links: [
            {
                name: "Report",
                key: "/HLC/Report",
                url: "/HLC/Report",
                title: "The report contains the historical and future supply telemetry for CPU/IOPS/HDD/SSD/Memory of Substrate Backend",
                icon: "AnalyticsView"
            },
        ],

    },
    {
        name: 'Allocation Map',
        links: [
            {
                name: "Overview",
                key: "/Allocation/AllocationOverview",
                url: "/Allocation/AllocationOverview",
                icon: "TaskGroup"
            },
            {
                name: "Override",
                key: "/Allocation/AllocationOverride",
                url: "/Allocation/AllocationOverride",
                icon: "EditCreate"
            },
            {
                name: "Simulation",
                key: "/Allocation/AllocationSimulation",
                url: "/Allocation/AllocationSimulation",
                icon: "Puzzle"
            }
        ],
    },
    {
        name: 'DC Selection Map',
        links: [
            {
                name: "Overview",
                key: "/DCSelection/Overview",
                url: "/DCSelection/Overview",
                icon: "TaskGroup"
            },
            {
                name: "Simulation",
                key: "/DCSelection/DCSelectionSimulation",
                url: "/DCSelection/DCSelectionSimulation",
                icon: "Puzzle"
            },
            {
                name: "Comparison",
                key: "/DCSelection/DCSelectionComparison",
                url: "/DCSelection/DCSelectionComparison",
                icon: "Contrast"
            }
        ],
    },
    {
        name: 'Demand Plan',
        links: [
            {
                name: "Simulation",
                key: "/DemandPlan/Simulate",
                url: "/DemandPlan/Simulate",
                icon: "Puzzle"
            }
        ],

    },
    {
        name: 'Alerting',
        links: [
            {
                name: "Config",
                key: "/Alerting/Config",
                url: "/Alerting/Config",
                title: "The page is to configure parameters to load alerting report",
                icon: "Settings"
            },
            {
                name: "Health",
                key: "/Alerting/Report",
                url: "/Alerting/Report",
                title: "Capacity health monitoring dashboard",
                icon: "Hospital"
            }
        ],

    },
  ];


const quickLinks: INavLinkGroup[] = [
    {
        name: 'Quick Links',
        links: [
            {
                name: "Wiki",
                url: "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/48552/Substrate-BE-Multi-metric-capacity-planning-(M2CP)",
                target: "_blank",
                icon: "Link"
            }
        ],

    },
];


const Navigation: React.FC<INavigationProps> = (props) => {
    const history = useHistory();
    
    const handleClick = useCallback((ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        if (item?.target !== "_blank") {
            ev?.preventDefault();
            if (item?.url) {
                history.push(item.url);
            }
        }
    }, [history]);

    return (

        <Stack styles={outerStackStyles}>
            <div style={{marginTop: "60px"}}></div>
            <Nav
                onRenderGroupHeader={_onRenderGroupHeader}
                styles={expertInsightsStyles}
                groups={props.links}
                selectedKey={props.active}
                onLinkClick={handleClick}
            />
            <Stack.Item shrink={0}>
                <div className={styles.separator} />
            </Stack.Item>
            <Nav
                onRenderGroupHeader={_onRenderGroupHeader}
                styles={quickLinkStyles}
                groups={quickLinks}
                onLinkClick={handleClick}
            />
        </Stack>
    );
};

function _onRenderGroupHeader(group?: IRenderGroupHeaderProps): JSX.Element | null {
    if (!group) return null; 
    return <div className={styles.groupHeader}>{group.name}</div>;
}


export default Navigation;