import * as React from "react";
import PageHeader from '../../common/pageheader/PageHeader';
import {
    MessageBar,
    MessageBarType,
    Link
} from '@fluentui/react';
import { IStyleSet, Label, ILabelStyles, Pivot, PivotItem } from '@fluentui/react';

export const ForecastAccuracyReport: React.FC = (props) => {
    return (
        <div>
            <PageHeader
                title="Demand Forecast"
                description="This section shows the Substrate backends' SSD/HDD/CPU/IOPS organic demand forecast accuracy and month over month change"
            />

            <Pivot aria-label="Basic Pivot Example" linkFormat="tabs">
                <PivotItem
                    headerText="Organic forecast accuracy"
                >
                    <MessageBar>
                        Please go to
                        <Link href="https://aka.ms/tesseract/m2cp/forecastaccuracy" inline>
                            aka.ms/tesseract/m2cp/forecastaccuracy
                        </Link>{' '}
                        to check original report
                    </MessageBar>
                    <iframe title="M2CP"
                        style={{ width: '100%', height: '850px', marginTop: '20px' }}
                        src="https://aka.ms/tesseract/m2cp/forecastaccuracy"
                        allowFullScreen={true}></iframe>
                </PivotItem>
                <PivotItem headerText="Forecast MoM">
                    <MessageBar>
                        Please go to
                        <Link href="https://msit.powerbi.com/groups/4f254de0-2683-463d-ac25-26bc56d20496/reports/a09a6482-0532-4852-b103-43e3e2f58b33/ReportSection" inline>
                            the link
                        </Link>{' '}
                        to check original report
                    </MessageBar>
                    <iframe title="Forecast MoM"
                        style={{ width: '100%', height: '1000px', marginTop: "20px", borderTopStyle: "groove" }}
                        src="https://msit.powerbi.com/reportEmbed?reportId=a09a6482-0532-4852-b103-43e3e2f58b33&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47"
                        frameBorder={0}
                        allowFullScreen={true}></iframe>
                </PivotItem>
            </Pivot>
            
        </div>

    )
}