import { ActionTypes } from "../actions/ActionTypes";
import { IBuyCakeAction } from "../actions/CakeActionsExample"
import { Reducer } from "redux"

export interface ICakeData {
    numberOfCakes: number;
}

const initState = {
    numberOfCakes: 10
}

export const cakeReducer: Reducer<ICakeData, IBuyCakeAction> = (state: ICakeData = initState, action: IBuyCakeAction): ICakeData => {
    switch (action.type) {
        case ActionTypes.BuyCakeActionExample: return {
            ...state,
            numberOfCakes: state.numberOfCakes - 1
        }
        default: return state
    }

}

export const makeBuyCakeAction = () => {
    return {
        type: ActionTypes.BuyCakeActionExample
    }
}