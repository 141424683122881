import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import { TextField } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { SpinButton, ISpinButtonStyles } from '@fluentui/react/lib/SpinButton';
import { getConfigsNew, updateConfigsNew } from '../../../services/alertingservice';
import { AlertingConfig, AlertingConfigNew } from '../../../models/alerting';
import LoadingState from '../../common/LoadingState';
import { useBoolean } from '@fluentui/react-hooks';
import {
    MessageBar,
    MessageBarType,
    Spinner,
    IDropdownOption
} from '@fluentui/react';
import PageHeader from '../../common/pageheader/PageHeader';
import {
    DetailsList,
    DetailsListLayoutMode,
    IDetailsListStyles,
    IDetailsHeaderProps,
    ConstrainMode,
    IDetailsFooterProps,
    DetailsRow,
    IDetailsColumnStyles,
    IColumn,
    IDetailsRowProps,
    IDetailsRowStyles,
    IDetailsColumnRenderTooltipProps,   
} from '@fluentui/react/lib/DetailsList';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { TTLConfigTableModal } from './modules/TTLConfigTableModal';
import { IconButton } from '@fluentui/react/lib/Button';
import { EnvFilter } from '../alerting/modules/EnvFilter'
import { RegionFilter } from '../alerting/modules/RegionFilter'
import { getBuffer, saveBuffer } from '../../../services/alertingservice'
import { Buffer } from '../../../models/alerting'
import { IUserPermissionData } from "../../../reducers/userPermissionReducer";
import { IAppState } from "../../../store";
import { useSelector } from "react-redux";
import { checkUserPermission } from "../../../utils/utils"
import { ToolType } from "../../../utils/ToolConstants"

const headerStyle: Partial<IDetailsColumnStyles> = {
    cellTitle: {
        color: 'white',
        backgroundColor: 'black',
        marginLeft: '1px'
    }
}

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );
    return defaultRender!({
        ...props,
        styles: {
            root: {
                selectors: {
                    '.ms-DetailsHeader-cell': {
                        whiteSpace: 'pre-wrap',
                        textOverflow: 'clip',
                        lineHeight: 'normal',
                    },
                    '.ms-DetailsHeader-cellTitle': {
                        height: '100%',
                        alignItems: 'center',
                    },
                },
            },
        },
        onRenderColumnHeaderTooltip,
    });
};

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                background: 'none',
                color: 'black'
            },
        },
    },
    headerWrapper: {
        flex: '0 0 auto',
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
};

const columns: IColumn[] = [{
    styles: headerStyle,
    key: 'geoCode',
    name: 'Region',
    fieldName: 'geoCode',
    isResizable: true,
    minHeight: 20,
    minWidth: 130,
    maxWidth: 130,
    ariaLabel: "GeoCode"
},
{
    styles: headerStyle,
    key: 'environment',
    name: 'Environment',
    fieldName: 'environment',
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    ariaLabel: "Environment"
},
{
    styles: headerStyle,
    key: 'hotWatchZonePrev',
    name: 'Hot/Watch Limit Prev',
    fieldName: 'hotWatchZonePrev',
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    ariaLabel: "Hot/Watch Limit Prev"
},
{
    styles: headerStyle,
    key: 'hotWatchZoneCurrent',
    name: 'Hot/Watch Limit Current',
    fieldName: 'hotWatchZoneCurrent',
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    ariaLabel: "Watch/Healthy Limit Current"
},
{
    styles: headerStyle,
    key: 'watchHealthyZonePrev',
    name: 'Watch/Healthy Limit Prev',
    fieldName: 'watchHealthyZonePrev',
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    ariaLabel: "Watch/Healthy Limit Prev"
    },
    {
        styles: headerStyle,
        key: 'watchHealthyZoneCurrent',
        name: 'Watch/Healthy Limit Current',
        fieldName: 'watchHealthyZoneCurrent',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        ariaLabel: "Watch/Healthy Limit Current"
    },
    {
        styles: headerStyle,
        key: 'healthyColdZonePrev',
        name: 'Healthy/Cold Limit Prev',
        fieldName: 'healthyColdZonePrev',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        ariaLabel: "Healthy/Cold Limit Prev"
    },
    {
        styles: headerStyle,
        key: 'healthyColdZoneCurrent',
        name: 'Healthy/Cold Limit Current',
        fieldName: 'healthyColdZoneCurrent',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        ariaLabel: "Healthy/Cold Limit Current"
    },
    {
        styles: headerStyle,
        key: 'maxEDB',
        name: 'MaxEDB',
        fieldName: 'maxEDB',
        isResizable: true,
        minWidth: 30,
        maxWidth: 100,
        ariaLabel: "MaxEDB"
    }
];

export const BufferGeneration: React.FC = () => {
    const defaultRegion = ['Europe', 'Latam', 'United States', 'Australia', 'Brazil', 'Canada',
        'China', 'France', 'Germany', 'India', 'Japan', 'Korea', 'Norway', 'Qatar', 'Singapore',
        'South Africa', 'Sweden', 'Switzerland', 'UAE', 'United Kingdom', 'Asia Pacific']
    const defaultEnv = ['Go Local', 'Multitenant', 'Government', 'BlackForest', 'Gallatin', 'ITAR', 'MSIT', 'SDF', 'TDF', 'Dedicated']

    const [regions, setRegions] = React.useState(defaultRegion);
    const [envs, setEnvs] = React.useState(defaultEnv);
    const [fiscalYear, setFiscalYear] = React.useState("2019");
    const [percentile, setPercentile] = React.useState("90");
    const [showErr, setShowErr] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState("");
    const [msgType, setMsgType] = React.useState(MessageBarType.error);
    const [loadingRegionBuffer, setLoadingRegionBuffer] = React.useState(false);
    const [showTable, setShowTable] = React.useState(false);
    const [detailsList, setDetailsList] = React.useState<Buffer[]>([]);
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const { userpermissions } = useSelector<IAppState, IUserPermissionData>(state => state.userPermissions);

    const _onRenderRow = (props: IDetailsRowProps, index: number) => {
        const rowStyles: Partial<IDetailsRowStyles> = {
            root: {
                background: props.itemIndex % 2 == 0 ? '#e3e3e3' : '#cacaca',
                color: 'black',
                marginBottom: '1px'
            },
        }
        return <DetailsRow {...props} styles={rowStyles} />;
    }

    const _renderItemColumn = (item: Buffer, index: number, column: IColumn) => {

        switch (column.key) {
            case 'hotWatchZoneCurrent':
            case 'watchHealthyZoneCurrent': 
            case 'healthyColdZoneCurrent':
                return <div style={{ fontSize: "18px" }}>{item[column.key].toFixed(2)}</div>
            default:
                return <div style={{ wordWrap: "break-word", fontSize: "18px", whiteSpace: "pre-wrap" }} >{item[column.key]}</div>;
        }
    }



    const _onRegionFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                regions.push(option.key.toString());
                setRegions(regions);
            }
            if (option.selected == false) {
                const arr_index = regions.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = regions.filter(key => key != option.key.toString());
                    setRegions(new_arr);
                }
            }
        }
    }

    

    const _onEnvFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                envs.push(option.key.toString());
                setEnvs(envs);
            }
            if (option.selected == false) {
                const arr_index = envs.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = envs.filter(key => key != option.key.toString());
                    setEnvs(new_arr);
                }
            }
        }
    }

    const _onFiscalYearChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setFiscalYear(newValue == undefined ? "2019" : newValue);
    }

    const _onPercentileChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPercentile(newValue == undefined ? "90" : newValue);
    }

    const _isInputValid = (value?: string): boolean => {
        if (value != undefined) {
            return !isNaN(parseInt(value));
        }
        return false;
    }

    const _onBufferGenerationClick = () => {
        var err = "";
        const isFiscalYearValid = _isInputValid(fiscalYear);
        if (!isFiscalYearValid) {
            err += "\n Fiscal Year must be non-empty integer;";
        } 
        const isPercentileValid = _isInputValid(percentile);
        if (!isPercentileValid) {
            err += "\n Percentile must be non-empty integer;";
        } else {
            if (parseInt(percentile) > 100 || parseInt(percentile) < 0) {
               err += "\n Percentile must be between 0 and 100;";
            }          
        }

        if (err != "") {
            setShowErr(true);
            setErrMsg(err);
            setMsgType(MessageBarType.error);
        } else {
            setShowErr(false);
            setErrMsg("");
            setLoadingRegionBuffer(true);
            setShowTable(false);
            getBuffer(regions.join(","), envs.join(","), parseInt(fiscalYear), parseInt(percentile))
                .then(res => {
                    setLoadingRegionBuffer(false);
                    setDetailsList(res);
                    setShowTable(true);

                })
                .catch(err => {
                    setLoadingRegionBuffer(false);
                    setShowErr(true);
                    setErrMsg(err);
                    setMsgType(MessageBarType.error);
                })
        }
    }

    const _onSaveBuffer = () => {
        const date = new Date();
        const buffer = detailsList.map(d => {
            const alertingConfigNew: AlertingConfigNew = {
                version: date,
                region: d.region,
                configType: 'TTL',
                geoCode: d.geoCode,
                environment: d.environment,
                sev1: parseFloat(d.hotWatchZoneCurrent.toFixed(2)),
                sev2: parseFloat(d.watchHealthyZoneCurrent.toFixed(2)),
                sev3: parseFloat(d.healthyColdZoneCurrent.toFixed(2)),
                maxEDB: d.maxEDB,
                changedBy: account?.username
            }
            return alertingConfigNew;
        });
        saveBuffer(buffer)
            .then(res => {
                setMsgType(MessageBarType.success);
                setErrMsg("Successfully save to DB...");
                setShowErr(true);
            })
            .catch(err => {
                setMsgType(MessageBarType.error);
                setErrMsg("Can't save to DB due to " + err);
                setShowErr(true);
            })
    }

    return (
        <div>
            <PageHeader
                title="Buffer Strategy Generation"
                description="This page is to generate buffer strategy which will influence short-/mid-term capacity management and long-term capacity planning"
            />

            <div>
                <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    <li>The buffer is the order to live duration of stamps in the specific region and environment</li>
                    <li>Order to Live duration = <b>Before DC Reservation(13 weeks)</b> + <b>DC Reservation to RTEG(ihub)</b> + <b>RTEG to Live(Assembly Line)</b></li>
                </MessageBar>
            </div>

            <div style={{ display: "grid", gridTemplateColumns: "20% 1% 20% 1% 20% 1% 20% 1% 10%", marginTop: "20px"}}>
                <RegionFilter onRegionFilter={_onRegionFilter} defaultOptions={defaultRegion} />
                <div></div>
                <EnvFilter defaultOptions={defaultEnv} onEnvFilter={_onEnvFilter} />
                <div></div>
                <TextField label="Fiscal Year From" required value={fiscalYear} onChange={_onFiscalYearChange}></TextField>
                <div></div>
                <TextField label="Percentile" required value={percentile} onChange={_onPercentileChange}></TextField>
                <div></div>
                <div style={{ marginTop: "28px" }}><PrimaryButton text="Generate Buffer" onClick={_onBufferGenerationClick} /></div>
            </div>
            {showErr && <MessageBar messageBarType={msgType} isMultiline={true}
                dismissButtonAriaLabel="Close" onDismiss={() => { setShowErr(false); setErrMsg("") }}>{errMsg}</MessageBar>}
            {showTable && < div >
                {checkUserPermission(userpermissions, ToolType.CAPACITYHEALTH, 'edit') && < div style={{ marginTop: "20px" }}><PrimaryButton text="Save Buffer" onClick={_onSaveBuffer} /></div>}
                <DetailsList
                    compact={true}
                    items={detailsList}
                    columns={columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    selectionPreservedOnEmptyClick
                    onRenderRow={_onRenderRow}
                    onRenderItemColumn={_renderItemColumn}
                    styles={gridStyles}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                    selectionMode={SelectionMode.none}
                />
            </div>}
            
            {loadingRegionBuffer && <div style={{ marginTop: "50px" }}><Spinner label="Generate buffer...." /></div>}
        </div>
    );
}
