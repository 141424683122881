import * as React from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

interface IModeSelectionProp {
    onModeSelection: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void,
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
};

const options: IDropdownOption[] = [
    { key: 'NEXT12MONTHMIN', text: 'Next 12 Months' },
    { key: 'TODAYMIN', text: 'Today' },
];

const stackTokens: IStackTokens = { childrenGap: 20 };

export const StatusTrendModeSelection: React.FunctionComponent<IModeSelectionProp> = (props) => {

    return (
        <Stack tokens={stackTokens}>
            <Dropdown
                placeholder="Select Mode"
                label="Select Mode"
                defaultSelectedKey="TODAYMIN"
                options={options}
                styles={dropdownStyles}
                onChange={props.onModeSelection}
            />
        </Stack>
    );
};
