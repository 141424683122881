import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { HashRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { msalInstance } from "./utils/msalInstance";
import { Provider } from "react-redux";
import store from "./store";

// Fluent UI icons.
initializeIcons();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <HashRouter>
                <MsalProvider instance={msalInstance}>
                    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest} >
                        <App />
                    </MsalAuthenticationTemplate>
                </MsalProvider>
            </HashRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);