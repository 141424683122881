import * as React from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    Modal,
    IIconProps,
    IDropdownOption
} from '@fluentui/react';
import { DefaultButton, PrimaryButton, IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { useState } from 'react';
import { Link } from "@fluentui/react";

import { ICOGSCalculatorRequest } from '../../../../models/PCM';
import { StatusSelection } from './StatusSelection'
import { round } from 'lodash';

interface IRequestEditModalProps {
    isModalOpen: boolean,
    hideModal: () => void,
    request: ICOGSCalculatorRequest,
    permission: boolean,
    save: (config: ICOGSCalculatorRequest) => void,
}

export const RequestEditModal: React.FunctionComponent<IRequestEditModalProps> = (props) => {
    const { isModalOpen, hideModal, request, permission } = props;
    const originalRequest = { ...request }
    const onStatusFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (request != undefined) {
                request.approveStatus = option.key.toString();
            }           
        }
    }

    const onClick = () => {
        request.approveStatus = originalRequest.approveStatus;
        hideModal();
    }
    
    // Use useId() to ensure that the IDs are unique on the page.
    // (It's also okay to use plain strings and manually ensure uniqueness.)
    const titleId = useId('title');

    return (
        <div>
            <Modal
                titleAriaId={titleId}
                isOpen={isModalOpen}
                onDismiss={hideModal}
                isBlocking={false}
                containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header}>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={onClick}
                    />
                </div>
                <div>
                    <div style={{ width: "600px", height: "750px" }}>
                        <div style={{ marginLeft: "30px"}}><p style={{marginTop: '0px', marginBottom: '5px', fontWeight: 700, fontSize: 17}}>Request details: </p></div>
                        <div className="ms-Grid" dir="ltr" style={{marginLeft: "30px", marginTop: '2px'}}>
                            <div className="ms-Grid-row">                    
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '2px', marginBottom: '2px'}}>WorkItem ID</p>
                                    <Link style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}} href={"https://o365exchange.visualstudio.com/O365 Core/_workitems/edit/"+request.workItemId} target="_blank">{request.workItemId}</Link>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '2px', marginBottom: '2px'}}>Status</p>
                                    <p style={{color: setColor(request.approveStatus), marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{request.approveStatus} </p>
                                </div>
                            </div>
                            <div className="ms-Grid-row">                    
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Tentative Start Date</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{formatDate(request.tentativeStartDate)}</p>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Frequency</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{request.frequency}</p>
                                </div>
                            </div>
                            <div className="ms-Grid-row">                    
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Griffin App Name</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{request.griffinAppName}</p>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Model</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{request.model}</p>
                                </div>
                            </div>
                            <div className="ms-Grid-row">                    
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Submitter</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{request.capacityReviewSubmittedBy}</p>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Submit Time</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{formatDate(request.capacityReviewSubmittedTime)}</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginLeft: "30px"}}><p style={{marginTop: '10px', marginBottom: '5px', fontWeight: 700, fontSize: 17}}>PCM details: </p></div>
                        <div className="ms-Grid" dir="ltr" style={{marginLeft: "30px", marginTop: '2px'}}>
                            <div className="ms-Grid-row">                    
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '2px', marginBottom: '2px'}}>Current Item Reads</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{request.currentItemReads.toLocaleString()} </p>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Expected Item Reads</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{request.expectedItemReads.toLocaleString()}</p>
                                </div>
                            </div>
                            <div className="ms-Grid-row">                    
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '2px', marginBottom: '2px'}}>Current Item Writes</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{request.currentItemWrites.toLocaleString()}</p>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Expected Item Writes</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{request.expectedItemWrites.toLocaleString()} </p>
                                </div>
                            </div>
                            <div className="ms-Grid-row">                    
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Current Item Queries</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{request.currentItemQueries.toLocaleString()}</p>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '2px', marginBottom: '2px'}}>Expected Item Queries</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{request.expectedItemQueries.toLocaleString()} </p>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginLeft: "30px"}}><p style={{marginTop: '10px', marginBottom: '5px', fontWeight: 700, fontSize: 17}}>Resource usage: </p></div>
                        <div className="ms-Grid" dir="ltr" style={{marginLeft: "30px", marginTop: '2px'}}>
                            <div className="ms-Grid-row">                    
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '2px', marginBottom: '2px'}}>Current Store CPU (CPU Time)</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{round(request.currentStoreCPU).toLocaleString()}</p>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Estimated Store CPU (CPU Time)</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{round(request.estimatedStoreCPU).toLocaleString()}</p>
                                </div>
                            </div>
                            <div className="ms-Grid-row">                    
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '2px', marginBottom: '2px'}}>Current Store IO (Page reads)</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{round(request.currentStoreIO).toLocaleString()}</p>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Estimated Store IO (Page reads)</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{round(request.estimatedStoreIO).toLocaleString()} </p>
                                </div>
                            </div>
                            <div className="ms-Grid-row">                    
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Additional Store CPU (Gcycles)</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{round(request.additionalGcycles).toLocaleString()}</p>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    <p style={{marginTop: '8px', marginBottom: '2px'}}>Additional Store IO (IOPS)</p>
                                    <p style={{marginTop: '2px', marginBottom: '2px', fontWeight: 200}}>{round(request.additionalIOPS).toLocaleString()}</p>
                                </div>
                            </div>                            
                        </div>
                        { permission && <div style={{ marginLeft: "30px", marginTop: "20px", width:200 }}><StatusSelection defaultOptions={request.approveStatus} onFilter={ onStatusFilter } /></div> }
                        { permission && <div style={{ marginLeft: "260px", marginTop: "20px" }}><PrimaryButton text="Submit" onClick={() => props.save(request)} /></div> }
                    </div>
                </div>
            </Modal>
        </div>
    );
};

function setColor(status: string) {
    switch(status) {
        case 'Approved':
            return "green";
        case 'Sent To Tesseract':
            return "green";
        case 'Rejected':
            return "red";
        default:
            return "orange";
        }
}

function formatDate(date: Date) {
    function pad(s: number) { return (s < 10) ? '0' + s : s; }
    var d = new Date(date)
    return [pad(d.getMonth()+1), pad(d.getDate()), d.getFullYear()].join('/')
}

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};