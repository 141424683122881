// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XEY_IOriVuXycf0pQ4_m {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}
.XEY_IOriVuXycf0pQ4_m > span {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Da69IL_QEjsgs1SbwegI {
  background-color: #C8C8C8;
  margin-top: 50px;
  margin-bottom: 20px;
  height: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/pageheader/PageHeader.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAAI;EACI,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,uBAAA;EACA,gBAAA;AAER;AAEA;EACI,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;AAAJ","sourcesContent":[".headerDescriptionAndLink {\n    width: 100%;\n    font-size: 14px;\n    font-weight: 400;\n    & > span {\n        display:-webkit-box;\n        -webkit-line-clamp: 3;\n        -webkit-box-orient: vertical;\n        text-overflow: ellipsis;\n        overflow: hidden;\n    }\n}\n\n.separator {\n    background-color: #C8C8C8;\n    margin-top: 50px;\n    margin-bottom: 20px;\n    height: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerDescriptionAndLink": `XEY_IOriVuXycf0pQ4_m`,
	"separator": `Da69IL_QEjsgs1SbwegI`
};
export default ___CSS_LOADER_EXPORT___;
