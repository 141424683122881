
import { DefaultButton, IButtonStyles, IconButton, IStackTokens, Label, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import * as React from "react"
import { IAllocationPlan } from "../../../../models/AllocationPlan";
import { fetchForestCapacityMetrics } from "../../../../services/allocationService";
import request from "../../../../utils/api";
import { GraphPlanInfluenceOnTtl } from "./GraphPlanInfluenceOnTtl";
import { StatusDropDown } from "./StatusDropDown";

interface IAllocationPlanRowProps {
    rowNum: number
}
interface IAllocationPlanEditPageProps {
    stamp: IStamp
    allocationPlans: IAllocationPlan[]
    hasEditPermission: boolean
    simulationMode?: boolean
    onDiscard?: () => void
    onSave?: (plans: IAllocationPlan[]) => void
}

export const AllocationPlanEditPage : React.FC<IAllocationPlanEditPageProps> = ({stamp, allocationPlans, hasEditPermission, simulationMode, onDiscard, onSave}) => {

    React.useEffect(() => {
        getForestCapacityMetrics();
    }, [])

    const [version, setVersion] = React.useState(Date.now());
    
    const [forestCapacityMetrics, setForestCapacityMetrics] = React.useState<IForestCapacityMetrics[]>([]);

    const [errorMsg, setErrorMsg] = React.useState<string>("");

    const [ttlBaseDate, setTtlBaseDate] = React.useState<Date>(new Date());

    const AllocationPlanRow: React.FC<IAllocationPlanRowProps> = ({rowNum}) => {
        const plan = allocationPlans[rowNum];

        const onForestChange = (event:React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.currentTarget.value)
            {
                plan.forest = event.currentTarget.value;
                setVersion(Date.now());
            }
        }

        const onGrowthChange = (event:React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.currentTarget.value)
            {
                plan.growth = parseInt(event.currentTarget.value);
                setVersion(Date.now());
            }
        }

        const onUpSkuChange = (event:React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.currentTarget.value)
            {
                plan.upSku = parseInt(event.currentTarget.value);
                setVersion(Date.now());
            }
        }

        const onUpSkuV2Change = (event:React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.currentTarget.value)
            {
                plan.upSku = parseInt(event.currentTarget.value);
                setVersion(Date.now());
            }
        }

        const onStatusChange = (event:React.FormEvent<Element>, selectedStatusKey? : string) => {
            if (selectedStatusKey)
            {
                plan.status = selectedStatusKey;
                setVersion(Date.now());
            }
        }

        const onPlanRemoved = () => {
            allocationPlans.splice(rowNum, 1);
            setVersion(Date.now());
        }

        return (
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg4">
                        <TextField defaultValue={plan.forest?.toString()} onBlur={onForestChange} />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField defaultValue={plan.growth?.toString()} onBlur ={onGrowthChange}/>
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField defaultValue={plan.upSku?.toString()} onBlur={onUpSkuChange} />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField defaultValue={plan.upSkuV2?.toString()} onChange={onUpSkuV2Change}/>
                    </div>
                    {simulationMode ? 
                        <div></div>
                        :
                        <div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg2">
                                <StatusDropDown selection={plan.status?.toLowerCase()} onChange={onStatusChange} />
                            </div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                <IconButton iconProps={{ iconName: 'Remove' }} title="Remove" ariaLabel="Remove" onClick={onPlanRemoved}/>
                            </div> 
                        </div>
                    }
                </div>
        )
    }

    const forests = allocationPlans.map(plan => plan.forest?.split(".")[0] || "");
    const dags = allocationPlans.map(plan => plan.growth || 0);
    const [hddExtras, hddTtls, cpuExtras, cpuTtls, iopsExtras, iopsTtls, ssdExtras, ssdTtls] = fromForestCapacityMetrics(forestCapacityMetrics);
    const {hddDagSize, cpuDagSize, iopsDagSize, ssdDagSize} = fetchDagSize();

    const tooltipStyle: IButtonStyles={
        root: {
            width: "100%",
            height: "5rem",
            fontSize: 16,
            border: "0px",
        }
    }

    const paraStyle = {
        marginTop: "2rem",
    }

    const onApproveAll = () => {
        allocationPlans.forEach(plan => plan.status = "Created");
        setVersion(Date.now());
    }

    const onSaveClicked = () => {
        if (allocatedDags < stamp.dagsEst) {
            setErrorMsg("Please allocate all allocable DAGs!");
        } else if (allocatedDags > stamp.dagsEst) {
            setErrorMsg("Too many DAGs are allocated!");
        } else {
            if (onSave) onSave(allocationPlans);
        }
    }
    const allocatedDags = allocationPlans
        .filter(plan => !plan.forest!.toLowerCase().includes("sparecapacity"))
        .map(plan => (plan.growth || 0) + (plan.upSku || 0) + (plan.upSkuV2 || 0))
        .reduce((carry, x) => carry + x)

    const historical = allocationPlans[0].status?.toLowerCase().includes("hist") || false
    return (
        <div className="ms-Fabric ms-font-su" style={{margin: ""}}>
            <div className="ms-font-su ms-fontColor-themePrimary">Stamp: {allocationPlans[0].deploymentGroupIdName}</div>
          
            <div className="ms-Grid ms-font-m-plus" dir="ltr" key={version}>
                <div className="ms-Grid-row" style={paraStyle}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg3">
                            <PrimaryButton styles={tooltipStyle}> Capacity GEO Code<br/>{stamp.capacityGeoCode}</PrimaryButton>
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg3">
                            <PrimaryButton styles={tooltipStyle} > Servers in DAG <br/> {stamp.serverCount} </PrimaryButton>
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg3">
                            <PrimaryButton styles={tooltipStyle} > DAG Copy <br/> {stamp.copyCount} </PrimaryButton>
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg3">
                            <PrimaryButton styles={tooltipStyle} > # Space Machines <br/> {stamp.numberOfServers - stamp.dagsEst * stamp.serverCount} </PrimaryButton>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row" style={paraStyle}>
                    <div className="ms-Grid-col ms-sm12  ms-md12 ms-lg9" style={{textAlign: "right"}}>
                        Remaining DAGs: {stamp.dagsEst - allocatedDags}
                    </div>
                </div>

                <div style={paraStyle} className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg4">
                        <Label style={{paddingLeft: "0.2rem"}}>Forest</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <Label style={{paddingLeft: "0.2rem"}}>Growth</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <Label style={{paddingLeft: "0.2rem"}}>Up SKU</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <Label style={{paddingLeft: "0.2rem"}}>Up SKU V2</Label>
                    </div>
                    {simulationMode ? 
                        <div></div>
                        :
                        <div>
                            <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg2">
                                <Label>Status</Label>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    {allocationPlans.map((plan, idx) =>
                        <AllocationPlanRow key={idx} rowNum={idx} />
                    )}
                </div>

                {(onDiscard && onSave) ? 
                <div className="ms-Grid-row" style={paraStyle} >
                    <div className="ms-Grid-col ms-sm12 ms-md9 ms-lg4">
                        <Stack horizontal tokens={{childrenGap: 20}}>
                            <DefaultButton style={{width: "7rem"}} text="Go Back" onClick={onDiscard}/> 
                            {hasEditPermission? <DefaultButton style={{width: "7rem"}} text="Approve" onClick={onApproveAll}/> : <div></div>}
                            {hasEditPermission? <PrimaryButton style={{width: "7rem"}} text="Save"  onClick={onSaveClicked}/> : <div></div>}
                        </Stack>
                    </div>
                </div>
                : 
                <div></div> }
                {errorMsg == "" ? 
                    <div></div> 
                    : 
                    <div className="ms-Grid-row" style={paraStyle}>
                      <MessageBar messageBarType={MessageBarType.error}> {errorMsg} </MessageBar>  
                    </div> 
                }

                <div>
                    {!simulationMode ? <div style={{marginTop: "1rem", fontFamily: "italic"}}>
                    <MessageBar dismissButtonAriaLabel="Close" messageBarType={MessageBarType.warning}>
                        Notes:
                        <br/>
                        * The chart reflects the TTL change at the estimated go live date of this stamp, and is calculated using a rough mathematical method.
                        <br/>
                        * This chart provides  insights on how the new capacity might influence (from an estimated perspective) on forest TTL but does not reflect the final influence
                        <br/>
                        * There will be no TTL gain for history stamps since the capacity already went live and counted in the current TTL.
                        <br/>
                        * For new golocal forests the TTL gain is not calculable and will be shown as zero.
                        <br/>
                        * For more details and formula, please refer to <a href="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/88153/Allocation-System?anchor=q%3A-how-the-ttl-gain-is-calculated%3F">M2CP WIKI</a>
                    </MessageBar>
                    </div> :<div></div>}
                    <MessageBar> TTL based on datetime: {ttlBaseDate.toLocaleString()} </MessageBar>
                    <div className="ms-Grid-row" style={paraStyle}>
                        <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
                            <GraphPlanInfluenceOnTtl title="HDD TTL" forests={forests} dags={dags} extras={hddExtras} ttls={hddTtls} dagSize={hddDagSize} historical = {historical}/>
                        </div>
                        <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5">
                            <GraphPlanInfluenceOnTtl title= "CPU TTL" forests={forests} dags={dags} extras={cpuExtras} ttls={cpuTtls} dagSize={cpuDagSize} historical = {historical}/>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm4 ms-md5 ms-lg5">
                            <GraphPlanInfluenceOnTtl title="IOPS TTL" forests={forests} dags={dags} extras={iopsExtras} ttls={iopsTtls} dagSize={iopsDagSize} historical = {historical}/>
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md5 ms-lg5">
                            <GraphPlanInfluenceOnTtl title="SSD TTL" forests={forests} dags={dags} extras={ssdExtras} ttls={ssdTtls} dagSize={ssdDagSize} historical = {historical}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


    function fromForestCapacityMetrics(forestCapacityMetrics: IForestCapacityMetrics[]) {
        console.log("fromForestCapacityMetrics", forestCapacityMetrics)
        const hddExtras: number[] = []
        const hddTtls: number[] = []
        const cpuExtras: number[] = []
        const cpuTtls: number[] = []
        const iopsExtras: number[] = []
        const iopsTtls: number[] = []
        const ssdExtras: number[] = []
        const ssdTtls: number[] = []
        

        if (forestCapacityMetrics.length != 0) {
            for (var i = 0; i < forests.length; ++i) {
                if (forests[i].toLowerCase().includes("sparecapacity")) {
                    continue;
                }
                let metrics = forestCapacityMetrics.filter(metrics => metrics.forest.toLowerCase() == forests[i].toLowerCase())[0];
                if (!metrics) {
                    hddExtras.push(-1);
                    hddTtls.push(-1);
                    iopsExtras.push(-1);
                    iopsTtls.push(-1);
                    cpuExtras.push(-1);
                    cpuTtls.push(-1);
                    ssdExtras.push(-1);
                    ssdTtls.push(-1);
                    continue;
                } 
                hddExtras.push(metrics.extraHdd);
                hddTtls.push(metrics.hddTtl);
                cpuExtras.push(metrics.extraCpu);
                cpuTtls.push(metrics.cpuTtl);
                iopsExtras.push(metrics.extraIops);
                iopsTtls.push(metrics.iopsTtl);
                ssdExtras.push(metrics.extraSsd);
                ssdTtls.push(metrics.ssdTtl);
            }
        }
        return [hddExtras, hddTtls, cpuExtras, cpuTtls, iopsExtras, iopsTtls, ssdExtras, ssdTtls];
    }

    function fetchDagSize() {
        return {
            hddDagSize: 400,
            cpuDagSize: 4000,
            iopsDagSize: 4000,
            ssdDagSize: 150
        }
    }

    function parseDate (dateStr: string) {
        let d = Date.parse(dateStr);
        return d? new Date(d) : undefined;
    }

    function getForestCapacityMetrics() {
        let d = new Date();
        if (allocationPlans[0].estimatedGoLiveDate) {
            let estimatedGoLiveDate = parseDate(allocationPlans[0].estimatedGoLiveDate as unknown as string)
            if (estimatedGoLiveDate && estimatedGoLiveDate > d) {
                d = estimatedGoLiveDate!
            }
        }
        setTtlBaseDate(d)
        
        const region = allocationPlans.filter(plan=>plan.region!.toLowerCase() != "mgmt")[0].region;

        fetchForestCapacityMetrics(region!, d).then(response => {
            console.log("fetchForestCapacityMetrics", response)
            setForestCapacityMetrics(response);
        });
    }
}