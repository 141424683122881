import { ActionTypes, RequestStatus } from "../actions/ActionTypes";
import { IGetUserPermissionAction } from "../actions/SideBarAction"
import { Reducer } from "redux"
import { ThunkDispatch } from "redux-thunk";
import { GetStateFunction } from "../store";
import { getUserPermission } from "../services/sidebarservice";
import { UserPermission } from "../models/permission"

export interface IUserPermissionData {
    userpermissions: UserPermission[];
    isLoadingPermission: boolean;
}

const initState = {
    userpermissions: [],
    isLoadingPermission: true
}

export const userPermissionReducer: Reducer<IUserPermissionData, IGetUserPermissionAction> = (state: IUserPermissionData = initState, action: IGetUserPermissionAction): IUserPermissionData => {
    switch (action.type) {
        case ActionTypes.GetUserPermission: {
            switch (action.status) {
                case RequestStatus.Requested: return {
                    ...state,
                    isLoadingPermission: true
                }
                case RequestStatus.Succeed: return {
                    ...state,
                    userpermissions: action.userpermission,
                    isLoadingPermission: false
                }
                case RequestStatus.Failed: return {
                    ...state,
                    userpermissions: [],
                    isLoadingPermission: false
                }
            }
        }
        default: return state
    }
}

export function loadUserPermissionIfNeeded(username?: string) {
    return async function (dispatch: ThunkDispatch<IGetUserPermissionAction, void, IGetUserPermissionAction>, getState: GetStateFunction): Promise<void> {
        const userPermissionData = getState().userPermissions;
        if (userPermissionData.userpermissions.length > 0) {
            return Promise.resolve();
        }

        dispatch({ type: ActionTypes.GetUserPermission, status: RequestStatus.Requested, userpermission: [] });
        getUserPermission(username)
            .then(data => dispatch({ type: ActionTypes.GetUserPermission, status: RequestStatus.Succeed, userpermission: data }))
            .catch(() => dispatch({ type: ActionTypes.GetUserPermission, status: RequestStatus.Failed, userpermission: [] }));
    }
}