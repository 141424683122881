import { ActionButton, IButtonStyles, IStackTokens, Stack } from "@fluentui/react";
import * as React from "react";
import { IAllocationPlan } from "../../../../models/AllocationPlan";
import { AllocationPlanGroupDetail } from "./AllocationPlanGroupDetail";

interface IAllocationPlanGroupProps {
    allocationPlans: IAllocationPlan[]
    edit: React.Dispatch<React.SetStateAction<string>>
    hasRerunPermission: boolean
}
export const AllocationPlanGroup: React.FC<IAllocationPlanGroupProps> = ({allocationPlans, edit, hasRerunPermission}) => {
    const withBorder = {
        marginTop: "1rem",
        borderBottom: "ridge",
        borderColor: "#faf9f8",
        textAlign: "center",
    }
    const withoutBorder = {
        marginTop: "1rem",
        textAlign: "center",
    }

    const stackTokens: IStackTokens = { childrenGap: 0 };
    const plan = allocationPlans.filter(plan=>plan.region!.toLowerCase() != "mgmt")[0] || allocationPlans[0];
    const [showingDetail, setShowingDetail] = React.useState<boolean>(false);

    const buttonStyles: IButtonStyles = {
        root : {
            marginTop: 0,
            fontSize: 10,
            width: "100%",
        },
        icon: {
            fontSize: 10
        },
        flexContainer: {
            alignItems: "baseline",
        }
    }

    const onEditClicked = () => {
        console.log("edit clicked");
        console.log(plan.demandId!.toString() + plan.deploymentGroupIdName!);
        edit(plan.demandId!.toString() + plan.deploymentGroupIdName!);
    }
    console.log("rendering group for plan: ", plan)
    console.log(allocationPlans)

    return (
            <div className="ms-Grid-row" style={showingDetail? withoutBorder: withBorder}>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{plan.demandId}</div>
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">{plan.deploymentGroupIdName}</div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{plan.region}</div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">{plan.estimatedGoLiveDate}</div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">{plan.status}</div>
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"> 
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm0 ms-md0 ms-lg1"/>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg3">
                                <ActionButton styles={buttonStyles} iconProps={{iconName: "Zoom"}} onClick={toggleShowingDetail}> 
                                    Peek
                                 </ActionButton>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg2">
                                <ActionButton styles={buttonStyles} iconProps={{iconName: "Edit"}} onClick={onEditClicked}> 
                                    Details
                                 </ActionButton>
                            </div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                {hasRerunPermission ? <ActionButton styles={buttonStyles} iconProps={{iconName: "Clear"}} > 
                                    Re-run
                                 </ActionButton> : <div/>}
                            </div>

                        </div>
                    </div>
            {showingDetail && 
                <div>
                    <AllocationPlanGroupDetail allocationPlans={allocationPlans}/>
                </div>
            }
        </div>
    )

    function toggleShowingDetail() {
        setShowingDetail(!showingDetail);
    }
};
