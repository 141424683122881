import { PrimaryButton, TextField } from "@fluentui/react";
import * as React from "react";
import { ICapacityGenerationProfileEntry } from "../../../../models/DemandPlanGenerationModel";
import { fetchCapacityGenerationProfileDetail, saveCapacityGenerationProfileDetail } from "../../../../services/demandPlanService";

export interface IParameterEditorProps {
    regions: string[],
    profileId: string,
}

export const RegionParamEditor: React.FC<IParameterEditorProps> = ({regions, profileId}) => {
    const [version, setVersion] = React.useState<number>(0);
    const [profileName, setProfileName] = React.useState<string>("");
    const [profileDescription, setProfileDescription] = React.useState<string>("");
    const regionParametersDataRef = React.useRef<ICapacityGenerationProfileEntry[]>([]);
    React.useEffect(() => {
        if (profileId == "new") {
            regionParametersDataRef.current =
                regions.map(region => {  
                    return {
                        profileId: "",
                        profileName: profileName,
                        profileDescription: profileDescription,
                        capacityType: "backend",
                        region: region,
                        targetTTL: 4.5,
                        supplyModifier: 1,
                        demandModifier: 1,
                        decomRatio: 1.5,
                    } as ICapacityGenerationProfileEntry
                });
            setVersion(Date.now());
        } else {
            fetchCapacityGenerationProfileDetail(profileId).then(response => {
                console.log(response);
                regionParametersDataRef.current = response as unknown as ICapacityGenerationProfileEntry[];
                setProfileName(regionParametersDataRef.current[0].profileName);
                setProfileDescription(regionParametersDataRef.current[0].profileDescription);
                setVersion(Date.now());
            });
            // fetchRegionParameters(profileId).then(
            //     response => {
            //         console.log(response);
            //         regionParametersDataRef.current = response;
            //     }
            // );
        }
    }, [])

    const _onTargetTtlChange = (region: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const onTargetTtlChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            for (let i = 0; i < regionParametersDataRef.current.length; ++i) {
                if (regionParametersDataRef.current[i].region == region) {
                    regionParametersDataRef.current[i].targetTTL = parseFloat(newValue||"4.5");
                    break;
                }
            }
            console.log(regionParametersDataRef.current);
        }
        onTargetTtlChange(event, newValue);
    }

    const _onSupplyModifierChange = (region: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const onSupplyModifierChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            for (let i = 0; i < regionParametersDataRef.current.length; ++i) {
                if (regionParametersDataRef.current[i].region == region) {
                    regionParametersDataRef.current[i].supplyModifier = parseFloat(newValue||"1");
                    break;
                }
            }
            console.log(regionParametersDataRef.current);
        }
        onSupplyModifierChange(event, newValue);
    }

    const _onDemandModifierChange = (region: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const onDemandModiferChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            for (let i = 0; i < regionParametersDataRef.current.length; ++i) {
                if (regionParametersDataRef.current[i].region == region) {
                    regionParametersDataRef.current[i].demandModifier = parseFloat(newValue||"1");
                    break;
                }
            }
            console.log(regionParametersDataRef.current);
        }
        onDemandModiferChange(event, newValue);
    }

    const _onDecomRatioChange = (region: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const onDecomRatioChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            for (let i = 0; i < regionParametersDataRef.current.length; ++i) {
                if (regionParametersDataRef.current[i].region == region) {
                    regionParametersDataRef.current[i].decomRatio = parseFloat(newValue||"1.5");
                    break;
                }
            }
            console.log(regionParametersDataRef.current);
        }
        onDecomRatioChange(event, newValue);
    }

    const _onSave = () => {
        console.log(regionParametersDataRef.current)
        for (let i = 0; i < regionParametersDataRef.current.length; ++i) {
            regionParametersDataRef.current[i].profileName = profileName;
            regionParametersDataRef.current[i].profileDescription = profileDescription;
        }
        saveCapacityGenerationProfileDetail(regionParametersDataRef.current).then(response => {
            if (response.status == 200) {
                alert("Save successfully!");
            }
        });
    }
    return (
        <div key={version} className="ms-Grid" dir="ltr" style={{padding: "0 2rem"}}>
            <div className="ms-Grid-row"> 
                <div className="ms-Grid-col ms-lg3 ms-md3 ms-sm3">
                    <TextField label="Profile Name" 
                        value={profileName} 
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setProfileName(newValue||"")}/>
                </div>
                <div className="ms-Grid-col ms-lg3 ms-md3 ms-sm3">
                    <TextField 
                        label="Profile Description" 
                        value={profileDescription} 
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setProfileDescription(newValue||"")}/>
                </div>
            </div>
            <div className="ms-Grid-row"> 

                <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                    Region
                </div>
                <div className="ms-Grid-col ms-lg2 ms-md2 ms-sm2">
                    Target TTL
                </div>
                <div className="ms-Grid-col ms-lg2 ms-md2 ms-sm2">
                    Supply Modifier
                </div>
                <div className="ms-Grid-col ms-l`g2 ms-md2 ms-sm2">
                    Demand Modifier
                </div>
                <div className="ms-Grid-col ms-lg2 ms-md2 ms-sm2">
                    Decom Ratio
                </div>
            </div>
            {regions.map((region, idx) => {
                return (
            <div className="ms-Grid-row"> 
                    <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                        {region}
                    </div>
                <div className="ms-Grid-col ms-lg2 ms-md2 ms-sm2">
                        <TextField defaultValue={regionParametersDataRef.current[idx]?.targetTTL?.toString() || ""} onChange={_onTargetTtlChange(region)}/>
                </div>
                <div className="ms-Grid-col ms-lg2 ms-md2 ms-sm2">
                        <TextField defaultValue={regionParametersDataRef.current[idx]?.supplyModifier?.toString() || ""} onChange={_onSupplyModifierChange(region)}/>
                </div>
                <div className="ms-Grid-col ms-lg2 ms-md2 ms-sm2">
                        <TextField defaultValue={regionParametersDataRef.current[idx]?.demandModifier?.toString() || ""} onChange={_onDemandModifierChange(region)}/>
                </div>
                <div className="ms-Grid-col ms-lg2 ms-md2 ms-sm2">
                        <TextField defaultValue={regionParametersDataRef.current[idx]?.decomRatio?.toString() || ""} onChange={_onDecomRatioChange(region)}/>
                </div>
            </div>)})}
            <div>
                <PrimaryButton text="Save" onClick={_onSave}/>
            </div>
        </div>
    );

}