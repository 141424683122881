import { PrimaryButton, TextField } from "@fluentui/react";
import * as React from "react";
import { ISellableProfileEntry } from "../../../../models/DemandPlanGenerationModel";
import { fetchDefaultSellableProfile, fetchSellableProfileDetail, saveSellableProfile } from "../../../../services/demandPlanService";

export interface ICapacitySellable {
    CPU: number,
    IOPS: number,
    HDD: number,
    SSD: number
}
export interface ICapacitySellableEditorProps {
    profileId: string
}
export const CapacitySellableEditor: React.FC<ICapacitySellableEditorProps> = ({profileId}) => {
    const [version, setVersion] = React.useState<number>(0);
    const [profileName, setProfileName] = React.useState<string>("");
    const [profileDescription, setProfileDescription] = React.useState<string>("");
    const sellableDataRef = React.useRef<ISellableProfileEntry[]>([]);
    React.useEffect(() => {
        if (profileId == "new") {
            fetchDefaultSellableProfile().then(response => {
                console.log(response);
                sellableDataRef.current = response as unknown as ISellableProfileEntry[];
                for (let i = 0; i < sellableDataRef.current.length; ++i) {
                    sellableDataRef.current[i].profileId = "";
                }
                sellableDataRef.current.sort((a, b) => {
                    if (a.region == b.region) {
                        return a.sku.localeCompare(b.sku); 
                    }
                    return a.region.localeCompare(b.region)
                });
                setProfileName(sellableDataRef.current[0].profileName);
                setProfileDescription(sellableDataRef.current[0].profileDescription);
                setVersion(Date.now());
            });
        } else {
            fetchSellableProfileDetail(profileId).then(response => {
                console.log(response);
                sellableDataRef.current = response as unknown as ISellableProfileEntry[];
                sellableDataRef.current.sort((a, b) => {
                    if (a.region == b.region) {
                        return a.sku.localeCompare(b.sku); 
                    }
                    return a.region.localeCompare(b.region)
                });
                setProfileName(sellableDataRef.current[0].profileName);
                setProfileDescription(sellableDataRef.current[0].profileDescription);
                setVersion(Date.now());
            });
        }
    }, [])
    React.useEffect(() => {
        console.log(profileId); 
    }, [])

    const _onCpuChange = (region: string, sku: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const onCpuChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            for (let i = 0; i < sellableDataRef.current.length; ++i) {
                if (sellableDataRef.current[i].region == region && sellableDataRef.current[i].sku == sku) {
                    if (newValue && parseFloat(newValue)) {
                        sellableDataRef.current[i].gcycles = parseFloat(newValue);
                    }
                    break
                }
            }
        }
        onCpuChange(event, newValue);
    }

    const _onHddChange = (region: string, sku: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const onHddChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            for (let i = 0; i < sellableDataRef.current.length; ++i) {
                if (sellableDataRef.current[i].region == region && sellableDataRef.current[i].sku == sku) {
                    if (newValue && parseFloat(newValue)) {
                        sellableDataRef.current[i].maxEDB = parseFloat(newValue);
                    }
                    break
                }
            }
        }
        onHddChange(event, newValue);
    }

    const _onSsdChange = (region: string, sku: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const onSsdChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            for (let i = 0; i < sellableDataRef.current.length; ++i) {
                if (sellableDataRef.current[i].region == region && sellableDataRef.current[i].sku == sku) {
                    if (newValue && parseFloat(newValue)) {
                        sellableDataRef.current[i].ssd = parseFloat(newValue);
                    }
                    break
                }
            }
        }
        onSsdChange(event, newValue);
    }

    const _onIopsChange = (region: string, sku: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const onIopsChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            for (let i = 0; i < sellableDataRef.current.length; ++i) {
                if (sellableDataRef.current[i].region == region && sellableDataRef.current[i].sku == sku) {
                    if (newValue && parseFloat(newValue)) {
                        sellableDataRef.current[i].iops = parseFloat(newValue);
                    }
                    break
                }
            }
        }
        onIopsChange(event, newValue);
    }

    const _onSave = () => {
        console.log(sellableDataRef.current);
        for (let i = 0; i < sellableDataRef.current.length; ++i) {
            sellableDataRef.current[i].profileDescription = profileDescription;
            sellableDataRef.current[i].profileName = profileName;
            sellableDataRef.current[i]["id"] = undefined;
        }
        saveSellableProfile(sellableDataRef.current).then(response => {
            console.log(response);
            if (response.status==200) {
                window.alert("Save successfully!");
            }
        });
    }
    return (
        <div key={version} className="ms-Grid" dir="ltr" style={{padding: "0 2rem"}}>
            <div className="ms-Grid-row"> 
                <div className="ms-Grid-col ms-lg3 ms-md3 ms-sm3">
                    <TextField label="Profile Name" 
                        value={profileName} 
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setProfileName(newValue||"")}/>
                </div>
                <div className="ms-Grid-col ms-lg3 ms-md3 ms-sm3">
                    <TextField 
                        label="Profile Description" 
                        value={profileDescription} 
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => setProfileDescription(newValue||"")}/>
                </div>
            </div>
            <div className="ms-Grid-row"> 

                <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                    Region
                </div>
                <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                    SKU
                </div>
                <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                    Copy Count
                </div>
                <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                    HDD
                </div>
                <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                    CPU
                </div>
                <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                    IOPS
                </div>
                <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                    SSD
                </div>
            </div>
            {sellableDataRef.current &&
                sellableDataRef.current.map(p => {
                return (
                <div className="ms-Grid-row"> 
                    <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                        {p.region}
                    </div>
                    <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                        {p.sku}
                    </div>
                    <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                        {p.copyCount.toString()}
                    </div>
                    <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                        <TextField onChange={_onHddChange(p.region, p.sku)} defaultValue={p.maxEDB.toString()}/>
                    </div>
                    <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                        <TextField onChange={_onHddChange(p.region, p.sku)} defaultValue={p.gcycles.toString()}/>
                    </div>
                    <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                        <TextField onChange={_onHddChange(p.region, p.sku)} defaultValue={p.iops.toString()}/>
                    </div>
                    <div className="ms-Grid-col ms-lg1 ms-md1 ms-sm1">
                        <TextField onChange={_onHddChange(p.region, p.sku)} defaultValue={p.ssd?.toString() || "0"}/>
                    </div>
                </div>)
            })}
            <div>
                <PrimaryButton text="Save" onClick={_onSave}/>
            </div>
        </div>
    );

}