import * as React from "react"

import {
  TextField,
  Spinner,
  SpinnerSize,
  DatePicker,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
 
import { IAllocationPlan } from "../../../../models/AllocationPlan";
import request from "../../../../utils/api";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { AllocationPlanEditPage } from "../AllocationOverview/AllocationPlanEditPage";
import PageHeader from '../../../common/pageheader/PageHeader';

interface ISimulationParams {
    region?: string,
    numDags?: number,
    sku?: string,
    estimatedGoLiveDate?: Date,
    hddSignificance?: number,
    cpuSignificance?: number,
    iopsSignificance?: number,
    ssdSignificance?: number
}
export const AllocationSimulationPage: React.FC = () => {
    React.useEffect(() => {
    }, [])

    const [simulationResults, setSimulationResults] = React.useState<IAllocationPlan[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [stamp, setStamp] = React.useState<IStamp>({
        demandId: 777001,
        deploymentGroupIdName: "simulation-1",
        capacityGeoCode: "",
        planResourceTypeName: "BackEnd",
        copyCount: 3,
        serverCount: 24,
        dagsEst: 87,
        numberOfServers: 2088
    })

    const [simulationParams, setSimulationParams] = React.useState<ISimulationParams>({region: "NAM", numDags: 87, hddSignificance: 5, cpuSignificance: 3, iopsSignificance: 2, ssdSignificance:2})
    const [paramsError, setParamsError] = React.useState<string>("");

    const onRegionChange = (event: React.FormEvent, newValue?: string) => {
        simulationParams.region = newValue;
        stamp.capacityGeoCode = simulationParams.region || "";
    }

    const onNumDagsChange = (event: React.FormEvent, newValue?: string) => {
        simulationParams.numDags = parseInt(newValue||"0") || 0
        stamp.dagsEst = simulationParams.numDags;
        stamp.numberOfServers = simulationParams.numDags * 24;
    }

    const onSkuChange = (event: React.FormEvent, newValue?: string) => {
        simulationParams.sku = newValue
    }

    const onEstimatedGoLiveChange = (newValue: Date | null | undefined) => {
        if (newValue) {
            simulationParams.estimatedGoLiveDate = newValue
        }
    }

    const onHddSigChange = (event: React.FormEvent, newValue?: string) => {
        simulationParams.hddSignificance = parseFloat(newValue||"0") || 0
    }
    const onCpuSigChange = (event: React.FormEvent, newValue?: string) => {
        simulationParams.cpuSignificance = parseFloat(newValue||"0") || 0
    }
    const onIopsSigChange = (event: React.FormEvent, newValue?: string) => {
        simulationParams.iopsSignificance = parseFloat(newValue||"0") || 0
    }
    const onSsdSigChange = (event: React.FormEvent, newValue?: string) => {
        simulationParams.ssdSignificance = parseFloat(newValue||"0") || 0
    }

    const isValidSignificance = (sig?: number) => {
        return sig && sig >= 0 && sig <= 10;
    }
    const isValid = (simulationParams: ISimulationParams) => {
        return isValidSignificance(simulationParams.hddSignificance)
        && isValidSignificance(simulationParams.cpuSignificance) 
        && isValidSignificance(simulationParams.iopsSignificance)
        && isValidSignificance(simulationParams.ssdSignificance)
    }
    const onSimulateClicked = () => {
        if (isValid(simulationParams)) {
            sendSimulationRequest();
        } else {
            setParamsError("Significance factors should be in range [0.0, 10.0]")
        }
    }

    const sendSimulationRequest = () => {
        setIsLoading(true);
        setSimulationResults([]);
        request({
            url: '/allocation/simulation/allocation_plans',
            method: 'get',
            params: {
                region: simulationParams.region,
                numDags: simulationParams.numDags,
                date: simulationParams.estimatedGoLiveDate,
                hddSig: simulationParams.hddSignificance,
                cpuSig: simulationParams.cpuSignificance,
                iopsSig: simulationParams.iopsSignificance,
                ssdSig: simulationParams.ssdSignificance
            },
            timeout: 20000,
        }).then(response => {
            setIsLoading(false);
            setSimulationResults(response.data);
            if (response.data.length == 0) {
                setParamsError("Response is empty, please check your input parameters, especially region.")
            }
            else {
                setParamsError("")
            }
        });
    }

    const [showRegionText, setShowRegionText] = React.useState(false)
    const [showSKUText, setShowSKUText] = React.useState(false)
    const [showHDDText, setShowHDDText] = React.useState(false)
    const [showCPUText, setShowCPUText] = React.useState(false)
    const [showIOPSText, setShowIOPSText] = React.useState(false)
    const [showSSDText, setShowSSDText] = React.useState(false)

    const handleRegionMouseEnter = () => {
        setShowRegionText(true)
    }
    const handleRegionMouseLeave = () => {
        setShowRegionText(false)
    }

    const handleSKUMouseEnter = () => {
        setShowSKUText(true)
    }
    const handleSKUMouseLeave = () => {
        setShowSKUText(false)
    }

    const handleHDDMouseEnter = () => {
        setShowHDDText(true)
    }
    const handleHDDMouseLeave = () => {
        setShowHDDText(false)
    }

    const handleCPUMouseEnter = () => {
        setShowCPUText(true)
    }
    const handleCPUMouseLeave = () => {
        setShowCPUText(false)
    }

    const handleIOPSMouseEnter = () => {
        setShowIOPSText(true)
    }
    const handleIOPSMouseLeave = () => {
        setShowIOPSText(false)
    }

    const handleSSDMouseEnter = () => {
        setShowSSDText(true)
    }
    const handleSSDMouseLeave = () => {
        setShowSSDText(false)
    }

    return (
        <div className="ms-Fabric ms-font-su">
            <PageHeader
                title="Allocation Simulation"
                description="The page is to simulate allocation map based on user inputs and multi-metric demand and supply telemetry."
            />
            <div className="ms-Grid" style={{margin: ""}} dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                        <TextField label="Region" defaultValue="NAM" required onChange={onRegionChange} onMouseEnter={handleRegionMouseEnter} onMouseLeave={handleRegionMouseLeave}/>   
                        {showRegionText && <MessageBar>Input a region such as NAM, APC, EUR.</MessageBar>} 
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">      
                        <TextField label="Number of DAGs" defaultValue="87" required onChange={onNumDagsChange}/>     
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                        <TextField label="SKU" readOnly defaultValue="Gen7-3Copy" required onChange={onSkuChange} onMouseEnter={handleSKUMouseEnter} onMouseLeave={handleSKUMouseLeave}/>
                        {showSKUText && <MessageBar>Currently support Gen7-3Copy SKU only.</MessageBar>} 
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                        <DatePicker label="Estimated Go Live Date" isRequired onSelectDate={onEstimatedGoLiveChange}/>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                    <TextField label="HDD Significance (0.0-10.0)" defaultValue="5" onChange={onHddSigChange} onMouseEnter={handleHDDMouseEnter} onMouseLeave={handleHDDMouseLeave}/>
                    {showHDDText && <MessageBar>A real number between 0.0 and 10.0 to measure the consumption of HDD.</MessageBar>} 
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                    <TextField label="CPU Significance (0.0-10.0)" defaultValue="3" onChange={onCpuSigChange} onMouseEnter={handleCPUMouseEnter} onMouseLeave={handleCPUMouseLeave}/>
                    {showCPUText && <MessageBar>A real number between 0.0 and 10.0 to measure the consumption of CPU.</MessageBar>} 
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                    <TextField label="IOPS Significance (0.0-10.0)" defaultValue="2" onChange={onIopsSigChange} onMouseEnter={handleIOPSMouseEnter} onMouseLeave={handleIOPSMouseLeave}/>
                    {showIOPSText && <MessageBar>A real number between 0.0 and 10.0 to measure the consumption of IOPS.</MessageBar>} 
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                    <TextField label="SSD Significance (0.0-10.0)" defaultValue="2" onChange={onSsdSigChange} onMouseEnter={handleSSDMouseEnter} onMouseLeave={handleSSDMouseLeave}/>
                    {showSSDText && <MessageBar>A real number between 0.0 and 10.0 to measure the consumption of SSD.</MessageBar>} 
                    </div>
                    <div className="ms-Grid-col ms-sm0 ms-md0 ms-lg2"/>
                </div>
                <div className="ms-Grid-row" style={{marginTop:"1rem"}}>
                    <div className="ms-Grid-col">
                        <PrimaryButton  style={{width: "8rem"}} onClick={onSimulateClicked}>Simulate</PrimaryButton>
                    </div>
                    <div className="ms-Grid-col">
                        {paramsError== "" ?
                        <div></div> 
                        : <div>
                            <MessageBar messageBarType={MessageBarType.error}> {paramsError}  </MessageBar>
                        </div>}
                    </div>
                </div>
            </div>

            <div style={{marginTop: "1rem"}}>
                <MessageBar dismissButtonAriaLabel="Close" messageBarType={MessageBarType.warning}>
                Disclaimer:
                <br/>
                * The simulation result is demonstrating a "what-if" scenario of the question: "What will the allocation map be like if we have an additional incoming stamp with parameters given", and it has no influence on the actual capacity.
                <br/>
                * The calculation only provides a rough estimation given current capacity status.
                <br/>
                </MessageBar>
            </div>

            {isLoading ? 
                <div style={{margin: "2rem"}}>
                    <Spinner label="Simulation in progress. This might take a while..." size={SpinnerSize.large} />
                </div> 
                : 
                <div>
                </div>
            }
            {
                simulationResults.length > 0 ?

                <div>
                    <AllocationPlanEditPage stamp={stamp} allocationPlans={simulationResults} hasEditPermission={false} simulationMode/>
                </div>
                :
                <div>
                </div>
            }
        </div>
    );




    function includesIgnoreCase(str: string, substr: string) {
        return str.toLowerCase().includes(substr.toLowerCase());
    }

    function groupBy<T, K extends keyof any>(alist: T[], getKey: (item: T) => K): Map<K, T[]> {
        const initialValue: Map<K, T[]> = new Map<K, T[]>();
        const groups = alist.reduce((carry, item) => {
            const group = getKey(item);
            if (!carry.get(group)) {
                carry.set(group, []);
            }
            carry.get(group)!.push(item);
            return carry;
        }, initialValue)

        return groups;
    }
};