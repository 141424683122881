import * as React from "react";
import axios from "axios";
import { fetchCapEx, fetchCapacityGenerationProfileDetail, fetchCapacityGenerationProfiles, fetchDcSelectionToken, fetchDecomPlans, fetchForecastVersions, fetchForestSnd, fetchHardwareRoadmapDetail, fetchHardwareRoadmapProfiles, fetchRegionSnd, fetchRnf, fetchScenarioDetail, fetchSellableProfileDetail, fetchSellableProfiles, generateDecomDemandPlans, saveScenarioDetail } from "../../../services/demandPlanService";
import { setDefaultTarget } from "@fluentui/react/lib/components/Layer/Layer.notification";
import { Text, ActionButton, ComboBox, DatePicker, DefaultButton, DetailsList, DetailsListLayoutMode, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IComboBox, IComboBoxOption, IDropdownOption, PrimaryButton, TextField, Stack, Label, ChoiceGroup } from "@fluentui/react";
import { dataSanitizeString } from "@microsoft/applicationinsights-common";
import { Chart, registerables } from "chart.js";
import { chart } from "highcharts";
import { fetchForestCapacityMetrics } from "../../../services/allocationService";
import { merge } from "lodash";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { ApiId } from "@azure/msal-browser";
import { getDetailsColumnStyles } from "office-ui-fabric-react";
import { DemandPlanSimulationPage } from "./DemandPlanSimulationPage";
import { DemandPlanSimulationChart } from "./DemandPlanSimulationChart";
import { asComboBoxOption, asDropdownOption, convertDate, distinct, formatDate, intersect, parseDate } from "../../../utils/utils";
import { ICapEx, ICapacityGenerationProfileEntry, ICapacityOrder, IDatacenterMap, IDecomPlan, IDemandPlan, IDemandPlanEntry, IHardwareRoadMap, IHardwareRoadmapEntry, IProfile, ISellableProfileEntry, ITimeSeriesDataPoint } from "../../../models/DemandPlanGenerationModel";
import  Papa  from 'papaparse';



interface IBackendSellable {
    sku: string,
    hdd: number,
    cpu: number,
    iops: number,
    ssd: number
}
interface ICapacityPlanGenerationParams {
    region: string,
    targetTtl: number,
    orderFrequency: number,
    supplyModifier: number,
    demandModifier: number
}

const DEFAULT_SELLABLE : IBackendSellable[] = [
    {
        sku: "Gen7-3Copy",
        hdd: 700,
        iops: 11000,
        cpu: 5000,
        ssd: 700000
    },
    {
        sku: "Gen7-4Copy",
        hdd: 400,
        iops: 9000,
        cpu: 4000,
        ssd: 500000
    }
]

// const SAFE_PARAMS : ICapacityPlanGenerationParams[] = 
// [
//     {
//         region: "eur",
//         targetTtl: 9,
//         orderFrequency: 1,
//         supplyModifier: 0.9,
//         demandModifier: 1.1,
//     },
//     {
//         region: "apc",
//         targetTtl: 9,
//         orderFrequency: 1,
//         supplyModifier: 0.9,
//         demandModifier: 1.1,
//     }
// ]
// const AGGR_PARAMS : ICapacityPlanGenerationParams[] = 
// [
//     {
//         region: "eur",
//         targetTtl: 2,
//         orderFrequency: 1,
//         supplyModifier: 1,
//         demandModifier: 1,
//     },
//     {
//         region: "apc",
//         targetTtl: 2,
//         orderFrequency: 1,
//         supplyModifier: 1,
//         demandModifier: 1,
//     }
// ]
// const DEFAULT_PARAMS : ICapacityPlanGenerationParams[] = 
// [
//     {
//         region: "eur",
//         targetTtl: 4.5,
//         orderFrequency: 1,
//         supplyModifier: 1,
//         demandModifier: 1,
//     },
//     {
//         region: "apc",
//         targetTtl: 4.5,
//         orderFrequency: 1,
//         supplyModifier: 1,
//         demandModifier: 1,
//     }
// ]

const DEFAULT_HARDWARE_ROADMAP: IHardwareRoadMap[] = 
[
    {
        sku: "WCS Gen7",
        numLegs: 6,
        numCopies: 3,
        serversPerRack: 18,
        serversPerDag: 24,
        minimumOrderQuantity: 20,
        spareRatio: 0.03,
        kwPerRack: 8.8
    }
];



const DEFAULT_UPSKU_RATIO: number = 1.5;
interface ICapacityPlanScenarioPageParams {
    id: string
}
export const CapacityPlanScenarioPage: React.FC<ICapacityPlanScenarioPageParams> = ({id}) => {
    const [regionToShow, setRegionToShow] = React.useState<string>("");
    const [forestToShow, setForestToShow] = React.useState<string>("");
    const [metricToShow, setMetricToShow] = React.useState<string>("");
    const [regions, setRegions] = React.useState<string[]>([]);
    const [regionForests, setRegionForests] = React.useState<Map<string, string[]>>();


    const [params, setParams] = React.useState<ICapacityGenerationProfileEntry[]>([]);
    const [sellable, setSellable] = React.useState<ISellableProfileEntry[]>([]);
    const [hardwareRoadmap, setHardwareRoadmap] = React.useState<IHardwareRoadmapEntry[]>([]);
    const [scenarioId, setScenarioId] = React.useState<string>(id);
    const [scenarioName, setScenarioName] = React.useState<string>("");
    const [scenarioDescription, setScenarioDescription] = React.useState<string>("");

    const capexMapping = React.useRef<Map<string, ICapEx[]>>(new Map());

    const onScenarioNameChange = (event: React.FormEvent, newValue?: string) => {
        setScenarioName(newValue || "");
    }

    const onScenarioDescriptionChange = (event: React.FormEvent, newValue?: string) => {
        setScenarioDescription(newValue || "");
    }

    //const [paramProfilesMapping, setParamProfilesMapping] = React.useState<Map<string, ICapacityPlanGenerationParams[]>>(new Map());
    const [planLevel, setPlanLevel] = React.useState<string>("");

    const [sndVersions, setSndVersions] = React.useState<string[]>([]);
    const [sndVersion, setSndVersion] = React.useState<string>("");

    const [paramProfiles, setParamProfiles] = React.useState<IProfile[]>([]);
    const [sellableProfiles, setSellableProfiles] = React.useState<IProfile[]>([]);
    const [hardwareRoadmapProfiles, setHardwareroadmapProfiles] = React.useState<IProfile[]>([]);

    const [selectedRegions, setSelectedRegions] = React.useState<string[]>([]);
    const [parameterProfile, setParameterProfile] = React.useState<string>("Select Profile");
    const [hardwareRoadmapProfile, setHardwareRoadmapProfile] = React.useState<string>("Select Profile");
    const [sellableProfile, setSellableProfile] = React.useState<string>("Select Profile");
    const [planDuration, setPlanDuration] = React.useState<number>(6);
    const [planStartDate, setPlanStartDate] = React.useState<Date>();
    const [supplyCutoffDate, setSupplyCutoffDate] = React.useState<Date>();
    

    const _chartDateStrs = React.useRef<string[]>([]);
    const _chartSupplyData = React.useRef<number[]>([]);
    const _chartDemandData = React.useRef<number[]>([]);
    const _chartSafeSupplyData = React.useRef<number[]>([]);
    const _chartDemandPlanEntries = React.useRef<IDemandPlanEntry[]>([]);
    const _chartSupplyWithNewDemandData = React.useRef<number[]>([]);

    const [demandPlans, setDemandPlans] = React.useState<IDemandPlan[]>([]);
    const [version, setVersion] = React.useState<number>(Date.now());
    const [demandPlanOverviewColumns, setDemandPlanOverviewColumns] = React.useState<IColumn[]>([]);
    const [demandPlanDetailColumns, setDemandPlanDetailColumns] = React.useState<IColumn[]>([]);
    const [showingForests, setShowingForests] = React.useState<boolean>(true);
    const [showingDetail, setShowingDetail] = React.useState<boolean>(false);
    const [showingChart, setShowingChart] = React.useState<boolean>(false);
    const [demandPlanCalculationFinished, setDemandPlanCalculationFinished] =React.useState<boolean>(false);
    const [contentToInspect, setContentToInspect] = React.useState<any>();

    const [showStampGenerationDialog, setShowStampGenerationDialog] = React.useState<boolean>(false);
    const [stamps, setStamps] = React.useState<ICapacityOrder[]>([]);

    const decomDemandPlanEntries = React.useRef<IDemandPlanEntry[]>([]);
    const stampsForDcSelection = React.useRef<any[]>([]);

    const finishedPromises = React.useRef<number>(0);
    const regionComboBox = React.useRef<IComboBox>();

    const CAPACITY_UNITS: any = {
        "HDD": "TB",
        "CPU": "GCycles",
        "IOPS": "IOPS",
        "SSD": "GB",
    }

    const _doSaveScenario = () => {
        const scenarioPayload = {
            scenarioId: scenarioId == "new" ? "" : scenarioId,
            scenarioName: scenarioName,
            scenarioDescription: scenarioDescription,
            regions: selectedRegions.join(","),
            parameterProfile: parameterProfile,
            capacityType: "backend", 
            capacitySellableProfile: sellableProfile,
            hardwareRoadmapProfile: hardwareRoadmapProfile,
        }
        console.log("_doSaveScenario, scenarioPayload", scenarioPayload)

        saveScenarioDetail(scenarioPayload).then((response) => {
            if (response.status == 200) {
                window.alert("Success");    
            } else {
                window.alert("Failed");
            }
        })
    }

    const getSellable = () => {
        return sellable[0];
    }


    const getHardwareRoadmap = () => {
        return DEFAULT_HARDWARE_ROADMAP[0];
    }
   
    const getSelectedCapacitySellable = (capacityMetric: string) => {
        const sellable = getSellable();
        switch (capacityMetric) {
            case "HDD":
                return sellable.maxEDB;
            case "IOPS":
                return sellable.iops;
            case "CPU":
                return sellable.gcycles;
            case "SSD":
                return sellable.ssd; 
            default:
                return -1;
        }
    }
    
    const _generateDemandPlans = () => {
        if (!selectedRegions || !regionForests) {
            return;
        }

        setupTableColumns();

        console.log("in generate demand pland");
        console.log("fetching capex");
        for (let r of selectedRegions) {
            const skuFamilies = distinct(hardwareRoadmap.filter(sku => sku.region.toLowerCase() == r.toLowerCase()).map(sku => sku.skuFamily));
            for (let s of skuFamilies) {
                fetchCapEx(r, s).then(response => {
                    console.log("============capex fetched!==========")
                    console.log(r);
                    console.log(s);
                    capexMapping.current.set(r + s, response);
                    console.log(response);
                });
            }

        }

        setShowingChart(false);
        setShowingDetail(false);
        setShowingForests(true);

        //growth
        if (planLevel == "forest") {
            while (demandPlans.length > 0) demandPlans.pop();
            setDemandPlanCalculationFinished(false);

            const forests = selectedRegions.flatMap(selectedRegion => regionForests.get(selectedRegion) || []);
            forests.sort();
            finishedPromises.current = 0;
            for (let forest of forests) {
                // if (forest != 'namprd11') continue;
                //console.log("updating demand plan for " + forest);
                fetchForestSnd(forest).then(response => {

                    finishedPromises.current ++;
                    console.log(finishedPromises.current);
                    const region = forest.slice(0, 3);
                    const [hddDateStrs, hddSupply, hddDemand, hddSafeSupply] = extractAsArrays(region, response.hddSupply.map(convertDate), response.hddDemand.map(convertDate));
                    const [cpuDateStrs, cpuSupply, cpuDemand, cpuSafeSupply] = extractAsArrays(region, response.cpuSupply.map(convertDate), response.cpuDemand.map(convertDate));
                    const [iopsDateStrs, iopsSupply, iopsDemand, iopsSafeSupply] = extractAsArrays(region, response.iopsSupply.map(convertDate), response.iopsDemand.map(convertDate));
                    const [ssdDateStrs, ssdSupply, ssdDemand, ssdSafeSupply] = extractAsArrays(region, response.ssdSupply.map(convertDate), response.ssdDemand.map(convertDate));
                    const hddDemandPlanEntries = _calculateDemandPlans(region, hddDateStrs, hddSupply, hddSafeSupply, "HDD");
                    const cpuDemandPlanEntries = _calculateDemandPlans(region, cpuDateStrs, cpuSupply, cpuSafeSupply, "CPU");
                    const iopsDemandPlanEntries = _calculateDemandPlans(region, iopsDateStrs, iopsSupply, iopsSafeSupply, "IOPS");
                    const ssdDemandPlanEntries = _calculateDemandPlans(region, ssdDateStrs, ssdSupply, ssdSafeSupply, "SSD");

                    // console.log(hddDemandPlanEntries)
                    // console.log(cpuDemandPlanEntries)
                    // console.log(iopsDemandPlanEntries)
                    // console.log(ssdDemandPlanEntries)

                    const demandPlan = {
                        name: forest,
                        hdd: hddDemandPlanEntries,
                        cpu: cpuDemandPlanEntries,
                        iops: iopsDemandPlanEntries,
                        ssd: ssdDemandPlanEntries,
                        overall: _mergeDemandPlanEntries([hddDemandPlanEntries, cpuDemandPlanEntries, iopsDemandPlanEntries, ssdDemandPlanEntries])
                    } as IDemandPlan;

                    demandPlans.push(demandPlan);
                    setVersion(Date.now());
                    if (finishedPromises.current == forests.length) {
                        setDemandPlanCalculationFinished(true);
                        setShowingForests(false);
                    }
                });

                setTimeout(() => {
                    setDemandPlanCalculationFinished(true);
                }, 30000);
            }
        } else if (planLevel == "region" && sndVersion && sndVersion != "") {
            while (demandPlans.length > 0) demandPlans.pop();
            setDemandPlanCalculationFinished(false);
            selectedRegions.sort();
            finishedPromises.current = 0;
            
            for (let region of selectedRegions) {
                fetchRegionSnd(region, sndVersion).then(response => {
                    finishedPromises.current ++;
                    console.log(finishedPromises.current);
                    const [hddDateStrs, hddSupply, hddDemand, hddSafeSupply] = extractAsArrays(region, response.hddSupply.map(convertDate), response.hddDemand.map(convertDate));
                    const [cpuDateStrs, cpuSupply, cpuDemand, cpuSafeSupply] = extractAsArrays(region, response.cpuSupply.map(convertDate), response.cpuDemand.map(convertDate));
                    const [iopsDateStrs, iopsSupply, iopsDemand, iopsSafeSupply] = extractAsArrays(region, response.iopsSupply.map(convertDate), response.iopsDemand.map(convertDate));
                    const [ssdDateStrs, ssdSupply, ssdDemand, ssdSafeSupply] = extractAsArrays(region, response.ssdSupply.map(convertDate), response.ssdDemand.map(convertDate));
                    const hddDemandPlanEntries = _calculateDemandPlans(region, hddDateStrs, hddSupply, hddSafeSupply, "HDD");
                    const cpuDemandPlanEntries = _calculateDemandPlans(region, cpuDateStrs, cpuSupply, cpuSafeSupply, "CPU");
                    const iopsDemandPlanEntries = _calculateDemandPlans(region, iopsDateStrs, iopsSupply, iopsSafeSupply, "IOPS");
                    const ssdDemandPlanEntries = _calculateDemandPlans(region, ssdDateStrs, ssdSupply, ssdSafeSupply, "SSD");

                    // console.log(hddDemandPlanEntries)
                    // console.log(cpuDemandPlanEntries)
                    // console.log(iopsDemandPlanEntries)
                    // console.log(ssdDemandPlanEntries)

                    const demandPlan = {
                        name: region + "-NonGov",
                        hdd: hddDemandPlanEntries,
                        cpu: cpuDemandPlanEntries,
                        iops: iopsDemandPlanEntries,
                        ssd: ssdDemandPlanEntries,
                        overall: _mergeDemandPlanEntries([hddDemandPlanEntries, cpuDemandPlanEntries, iopsDemandPlanEntries, ssdDemandPlanEntries])
                    } as IDemandPlan;

                    demandPlans.push(demandPlan);
                    setVersion(Date.now());
                    if (finishedPromises.current == selectedRegions.length) {
                        setDemandPlanCalculationFinished(true);
                        setShowingForests(false);
                    }

                });
            }

        }

        // decom
        selectedRegions.forEach(selectedRegion => {
            fetchDecomPlans(selectedRegion).then(response => {
                const decomPlans = response.map(plan => ({
                    region: plan.region,
                    month: new Date(plan.month),
                    sku: plan.sku,
                    decomDags: plan.decomDags
                } as IDecomPlan));
                console.log(decomPlans);
                const decomEntries = decomPlans
                .filter(plan => plan.month >= (planStartDate || Date.now()) && plan.month >= (supplyCutoffDate || Date.now()))
                .map(plan => {
                    return {
                        date: plan.month,
                        numUnits: Math.ceil(plan.decomDags / getUpSkuRatio(plan.region, plan.sku)),
                        notes: selectedRegion
                    } as IDemandPlanEntry;
                });
                decomEntries.forEach(e => decomDemandPlanEntries.current!.push(e));
                setVersion(Date.now());
            })
        });
    }
    function getUpSkuRatio(region: string, sku: String) {
        return params.filter(p => p.region.toLowerCase() == region.toLowerCase())[0].decomRatio;
    }

    const renderDemandPlan = (demandPlan: IDemandPlan) => {
        return (
            <div>
                {demandPlan.name}
            </div>
        )
    }

    const _doShowRegion = (item: any) => {
        setRegionToShow(item.name);
        setShowingForests(true);
    }

    const _doShowDetail = (item: any) => {
        if (item.name.toLowerCase().includes("total")) {
            return;
        }
        setForestToShow(item.name);
        setShowingDetail(true);
    }

    const _doShowChart = (item: any) => {
        setMetricToShow(item.name.toUpperCase());
        if(planLevel == "forest") {
            fetchForestSnd(forestToShow).then(response => {

                //const parameters = params.filter(p => p.region == regionToShow)[0];
                const region = forestToShow.slice(0, 3);
                const [hddDateStrs, hddSupply, hddDemand, hddSafeSupply] = extractAsArrays(region, response.hddSupply.map(convertDate), response.hddDemand.map(convertDate));
                const [cpuDateStrs, cpuSupply, cpuDemand, cpuSafeSupply] = extractAsArrays(region, response.cpuSupply.map(convertDate), response.cpuDemand.map(convertDate));
                const [iopsDateStrs, iopsSupply, iopsDemand, iopsSafeSupply] = extractAsArrays(region, response.iopsSupply.map(convertDate), response.iopsDemand.map(convertDate));
                const [ssdDateStrs, ssdSupply, ssdDemand, ssdSafeSupply] = extractAsArrays(region, response.ssdSupply.map(convertDate), response.ssdDemand.map(convertDate));

                const hddDemandPlanEntries = _calculateDemandPlans(region, hddDateStrs, hddSupply, hddSafeSupply, "HDD");
                const cpuDemandPlanEntries = _calculateDemandPlans(region, cpuDateStrs, cpuSupply, cpuSafeSupply, "CPU");
                const iopsDemandPlanEntries = _calculateDemandPlans(region, iopsDateStrs, iopsSupply, iopsSafeSupply, "IOPS");
                const ssdDemandPlanEntries = _calculateDemandPlans(region, ssdDateStrs, ssdSupply, ssdSafeSupply, "SSD");

                switch (item.name.toUpperCase()) {
                    case "HDD":
                        _chartDateStrs.current = hddDateStrs
                        _chartSupplyData.current = hddSupply;
                        _chartDemandData.current = hddDemand;
                        _chartSafeSupplyData.current = hddSafeSupply
                        _chartDemandPlanEntries.current = hddDemandPlanEntries
                        break;
                    case "CPU":
                        _chartDateStrs.current = cpuDateStrs
                        _chartSupplyData.current = cpuSupply;
                        _chartDemandData.current = cpuDemand;
                        _chartSafeSupplyData.current = cpuSafeSupply
                        _chartDemandPlanEntries.current = cpuDemandPlanEntries
                        break;
                    case "IOPS":
                        _chartDateStrs.current = iopsDateStrs
                        _chartSupplyData.current = iopsSupply;
                        _chartDemandData.current = iopsDemand;
                        _chartSafeSupplyData.current = iopsSafeSupply
                        _chartDemandPlanEntries.current = iopsDemandPlanEntries
                        break;
                    case "SSD":
                        _chartDateStrs.current = ssdDateStrs
                        _chartSupplyData.current = ssdSupply;
                        _chartDemandData.current = ssdDemand;
                        _chartSafeSupplyData.current = ssdSafeSupply
                        _chartDemandPlanEntries.current = ssdDemandPlanEntries
                        break;
                    default:
                }
                //TODO fix param region
                const par = params.filter(p => p.region.toLowerCase() == regionToShow.toLowerCase())[0];
                _chartSupplyData.current = _chartSupplyData.current.map(p => p * par.supplyModifier);
                _chartDemandData.current = _chartDemandData.current.map(p => p * par.demandModifier);
                _chartSafeSupplyData.current = _chartSafeSupplyData.current.map(p => p * par.demandModifier);
                _chartSupplyWithNewDemandData.current = _calculateSupplyWithDemandPlans(region, _chartDateStrs.current, _chartSupplyData.current, _chartDemandPlanEntries.current, item.name);
                setShowingChart(true);
                setVersion(Date.now());
            });
        } else if (planLevel == "region") {
            //
            fetchRegionSnd(regionToShow, sndVersion).then(response => {

                const parameters = params.filter(p => p.region == regionToShow)[0];
                const region = forestToShow.slice(0, 3);
                const [hddDateStrs, hddSupply, hddDemand, hddSafeSupply] = extractAsArrays(region, response.hddSupply.map(convertDate), response.hddDemand.map(convertDate));
                const [cpuDateStrs, cpuSupply, cpuDemand, cpuSafeSupply] = extractAsArrays(region, response.cpuSupply.map(convertDate), response.cpuDemand.map(convertDate));
                const [iopsDateStrs, iopsSupply, iopsDemand, iopsSafeSupply] = extractAsArrays(region, response.iopsSupply.map(convertDate), response.iopsDemand.map(convertDate));
                const [ssdDateStrs, ssdSupply, ssdDemand, ssdSafeSupply] = extractAsArrays(region, response.ssdSupply.map(convertDate), response.ssdDemand.map(convertDate));

                const hddDemandPlanEntries = _calculateDemandPlans(region, hddDateStrs, hddSupply, hddSafeSupply, "HDD");
                const cpuDemandPlanEntries = _calculateDemandPlans(region, cpuDateStrs, cpuSupply, cpuSafeSupply, "CPU");
                const iopsDemandPlanEntries = _calculateDemandPlans(region, iopsDateStrs, iopsSupply, iopsSafeSupply, "IOPS");
                const ssdDemandPlanEntries = _calculateDemandPlans(region, ssdDateStrs, ssdSupply, ssdSafeSupply, "SSD");

                switch (item.name.toUpperCase()) {
                    case "HDD":
                        _chartDateStrs.current = hddDateStrs
                        _chartSupplyData.current = hddSupply;
                        _chartDemandData.current = hddDemand;
                        _chartSafeSupplyData.current = hddSafeSupply
                        _chartDemandPlanEntries.current = hddDemandPlanEntries
                        break;
                    case "CPU":
                        _chartDateStrs.current = cpuDateStrs
                        _chartSupplyData.current = cpuSupply;
                        _chartDemandData.current = cpuDemand;
                        _chartSafeSupplyData.current = cpuSafeSupply
                        _chartDemandPlanEntries.current = cpuDemandPlanEntries
                        break;
                    case "IOPS":
                        _chartDateStrs.current = iopsDateStrs
                        _chartSupplyData.current = iopsSupply;
                        _chartDemandData.current = iopsDemand;
                        _chartSafeSupplyData.current = iopsSafeSupply
                        _chartDemandPlanEntries.current = iopsDemandPlanEntries
                        break;
                    case "SSD":
                        _chartDateStrs.current = ssdDateStrs
                        _chartSupplyData.current = ssdSupply;
                        _chartDemandData.current = ssdDemand;
                        _chartSafeSupplyData.current = ssdSafeSupply
                        _chartDemandPlanEntries.current = ssdDemandPlanEntries
                        break;
                    default:
                }
                //TODO fix param region
                const par = params.filter(p => p.region.toLowerCase() == regionToShow.toLowerCase())[0];
                _chartSupplyData.current = _chartSupplyData.current.map(p => p * par.supplyModifier);
                _chartDemandData.current = _chartDemandData.current.map(p => p * par.demandModifier);
                _chartSafeSupplyData.current = _chartSafeSupplyData.current.map(p => p * par.demandModifier);
                _chartSupplyWithNewDemandData.current = _calculateSupplyWithDemandPlans(region, _chartDateStrs.current, _chartSupplyData.current, _chartDemandPlanEntries.current, item.name);
                setShowingChart(true);
                setVersion(Date.now());
            })
        }
    }


    const futureSeveralMonths = (startDate: Date, numMonths: number) => {
        console.log("in future several months")
        console.log(startDate);
        console.log(numMonths);
        const date = startDate;
        if (startDate.getDate() == 1) {
            startDate.setMonth(startDate.getMonth() - 1)
        }
        const dates = []
        for (let i = 1; i <= numMonths; ++i) {
            const d = new Date(date.getTime());
            d.setMonth(d.getMonth() + i);
            d.setDate(1);
            d.setHours(12);
            dates.push(d);
        }
        return dates;
    }

    // const sumDemandPlanEntries = (demandPlanEntries: IDemandPlanEntry[][]) => {
    //     return demandPlanEntries[0];
    // }

    // const withSummary = (demandPlans: IDemandPlan[]) => {
    //     if (demandPlans.length == 0) {
    //         return demandPlans;
    //     }

    //     let summary = {
    //         name: "Total",
    //         orderFrequency: demandPlans[0].orderFrequency,
    //         cpu: sumDemandPlanEntries(demandPlans.map(dp => dp.cpu || [])),
    //         iops: sumDemandPlanEntries(demandPlans.map(dp => dp.iops || [])),
    //         hdd: sumDemandPlanEntries(demandPlans.map(dp => dp.hdd || [])),
    //         ssd: sumDemandPlanEntries(demandPlans.map(dp => dp.ssd || [])),
    //         overall: sumDemandPlanEntries(demandPlans.map(dp => dp.overall))
    //    } as IDemandPlan
    // }

    const asOverviewItem = (demandPlan: IDemandPlan) => {
        const item: any = {}
        item["name"] = demandPlan.name;
        for (let entry of demandPlan.overall) {
            item[formatDate(entry.date)] = entry.numUnits
        }
        return item;
    }

    const createDecomOverviewItems = (demandPlanEntries: IDemandPlanEntry[]) => {
        console.log("in createDecomOverviewItems");
        console.log(demandPlanEntries);
        const items = []
        for (let region of selectedRegions) {
            let entries = demandPlanEntries.filter(e => e.notes?.toLowerCase().includes(region.toLowerCase()));
            let item: any = {}
            item["name"] = region.toUpperCase() + " Decom";
            for (let entry of entries) {
                item[formatDate(entry.date)] = entry.numUnits
            }
            items.push(item);
        }
        return items;
    }

    const createForestOverviewItems = (demandPlans: IDemandPlan[], region: string) => {
        demandPlans = demandPlans
            .filter(dp => dp.name.toLowerCase().includes(region.toLowerCase()));
        const overviewItems = demandPlans.map(asOverviewItem);
        const sumItem: any = {};
        sumItem["name"] = "Total Growth";
        for (let demandPlan of demandPlans) {
            for (let entry of demandPlan.overall) {
                sumItem[formatDate(entry.date)] = (sumItem[formatDate(entry.date)] || 0) + (entry.numUnits || 0);
            }
        }
        return [sumItem, ...overviewItems];
    }

    const createRegionOverviewItems = () => {
        return selectedRegions.map(region => {
            const forestOverviewItems = createForestOverviewItems(demandPlans, region);

            console.log("creating region overview items");
            console.log(demandPlans);
            console.log(forestOverviewItems);
            console.log(forestOverviewItems[0]);
            const regionItem = forestOverviewItems[0];
            //TODO
            regionItem["name"] = region;
            console.log(regionItem);
            return regionItem;
        })
        
    }
    const createDetailItems = (demandPlan: IDemandPlan) => {

        const hdd: any = {};
        const cpu: any = {};
        const iops: any = {};
        const ssd: any = {};
        hdd["name"] = "HDD";
        for (let entry of demandPlan.hdd || []) {
            hdd[formatDate(entry.date)] = entry.numUnits
        }
        cpu["name"] = "CPU";
        for (let entry of demandPlan.cpu || []) {
            hdd[formatDate(entry.date)] = entry.numUnits
        }
        iops["name"] = "IOPS";
        for (let entry of demandPlan.iops || []) {
            iops[formatDate(entry.date)] = entry.numUnits
        }
        ssd["name"] = "SSD";
        for (let entry of demandPlan.ssd || []) {
            ssd[formatDate(entry.date)] = entry.numUnits
        }

        return [hdd, cpu, iops, ssd];
    }

    function getCapexPerRack(region: string, skuFamily: string, date: Date) {
        console.log("in getCapexPerRack");
        console.log(region)
        console.log(skuFamily)
        console.log(date)
        const capexEntries = capexMapping.current.get(region.toLowerCase() + skuFamily);
        console.log(capexEntries);
        if (!capexEntries) {
            return -10000;
        }
        const targetEntries =  capexEntries.filter(entry => entry.finance_FiscalYear == date.getFullYear() && entry.finance_FiscalQuarter == "Q" + (Math.floor(date.getMonth() / 3) + 1).toString());
        console.log(targetEntries);
        if (!targetEntries || targetEntries.length == 0) {
            return -10000;
        }
        return targetEntries[0].capexPerRack;
    }
    const createCapaciyOrderItems = (capacityOrders: ICapacityOrder[]): Object[] => {
        let cos: string[] = []
        return capacityOrders.map(
            co => {
                const spareRatio = co.hardwareRoadmap.numCopies == 3 ? 0.03 : 0.01;
                let estimatedDags = Math.floor(calculateDagsPerOrder(co.hardwareRoadmap)).toString();
                if (cos.includes(co.deploymentGroupIdName)) {
                    estimatedDags = "-"
                } else {
                    cos.push(co.deploymentGroupIdName);
                }
                return {
                    DeploymentGroupIdName: co.deploymentGroupIdName,
                    PlanIntentTypeName: co.planIntentTypeName,
                    PlanRtegDate: `${co.planRtegDate.getFullYear()}-${co.planRtegDate.getMonth()+1}-${co.planRtegDate.getDate()}`,
                    DataCenter: co.dataCenter,
                    NumRacks: Math.max(5, co.hardwareRoadmap.minimumOrderQuantity),//co.hardwareRoadMap.minimumOrderQuantity,
                    ServersPerRack: co.hardwareRoadmap.serversPerRack,
                    Sku: co.hardwareRoadmap.skuFamily,
                    CapEx: Math.max(5, co.hardwareRoadmap.minimumOrderQuantity) * getCapexPerRack(co.hardwareRoadmap.region, co.hardwareRoadmap.skuFamily, co.planRtegDate),
                    EstimatedDags: estimatedDags
                }
            }
        )
    }
    
    React.useEffect(() => {
        if (!paramProfiles.filter(p => p.profileName == parameterProfile)[0]) return;
        const profileId = paramProfiles.filter(p => p.profileName == parameterProfile)[0].profileId;
        fetchCapacityGenerationProfileDetail(profileId).then((response) => {
            setParams(response);
        })
    } , [parameterProfile, paramProfiles]);

    React.useEffect(() => {
        if (!sellableProfiles.filter(p => p.profileName == sellableProfile)[0]) return;
        const profileId = sellableProfiles.filter(p => p.profileName == sellableProfile)[0].profileId;
        fetchSellableProfileDetail(profileId).then((response) => {
            setSellable(response);
        });
    } , [sellableProfile, sellableProfiles]);

    React.useEffect(() => {
        if (!hardwareRoadmapProfiles.filter(p => p.profileName == hardwareRoadmapProfile)[0]) return;
        const profileId = hardwareRoadmapProfiles.filter(p => p.profileName == hardwareRoadmapProfile)[0].profileId;
        fetchHardwareRoadmapDetail(profileId).then((response) => {
            setHardwareRoadmap(response);
        });
    } , [hardwareRoadmapProfile, hardwareRoadmapProfiles]);

    const setupTableColumns = () => {
        setDemandPlanOverviewColumns(["Forest", ...futureSeveralMonths(planStartDate||new Date(), planDuration).map(formatDate)]
            .map(val => {
                return {
                    key: val,
                    name: val,
                    fieldName: val == "Forest" ? "name" : val,
                    maxWidth: 200,
                    isResizable: true
                } as IColumn;
            }));
        setDemandPlanDetailColumns(["Date", ...futureSeveralMonths(planStartDate||new Date(), planDuration).map(formatDate)]
            .map(val => {
                return {
                    key: val,
                    name: val,
                    fieldName: val == "Date" ? "name" : val,
                    maxWidth: 200,
                    isResizable: true
                } as IColumn;
            }));

    }
    React.useEffect(() => {
        fetchForecastVersions().then(response => {
            setSndVersions(response);
        })
        fetchCapacityGenerationProfiles().then(response => {
            setParamProfiles(response);
        })
        fetchSellableProfiles().then(response => {
            setSellableProfiles(response);
        })
        fetchHardwareRoadmapProfiles().then(response => {
            setHardwareroadmapProfiles(response);
        })
        // setParamProfiles(["default", "aggressive", "safe"]);
        // setSellableProfiles(["default"]);

        // paramProfilesMapping.set("default", DEFAULT_PARAMS);
        // paramProfilesMapping.set("aggressive", AGGR_PARAMS);
        // paramProfilesMapping.set("safe", SAFE_PARAMS);

        //setHardwareroadmapProfiles(["default"]);
        setupTableColumns();
        fetchRnf().then(
            response => {
                console.log("fetch rnf then")
                response.json().then(body => {
                    let regionForests: Map<string, string[]> = new Map(Object.entries(body));
                    console.log(regionForests);

                    let regions = []
                    for (let o of regionForests.keys()) {
                        regions.push(o);
                    }
                    regions.sort();
                    setRegions(regions);
                    setRegionForests(regionForests);
                });
            }
        );

        fetchScenarioDetail(id).then(
            response => {
                console.log(response);
                console.log(response.parameterProfile);
                setSelectedRegions(response.regions.split(","));
                setScenarioName(response.scenarioName)
                setScenarioDescription(response.scenarioDescription)
                setParameterProfile(response.parameterProfile);
                setSellableProfile(response.capacitySellableProfile);
                setHardwareRoadmapProfile(response.hardwareRoadmapProfile);
            }
        )
    }, []);
    
    const getCapacityGeoCode = (region: string) => {
        const regionCapacityGeoCodeMapping = new Map(Object.entries({
            "nam": "UnitedStates",
            "eur": "Europe",
            "lam": "LATAM",
            "apc": "AsiaPacific",
            "jpn": "Japan",
            "ind": "India",
            "gbr": "UnitedKingdom"
        }));
        return regionCapacityGeoCodeMapping.get(region.toLowerCase());
    }
    const createStampName = (region: string, date: Date, seq:number) => {
        return `${getCapacityGeoCode(region)?.toUpperCase()}${date.getUTCFullYear()}${date.getUTCMonth()+1}-${seq}-EXO-MT`
    }

    function fillCapacityOrdersWithDataCenters(capacityOrders: ICapacityOrder[], stampToDcs: Record<string, string[]>) {
        const coWithDcs: ICapacityOrder[] = [];
        console.log(stampToDcs);
        let currentStampName = "";
        let index = 0;
        for (let order of capacityOrders) {
            if (currentStampName != order.deploymentGroupIdName) {
                currentStampName = order.deploymentGroupIdName;
                index = 0;
            }
            const datacenters = stampToDcs[order.deploymentGroupIdName];
            if (datacenters) {
                order.dataCenter = datacenters[index];
                index ++;
            }
            coWithDcs.push(order);
        }
        console.log("orders")
        console.log(coWithDcs);
        return coWithDcs;
    }

    const _doExportToCsv = () => {
        const data  = createCapaciyOrderItems(stamps);
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'stamps.csv';
        a.click();
    }

    const _doDcSelection = () => {
        fetchDcSelectionToken().then(response => {
            response.text().then(bearer => {
                console.log(bearer);
                const config = {
                    headers: {
                        Authorization: "Bearer " + bearer
                    }
                }
                
                const payload = {
                    TopN: 1,
                    EnableNetworkCalculation: true,
                    StampInfo: stampsForDcSelection.current
                }

                //const testlink = "https://intelligentplacement.azurewebsites.net/api/Hello"
                axios.post("https://intelligentplacement.azurewebsites.net/api/stamp/model/dcselection", payload, config).then((response) => {
                    console.log(response.data);
                    const stampToDcs: Record<string, string[]> = {};

                    for (let item of response.data) {
                        let dcs: string[] = []
                        stampToDcs[item['stampName']] = dcs
                        for (let dcRec of item.recommendation[0].dcs) {
                            dcs.push(dcRec.name);
                        }
                    }
                    console.log(stampToDcs);


                    const capacityOrdersWithDcs = fillCapacityOrdersWithDataCenters(stamps, stampToDcs);
                    console.log(capacityOrdersWithDcs)
                    setStamps(capacityOrdersWithDcs);
                    //setVersion(Date.now());
                });

            })
        })

    }
    const addStampForDcSelection = (region: string, planRtegDate: Date, stampName: string, hwrdmp: IHardwareRoadmapEntry, intent: string) => {
        stampsForDcSelection.current.push(
            {
                StampName: stampName,
                Region: region.toUpperCase(),
                Layout: "MT-3-copy",
                DagCount: calculateDagsPerOrder(hwrdmp),
                RackCntPerDC: hwrdmp.minimumOrderQuantity,
                SKU: hwrdmp.skuFamily,
                kwPerRack: DEFAULT_HARDWARE_ROADMAP[0].kwPerRack, // TODO fix kwPerRack
                PlanDockDate: formatDate(planRtegDate),
                ServerCntPerRack: hwrdmp.serversPerRack,
                CapacityGeoCode: "",
                ReservationStatus: "",
                PlanIntent: intent,
                FiscalYear: 2024
            } 
        );
    }
            
        // })
        // axios.get(testlink, config).then((response) => {
        //     console.log(response.data);
        // })

    const createCapacityOrders = (region: string,  planRtegDate: Date, seq: number, hwrdmp: IHardwareRoadmapEntry, intent: string) => {

        const stampName = createStampName(region, planRtegDate, seq) + "-" + intent.toUpperCase() ;
        const orders: ICapacityOrder[] = [];

        // console.log("in create capacity orders")
        addStampForDcSelection(region, planRtegDate, stampName, hwrdmp, intent);
        for (let i = 0; i < hwrdmp.numLegs; ++i) {
            orders.push({
                planRtegDate: planRtegDate,
                planIntentTypeName: intent,
                deploymentGroupIdName: stampName,
                dataCenter: "DC" + i,
                hardwareRoadmap: hwrdmp,
            })
        }
        return orders;
    }

    const calculateDagsPerOrder = (hwrdmp: IHardwareRoadmapEntry) => {
        const spareRatio = hwrdmp.numCopies == 3 ? 0.03 : 0.01;
        return Math.trunc(
            //hardwareRoadMap.minimumOrderQuantity 
            //TODO better decision on minimum order quantity
            Math.max(5, hwrdmp.minimumOrderQuantity) * hwrdmp.numLegs * hwrdmp.serversPerRack * (1-spareRatio) / hwrdmp.serversPerDag
        );
    }

    const generateStampsForRegion = (region: string) => {
        let hardwareRoadMap = getHardwareRoadmap();
        let regions = selectedRegions;
        let regionIndex = 0;
        for (let i = 0; i < regions.length; i++) {
            if (region.toLowerCase() == regions[i]) {
                regionIndex = i;
            }
        }
        let plans = new Map<Date, number>();
        // for (let demandPlan of demandPlans) {
        //     for (let entry of demandPlan.overall) {
        //         plans.set(entry.date, (plans.get(entry.date) || 0) + (entry.numUnits || 0));
        //     }
        // }
        let growthDp: IDemandPlanEntry[] = []
        for (let plan of Object.entries(createRegionOverviewItems()[regionIndex])) {

            if (plan[0] == "name") continue;
            growthDp.push({
                date: new Date(plan[0]),
                numUnits: plan[1],
                unitCapacity: 0,
                notes: "Gen7"
            } as IDemandPlanEntry);
        }

        // console.log("in stamp generation");
        // console.log("======")
        growthDp.sort((a, b) => a.date.getTime() - b.date.getTime());
        // console.log(growthDp);

        let i = 0;
        let seq = 0;

        let date = growthDp[0].date;
        let hwrdmp = hardwareRoadmap.filter(entry => entry.region.toLowerCase() == region && entry.startDate <= formatDate(date) && entry.endDate >= formatDate(date))[0]
        if (!hwrdmp) {
            let entries = hardwareRoadmap.filter(entry => entry.region.toLowerCase() == region);
            entries.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
            hwrdmp = entries[entries.length - 1];
        }
        let dagsPerOrder = calculateDagsPerOrder(hwrdmp);
        let pendingDags = dagsPerOrder

        // console.log("dags per order")
        // console.log(dagsPerOrder)
        const capacityOrders: ICapacityOrder[] = [];
        while (i < growthDp.length) {
            const date = growthDp[i].date;
            let hwrdmp = hardwareRoadmap.filter(entry => entry.region.toLowerCase() == region && entry.startDate <= formatDate(date) && entry.endDate >= formatDate(date))[0]
            if (!hwrdmp) {
                let entries = hardwareRoadmap.filter(entry => entry.region.toLowerCase() == region);
                entries.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
                hwrdmp = entries[entries.length - 1];
            }
            const dagsPerOrder = calculateDagsPerOrder(hwrdmp);
            pendingDags += growthDp[i].numUnits;
            // console.log("pendingDags");
            // console.log(pendingDags);
            while (pendingDags >= dagsPerOrder) {
                pendingDags -= dagsPerOrder;
                seq ++;
                
                const stampCapacityOrders = createCapacityOrders(region, growthDp[i].date, seq, hwrdmp, "Growth");
                for (let order of stampCapacityOrders) {
                    capacityOrders.push(order);
                }
            }
            seq = 0;
            i++;
        }
        //dcom
        let decomDp: IDemandPlanEntry[] = decomDemandPlanEntries.current!.filter(e => e.notes?.toLowerCase().includes(region.toLowerCase()));

        // console.log("in stamp generation");
        // console.log("======")
        decomDp.sort((a, b) => a.date.getTime() - b.date.getTime());
        // console.log(decomDp);

        i = 0;
        seq = 0;
        pendingDags = dagsPerOrder
        while (i < decomDp.length) {
            pendingDags += decomDp[i].numUnits;
            console.log("pendingDags");
            console.log(pendingDags);
            while (pendingDags > dagsPerOrder) {
                pendingDags -= dagsPerOrder;
                seq ++;
                
                const stampCapacityOrders = createCapacityOrders(region, decomDp[i].date, seq, hwrdmp, "DecomPair");
                for (let order of stampCapacityOrders) {
                    capacityOrders.push(order);
                }
            }
            seq = 0;
            i++;
        }
        return capacityOrders;
    }

    const generateStamps = () => {
        let capacityOrders: ICapacityOrder[] = []
        for (let region of selectedRegions) {
            generateStampsForRegion(region).forEach(s => capacityOrders.push(s));
        }
        setStamps(capacityOrders)
    }

    const onParamProfileChange = (event: React.FormEvent<HTMLElement>, option?: IDropdownOption) => {
        if (option?.key) {
            setParameterProfile(option?.key.toString());
            const profileId = paramProfiles.filter(p => p.profileName == option?.key.toString())[0].profileId;
            fetchCapacityGenerationProfileDetail(profileId).then((response) => {
                setParams(response)
            })
        }
    }
    const onSellableProfileChange = (event: React.FormEvent<HTMLElement>, option?: IDropdownOption) => {
        if (option?.key) {
            setSellableProfile(option?.key.toString());
            const profileId = sellableProfiles.filter(p => p.profileName == option?.key.toString())[0].profileId;
            fetchSellableProfileDetail(profileId).then((response) => {
                setSellable(response);
            });
        }
    }
    const onHardwareRoadmapProfileChange = (event: React.FormEvent<HTMLElement>, option?: IDropdownOption) => {
        if (option?.key) {
            setHardwareRoadmapProfile(option?.key.toString());
            const profileId =  hardwareRoadmapProfiles.filter(p => p.profileName == option?.key.toString())[0].profileId;
            fetchHardwareRoadmapDetail(profileId).then((response) => {
                console.log(response);
                setHardwareRoadmap(response);
            })
        }
    }

    const onSelectedRegionsChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        console.log(option);
        if (!option) return;
        if (option.selected) {
            setSelectedRegions([...selectedRegions, option.key.toString()]);
        } else {
            setSelectedRegions(selectedRegions.filter(r => r != option.key.toString()));
        }
    }
    const openDialog = (content: any): void => {
        console.log(content);
        setContentToInspect(content);
    }
    const closeDialog = (): void => setContentToInspect(undefined);

    function _onSupplyCutoffDateChange(newValue: Date | null | undefined): void {
        console.log(newValue);
        if (newValue) {
            setSupplyCutoffDate(newValue);
        }
    }

    function _onPlanStartDateChange(newValue?: Date | null | undefined): void {
        if (newValue) {
            setPlanStartDate(newValue);
        }
    }

    function _onPlanDurationChange(event: React.FormEvent<HTMLElement>, newValue?: string): void {
        console.log(newValue);
        if (newValue) {
            setPlanDuration(parseInt(newValue));
        }
    }

    return (
        <div className="ms-Grid" dir="ltr"> 
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                    <h3>Scenario Config</h3>
                    <div className="ms-Grid-row">
                        <ComboBox
                            multiSelect
                            //componentRef={regionComboBox}
                            label="Regions"
                            selectedKey={selectedRegions}
                            onChange={onSelectedRegionsChange}
                            styles={{root: {width: 200}}}
                            options={regions.map(asComboBoxOption)}
                        />
                    </div>
                    <div className="ms-Grid-row">
                        <Stack horizontal>
                            <ChoiceGroup
                                label="Plan Level"
                                styles={{root: {width: 200}}}
                                options={["forest", "region"].map(k => {return {key: k, text: k}})}
                                onChange={(ev, option) => setPlanLevel(option?.key.toString() || "region")}
                            />
                            <ActionButton style={{paddingTop: "2.8rem"}} onClick={() => openDialog(params)} iconProps={{ iconName: 'Search' }}/>
                        </Stack>
                    </div>
                    {planLevel == "region" && 
                    <div>
                            <Dropdown
                                label="S&D Version"
                                styles={{root: {width: 200}}}
                                options={sndVersions.map(asDropdownOption)}
                                selectedKey={sndVersion}
                                onChange={(ev, option) => {setSndVersion(option?.key.toString() || "")}} 
                            />

                    </div>}
                    <div className="ms-Grid-row">
                        <Stack horizontal>
                            <Dropdown
                                label="Parameter Profile"
                                styles={{root: {width: 200}}}
                                options={paramProfiles.map(p=>p.profileName).map(asDropdownOption)}
                                selectedKey={parameterProfile}
                                onChange={onParamProfileChange}
                            />
                            <ActionButton style={{paddingTop: "2.8rem"}} onClick={() => openDialog(params)} iconProps={{ iconName: 'Search' }}/>
                        </Stack>
                    </div>
                    <div className="ms-Grid-row">
                        <Stack horizontal>
                            <Dropdown
                                label="Capacity Sellable Profile"
                                styles={{root: {width: 200}}}
                                options={sellableProfiles.map(p=>p.profileName).map(asDropdownOption)}
                                selectedKey={sellableProfile}
                                onChange={onSellableProfileChange}
                            />
                            <ActionButton style={{paddingTop: "2.8rem"}} onClick={() => openDialog(sellable)} iconProps={{ iconName: 'Search' }}/>
                        </Stack>
                    </div>
                    <div className="ms-Grid-row">
                        <Stack horizontal>
                            <Dropdown
                                label="Hardware Roadmap Profile"
                                styles={{root: {width: 200}}}
                                options={hardwareRoadmapProfiles.map(p=>p.profileName).map(asDropdownOption)}
                                selectedKey={hardwareRoadmapProfile}
                                onChange={onHardwareRoadmapProfileChange}
                            />
                            <ActionButton style={{paddingTop: "2.8rem"}} onClick={() => openDialog(hardwareRoadmap)} iconProps={{ iconName: 'Search' }}/>
                        </Stack>
                    </div>
                    <div className="ms-Grid-row">
                        <Stack horizontal>
                            <DatePicker
                                label="Lock Horizon"
                                onSelectDate={_onSupplyCutoffDateChange}
                            />
                        </Stack>
                    </div>
                    <div className="ms-Grid-row">
                        <Stack horizontal>
                            <DatePicker
                                label="Plan Start Date"
                                onSelectDate={_onPlanStartDateChange}
                            />
                        </Stack>
                    </div>
                    <div className="ms-Grid-row">
                        <Stack horizontal>
                            <TextField
                                label="Plan Duration (Months)"
                                onChange={_onPlanDurationChange}
                            />
                        </Stack>
                    </div>
                </div>

                <div className="ms-Grid-col ms-lg3 ms-md3 ms-sm3">
                    <div className="ms-Grid-row">
                        <h3>Scenario Metadata</h3>
                        <Label>Scenario ID</Label>
                        <TextField 
                            disabled 
                            value={scenarioId}/>
                        <Label >Name</Label>
                        <TextField 
                            value={scenarioName}
                            onChange={onScenarioNameChange}/>
                        <Label>Description</Label>
                        <TextField 
                            value={scenarioDescription}
                            onChange={onScenarioDescriptionChange}/>
                    </div>
                    <div className="ms-Grid-row" style={{marginTop: "1rem"}}>
                        <Stack horizontal tokens={{childrenGap: '1rem'}}>
                            <PrimaryButton onClick={_doSaveScenario}>
                                Save Scenario
                            </PrimaryButton>
                            <DefaultButton onClick={_generateDemandPlans}>
                                Generate Demand Plan
                            </DefaultButton>
                        </Stack>
                    </div>
                </div>
            </div>
            <Dialog
                hidden={!contentToInspect}
                onDismiss={closeDialog}
                minWidth="90vw"
                maxWidth="90vw"
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Detail',
                }}
                modalProps={{
                    isBlocking: false,
                }}
            >
                <div>
                    {contentToInspect && 
                    // Object.entries(contentToInspect).map(([key, value], index) => (
                    //     <div style={{margin: "0.5rem"}} key={index} variant="medium">
                    //         {`${key}: ${JSON.stringify(value, null, 2)}`}
                    //     </div>
                    // ))
                    <DetailsList
                        items={contentToInspect}
                    />
                    
                    }
                </div>
                <ActionButton onClick={closeDialog} style={{ margin: '20px 0' }}>
                    Close
                </ActionButton>
            </Dialog>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{marginTop:"1rem"}}>
                </div>
            </div>
            {showingChart && 
                <div>
                    <div>
                        <h2>
                            {`${forestToShow.toUpperCase()}: ${metricToShow.toUpperCase()} (${CAPACITY_UNITS[metricToShow.toUpperCase()]})`}
                        </h2>
                        <DemandPlanSimulationChart
                            key={version}
                            dateStrs={_chartDateStrs.current}
                            supplyData={_chartSupplyData.current}
                            demandData={_chartDemandData.current}
                            safeSupplyData={_chartSafeSupplyData.current}
                            supplyWithNewDemandData={_chartSupplyWithNewDemandData.current}
                        />

                    </div>
                    <div>
                        <PrimaryButton
                            onClick={()=>{setShowingChart(false)}}>
                            Back
                        </PrimaryButton>
                    </div>
                </div>
            }
            {showingDetail && !showingChart && 
                <div>
                    <div>
                        <h2>
                            {forestToShow.toUpperCase()}
                        </h2>
                        <DetailsList
                            items={createDetailItems(demandPlans.filter(demandPlan => demandPlan.name==forestToShow)[0])}
                            columns={demandPlanDetailColumns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            onItemInvoked={_doShowChart}
                        />

                    </div>
                    <div>
                        <PrimaryButton
                            onClick={()=>{setShowingDetail(false)}}>
                            Back
                        </PrimaryButton>
                    </div>
                </div>
            }
            {showingForests && !showingDetail && !showingChart &&
                <div key={version}>
                    <DetailsList
                        items={createForestOverviewItems(demandPlans, regionToShow)}
                        columns={demandPlanOverviewColumns}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        onItemInvoked={_doShowDetail}
                    />
                    {demandPlanCalculationFinished &&
                        <PrimaryButton
                            onClick={()=>{setShowingForests(false)}}>
                            Back
                        </PrimaryButton>}
                </div>
            }

            {!showingForests && !showingDetail && !showingChart &&
                <div key={version}>
                    <div>
                        <DetailsList
                            items={createRegionOverviewItems()}
                            columns={demandPlanOverviewColumns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            onItemInvoked={_doShowRegion}
                        />
                    </div>
                    <div>
                        <DetailsList
                            items={createDecomOverviewItems(decomDemandPlanEntries.current!)}
                            columns={demandPlanOverviewColumns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                        />
                    </div>
                    <div>
                        <PrimaryButton 
                            onClick={() => generateStamps()} //setShowStampGenerationDialog(true)}
                            text="Generate Stamps" />
                        <DefaultButton 
                            onClick={_doDcSelection}>
                                Recommend DCs
                        </DefaultButton>
                    </div>
                        
                    
                    <DetailsList
                        items={createCapaciyOrderItems(stamps)}
                    />
                    {stamps.length != 0 &&
                        <DefaultButton
                            onClick={_doExportToCsv}>
                            Export to CSV
                        </DefaultButton>
                    }

                    <Dialog
                        hidden={!showStampGenerationDialog}
                        onDismiss={() => setShowStampGenerationDialog(false)}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: "Stamp Generation Parameters",
                            closeButtonAriaLabel: 'Close',
                            subText: "Stamps for the region will be generated using the following parameters",
                        }}
                    >
                        <TextField
                            label="SKU"
                        />
                        <TextField
                            label="Minimum Order Quantity"
                        />
                        <DatePicker
                            label="Order Start Time"
                        />
                        <DatePicker
                            label="Order End Time"
                        />
                        <DialogFooter>
                            <DefaultButton 
                                onClick={() => setShowStampGenerationDialog(false)} 
                                text="Cancel" />
                            <PrimaryButton 
                                onClick={() => {generateStamps(); setShowStampGenerationDialog(false)}} 
                                text="Generate" />
                        </DialogFooter>
                    </Dialog>




                </div>
            }
        </div>);


    // ========================== utils========================================================
            // TODO fix params[0]
    // given supply data points and demand data points, extract the info and save the numeric values into 4 arrays
    // these arrays will be used in the chart.
    function extractAsArrays (region: string, supplyDataPoints: ITimeSeriesDataPoint[], demandDataPoints: ITimeSeriesDataPoint[]) {
        const dateStrs = intersect(
            supplyDataPoints.map(p => p.date.toISOString().slice(0, 10)), 
            demandDataPoints.map(p => p.date.toISOString().slice(0, 10)));

        const supply: number[] = supplyDataPoints
            .filter(d => dateStrs.includes(formatDate(d.date)))
            .map(d => d.value);

        const demand: number[] = demandDataPoints
            .filter(d => dateStrs.includes(formatDate(d.date)))
            .map(d => d.value);
        
        if (supplyCutoffDate) {
            let supplyCutOffIndex = -1;
            for (let i = 0; i < dateStrs.length; ++i) {
                if (dateStrs[i] > formatDate(supplyCutoffDate)) {
                    supplyCutOffIndex = i;
                    break 
                }
            }
            if (supplyCutOffIndex != -1) {
                for (let i = supplyCutOffIndex; i < demand.length; ++i) {
                    supply[i] = supply[supplyCutOffIndex];
                }
            }
        }

        const param = params.filter(p => p.region == region)[0];
        const numShiftDays =1 * 30 + param.targetTTL * 30;
        const safeSupply = demandDataPoints
            .filter(d => {
                let shiftedDate = new Date(d.date);
                shiftedDate.setDate(shiftedDate.getDate() - numShiftDays);
                return dateStrs.includes(formatDate(shiftedDate))
            })
            .map(d => d.value)
        return [dateStrs, supply, demand, safeSupply];
    }

    function getSkuCopy(hardwareRoadmap: IHardwareRoadmapEntry[], date: Date, region: string) {
        console.log("in get skucopy")
        console.log(formatDate(date))
        console.log(region);
        console.log(hardwareRoadmap[0].startDate);
        console.log(hardwareRoadmap[0].endDate);
        console.log(hardwareRoadmap[0].region.toLowerCase());
        console.log(hardwareRoadmap[0].region.toLowerCase().length);
        const entry = hardwareRoadmap.filter(entry => entry.region.toLowerCase() == region && entry.startDate <= formatDate(date) && entry.endDate >= formatDate(date))[0]
        if (!entry) {
            return skuCopyStr("WCS Gen7", 3);
        }
        return skuCopyStr(entry.shortSku, entry.numCopies);
    }

    function skuCopyStr(sku: string, numCopies: number) {
        return (sku + '-' + numCopies.toString()).toLowerCase();
    }

    function getUnitSellable(skuFamily: string, region: string, decidingFactor: string) {
        console.log("in get unit sellable")
        console.log(region);
        console.log(skuFamily);
        console.log("sellable")
        console.log(sellable)
        const unitSellable = sellable.filter(entry => skuCopyStr(entry.sku, entry.copyCount).toLowerCase() == skuFamily.toLowerCase() && entry.region.toLowerCase() == region.toLowerCase());
        console.log()
        console.log("unit sellable")
        console.log(unitSellable)
        if (unitSellable.length < 1) {
            console.log("error getting unit sellable!")
            console.log(skuFamily.toLowerCase());
            console.log(sellable.filter(e => e.region.toLowerCase() == region.toLowerCase()));
            return -1;
        }
        switch (decidingFactor) {
            case "HDD":
                return unitSellable[0].maxEDB;
            case "IOPS":
                return unitSellable[0].iops;
            case "CPU":
                return unitSellable[0].gcycles;
            case "SSD":
                return unitSellable[0].ssd; 
            default:
                return -1;
        }

    }
    // calculate the demand plans using dates, supply and safesupply, return as arrays of DemandPlan
    function _calculateDemandPlans(region: string, dateStrs: string[], supply: number[], safeSupply: number[], decidingFactor: string) {
        const paramProfile = params.filter(entry => entry.region == region)[0];
        supply = supply.map(e => e * paramProfile.supplyModifier);
        safeSupply = safeSupply.map(e => e * paramProfile.demandModifier);
        let i = 0;
        while (dateStrs[i] < formatDate(new Date(Date.now()))) {
            i++;
        }
        while (i < dateStrs?.length &&  supply[i]! > safeSupply[i]!) {
            i++;
        }
        if (i >= dateStrs?.length || i >= supply.length || i > safeSupply.length) {
            return [];
        }
        let supplyNeededDate = parseDate(dateStrs[i-1])!;
        //const lastDate = parseDate(dateStrs[dateStrs.length-1])!;
        let lastDate = new Date(Date.now());
        if (!planStartDate) {
            lastDate = new Date(Date.now());
        }
        lastDate.setMonth(lastDate.getMonth() + planDuration); //TODO with plan duration
        supplyNeededDate.setDate(1);

        const demandPlans: IDemandPlanEntry[] = [];
        let purchasedDags = 0;
        while (supplyNeededDate < lastDate) {
            let nextSupplyDate = new Date(supplyNeededDate);
            nextSupplyDate.setMonth(nextSupplyDate.getMonth() + 1);//params[0].orderFrequency);
            
            const targetCapacity = safeSupply[dateStrs.indexOf(formatDate(nextSupplyDate))];
            const currentCapcity = supply[dateStrs.indexOf(formatDate(nextSupplyDate))];
            const skuFamily = getSkuCopy(hardwareRoadmap, nextSupplyDate, region);
            const sellable = getUnitSellable(skuFamily, region, decidingFactor);
            const numDags = Math.ceil((targetCapacity - currentCapcity) / sellable);//_capacitySellable.current);
            if (numDags < 0) {
                console.log(targetCapacity)
                console.log(currentCapcity)
                console.log(sellable)
            }
            // console.log("===========");
            
            // console.log(decidingFactor);
            // console.log(dateStrs);
            // console.log(supply);
            // console.log(safeSupply);
            //  console.log(nextSupplyDate);
            //  console.log(currentCapcity);
            //  console.log(targetCapacity);
            //  console.log(numDags);
            // console.log("===========")
            supplyNeededDate = nextSupplyDate;
            const orderNeededDate = new Date(supplyNeededDate.getTime());
            orderNeededDate.setMonth(orderNeededDate.getMonth());//- _leadingTime.current);
            if (numDags) {
                demandPlans.push({
                    date: orderNeededDate,
                    numUnits: numDags - purchasedDags,
                    unitCapacity: sellable,
                    skuFamily: skuFamily,
                    decidingFactor: decidingFactor,
                    //notes: decidingFactor
                });
                purchasedDags = numDags;
            }
            //monthlyDemandPlan.push()
        }
        
        return demandPlans.slice(0, demandPlans.length-2);
        //console.log(formatDate(supplyNeededDate));
    }

    // merge demand plan entries, demand plans from different metrics need to be put together as the final deman plan.
    // the merge will also align dates.
    function _mergeDemandPlanEntries(demandPlans: IDemandPlanEntry[][]) : IDemandPlanEntry[] {
        const merge = (dp1: IDemandPlanEntry[], dp2: IDemandPlanEntry[]): IDemandPlanEntry[] => {
            if (dp1.length == 0) {
                return dp2;
            }
            if (dp2.length == 0) {
                return dp1;
            }
            let i = 0;
            let j = 0;
            const mergedDemandPlans: IDemandPlanEntry[] = [];
            if (formatDate(dp1[i].date) < formatDate(dp2[j].date)) {
                mergedDemandPlans.push(dp1[i]);
                i++;
            }

            if (formatDate(dp2[j].date) < formatDate(dp1[i].date)) {
                mergedDemandPlans.push(dp2[j]);
                j++;
            }
            // console.log(dp1);
            // console.log(dp2);
            while (1) {
                

                if (i >= dp1.length) {
                    dp2.slice(j, dp2.length).forEach(plan => mergedDemandPlans.push(plan));
                    break;
                }
                if (j >= dp2.length) {
                    dp1.slice(i, dp1.length).forEach(plan => mergedDemandPlans.push(plan));
                    break;
                }

                if (dp1[i].numUnits < dp2[j].numUnits) {
                    mergedDemandPlans.push(dp2[j]);
                } else {
                    mergedDemandPlans.push(dp1[i]);
                }
                i++; 
                j++;
            }

            return mergedDemandPlans;
        }

        if (demandPlans.length == 1) {
            return demandPlans[0];
        }
        return _mergeDemandPlanEntries(
            [
                merge(demandPlans[0], demandPlans[1]), 
                ...demandPlans.slice(2, demandPlans.length)
            ]);
    }

    // calculate the supply after adding demand plans, the data is used to show the step-ish graph on the chart.
    function _calculateSupplyWithDemandPlans(region: string, dateStrs: string[], supplyData: number[], demandPlanEntries: IDemandPlanEntry[], selectedCapacityMetric: string) {
        const supplyWithDemandPlans: number[] = [];

        let j = -1;
        console.log("calculating new supply")
        console.log(demandPlanEntries)
        
        for (let i = 0; i < dateStrs.length; i++) {
            // const dateWithOffset = parseDate(dateStrs[i])!;
            // dateWithOffset.setMonth(dateWithOffset.getMonth() - _leadingTime.current);
            const date = parseDate(dateStrs[i])!;
            while (j < demandPlanEntries.length-1 && date >= demandPlanEntries[j+1].date) {
                j++;
            }
            //const sellable = getUnitSellable(demandPlanEntries[i].skuFamily, region, selectedCapacityMetric);
            if (j != -1) {
                supplyWithDemandPlans.push(supplyData[i] + calculateTotalCapacity(demandPlanEntries.slice(0, j+2)));
            } else {
                supplyWithDemandPlans.push(supplyData[i]);
            }

        }
        return supplyWithDemandPlans;
    }

    function calculateTotalCapacity(demandPlanEntries: IDemandPlanEntry[]) {
        let sum = 0;
        for (let i = 0; i < demandPlanEntries.length; ++i) {
            sum = sum + demandPlanEntries[i].numUnits * demandPlanEntries[i].unitCapacity;
        }
        return sum;
    }
    // ========================================================
}

