import * as React from "react";
import PageHeader from '../../common/pageheader/PageHeader';
import {
    MessageBar,
    MessageBarType,
    Link
} from '@fluentui/react';

export const M2CPReport: React.FC = (props) => {
    return (
        <div>
            <PageHeader
                title="Multi-metrics TTL"
                description="The report includes demand, supply, and the corresponding time-to-live (TTL) for CPU/IOPS/HDD/SSD/Memory of Substrate Backend"
            />
            <MessageBar>
                Please go to
                <Link href="https://aka.ms/tesseract/m2cp/report" inline>
                    aka.ms/tesseract/m2cp/report
                </Link>{' '}
                to check original report
            </MessageBar>
            <iframe title="M2CP"
                style={{ width: '100%', height: '850px', marginTop: '20px' }}
                src="https://msit.powerbi.com/reportEmbed?reportId=04d95e5c-4f80-423a-b05b-f07a024e784d&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9kZi1tc2l0LXNjdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D"
                frameBorder={0}
                allowFullScreen={true}></iframe>
        </div>

    )
}