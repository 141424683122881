import * as React from 'react';
import * as XLSX from 'xlsx';
import {
    DetailsList,
    DetailsListLayoutMode,
    IDetailsListStyles,
    IDetailsHeaderProps,
    ConstrainMode,
    DetailsRow,
    IDetailsColumnStyles,
    IColumn,
    IDetailsRowProps,
    IDetailsRowStyles,
} from '@fluentui/react/lib/DetailsList';
import {
    Checkbox,
    DirectionalHint,
    HoverCard,
    IExpandingCardProps,
    Spinner,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { useBoolean } from '@fluentui/react-hooks';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IDetailsColumnRenderTooltipProps } from '@fluentui/react/lib/DetailsList';
import { RegionStatusTableModal } from './modules/RegionStatusTableModal';
import { EnvFilter } from './modules/EnvFilter';
import { RegionFilter } from './modules/RegionFilter';
import { ColumnSelection } from './modules/ColumnSelection';
import { RegionStatus, AlertingConfigNew, CopsQC, RegionStatusTrend, RegionEnvironmentStatus } from '../../../models/alerting';
import { saveRegionStatus } from '../../../services/alertingservice';
import { StatusTrend } from '../alerting/modules/StatusTrend'
import styles from "./RegionTTL.less"
import { IUserPermissionData } from "../../../reducers/userPermissionReducer";
import { IAppState } from "../../../store";
import { useSelector } from "react-redux";
import { checkUserPermission } from "../../../utils/utils"
import { ToolType } from "../../../utils/ToolConstants"
import { fetchRegionGeoMapping } from '../../../services/costsavingservice';
import { useHistory } from 'react-router';
import { IRegionGeoMapping } from '../../../models/CostSaving';


interface IRegionTTLProps {
    data: RegionStatus[],
    configs: AlertingConfigNew[],
    copsQC?: CopsQC,
    regionStatusTrend: RegionStatusTrend[]
    isColdRegionMapping: RegionEnvironmentStatus[]
}

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                background: 'none',
                color: 'black'
            },
        },
    },
    headerWrapper: {
        flex: '0 0 auto',
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
};

const hoverCardClassNames = mergeStyleSets({
    compactCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        fontSize: '18px'
    },
    expandedCard: {
        padding: '16px 24px',
    },
    item: {
        selectors: {
            '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
            },
        },
    },
});

const headerStyle: Partial<IDetailsColumnStyles> = {
    cellTitle: {
        color: 'white',
        backgroundColor: 'black',
        marginLeft: '1px'
    }
}

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );
    return defaultRender!({
        ...props,
        styles: {
            root: {
                selectors: {
                    '.ms-DetailsHeader-cell': {
                        whiteSpace: 'pre-wrap',
                        textOverflow: 'clip',
                        lineHeight: 'normal',
                    },
                    '.ms-DetailsHeader-cellTitle': {
                        height: '100%',
                        alignItems: 'center',
                    },
                },
            },
        },
        onRenderColumnHeaderTooltip,
    });
};



const getColor = (ttl: number, red: number, green: number, blue: number) => {
    if (ttl > red && ttl <= green) {
        return { "backgroundColor": "yellow", width: "135px", color: "black", fontSize: "18px", textAlign: "center" };
    } else if (ttl <= red) {
        return { "backgroundColor": "red", width: "135px", color: "white", fontSize: "18px", textAlign: "center" };
    } else if (ttl > green && ttl <= blue) {
        return { "backgroundColor": "green", width: "135px", color: "white", fontSize: "18px", textAlign: "center" };
    } else {
        return { "backgroundColor": "#3483eb", width: "135px", color: "white", fontSize: "18px", textAlign: "center"}
    }
}

const getCumstomerImpactColor = (color: string) => {
    switch (color){       
        case "red": return { "backgroundColor": "red", width: "100px", height: "20px" };
        case "yellow": return { "backgroundColor": "yellow", width: "100px", height: "20px" }
        case "blue": return { "backgroundColor": "#3483eb", width: "100px", height: "20px" }
        default: return { width: "135px" }
    }      
}

const getConfigForRegionEnv = (item: RegionStatus, configs: AlertingConfigNew[]) => {
    const config = configs.find(c => c.geoCode == item.region && c.environment == item.environment);
    if (config == undefined || config == null) {
        console.log("empty config:" + item.region + " " + item.environment);
    }
    return {
        "red": config?.sev1,
        "green": config?.sev2,
        "blue": config?.sev3
    }
}

export const RegionTTL: React.FunctionComponent<IRegionTTLProps> = (props) => {
    const history = useHistory();

    const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = detailColumns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(detailsItem, currColumn.fieldName!, currColumn.isSortedDescending);
        setDetailsItem(newItems);
        setDetailColumns(newColumns);
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const columns = [
        {
            styles: headerStyle,
            key: 'region',
            name: 'Region',
            fieldName: 'region',
            isResizable: true,
            minHeight: 20,
            minWidth: 30,
            maxWidth: 130,
            ariaLabel: "Region"
        },
        {
            styles: headerStyle,
            key: 'env',
            name: 'Environment',
            fieldName: 'environment',
            isResizable: true,
            minWidth: 30,
            maxWidth: 95,
            ariaLabel: "Environment"
        },
        {
            styles: headerStyle,
            key: 'todayttlhdd',
            name: 'HDD TTL (Today)',
            fieldName: 'todayTTLHDD',
            isResizable: true,
            minWidth: 30,
            maxWidth: 80,
            ariaLabel: "HDD Today TTL"
        },
        {
            styles: headerStyle,
            key: 'todayttlcpu',
            name: 'CPU TTL (Today)',
            fieldName: 'todayTTLCPU',
            isResizable: true,
            minWidth: 30,
            maxWidth: 100,
            ariaLabel: "CPU Today TTL"
        },
        {
            styles: headerStyle,
            key: 'todayttliops',
            name: 'IOPS TTL (Today)',
            fieldName: 'todayTTLIOPS',
            isResizable: true,
            minWidth: 30,
            maxWidth: 100,
            ariaLabel: "IOPS Today TTL"
        },       
        {
            styles: headerStyle,
            key: 'todayttlssd',
            name: 'SSD TTL (Today)',
            fieldName: 'todayTTLSSD',
            isResizable: true,
            minWidth: 30,
            maxWidth: 100,
            ariaLabel: "SSD Today TTL"
        },
        {
            styles: headerStyle,
            key: 'todayttlssdphoenix',
            name: 'SSD Phoneix TTL (Today)',
            fieldName: 'todayTTLSSDPhoenix',
            isResizable: true,
            minWidth: 30,
            maxWidth: 100,
            ariaLabel: "SSD Phoenix Today TTL"
        },
        {
            styles: headerStyle,
            key: 'todayttlssdmcdb',
            name: 'SSD MCDB TTL (Today)',
            fieldName: 'todayTTLSSDMCDB',
            isResizable: true,
            minWidth: 30,
            maxWidth: 100,
            ariaLabel: "SSD MCDB Today TTL"
        },
        {
            styles: headerStyle,
            key: 'todayttlmin',
            name: 'TTL min (Today)',
            fieldName: 'todayTTLMin',
            isResizable: true,
            minWidth: 30,
            maxWidth: 55,
            ariaLabel: "Today min TTL"
        },
        {
            styles: headerStyle,
            key: 'servertoday',
            name: 'Server Status (today)',
            fieldName: 'todayTTLMin',
            isResizable: true,
            minWidth: 30,
            maxWidth: 120,
            ariaLabel: "Server Status (today)"
        }, 
        {
            styles: headerStyle,
            key: '6mttl',
            name: 'TTL min (at 6m)',
            fieldName: 'sixMonthsTTL',
            isResizable: true,
            minWidth: 30,
            maxWidth: 57,
            ariaLabel: "TTL min at 6m"
        },
        {
            styles: headerStyle,
            key: '12mttl',
            name: 'TTL min (at 12m)',
            fieldName: 'oneYearTTL',
            isResizable: true,
            minWidth: 30,
            maxWidth: 50,
            ariaLabel: "TTL min at 12m"
        },
        {
            styles: headerStyle,
            key: 'minttl',
            name: 'TTL min (next 12m)',
            fieldName: 'minTTL',
            isResizable: true,
            minWidth: 30,
            maxWidth: 80,
            ariaLabel: "TTL min (next 12m)"
        },
        {
            styles: headerStyle,
            key: 'currentfyttl',
            name: 'End of FY' + (new Date().getMonth() + 1 > 7 ? new Date().getFullYear() + 1 : new Date().getFullYear()) + ' TTL',
            fieldName: 'currentFYTTL',
            isResizable: true,
            minWidth: 30,
            maxWidth: 80,
            ariaLabel: 'End of FY' + (new Date().getMonth() + 1 > 7 ? new Date().getFullYear() + 1 : new Date().getFullYear()) + ' TTL',
        },
        {
            styles: headerStyle,
            key: 'nextfyttl',
            name: 'End of FY' + (new Date().getMonth() + 1 > 7 ? new Date().getFullYear() + 2 : new Date().getFullYear() + 1) + ' TTL',
            fieldName: 'nextFYTTL',
            isResizable: true,
            minWidth: 30,
            maxWidth: 80,
            ariaLabel: 'End of FY' + (new Date().getMonth() + 1 > 7 ? new Date().getFullYear() + 2 : new Date().getFullYear() + 1) + ' TTL',
        }, 
        {
            styles: headerStyle,
            key: 'minttldate',
            name: 'Date of TTL min (next 12m)',
            fieldName: 'minTTLDate',
            isResizable: true,
            minWidth: 30,
            maxWidth: 100,
            ariaLabel: "Date of TTL min (next 12m)"
        },  
        {
            styles: headerStyle,
            key: 'server',
            name: 'Server Status (next 12m)',
            fieldName: 'minTTL',
            isResizable: true,
            minWidth: 30,
            maxWidth: 120,
            ariaLabel: "Server Status (next 12m)"
        },
        {
            styles: headerStyle,
            key: 'activeuserratio',
            name: 'Active User Ratio',
            fieldName: 'activeUserRatio',
            isResizable: true,
            minWidth: 30,
            maxWidth: 68,
            ariaLabel: "Active User Ratio"
        }, 
        {
            styles: headerStyle,
            key: 'livedag',
            name: 'Live Dags',
            fieldName: 'liveDag',
            isResizable: true,
            minWidth: 30,
            maxWidth: 48,
            ariaLabel: "Live Dags"
        }, 
        {
            styles: headerStyle,
            key: 'dagratio',
            name: 'Dag Ratio',
            fieldName: 'dagRatio',
            isResizable: true,
            minWidth: 30,
            maxWidth: 52,
            ariaLabel: "Dag Ratio"
        }, 
        {
            styles: headerStyle,
            key: 'newdags',
            name: 'New Dags Till FY' + (new Date().getMonth() + 1 > 7 ? new Date().getFullYear() + 1 : new Date().getFullYear()),
            fieldName: 'newDags',
            isResizable: true,
            minWidth: 30,
            maxWidth: 86,
            ariaLabel: 'New Dags Till FY' + (new Date().getMonth() + 1 > 7 ? new Date().getFullYear() + 1 : new Date().getFullYear())
        },
        {
            styles: headerStyle,
            key: 'hdddemand',
            name: 'HDD Demand Total (Today)',
            fieldName: 'hddDemand',
            isResizable: true,
            minWidth: 30,
            maxWidth: 92,
            ariaLabel: "HDD Total Demand (Today)"
        }, 
        {
            styles: headerStyle,
            key: 'hddenterprisedemand',
            name: 'HDD Demand Enterprise (Today)',
            fieldName: 'hddEnterpriseDemand',
            isResizable: true,
            minWidth: 125,
            maxWidth: 125,
            ariaLabel: "HDD Demand Enterprise (Today)"
        }, 
        {
            styles: headerStyle,
            key: 'hddconsumerdemand',
            name: 'HDD Demand Consumer (Today)',
            fieldName: 'hddConsumerDemand',
            isResizable: true,
            minWidth: 130,
            maxWidth: 130,
            ariaLabel: "HDD Demand Consumer (Today)"
        }, 
        {
            styles: headerStyle,
            key: 'hddenterpriseratio',
            name: 'HDD Demand Enterprise % (Today)',
            fieldName: 'hddEnterpriseDemandRatio',
            isResizable: true,
            minWidth: 140,
            maxWidth: 140,
            ariaLabel: "HDD Demand Enterprise % (Today)"
        }, 
        {
            styles: headerStyle,
            key: 'hddconsumerratio',
            name: 'HDD Demand Consumer % (Today)',
            fieldName: 'hddConsumerDemandRatio',
            isResizable: true,
            minWidth: 140,
            maxWidth: 140,
            ariaLabel: "HDD Demand Consumer % (Today)"
        }, 
        {
            styles: headerStyle,
            key: 'rootcause',
            name: 'Root Cause',
            fieldName: 'rootCause',
            isResizable: true,
            minWidth: 100,
            maxWidth: 230,
            ariaLabel: "Root Cause"
        },
        {
            styles: headerStyle,
            key: 'mitigation',
            name: 'Mitigation Strategy',
            fieldName: 'mitigationStrategy',
            isResizable: true,
            minWidth: 100,
            maxWidth: 230,
            ariaLabel: "Mitigation Strategy"
        },
        {
            styles: headerStyle,
            key: 'recovery',
            name: 'Project Recovery Date',
            fieldName: 'projectRecoveryDate',
            isResizable: true,
            minWidth: 100,
            maxWidth: 100,
            ariaLabel: "Project Recovery Date"
        },
        {
            styles: headerStyle,
            key: 'impact',
            name: 'Customer Impact',
            fieldName: 'customerImpact',
            isResizable: true,
            minWidth: 30,
            maxWidth: 100,
            ariaLabel: "Customer Impact"
        },
        {
            styles: headerStyle,
            key: 'regionsselected',
            name: 'Regions Selected',
            fieldName: 'regions selected',
            isResizable: true,
            minHeight: 20,
            minWidth: 30,
            maxWidth: 130,
            ariaLabel: "Regions Selected",
        },
    ];

    const defaultSelectedKeys = ['Multitenant', 'Go Local', 'Gallatin'];
    const defaultColumnKeys = ['region', 'env', 'servertoday', 'server', 'minttl', 'minttldate', 'todayttlmin', '6mttl', 'rootcause', 'mitigation', 'recovery', 'impact', 'regionsselected']
    const [data, setData] = React.useState<RegionStatus[]>(props.data);
    const [detailsItem, setDetailsItem] = React.useState<RegionStatus[]>(props.data.filter(item => defaultSelectedKeys.includes(item.environment)));
    const [detailColumns, setDetailColumns] = React.useState<IColumn[]>(columns.filter(col => defaultColumnKeys.includes(col.key)));
    const [index, setIndex] = React.useState(-1);
    const [selectedKeys, setSelectedKeys] = React.useState(defaultSelectedKeys);
    const [columnKeys, setColumnKeys] = React.useState(defaultColumnKeys);
    const [regionKeys, setRegionKeys] = React.useState<string[]>([]);
    const [loadingRegionStatus, setLoadingRegionStatus] = React.useState(false);
    const [regionFilter, setRegionFilter] = React.useState<string>();
    const [envFilter, setEnvFilter] = React.useState<string>();
    const [regionGeoMappings, setRegionGeoMappings] = React.useState<IRegionGeoMapping[]>([]);
    const [isModalOpen, { setTrue: openModal, setFalse: hideModal }] = useBoolean(false);
    const [selectedItem, setSelectedItem] = React.useState<RegionStatus>();
    const [errMsg, setErrMsg] = React.useState("");
    const { userpermissions } = useSelector<IAppState, IUserPermissionData>(state => state.userPermissions);
    const [selectedRegionsStatusIds, setSelectedRegionsStatusIds] = React.useState<number[]>([]);

    React.useEffect(() => {
        fetchRegionGeoMapping()
            .then(res => {
                if (res != undefined) {
                    setRegionGeoMappings(res);
                }
            })
       
    }, []);

    function onSimulateCostClicked() {
        if (selectedRegionsStatusIds.length === 0) {
            alert('No region / environments selected for mitigation');
            return;
        }
        var firstRegionStatus: RegionStatus = props.data[selectedRegionsStatusIds[0]];
        var firstRegionStatusEnv = firstRegionStatus.environment;
        var firstCapacityGeoCode = firstRegionStatus.region;
        var firstRegionStatusRegion = getRegionFromGeoCode(firstCapacityGeoCode);
        console.log("Got reg from geoCode: ", firstRegionStatusRegion);
        history.push({ pathname: '/CostSaving/Overview', state: { frsEnv: firstRegionStatusEnv, frsRegion: firstRegionStatusRegion } });
    }

    function getRegionFromGeoCode(geoCode: string) {
        console.log(regionGeoMappings);
        var regGeoMapping: IRegionGeoMapping | undefined = regionGeoMappings.find(data => {
            return data.capacityGeoCode == geoCode;
        });
        if (regGeoMapping == undefined) {
            return "";
        }

        return regGeoMapping.region;
    }

    function _onChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
        console.log(`The option has been changed to ${isChecked}.`);
        var selectedRegionStatusId = parseInt(ev?.target.id) - 1;
        if (selectedRegionsStatusIds.includes(selectedRegionStatusId)) {
            var remIndex = selectedRegionsStatusIds.indexOf(selectedRegionStatusId);
            selectedRegionsStatusIds.splice(remIndex, 1);
        } else {
            selectedRegionsStatusIds.push(selectedRegionStatusId)
        }
        console.log(selectedRegionsStatusIds)
    }

    const onRowSelection = (item: RegionStatus, index: number) => {
        if (checkUserPermission(userpermissions, ToolType.CAPACITYHEALTH, 'edit')) {
            openModal();
            setSelectedItem(item);
            setIndex(index);
        }      
    }

    const onRowSave = (item?: RegionStatus) => {
        hideModal();
        if (item != undefined) {
            const newDetails = [...detailsItem];
            newDetails[index].rootCause = item.rootCause;
            newDetails[index].customerImpact = item.customerImpact;
            newDetails[index].mitigationStrategy = item.mitigationStrategy;
            newDetails[index].projectRecoveryDate = item.projectRecoveryDate;
            setDetailsItem(newDetails);
            
            item.version = new Date();
            saveRegionStatus(item)
                .then(res => {
                    if (res.status != 200) {
                        setErrMsg("Save locally but can't save to DB properly");
                    }
                })
        }
    }

    const onRenderCompactCard = (item: AlertingConfigNew): JSX.Element => {
        return (
            <div className={hoverCardClassNames.compactCard}>
                <b>{item.geoCode}</b> -- <b>{item.environment} </b>
            </div>
        );
    };

    const onRenderExpandedCard = (item: AlertingConfigNew): JSX.Element => {
        return (
            <div><div><p>MaxEDB: {item.maxEDB}</p></div>
            <div style={{ display: "grid", gridTemplateRows: "50% 50% 50% 50%" }}>
                <div style={{ display: "grid", gridTemplateColumns: "10% 5% 85%" }}>
                    <div style={{ backgroundColor: "#3483eb", height: "20px" }}> </div>
                    <div></div>
                    <div>TTL {">"} {item.sev3} months </div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "10% 5% 85%" }}>
                    <div style={{ backgroundColor: "green", height: "20px" }}></div>
                    <div></div>
                    <div> TTL {">"} {item.sev2} months and TTL {"<="} {item.sev3} months</div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "10% 5% 85%" }}>
                    <div style={{ backgroundColor: "yellow", height: "20px" }}></div>
                    <div></div>
                    <div> TTL {">"} {item.sev1} months and {"<="} {item.sev2} months</div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "10% 5% 85%" }}>
                    <div style={{ backgroundColor: "red", height: "20px" }}></div>
                    <div></div>
                    <div> TTL {"<="} {item.sev1} months</div>
                </div>
            </div>
            </div>
        );
    };

/*    function isColdRegion(geocode: string, env: string) {
        var ans: boolean = false;
        checkIsColdRegEnv(geocode, env).then(
            response => {
                ans = response;
            },
            ex => {
                console.log("exception", ex);
                ans = false;
            }
        )
        return ans;
    }*/

    const _renderItemColumn = (item: RegionStatus, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName as keyof RegionStatus] as string;
        const config = props.configs.find(c => c.geoCode == item.region && c.environment == item.environment);
        const expandingCardProps: IExpandingCardProps = {
            onRenderCompactCard: onRenderCompactCard,
            onRenderExpandedCard: onRenderExpandedCard,
            renderData: config,
            directionalHint: DirectionalHint.rightTopEdge,
            gapSpace:15
        };

        switch (column.key) {
            case 'servertoday':
                return <HoverCard expandingCardProps={expandingCardProps} instantOpenOnClick={true} expandedCardOpenDelay={0}>
                    <div style={getColor(Number(fieldContent), Number(getConfigForRegionEnv(item, props.configs).red), Number(getConfigForRegionEnv(item, props.configs).green), Number(getConfigForRegionEnv(item, props.configs).blue))}>{item["todayRestrictedRes"]}</div >
                </HoverCard>
            case 'server':           
                return <HoverCard expandingCardProps={expandingCardProps} instantOpenOnClick={true} expandedCardOpenDelay={ 0}>
                    <div style={getColor(Number(fieldContent), Number(getConfigForRegionEnv(item, props.configs).red), Number(getConfigForRegionEnv(item, props.configs).green), Number(getConfigForRegionEnv(item, props.configs).blue))}>{item["restrictedRes"]}</div >
                    </HoverCard>
            case 'minttl':
            case 'todayttlmin':
            case 'todayttlcpu':
            case 'todayttliops':
            case 'todayttlhdd':
            case 'todayttlssd':
            case 'todayttlssdphoenix':
            case 'todayttlssdmcdb':
            case '6mttl':
            case '12mttl': 
            case 'currentfyttl':
            case 'nextfyttl': 
                return <div style={{ fontSize: "18px" }}>{parseFloat(fieldContent).toFixed(2)}</div>
            case 'livedag':
            case 'newdags': 
            case 'hdddemand':
            case 'hddenterprisedemand':
            case 'hddconsumerdemand':
                return <div style={{ fontSize: "18px" }}>{parseFloat(fieldContent).toLocaleString()}</div>
            case 'dagratio':
            case 'hddconsumerratio':
            case 'hddenterpriseratio':
            case 'activeuserratio': 
                return <div style={{ fontSize: "18px" }}>{(parseFloat(fieldContent)*100).toFixed(2)}%</div>
            case 'minttldate':
                return <div style={{ fontSize: "18px" }}> {fieldContent.substring(0, 10)}</div>
            case 'region':
                return <div style={{ fontSize: "18px", fontWeight: "700" }} >{fieldContent}</div>;
            case 'impact':
                return <div style={getCumstomerImpactColor(fieldContent)} ><span></span></div>;
            case 'regionsselected':
                var regionSelectedDiv = <div style={{ wordWrap: "break-word", fontSize: "18px", whiteSpace: "pre-wrap" }} ></div>;

                if (props.isColdRegionMapping.length != 0 && props.isColdRegionMapping.find(data => { return data.environment == item.environment && data.geocode == item.region })?.status == "Cold") {
                    regionSelectedDiv = <div style={{ fontSize: '18px', marginLeft: '2.5em' }}><Checkbox id={(index + 1).toString()} onChange={_onChange} /></div>;
                }
                return regionSelectedDiv;
            default:
                return <div style={{ wordWrap: "break-word", fontSize: "18px", whiteSpace: "pre-wrap" }} >{fieldContent}</div>;
        }
    }

    const _onRenderRow = (props: IDetailsRowProps, index: number) => {
        const rowStyles: Partial<IDetailsRowStyles> = {
            root: {
                background: props.itemIndex % 2 == 0 ? '#e3e3e3' : '#cacaca',
                color: 'black',
                marginBottom: '1px'
            },
        }
        return <DetailsRow {...props} styles={rowStyles} />;
    }

    const _onRegionFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                regionKeys.push(option.key.toString());
                setRegionKeys(regionKeys);
                setDetailsItem(data.filter(item => regionKeys.includes(item.region) && selectedKeys.includes(item.environment)))
            }
            if (option.selected == false) {
                const arr_index = regionKeys.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = regionKeys.filter(key => key != option.key.toString());
                    setRegionKeys(new_arr);
                    if (new_arr.length == 0) {
                        setDetailsItem(data.filter(item => selectedKeys.includes(item.environment)));
                    } else {
                        setDetailsItem(data.filter(item => new_arr.includes(item.region) && selectedKeys.includes(item.environment)));
                    }                    
                }
            }
        }
    }

    const _onEnvFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                selectedKeys.push(option.key.toString());
                setSelectedKeys(selectedKeys);
                if (regionKeys.length == 0) {
                    setDetailsItem(data.filter(item => selectedKeys.includes(item.environment)));
                } else {
                    setDetailsItem(data.filter(item => selectedKeys.includes(item.environment) && regionKeys.includes(item.region)));
                }
                
            }
            if (option.selected == false) {
                const arr_index = selectedKeys.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = selectedKeys.filter(key => key != option.key.toString());
                    setSelectedKeys(new_arr);                   
                    if (regionKeys.length == 0) {
                        setDetailsItem(data.filter(item => new_arr.includes(item.environment)));
                    } else {
                        setDetailsItem(data.filter(item => new_arr.includes(item.environment) && regionKeys.includes(item.region)));
                    }
                }
            }
        }
    }

    const _onColumnFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                columnKeys.push(option.key.toString());
                setColumnKeys(columnKeys);
                setDetailColumns(columns.filter(col => columnKeys.includes(col.key)))
            }
            if (option.selected == false) {
                const arr_index = columnKeys.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = columnKeys.filter(key => key != option.key.toString());
                    setColumnKeys(new_arr);
                    setDetailColumns(columns.filter(col => new_arr.includes(col.key)));
                }
            }
        }
    }

    const _onDownloadCapacityHealth = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "CapacityHealth");
        XLSX.writeFile(workbook, "CapacityHealthReport.xlsx");
    }




    return (
        <div>
            <StatusTrend data={props.regionStatusTrend} />
            <div className={styles.separator}></div>
            <MessageBar messageBarType={MessageBarType.warning}>
                Please note that the live dags and dag ratio comes from COPS data which using COPS QC date due to COPS data incomplete issue
                <li>COPSMultitenant Date:{props.copsQC == undefined ? 'null' : props.copsQC.copsMultitenant}</li>
                <li>COPSMultitenant QC Date: {props.copsQC == undefined ? 'null' : props.copsQC.qC_Date}</li>
                <li>COPSGallatin Date:{props.copsQC == undefined ? 'null' : props.copsQC.copsGallatin}</li>
                <li>COPSGallatin QC Date: {props.copsQC == undefined ? 'null' : props.copsQC.qC_COPSGallatin}</li>
            </MessageBar>          

            <div className="ms-Grid" dir="ltr" style={{ marginTop: '20px' }}>
                {errMsg != "" && < MessageBar messageBarType={MessageBarType.error}>
                    ERROR -- {errMsg}
                    </MessageBar>}
            </div>
            <div className="ms-Grid" dir="ltr" style={{marginTop: '10px'}}>
                <div className="ms-Grid-row">                    
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"><EnvFilter defaultOptions={selectedKeys} onEnvFilter={_onEnvFilter} /></div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"><RegionFilter onRegionFilter={_onRegionFilter} /></div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"><ColumnSelection defaultOptions={columnKeys} onColFilter={_onColumnFilter} /></div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{ marginTop: "40px", fontWeight: 700 }}>Refreshed on: {data[0].datadate}</div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg1" style={{ marginTop: "30px", textAlign: 'left' }}><PrimaryButton iconProps={{ iconName: "ArrowUpRight" }} onClick={onSimulateCostClicked}>Cost Saving</PrimaryButton></div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" style={{ marginTop: "30px", textAlign: 'left' }}><PrimaryButton iconProps={{ iconName: "Download" }} onClick={_onDownloadCapacityHealth}>Download</PrimaryButton></div>
                </div>
            </div>

            {loadingRegionStatus && <div style={{marginTop: "50px"}}><Spinner label="Loading region status...."/></div>}
            {!loadingRegionStatus &&
                <DetailsList
                    isHeaderVisible={true}
                    compact={true}
                    items={detailsItem}
                    columns={detailColumns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    onRenderItemColumn={_renderItemColumn}
                    onRenderRow={_onRenderRow}
                    onColumnHeaderClick={_onColumnClick}
                    selectionPreservedOnEmptyClick
                    styles={gridStyles}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                    onItemInvoked={onRowSelection}
                    selectionMode={SelectionMode.none}
                />}
            {isModalOpen && <RegionStatusTableModal isModalOpen={isModalOpen} hideModal={hideModal} item={selectedItem} save={onRowSave} />}
        </div>
    );


};
