import { getJson } from "../utils/apiServiceBase";
import { INavLinkGroup } from "@fluentui/react";
import { UserPermission } from "../models/permission";
const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export async function getSidebarsBySG(username?: string): Promise<INavLinkGroup[]> {
    return await getJson<INavLinkGroup[]>("api/Home/getsidebars?username=" + username);
}

export async function getUserPermission(username?: string): Promise<UserPermission[]> {
    return await getJson<UserPermission[]>("api/Home/getuserpermission?username=" + username);
}

export async function getUserSecurityGroupsFromLocal(): Promise<string[]> {
    await delay(5000);   
    const response = await fetch('usersecuritygroups.json', {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
    return response.json();
}