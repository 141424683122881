import * as React from "react";;
import {
    Checkbox,
    DirectionalHint,
    getTheme,
    HoverCard,
    IDetailsColumnStyles,
    IDetailsRowProps,
    IDetailsRowStyles,
    IExpandingCardProps,
    IScrollablePaneStyles,
    ITooltipHostStyles,
    ITooltipProps,
    mergeStyleSets,
    ScrollablePane,
    Spinner,
    SpinnerSize,
    TooltipDelay,
    TooltipHost,
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    SelectionMode,
    IColumn,
    IDetailsListProps,
    DetailsRow,
    PrimaryButton,
    IDropdownOption
} from '@fluentui/react';

import { computeAICPUTTL, computeAIHDDTTL, computeAIIOPSTTL, computeAISSDTTL, computeNewCPUTTL, computeNewHDDTTL, computeNewIOPSTTL, computeNewSSDTTL, fetchCPUDemandData, fetchHDDDemandData, fetchIOPSDemandData, fetchSSDDemandData } from '../../../services/costsavingservice';
import { IAIStamp, IDemandData, IDeraStamp, IHLCStamp, ITTLResultsData } from "../../../models/CostSaving";
import { Dictionary } from "lodash";
import { ColumnSelection } from "./modules/ColumnSelection";

interface ITTLDeltasChart {
    environment: string
    region: string
    hlcStamps: IHLCStamp[]
    deraStamps: IDeraStamp[]
    aiStamps: IAIStamp[]
    threshold: number
    startRange: number
    endRange: number
}

const monthNames: Dictionary<string> = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
}

const headerStyle: Partial<IDetailsColumnStyles> = {
    cellTitle: {
        color: 'white',
        backgroundColor: 'black',
        marginLeft: '1px'
    }
}

export const AITTLDeltasChart: React.FC<ITTLDeltasChart> = ({ environment, region, hlcStamps, deraStamps, aiStamps, threshold, startRange, endRange }) => {
    let [cpuDemandData, setCpuDemandData] = React.useState<IDemandData[]>([]);
    let [iopsDemandData, setIopsDemandData] = React.useState<IDemandData[]>([]);
    let [hddDemandData, setHddDemandData] = React.useState<IDemandData[]>([]);
    let [ssdDemandData, setSsdDemandData] = React.useState<IDemandData[]>([]);

    let [oldCpuDemandData, setOldCpuDemandData] = React.useState<IDemandData[]>([]);
    let [oldIopsDemandData, setOldIopsDemandData] = React.useState<IDemandData[]>([]);
    let [oldHddDemandData, setOldHddDemandData] = React.useState<IDemandData[]>([]);
    let [oldSsdDemandData, setOldSsdDemandData] = React.useState<IDemandData[]>([]);

    let [newCpuDemandData, setNewCpuDemandData] = React.useState<IDemandData[]>([]);
    let [newIopsDemandData, setNewIopsDemandData] = React.useState<IDemandData[]>([]);
    let [newHddDemandData, setNewHddDemandData] = React.useState<IDemandData[]>([]);
    let [newSsdDemandData, setNewSsdDemandData] = React.useState<IDemandData[]>([]);

    const [isLoadingCPU, setIsLoadingCPU] = React.useState<boolean>(true);
    const [isLoadingIOPS, setIsLoadingIOPS] = React.useState<boolean>(true);
    const [isLoadingHDD, setIsLoadingHDD] = React.useState<boolean>(true);
    const [isLoadingSSD, setIsLoadingSSD] = React.useState<boolean>(true);

    const defaultColumnKeys = ['column0', 'column1', 'column3', 'column4', 'column6', 'column7', 'column9', 'column10', 'column12', 'column13', 'column18', 'column23', 'column28', 'column33'];
    const [columnKeys, setColumnKeys] = React.useState(defaultColumnKeys);
   
    function getCPUDemandData() {
        fetchCPUDemandData(region, environment).then(
            response => {
                console.log("cpu", response);
                setCpuDemandData(response);
                setIsLoadingCPU(false);
            },
            ex => {
                console.log("Error fetching CPU demand data");
                setCpuDemandData([]);
                setIsLoadingCPU(false);
                //setParamsError("Error retrieving stamp information");
            }
        )
    }

    function getIOPSDemandData() {
        fetchIOPSDemandData(region, environment).then(
            response => {
                console.log("iops", response);
                setIopsDemandData(response);
                setIsLoadingIOPS(false);
            },
            ex => {
                console.log("Error fetching IOPS demand data");
                setIopsDemandData([]);
                setIsLoadingIOPS(false);
                //setParamsError("Error retrieving stamp information");
            }
        )
    }

    function getHDDDemandData() {
        fetchHDDDemandData(region, environment).then(
            response => {
                console.log("hdd", response);
                setHddDemandData(response);
                setIsLoadingHDD(false);
            },
            ex => {
                console.log("Error fetching HDD demand data");
                setHddDemandData([]);
                setIsLoadingHDD(false);
                //setParamsError("Error retrieving stamp information");
            }
        )
    }

    function getSSDDemandData() {
        console.log(region, environment);
        fetchSSDDemandData(region, environment).then(
            response => {
                console.log("ssd", response);
                setSsdDemandData(response);
                setIsLoadingSSD(false);
            },
            ex => {
                console.log("Error fetching SSD demand data");
                setSsdDemandData([]);
                setIsLoadingSSD(false);
                //setParamsError("Error retrieving stamp information");
            }
        )
    }

    React.useEffect(() => {
        (async () => {
            try {
                await Promise.all([getCPUDemandData(), getIOPSDemandData(), getHDDDemandData(), getSSDDemandData()]);
            } catch (err) {
                console.log('Error occured when fetching demand data for TTL metrics');
            }
        })();
    }, []);

    function roundTwoDecimals(num: number) {
        const rounded = (Math.round(num * 100) / 100).toFixed(2);
        if (num >= 100000000) {
            const numLen = rounded.indexOf('.');
            const numZeros = 6;
            const numDigitsInFront = numLen - numZeros;
            const wholeNumber = rounded.substring(0, numDigitsInFront);
            const decimal = rounded.substring(numDigitsInFront, numDigitsInFront + 2);
            const newRep = wholeNumber + "." + decimal + "M";
            return newRep;
        }

        if (num >= 100000) {
            const numLen = rounded.indexOf('.');
            const numZeros = 3;
            const numDigitsInFront = numLen - numZeros;
            const wholeNumber = rounded.substring(0, numDigitsInFront);
            const decimal = rounded.substring(numDigitsInFront, numDigitsInFront + 2);
            const newRep = wholeNumber + "." + decimal + "K";
            return newRep;
        }

        return (Math.round(num * 100) / 100).toFixed(2);
    }

    function getAIStampsAfterSixMonths() {
        const sixMonthsLater = new Date();
        sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);

        var aiStampsAfter6Months = aiStamps.filter(data => (dateComparison(new Date(data.recommended_date), sixMonthsLater) > -1));
        return aiStampsAfter6Months;
    }

    function fetchNewHDDTTL(deselectedStamps: IHLCStamp[]) {
        computeNewHDDTTL(region, environment, hddDemandData, deselectedStamps).then(
            response => {
                console.log("base ttl hdd", response);
                setOldHddDemandData(response);
                computeAIHDDTTL(region, environment, hlcStamps, getAIStampsAfterSixMonths(), response).then(
                    response => {
                        console.log("new ttl hdd", response);
                        setNewHddDemandData(response);
                        setIsLoadingHDD(false);
                    },
                    ex => {
                        setNewHddDemandData([]);
                        setIsLoadingHDD(false);
                    }
                )
            },
            ex => {
                setOldHddDemandData([]);
                setNewHddDemandData([]);
                setIsLoadingHDD(false);
            }
        )
    }

    function fetchNewCPUTTL(deselectedStamps: IHLCStamp[]) {
        computeNewCPUTTL(region, environment, cpuDemandData, deselectedStamps).then(
            response => {
                console.log("base ttl cpu", response);
                setOldCpuDemandData(response);
                computeAICPUTTL(region, environment, hlcStamps, getAIStampsAfterSixMonths(), response).then(
                    response => {
                        console.log("new ttl cpu", response);
                        setNewCpuDemandData(response);
                        setIsLoadingCPU(false);
                    },
                    ex => {
                        setNewCpuDemandData([]);
                        setIsLoadingCPU(false);
                    }
                )
            },
            ex => {
                setOldCpuDemandData([]);
                setNewCpuDemandData([]);
                setIsLoadingCPU(false);
            }
        )
    }

    function fetchNewIOPSTTL(deselectedStamps: IHLCStamp[]) {
        computeNewIOPSTTL(region, environment, iopsDemandData, deselectedStamps).then(
            response => {
                console.log("base ttl iops", response);
                setOldIopsDemandData(response);
                computeAIIOPSTTL(region, environment, hlcStamps, getAIStampsAfterSixMonths(), response).then(
                    response => {
                        console.log("new ttl iops", response);
                        setNewIopsDemandData(response);
                        setIsLoadingIOPS(false);
                    },
                    ex => {
                        setNewIopsDemandData([]);
                        setIsLoadingIOPS(false);
                    }
                )
            },
            ex => {
                setOldIopsDemandData([]);
                setNewIopsDemandData([]);
                setIsLoadingIOPS(false);
            }
        )
    }

    function fetchNewSSDTTL(deselectedStamps: IHLCStamp[]) {
        computeNewSSDTTL(region, environment, ssdDemandData, deselectedStamps).then(
            response => {
                console.log("base ttl ssd", response);
                setOldSsdDemandData(response);
                computeAISSDTTL(region, environment, hlcStamps, getAIStampsAfterSixMonths(), response).then(
                    response => {
                        console.log("new ttl ssd", response);
                        setNewSsdDemandData(response);
                        setIsLoadingSSD(false);
                    },
                    ex => {
                        setNewSsdDemandData([]);
                        setIsLoadingSSD(false);
                    }
                )
            },
            ex => {
                setOldSsdDemandData([]);
                setNewSsdDemandData([]);
                setIsLoadingSSD(false);
            }
        )
    }

    function dateComparison(date1: Date, date2: Date) {
        if (date1 == date2) {
            return 0;
        } else if (date1 < date2) {
            return -1;
        }
        return 1;
    }

    function isNotDecomStamps(stamp: IHLCStamp) {
        var stampPlanIntent: string = stamp.planIntentName!;
        if (stampPlanIntent == "DecommPair") {
            return false;
        }

        return true;
    }

    function getDeselectedStamps() {
        const sixMonthsLater = new Date();
        sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);

        var stampsAfter6Months = hlcStamps.filter(data => (dateComparison(new Date(data.goliveETA), sixMonthsLater) > -1));
        var noDecomStamps = stampsAfter6Months.filter(data => isNotDecomStamps(data));

        return noDecomStamps;
    }

    async function fetchNewTTL() {
        //console.log(deselectedStamps);
        try {
            setIsLoadingCPU(true);
            setIsLoadingIOPS(true);
            setIsLoadingHDD(true);
            setIsLoadingSSD(true);

            var deselectedStamps: IHLCStamp[] = getDeselectedStamps();
            console.log("Deselected stamps: ", deselectedStamps);

            await Promise.all([fetchNewCPUTTL(deselectedStamps), fetchNewIOPSTTL(deselectedStamps), fetchNewHDDTTL(deselectedStamps), fetchNewSSDTTL(deselectedStamps)]);
        } catch (err) {
            console.log('Error occured when fetching new results for TTL metrics');
        }
    }

    function genDateHeaders() {
        let today = new Date();
        let todayMonth = today.getMonth() + 1;
        let todayYear = today.getFullYear();
        let headers = [];
        let numDates = Math.min(cpuDemandData.length, iopsDemandData.length, hddDemandData.length, ssdDemandData.length, endRange);
        for (let i = 0; i < numDates; i++) {
            let newMonth = (todayMonth + i) % 12;
            if (newMonth == 0) {
                newMonth = 12;
            }
            if (newMonth == 1) {
                todayYear += 1;
            }
            let newDateHeader = monthNames[newMonth] + " " + todayYear;
            headers.push(newDateHeader);
        }
        return headers;
    }

    function genNumGoLiveStampsSummaryRow() {
        let today = new Date();
        let todayMonth = today.getMonth() + 1;
        let todayYear = today.getFullYear();
        let numDates = Math.min(cpuDemandData.length, iopsDemandData.length, hddDemandData.length, ssdDemandData.length);
        let numGoLiveStampSummaryRow = [];

        for (let i = 0; i < numDates; i++) {
            numGoLiveStampSummaryRow.push("");
        }

        var stampMonthCount: Dictionary<number> = {}; 

        aiStamps.forEach(aiStamp => {
            var goLiveETA: Date = aiStamp.recommended_date;
            var dateString = goLiveETA.toString().split("T")[0];
            var dateComponents = dateString.split("-");
            var dateYear = +dateComponents[0];
            var dateMonth = +dateComponents[1];

            var yearDiff = dateYear - todayYear;
            var monthDiff = dateMonth - todayMonth;

            var index = (yearDiff * 12) + monthDiff;
            if (!(index in stampMonthCount)){
                stampMonthCount[index] = 0;
            }

            stampMonthCount[index] += 1;
        });

        for (let key in stampMonthCount) {
            let value = stampMonthCount[key];
            numGoLiveStampSummaryRow[+key] = +value + " stamp(s)"
        }

        return numGoLiveStampSummaryRow;
    }

    function genDemandRow(metricData: IDemandData[]) {
        const demand: string[] = [];
        metricData.forEach(data => { demand.push(roundTwoDecimals(data.demand)) });
        return demand;
    }

    function genCapacityRow(metricData: IDemandData[]) {
        const capRow: string[] = [];
        metricData.forEach(data => { capRow.push(roundTwoDecimals(data.supply)) });
        return capRow;
    }

    function genTTLRow(metricData: IDemandData[]) {
        const ttlRow: string[] = [];
        metricData.forEach(data => { ttlRow.push(roundTwoDecimals(data.ttl)) });
        return ttlRow;
    }

    function genDemandDelta(metricData: IDemandData[]) {
        const demandDiff: number[] = [0];
        for (var i = 1; i < metricData.length; i++) {
            var prevDemand = metricData[i - 1].demand;
            var currDemand = metricData[i].demand;

            var diff = currDemand - prevDemand;
            demandDiff.push(diff);
        }
        const demandDiffCol: string[] = [];
        demandDiff.forEach(data => { demandDiffCol.push("+" + roundTwoDecimals(data)) });
        demandDiffCol[0] = "";
        return demandDiffCol;
    }

    function genDemandDeltaPercent(metricData: IDemandData[]) {
        const demandDiff: number[] = [0];
        for (var i = 1; i < metricData.length; i++) {
            var prevDemand = metricData[i - 1].demand;
            var currDemand = metricData[i].demand;

            var diff = currDemand - prevDemand;
            var percent = (diff / prevDemand) * 100;
            demandDiff.push(percent);
        }

        const demandDiffCol: string[] = [];
        demandDiff.forEach(data => { demandDiffCol.push("+" + roundTwoDecimals(data) + "%") });
        demandDiffCol[0] = "";
        return demandDiffCol;
    }

    function genSupplyDelta(oldMetricData: IDemandData[], newMetricData: IDemandData[]) {
        const supplyDiff: number[] = [];
        const minLength = Math.min(oldMetricData.length, newMetricData.length);
        for (var i = 0; i < minLength; i++) {
            var oldSupply = oldMetricData[i].supply;
            var newSupply = newMetricData[i].supply;

            var diff = newSupply - oldSupply;
            supplyDiff.push(diff);
        }

        const supplyDiffCol: string[] = [];
        supplyDiff.forEach(data => { supplyDiffCol.push("+" + roundTwoDecimals(data)) });

        return supplyDiffCol;
    }

    function genSupplyDeltaPercent(oldMetricData: IDemandData[], newMetricData: IDemandData[]) {
        const supplyDiffPercent: number[] = [];
        const minLength = Math.min(oldMetricData.length, newMetricData.length);
        for (var i = 0; i < minLength; i++) {
            var oldSupply = oldMetricData[i].supply;
            var newSupply = newMetricData[i].supply;

            var diff = newSupply - oldSupply;
            var percent = (diff / oldSupply) * 100;
            supplyDiffPercent.push(percent);
        }
        const supplyDiffPercentCol: string[] = [];
        supplyDiffPercent.forEach(data => { supplyDiffPercentCol.push("+" + roundTwoDecimals(data) + "%") });

        return supplyDiffPercentCol;
    }

    function genTableData() {
        var items: ITTLResultsData[] = [];
        const dateHeaders: string[] = genDateHeaders();
        const numGoLiveStampSummaryRow: string[] = genNumGoLiveStampsSummaryRow();
        const demandCPURow: string[] = genDemandRow(cpuDemandData);
        const demandIOPSRow: string[] = genDemandRow(iopsDemandData);
        const demandHDDRow: string[] = genDemandRow(hddDemandData);
        const demandSSDRow: string[] = genDemandRow(ssdDemandData);
        const demandCPUDeltaRow: string[] = genDemandDelta(cpuDemandData);
        const demandIOPSDeltaRow: string[] = genDemandDelta(iopsDemandData);
        const demandHDDDeltaRow: string[] = genDemandDelta(hddDemandData);
        const demandSSDDeltaRow: string[] = genDemandDelta(ssdDemandData);
        const demandCPUDeltaPercentRow: string[] = genDemandDeltaPercent(cpuDemandData);
        const demandIOPSDeltaPercentRow: string[] = genDemandDeltaPercent(iopsDemandData);
        const demandHDDDeltaPercentRow: string[] = genDemandDeltaPercent(hddDemandData);
        const demandSSDDeltaPercentRow: string[] = genDemandDeltaPercent(ssdDemandData);
        const capBeforeCPURow: string[] = genCapacityRow(oldCpuDemandData);
        const capBeforeIOPSRow: string[] = genCapacityRow(oldIopsDemandData);
        const capBeforeHDDRow: string[] = genCapacityRow(oldHddDemandData);
        const capBeforeSSDRow: string[] = genCapacityRow(oldSsdDemandData);
        const capAfterCPURow: string[] = genCapacityRow(newCpuDemandData);
        const capAfterIOPSRow: string[] = genCapacityRow(newIopsDemandData);
        const capAfterHDDRow: string[] = genCapacityRow(newHddDemandData);
        const capAfterSSDRow: string[] = genCapacityRow(newSsdDemandData);
        const capCurrentCPURow: string[] = genCapacityRow(cpuDemandData);
        const capCurrentIOPSRow: string[] = genCapacityRow(iopsDemandData);
        const capCurrentHDDRow: string[] = genCapacityRow(hddDemandData);
        const capCurrentSSDRow: string[] = genCapacityRow(ssdDemandData);
        const ttlBeforeCPURow: string[] = genTTLRow(oldCpuDemandData);
        const ttlBeforeIOPSRow: string[] = genTTLRow(oldIopsDemandData);
        const ttlBeforeHDDRow: string[] = genTTLRow(oldHddDemandData);
        const ttlBeforeSSDRow: string[] = genTTLRow(oldSsdDemandData);
        const ttlAfterCPURow: string[] = genTTLRow(newCpuDemandData);
        const ttlAfterIOPSRow: string[] = genTTLRow(newIopsDemandData);
        const ttlAfterHDDRow: string[] = genTTLRow(newHddDemandData);
        const ttlAfterSSDRow: string[] = genTTLRow(newSsdDemandData);
        const ttlCurrentCPURow: string[] = genTTLRow(cpuDemandData);
        const ttlCurrentIOPSRow: string[] = genTTLRow(iopsDemandData);
        const ttlCurrentHDDRow: string[] = genTTLRow(hddDemandData);
        const ttlCurrentSSDRow: string[] = genTTLRow(ssdDemandData);
        const capCPUDeltaRow: string[] = genSupplyDelta(oldCpuDemandData, newCpuDemandData);
        const capIOPSDeltaRow: string[] = genSupplyDelta(oldIopsDemandData, newIopsDemandData);
        const capHDDDeltaRow: string[] = genSupplyDelta(oldHddDemandData, newHddDemandData);
        const capSSDDeltaRow: string[] = genSupplyDelta(oldSsdDemandData, newSsdDemandData);
        const capCPUDeltaPercentRow: string[] = genSupplyDeltaPercent(oldCpuDemandData, newCpuDemandData);
        const capIOPSDeltaPercentRow: string[] = genSupplyDeltaPercent(oldIopsDemandData, newIopsDemandData);
        const capHDDDeltaPercentRow: string[] = genSupplyDeltaPercent(oldHddDemandData, newHddDemandData);
        const capSSDDeltaPercentRow: string[] = genSupplyDeltaPercent(oldSsdDemandData, newSsdDemandData);

        const minEndRange = Math.min(dateHeaders.length, endRange);
        for (var i = startRange; i < minEndRange; i++) {
            const dateVal = dateHeaders[i];

            var numGoLiveStampSummaryVal = "";
            if (i < numGoLiveStampSummaryRow.length) {
                numGoLiveStampSummaryVal = numGoLiveStampSummaryRow[i];
            }

            var cpuDemandVal = "";
            if (i < demandCPURow.length) {
                cpuDemandVal = demandCPURow[i];
            }

            var iopsDemandVal = "";
            if (i < demandIOPSRow.length) {
                iopsDemandVal = demandIOPSRow[i];
            }

            var hddDemandVal = "";
            if (i < demandHDDRow.length) {
                hddDemandVal = demandHDDRow[i];
            }

            var ssdDemandVal = "";
            if (i < demandSSDRow.length) {
                ssdDemandVal = demandSSDRow[i];
            }

            var cpuDemandDeltaVal = "";
            if (i < demandCPUDeltaRow.length) {
                cpuDemandDeltaVal = demandCPUDeltaRow[i];
            }

            var iopsDemandDeltaVal = "";
            if (i < demandIOPSDeltaRow.length) {
                iopsDemandDeltaVal = demandIOPSDeltaRow[i];
            }

            var hddDemandDeltaVal = "";
            if (i < demandHDDDeltaRow.length) {
                hddDemandDeltaVal = demandHDDDeltaRow[i];
            }

            var ssdDemandDeltaVal = "";
            if (i < demandSSDDeltaRow.length) {
                ssdDemandDeltaVal = demandSSDDeltaRow[i];
            }

            var cpuDemandDeltaPercentVal = "";
            if (i < demandCPUDeltaPercentRow.length) {
                cpuDemandDeltaPercentVal = demandCPUDeltaPercentRow[i];
            }

            var iopsDemandDeltaPercentVal = "";
            if (i < demandIOPSDeltaPercentRow.length) {
                iopsDemandDeltaPercentVal = demandIOPSDeltaPercentRow[i];
            }

            var hddDemandDeltaPercentVal = "";
            if (i < demandHDDDeltaPercentRow.length) {
                hddDemandDeltaPercentVal = demandHDDDeltaPercentRow[i];
            }

            var ssdDemandDeltaPercentVal = "";
            if (i < demandSSDDeltaPercentRow.length) {
                ssdDemandDeltaPercentVal = demandSSDDeltaPercentRow[i];
            }

            var cpuCapBeforeVal = "";
            if (i < capBeforeCPURow.length) {
                cpuCapBeforeVal = capBeforeCPURow[i];
            }

            var iopsCapBeforeVal = "";
            if (i < capBeforeIOPSRow.length) {
                iopsCapBeforeVal = capBeforeIOPSRow[i];
            }

            var hddCapBeforeVal = "";
            if (i < capBeforeHDDRow.length) {
                hddCapBeforeVal = capBeforeHDDRow[i];
            }

            var ssdCapBeforeVal = "";
            if (i < capBeforeSSDRow.length) {
                ssdCapBeforeVal = capBeforeSSDRow[i];
            }

            var cpuCapAfterVal = "";
            if (i < capAfterCPURow.length) {
                cpuCapAfterVal = capAfterCPURow[i];
            }

            var iopsCapAfterVal = "";
            if (i < capAfterIOPSRow.length) {
                iopsCapAfterVal = capAfterIOPSRow[i];
            }

            var hddCapAfterVal = "";
            if (i < capAfterHDDRow.length) {
                hddCapAfterVal = capAfterHDDRow[i];
            }

            var ssdCapAfterVal = "";
            if (i < capAfterSSDRow.length) {
                ssdCapAfterVal = capAfterSSDRow[i];
            }

            var cpuTTLBeforeVal = "";
            if (i < ttlBeforeCPURow.length && ttlBeforeCPURow[i] !== '0.00') {
                cpuTTLBeforeVal = ttlBeforeCPURow[i];
            }

            var iopsTTLBeforeVal = "";
            if (i < ttlBeforeIOPSRow.length && ttlBeforeIOPSRow[i] !== '0.00') {
                iopsTTLBeforeVal = ttlBeforeIOPSRow[i];
            }

            var hddTTLBeforeVal = "";
            if (i < ttlBeforeHDDRow.length && ttlBeforeHDDRow[i] !== '0.00') {
                hddTTLBeforeVal = ttlBeforeHDDRow[i];
            }

            var ssdTTLBeforeVal = "";
            if (i < ttlBeforeSSDRow.length && ttlBeforeSSDRow[i] !== '0.00') {
                ssdTTLBeforeVal = ttlBeforeSSDRow[i];
            }

            var cpuTTLAfterVal = "";
            if (i < ttlAfterCPURow.length && ttlAfterCPURow[i] !== '0.00') {
                cpuTTLAfterVal = ttlAfterCPURow[i];
            }

            var iopsTTLAfterVal = "";
            if (i < ttlAfterIOPSRow.length && ttlAfterIOPSRow[i] !== '0.00') {
                iopsTTLAfterVal = ttlAfterIOPSRow[i];
            }

            var hddTTLAfterVal = "";
            if (i < ttlAfterHDDRow.length && ttlAfterHDDRow[i] !== '0.00') {
                hddTTLAfterVal = ttlAfterHDDRow[i];
            }

            var ssdTTLAfterVal = "";
            if (i < ttlAfterSSDRow.length && ttlAfterSSDRow[i] !== '0.00') {
                ssdTTLAfterVal = ttlAfterSSDRow[i];
            }

            var cpuCapDeltaVal = "";
            if (i < capCPUDeltaRow.length && capCPUDeltaRow[i] !== '+0.00') {
                cpuCapDeltaVal = capCPUDeltaRow[i];
            }

            var iopsCapDeltaVal = "";
            if (i < capIOPSDeltaRow.length && capIOPSDeltaRow[i] !== '+0.00') {
                iopsCapDeltaVal = capIOPSDeltaRow[i];
            }

            var hddCapDeltaVal = "";
            if (i < capHDDDeltaRow.length && capHDDDeltaRow[i] !== '+0.00') {
                hddCapDeltaVal = capHDDDeltaRow[i];
            }

            var ssdCapDeltaVal = "";
            if (i < capSSDDeltaRow.length && capSSDDeltaRow[i] !== '+0.00') {
                ssdCapDeltaVal = capSSDDeltaRow[i];
            }

            var cpuCapDeltaPercentVal = "";
            if (i < capCPUDeltaPercentRow.length && capCPUDeltaPercentRow[i] !== '+0.00%') {
                cpuCapDeltaPercentVal = capCPUDeltaPercentRow[i];
            }

            var iopsCapDeltaPercentVal = "";
            if (i < capIOPSDeltaPercentRow.length && capSSDDeltaPercentRow[i] !== '+0.00%') {
                iopsCapDeltaPercentVal = capIOPSDeltaPercentRow[i];
            }

            var hddCapDeltaPercentVal = "";
            if (i < capHDDDeltaPercentRow.length && capSSDDeltaPercentRow[i] !== '+0.00%') {
                hddCapDeltaPercentVal = capHDDDeltaPercentRow[i];
            }

            var ssdCapDeltaPercentVal = "";
            if (i < capSSDDeltaPercentRow.length && capSSDDeltaPercentRow[i] !== '+0.00%') {
                ssdCapDeltaPercentVal = capSSDDeltaPercentRow[i];
            }

            var cpuTTLCurrentVal = "";
            if (i < ttlCurrentCPURow.length) {
                cpuTTLCurrentVal = ttlCurrentCPURow[i];
            }

            var iopsTTLCurrentVal = "";
            if (i < ttlCurrentIOPSRow.length) {
                iopsTTLCurrentVal = ttlCurrentIOPSRow[i];
            }

            var hddTTLCurrentVal = "";
            if (i < ttlCurrentHDDRow.length) {
                hddTTLCurrentVal = ttlCurrentHDDRow[i];
            }

            var ssdTTLCurrentVal = "";
            if (i < ttlCurrentSSDRow.length) {
                ssdTTLCurrentVal = ttlCurrentSSDRow[i];
            }

            var cpuCapCurrentVal = "";
            if (i < capCurrentCPURow.length) {
                cpuCapCurrentVal = capCurrentCPURow[i];
            }

            var iopsCapCurrentVal = "";
            if (i < capCurrentIOPSRow.length) {
                iopsCapCurrentVal = capCurrentIOPSRow[i];
            }

            var hddCapCurrentVal = "";
            if (i < capCurrentHDDRow.length) {
                hddCapCurrentVal = capCurrentHDDRow[i];
            }

            var ssdCapCurrentVal = "";
            if (i < capCurrentSSDRow.length) {
                ssdCapCurrentVal = capCurrentSSDRow[i];
            }

            var ttlItem: ITTLResultsData = {
                date: dateVal,
                numGoLiveStampSummary: numGoLiveStampSummaryVal,
                cpuDemand: cpuDemandVal,
                iopsDemand: iopsDemandVal,
                hddDemand: hddDemandVal,
                ssdDemand: ssdDemandVal,
                cpuCapBefore: cpuCapBeforeVal,
                iopsCapBefore: iopsCapBeforeVal,
                hddCapBefore: hddCapBeforeVal,
                ssdCapBefore: ssdCapBeforeVal,
                cpuCapAfter: cpuCapAfterVal,
                iopsCapAfter: iopsCapAfterVal,
                hddCapAfter: hddCapAfterVal,
                ssdCapAfter: ssdCapAfterVal,
                cpuTTLBefore: cpuTTLBeforeVal,
                iopsTTLBefore: iopsTTLBeforeVal,
                hddTTLBefore: hddTTLBeforeVal,
                ssdTTLBefore: ssdTTLBeforeVal,
                cpuTTLAfter: cpuTTLAfterVal,
                iopsTTLAfter: iopsTTLAfterVal,
                hddTTLAfter: hddTTLAfterVal,
                ssdTTLAfter: ssdTTLAfterVal,
                cpuDemandDelta: cpuDemandDeltaVal,
                iopsDemandDelta: iopsDemandDeltaVal,
                hddDemandDelta: hddDemandDeltaVal,
                ssdDemandDelta: ssdDemandDeltaVal,
                cpuDemandDeltaPercent: cpuDemandDeltaPercentVal,
                iopsDemandDeltaPercent: iopsDemandDeltaPercentVal,
                hddDemandDeltaPercent: hddDemandDeltaPercentVal,
                ssdDemandDeltaPercent: ssdDemandDeltaPercentVal,
                cpuCapDelta: cpuCapDeltaVal,
                iopsCapDelta: iopsCapDeltaVal,
                hddCapDelta: hddCapDeltaVal,
                ssdCapDelta: ssdCapDeltaVal,
                cpuCapDeltaPercent: cpuCapDeltaPercentVal,
                iopsCapDeltaPercent: iopsCapDeltaPercentVal,
                hddCapDeltaPercent: hddCapDeltaPercentVal,
                ssdCapDeltaPercent: ssdCapDeltaPercentVal,
                cpuTTLCurrent: cpuTTLCurrentVal,
                iopsTTLCurrent: iopsTTLCurrentVal,
                hddTTLCurrent: hddTTLCurrentVal,
                ssdTTLCurrent: ssdTTLCurrentVal,
                cpuCapCurrent: cpuCapCurrentVal,
                iopsCapCurrent: iopsCapCurrentVal,
                hddCapCurrent: hddCapCurrentVal,
                ssdCapCurrent: ssdCapCurrentVal
            };

            items.push(ttlItem);
        }

        return items;
    }

    const columns: IColumn[] = [
        {
            styles: headerStyle,
            key: 'column0',
            name: 'Date',
            fieldName: 'date',
            minWidth: 60,
            maxWidth: 70,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.date}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column1',
            name: '# Stamps that Go Live',
            fieldName: 'numStampsGoLive',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.numGoLiveStampSummary}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column2',
            name: 'CPU TTL Current',
            fieldName: 'cpuTTLCurrent',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.cpuTTLCurrent! < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.cpuTTLCurrent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column3',
            name: 'CPU TTL No Future Supplies',   
            fieldName: 'cpuTTLBefore',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.cpuTTLBefore < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.cpuTTLBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column4',
            name: 'CPU TTL With Recomendation',
            fieldName: 'cpuTTLAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.cpuTTLAfter < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.cpuTTLAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column5',
            name: 'IOPS TTL Current',
            fieldName: 'iopsTTLCurrent',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.iopsTTLCurrent! < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.iopsTTLCurrent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column6',
            name: 'IOPS TTL No Future Supplies',
            fieldName: 'iopsTTLBefore',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.iopsTTLBefore < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.iopsTTLBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column7',
            name: 'IOPS TTL With Recomendation',
            fieldName: 'iopsTTLAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.iopsTTLAfter < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.iopsTTLAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column8',
            name: 'HDD TTL Current',
            fieldName: 'hddTTLCurrent',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.hddTTLCurrent! < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.hddTTLCurrent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column9',
            name: 'HDD TTL No Future Supplies',
            fieldName: 'hhdTTLBefore',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.hddTTLBefore < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.hddTTLBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column10',
            name: 'HDD TTL With Recomendation',
            fieldName: 'hhdTTLAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.hddTTLAfter < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.hddTTLAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column11',
            name: 'SSD TTL Current',
            fieldName: 'ssdTTLCurrent',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.ssdTTLCurrent! < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.ssdTTLCurrent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column12',
            name: 'SSD TTL No Future Supplies',
            fieldName: 'ssdTTLBefore',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.ssdTTLBefore < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.ssdTTLBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column13',
            name: 'SSD TTL With Recomendation',
            fieldName: 'ssdTTLAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                var spanStyle = +item.ssdTTLAfter < threshold ? { color: "red" } : {};
                return <span style={spanStyle}>{item.ssdTTLAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column14',
            name: 'CPU Capacity Current',
            fieldName: 'cpuCapCurrent',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.cpuCapCurrent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column15',
            name: 'CPU Capacity No Future Supplies',
            fieldName: 'cpuCapBefore',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.cpuCapBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column16',
            name: 'CPU Capacity With Recomendation',
            fieldName: 'cpuCapAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.cpuCapAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column17',
            name: 'CPU Capacity Delta (NoSupply-Recommendation)',
            fieldName: 'cpuCapDelta',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'green' }}>{item.cpuCapDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column18',
            name: 'CPU Capacity Delta % (NoSupply-Recommendation)',
            fieldName: 'cpuCapDeltaPercent',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'green' }}>{item.cpuCapDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column19',
            name: 'IOPS Capacity Current',
            fieldName: 'iopsCapCurrent',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.iopsCapCurrent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column20',
            name: 'IOPS Capacity No Future Supplies',
            fieldName: 'iopsCapBefore',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.iopsCapBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column21',
            name: 'IOPS Capacity With Recomendation',
            fieldName: 'iopsCapAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.iopsCapAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column22',
            name: 'IOPS Capacity Delta (NoSupply-Recommendation)',
            fieldName: 'iopsCapDelta',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'green' }}>{item.iopsCapDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column23',
            name: 'IOPS Capacity Delta % (NoSupply-Recommendation)',
            fieldName: 'iopsCapDeltaPercent',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'green' }}>{item.iopsCapDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column24',
            name: 'HDD Capacity Current',
            fieldName: 'hddCapCurrent',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.hddCapCurrent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column25',
            name: 'HDD Capacity No Future Supplies',
            fieldName: 'hhdCapBefore',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.hddCapBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column26',
            name: 'HDD Capacity With Recomendation',
            fieldName: 'hhdCapAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.hddCapAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column27',
            name: 'HDD Capacity Delta (NoSupply-Recommendation)',
            fieldName: 'hddCapDelta',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'green' }}>{item.hddCapDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column28',
            name: 'HDD Capacity Delta % (NoSupply-Recommendation)',
            fieldName: 'hddCapDeltaPercent',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'green' }}>{item.hddCapDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column29',
            name: 'SSD Capacity Current',
            fieldName: 'ssdCapCurrent',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.ssdCapCurrent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column30',
            name: 'SSD Capacity No Future Supplies',
            fieldName: 'ssdCapBefore',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.ssdCapBefore}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column31',
            name: 'SSD Capacity With Recomendation',
            fieldName: 'ssdCapAfter',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.ssdCapAfter}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column32',
            name: 'SSD Capacity Delta (NoSupply-Recommendation)',
            fieldName: 'ssdCapDelta',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'green' }}>{item.ssdCapDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column33',
            name: 'SSD Capacity Delta % (NoSupply-Recommendation)',
            fieldName: 'ssdCapDeltaPercent',
            minWidth: 175,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: 'green' }}>{item.ssdCapDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column34',
            name: 'CPU Demand',
            fieldName: 'cpuDemand',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.cpuDemand}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column35',
            name: 'CPU Demand Delta',
            fieldName: 'cpuDemandDelta',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.cpuDemandDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column36',
            name: 'CPU Demand Delta %',
            fieldName: 'cpuDemandDeltaPercent',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.cpuDemandDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column37',
            name: 'IOPS Demand',
            fieldName: 'iopsDemand',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.iopsDemand}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column38',
            name: 'IOPS Demand Delta',
            fieldName: 'iopsDemandDelta',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.iopsDemandDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column39',
            name: 'IOPS Demand Delta %',
            fieldName: 'iopsDemandDeltaPercent',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.iopsDemandDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column40',
            name: 'HDD Demand',
            fieldName: 'hddDemand',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.hddDemand}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column41',
            name: 'HDD Demand Delta',
            fieldName: 'hddDemandDelta',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.hddDemandDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column42',
            name: 'HDD Demand Delta %',
            fieldName: 'hddDemandDeltaPercent',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.hddDemandDeltaPercent}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column43',
            name: 'SSD Demand',
            fieldName: 'ssdDemand',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span>{item.ssdDemand}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column44',
            name: 'SSD Demand Delta',
            fieldName: 'ssdDemandDelta',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.ssdDemandDelta}</span>;
            },
            isPadded: true,
        },
        {
            styles: headerStyle,
            key: 'column45',
            name: 'SSD Demand Delta %',
            fieldName: 'ssdDemandDeltaPercent',
            minWidth: 125,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ITTLResultsData) => {
                return <span style={{ color: "green" }}>{item.ssdDemandDeltaPercent}</span>;
            },
            isPadded: true,
        },
    ];

    const options: IDropdownOption[] = [
        { key: 'column0', text: 'Date' },
        { key: 'column1', text: '# Stamps that Go Live' },
        { key: 'column2', text: 'CPU TTL Current' },
        { key: 'column3', text: 'CPU TTL No Future Supplies' },
        { key: 'column4', text: 'CPU TTL With Recomendation' },
        { key: 'column5', text: 'IOPS TTL Current' },
        { key: 'column6', text: 'IOPS TTL No Future Supplies' },
        { key: 'column7', text: 'IOPS TTL With Recomendation' },
        { key: 'column8', text: 'HDD TTL Current' },
        { key: 'column9', text: 'HDD TTL No Future Supplies' },
        { key: 'column10', text: 'HDD TTL With Recomendation' },
        { key: 'column11', text: 'SSD TTL Current' },
        { key: 'column12', text: 'SSD TTL No Future Supplies' },
        { key: 'column13', text: 'SSD TTL With Recomendation' },
        { key: 'column14', text: 'CPU Capacity Current' },
        { key: 'column15', text: 'CPU Capacity No Future Supplies' },
        { key: 'column16', text: 'CPU Capacity With Recomendation' },
        { key: 'column17', text: 'CPU Capacity Delta (NoSupply-Recommendation)' },
        { key: 'column18', text: 'CPU Capacity Delta % (NoSupply-Recommendation)' },
        { key: 'column19', text: 'IOPS Capacity Current' },
        { key: 'column20', text: 'IOPS Capacity No Future Supplies' },
        { key: 'column21', text: 'IOPS Capacity With Recomendation' },
        { key: 'column22', text: 'IOPS Capacity Delta (NoSupply-Recommendation)' },
        { key: 'column23', text: 'IOPS Capacity Delta % (NoSupply-Recommendation)' },
        { key: 'column24', text: 'HDD Capacity Current' },
        { key: 'column25', text: 'HDD Capacity No Future Supplies' },
        { key: 'column26', text: 'HDD Capacity With Recomendation' },
        { key: 'column27', text: 'HDD Capacity Delta (NoSupply-Recommendation)' },
        { key: 'column28', text: 'HDD Capacity Delta % (NoSupply-Recommendation)' },
        { key: 'column29', text: 'SSD Capacity Current' },
        { key: 'column30', text: 'SSD Capacity No Future Supplies' },
        { key: 'column31', text: 'SSD Capacity With Recomendation' },
        { key: 'column32', text: 'SSD Capacity Delta (NoSupply-Recommendation)' },
        { key: 'column33', text: 'SSD Capacity Delta % (NoSupply-Recommendation)' },
        { key: 'column34', text: 'CPU Demand' },
        { key: 'column35', text: 'CPU Demand Delta' },
        { key: 'column36', text: 'CPU Demand Delta %' },
        { key: 'column37', text: 'IOPS Demand' },
        { key: 'column38', text: 'IOPS Demand Delta' },
        { key: 'column39', text: 'IOPS Demand Delta %' },
        { key: 'column40', text: 'HDD Demand' },
        { key: 'column41', text: 'HDD Demand Delta' },
        { key: 'column42', text: 'HDD Demand Delta %' },
        { key: 'column43', text: 'SSD Demand' },
        { key: 'column44', text: 'SSD Demand Delta' },
        { key: 'column45', text: 'SSD Demand Delta %' },
        
    ];

    const _onRenderRow = (props: IDetailsRowProps, index: number) => {
        const rowStyles: Partial<IDetailsRowStyles> = {
            root: {
                background: props.itemIndex % 2 == 0 ? '#e3e3e3' : '#cacaca',
                color: 'black',
                marginBottom: '1px'
            },
        }
        return <DetailsRow {...props} styles={rowStyles} />;
    }
    function _getKey(item: any, index ?: number): string {
        return item.key;
    }

    const [detailColumns, setDetailColumns] = React.useState<IColumn[]>(columns.filter(col => defaultColumnKeys.includes(col.key)));

    const _onColumnFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                columnKeys.push(option.key.toString());
                setColumnKeys(columnKeys);
                setDetailColumns(columns.filter(col => columnKeys.includes(col.key)))
            }
            if (option.selected == false) {
                const arr_index = columnKeys.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = columnKeys.filter(key => key != option.key.toString());
                    setColumnKeys(new_arr);
                    setDetailColumns(columns.filter(col => new_arr.includes(col.key)));
                }
            }
        }
    }

    return (
        <div>
            {
                (isLoadingCPU || isLoadingIOPS || isLoadingHDD || isLoadingSSD) ?
                    <div style={{ margin: "2rem" }}>
                        <Spinner label="Fetching TTL metric information. This might take a while..." size={SpinnerSize.large} />
                    </div>
                    :
                    <div style={{ textAlign: "center" }}>
                        <PrimaryButton onClick={fetchNewTTL}>Compute AI Recommended TTL</PrimaryButton>
                        <br />
                        <br />
                        <br />
                        <div style={{ width: '20%', marginLeft: '40%' }}>
                            <ColumnSelection options={options} defaultOptions={columnKeys} onColFilter={_onColumnFilter} />
                        </div>
                        <br />
                        <DetailsList
                            items={genTableData()}
                            compact={true}
                            columns={detailColumns}
                            selectionMode={SelectionMode.none}
                            getKey={_getKey}
                            setKey="none"
                            onRenderRow={_onRenderRow}
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                        />
                    </div>
            }
        </div>
    )
}
