import * as React from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

interface IRegionFilterProp {
    onRegionFilter: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void,
    defaultOptions?: string[]
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
};

const options: IDropdownOption[] = [
    { key: 'Asia Pacific', text: 'Asia Pacific' },
    { key: 'Europe', text: 'Europe' },
    { key: 'Latam', text: 'Latam' },
    { key: 'United States', text: 'United States'},
    { key: 'Australia', text: 'Australia' },
    { key: 'Brazil', text: 'Brazil' },
    { key: 'Canada', text: 'Canada' },
    { key: 'China', text: 'China' },
    { key: 'France', text: 'France' },
    { key: 'Germany', text: 'Germany' },
    { key: 'India', text: 'India' },
    { key: 'Japan', text: 'Japan' },
    { key: 'Korea', text: 'Korea' },
    { key: 'Norway', text: 'Norway' },
    { key: 'Qatar', text: 'Qatar' },
    { key: 'Singapore', text: 'Singapore' },
    { key: 'South Africa', text: 'South Africa' },
    { key: 'Sweden', text: 'Sweden' },
    { key: 'Switzerland', text: 'Switzerland' },
    { key: 'UAE', text: 'UAE' },
    { key: 'United Kingdom', text: 'United Kingdom' },
];

const stackTokens: IStackTokens = { childrenGap: 20 };

export const RegionFilter: React.FunctionComponent<IRegionFilterProp> = (props) => {

    return (
        <Stack tokens={stackTokens}>
            <Dropdown
                placeholder="Select options"
                label="Select Region"
                defaultSelectedKeys={props.defaultOptions == undefined ? [] : props.defaultOptions}
                multiSelect
                options={options}
                styles={dropdownStyles}
                onChange={props.onRegionFilter}
            />
        </Stack>
    );
};
