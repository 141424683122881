export enum ActionTypes {
    BuyCakeActionExample,
    GetSideBarAction,
    GetAccessTokenAction,
    GetUserPermission
}

export enum AllocationActionTypes {
    GetAllocationOverrides,
}

export enum RequestStatus {
    NotStarted,
    Requested,
    Succeed,
    Failed
}