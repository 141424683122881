import { DefaultButton, IStackTokens, PrimaryButton, Stack } from "@fluentui/react";
import * as React from "react";

interface ISelectionGroupProps {
    options: string[],
    onChange: (selectedOpions: string[]) => void
}

export const SelectionGroup: React.FC<ISelectionGroupProps> = ({options, onChange}) => {
    const stackTokens: IStackTokens = 
        {
            childrenGap: 40
        }
    
    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

    let selections = selectedOptions;
    const onOptionClicked = (option: string) => {
        return () => {
            if (selectedOptions.includes(option)) {
                selections = selections.filter(selection => selection != option);
                setSelectedOptions(selectedOptions.filter(o => o != option));
            } else {
                selections.push(option);
                setSelectedOptions(selectedOptions.concat([option]));
            }
            onChange(selections);
        }
    }

    const renderOption = (option: string, index: number) => {
        return (
            selectedOptions.includes(option) ? 
                <PrimaryButton key={index} onClick={onOptionClicked(option)}> {option} </PrimaryButton>
                : <DefaultButton key={index} onClick= {onOptionClicked(option)}> {option} </DefaultButton>
        )//
    }

    return (
        <div>
            <Stack horizontal tokens={stackTokens}>
                {options.map((option, index) => renderOption(option, index))}
            </Stack>
        </div>
    )
}