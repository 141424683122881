import React from "react";

import {
    Label,
    TextField,
    Spinner,
    SpinnerSize,
    Dropdown,
    MessageBar,
    MessageBarType,
    divProperties,
    IDropdownOption,
    DropdownMenuItemType,
    ITag
} from '@fluentui/react';
import { DefaultButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { fetchSimulationParams, fetchDCNames, fetchStampInfo } from '../../../../services/dcSelectionService';
import { useHistory, useLocation } from 'react-router-dom'
import PageHeader from '../../../common/pageheader/PageHeader';
import { ISelectionPlan, EmptyISelectionPlan, IStampInfo, EmptyStampInfo, IDCInformation } from "../../../../models/DCselectionPlan";
import { DCNameFilter } from './modules/DCNameFilter';
import { DCSelectionSimulationGroup } from "./DCSelectionSimulationGroup";

interface ISimulationParams {
    EnableNetworkCalCulation: boolean,
    StampName?: string,
    GfsName: string[],
    DCCostSnapshotDate: Date
}

var EmptyISimulationParams: ISimulationParams = {
    EnableNetworkCalCulation: true,
    StampName: "",
    GfsName: [],
    DCCostSnapshotDate: new Date()
};


export const DCSelectionSimulationPage: React.FC = () => {
    const location = useLocation()
    const history = useHistory()
    const { state } = location

    function getRegion() {
        if (state === undefined || state.plan === undefined) {
            return "";
        }
        return state.plan.region;
    }

    function getDagCnt() {
        if (state === undefined || state.plan === undefined) {
            return 0;
        }
        return state.plan.dagCnt;
    }

    function getSkuFamily() {
        if (state === undefined || state.plan === undefined) {
            return "";
        }
        return state.plan.sku;
    }

    function getStampName() {
        if (state === undefined || state.plan === undefined) {
            return "";
        }
        return state.plan.stampName;
    }

    function getCapacityGeoCode() {
        if (state === undefined || state.plan === undefined) {
            return "";
        }
        return state.plan.capacityGeoCode;
    }

    function getReservationStatus() {
        if (state === undefined || state.plan === undefined) {
            return "";
        }
        return state.plan.reservationStatus;
    }

    function getFiscalYear() {
        if (state === undefined || state.plan === undefined) {
            return 0;
        }
        return state.plan.fiscalYear;
    }

    function getPlanIntent() {
        if (state === undefined || state.plan === undefined) {
            return "";
        }
        return state.plan.planIntent;
    }

    function getDCCostDate() {
        if (state === undefined || state.plan === undefined) {
            return "";
        }
        return state.plan.dcCostSnapshotDate;
    }

    const [stampInfo, setStampInfo] = React.useState<IStampInfo>();
    const [stampName, setStampName] = React.useState<string>(getStampName());
    const [layout, setLayout] = React.useState<string>("");
    function getStampInfo() {
        fetchStampInfo(stampName).then(
            response => {
                console.log("stamp information", response);
                setStampInfo(response);
                setLayout(response.layout);
            },
            ex => {
                console.log("Error fetching stamp info");
                setStampInfo(EmptyStampInfo);
                setParamsError("Error retrieving stamp information");
            }
        )
    }

    function getStampDC(): string[] {
        if (state === undefined || state.plan === undefined) {
            return [];
        }
        var dcs: IDCInformation[] = state.plan.recommendation[0].dcs;
        var dcNames: string[] = [];
        dcs.forEach(dc => dcNames.push(dc.name));
        return dcNames;
    }

    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

    React.useEffect(() => {
        console.log("triggered reset");
        getStampInfo();
        setSelectedKeys(getStampDC());
    }, [])


    const [region, setRegion] = React.useState<string>(getRegion());
    const [numDags, setNumDags] = React.useState(getDagCnt());
    const [sku, setSku] = React.useState<string>(getSkuFamily());
    const [dcCostDate, setDCCostDate] = React.useState<Date>(getDCCostDate());
    
    const [simulationParams, setSimulationParams] = React.useState<ISimulationParams>(EmptyISimulationParams);
    const [simulationResults, setSimulationResults] = React.useState<ISelectionPlan>(EmptyISelectionPlan);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [paramsError, setParamsError] = React.useState<string>("");


    const _onEnvFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                if (!selectedKeys.includes(option.key.toString())) {
                    selectedKeys.push(option.key.toString());
                    setSelectedKeys(selectedKeys);
                }
                //setDetailsItem(data.filter(item => selectedKeys.includes(item.environment)))
            }
            if (option.selected == false) {
                const arr_index = selectedKeys.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = selectedKeys.filter(key => key != option.key.toString());
                    setSelectedKeys(new_arr);
                    //setDetailsItem(data.filter(item => new_arr.includes(item.environment)));
                }
            }
        }
    }

    /*const onRegionChange = (event: React.FormEvent, newValue?: string) => {
        setRegion(newValue === undefined ? "" : newValue);
        simulationParams.Region = newValue;
    }

    const onNumDagsChange = (event: React.FormEvent, newValue?: string) => {
        setNumDags(newValue === undefined ? 0 : newValue);
        simulationParams.DagCount = parseInt(newValue||"0") || 0;
    }

    const onSkuChange = (event: React.FormEvent, newValue?: string) => {
        setSku(newValue === undefined ? "" : newValue);
        simulationParams.SkuFamily = newValue;
    }

    const onLayoutChange = (event: React.FormEvent, newValue?: string) => {
        setLayout(newValue === undefined ? "MT-3-copy" : newValue);
        simulationParams.Layout = newValue;
    }

    const onStampNameChange = (event: React.FormEvent, newValue?: string) => {
        setStampName(newValue === undefined ? "" : newValue);
        simulationParams.StampName = newValue;
    }*/

    const isValid = (simulationParams: ISimulationParams) => {
        if (simulationParams.GfsName.length === stampInfo?.dcCount && simulationParams.StampName !== "") {
            return true;
        }

        if (simulationParams.GfsName.length !== stampInfo?.dcCount) {
            setParamsError("Incorrect number of data centers entered");
            return false;
        }

        setParamsError("Missing inputs");
        return false;

        /*if (simulationParams.JobType === "WhatIf") {
            if (simulationParams.GfsName.length > 1 && simulationParams.SkuFamily !== "" && simulationParams.StampName !== "" && simulationParams.DagCount !== 0 && simulationParams.Layout !== "") {
                if (simulationParams.GfsName.length === stampInfo?.dcCount) {
                    return true;
                } else {
                    setParamsError("Incorrect number of data centers entered");
                    return false;
                }
            } else {
                setParamsError("Missing inputs");
                return false;
            }
        } else {
            if (!simulationParams.GfsName.length && simulationParams.SkuFamily !== "" && simulationParams.StampName !== "" && simulationParams.DagCount !== 0 && simulationParams.Layout !== "") {
                if (simulationParams.GfsName.length === stampInfo?.dcCount) {
                    return true;
                } else {
                    setParamsError("Incorrect number of data centers entered");
                    return false;
                }
            } else {
                setParamsError("Missing inputs");
                return false
            }
        }*/
    }

    const onSimulateClicked = () => {
        simulationParams.StampName = stampInfo?.stampName;
        simulationParams.GfsName = selectedKeys;
        simulationParams.DCCostSnapshotDate = dcCostDate;

        console.log("simulation params: ", simulationParams)
        if (isValid(simulationParams)) {
            setParamsError("");
            sendSimulationRequest();
        }
    }

    const sendSimulationRequest = () => {
        setIsLoading(true);
        setTimeout(() => {
            fetchSimulationParams(simulationParams).then(
                (response) => {
                    setSimulationResults(EmptyISelectionPlan);
                    console.log("simulation result: ", response);
                    setIsLoading(false);
                    if (response[0].recommendation == null) {
                        setParamsError("Error running simulation");
                        return;
                    }
                    setSimulationResults(response[0]);
                },
                (ex) => {
                    console.log("caught error: ", ex);
                    setParamsError(ex.message);
                    setIsLoading(false);
                },
            );
        }, 1000);
    }

    function onClearInputs() {
        simulationParams.GfsName = [];
        setSelectedKeys([]);
        setParamsError("");
    }

    const onGoToOverview = () => {
        history.push({ pathname: '/DCSelection/Overview' })
    } 

    return (
        <div className="ms-Fabric ms-font-su">
            <PrimaryButton style={{ width: "8rem" }} onClick={onGoToOverview}>Overview</PrimaryButton>
            <PageHeader
                title="Simulate DC Selection"
                description="We can simulate different DC selections"
            />
            <div className="ms-Grid" style={{margin: ""}} dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                        <TextField disabled label="Stamp Name" required value={stampName} />
                    </div>
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                        <TextField disabled label="Region" required value={region} />
                    </div>
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                        <TextField disabled label="Number of DAGs" value={numDags.toString()} required />
                    </div>
                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                        <TextField disabled label="SKU" value={sku} required />
                    </div>
                   
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                        <TextField disabled label="Layout" required value={layout} />
                    </div>
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                        <TextField disabled label="DC Cost Date" value={dcCostDate.toString()} required />
                    </div>
                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><DCNameFilter defaultOptions={selectedKeys} onEnvFilter={_onEnvFilter} dcCostSnapshotDate={state.plan.dcCostSnapshotDate} onSetParamsError={setParamsError} /></div>
                </div>
                <div className="ms-Grid-row" style={{marginTop:"1rem"}}>
                    <div className="ms-Grid-col">
                        <PrimaryButton  style={{width: "8rem"}} onClick={onSimulateClicked}>Simulate</PrimaryButton>
                    </div>
                    <div className="ms-Grid-col">
                        <PrimaryButton style={{ width: "8rem", backgroundColor: "red", borderColor: "red" }} onClick={onClearInputs}>Clear DCs</PrimaryButton>
                    </div>
                    <div className="ms-Grid-col">
                        {paramsError== "" ?
                        <div></div> 
                        : <div>
                            <MessageBar messageBarType={MessageBarType.error}> {paramsError}  </MessageBar>
                        </div>}
                    </div>
                </div>
            </div>
            {/*<div style={{marginTop: "1rem"}}>
                <MessageBar dismissButtonAriaLabel="Close" messageBarType={MessageBarType.warning}>
                Disclaimer:
                <br/>
                *  Since we need to call ORCAS to simulate the networking cost, it may take a long time to finish. We will first provide a quick estimation of networking cost.
                <br/>
                * Once the simulation result is done, we will inform you with a notification email.
                <br />
                 
                </MessageBar>
            </div>*/}
            {
                isLoading ?
                <div style={{margin: "2rem"}}>
                <Spinner label="Simulation in progress. This might take a while..." size={SpinnerSize.large} />
                </div> 
                :
                <div style={{margin: "2rem"}} className="ms-Grid ms-font-s" dir="ltr">
                    <div className="ms-Grid-row" style={{fontWeight: "bold", padding: "0.8rem 0.2rem", textAlign: "center"}}>
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">StampName</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Region</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Capacity Geo Code</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Reservation Status</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Fiscal Year</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Plan Intent</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">DCs (Priority1)</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Hosting Cost ($/yr)</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Networking Cost ($/yr)</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">DAG Cnt</div>
                    </div>
                    <DCSelectionSimulationGroup simulationResult={ simulationResults } />
                </div>
            }
        </div>
    )
    /*function removeDuplicates(dcs: ITag[], possibleDupes: ITag[]) {
        return dcs.filter(dc => !listContainsDC(dc, possibleDupes));
    }
    function listContainsDC(dc: ITag, dcs: ITag[]) {
        if (!dcs || !dcs.length || dcs.length === 0) {
            return false;
        }
        return dcs.filter(item => item.name === dc.name).length > 0;  
    }
    function doesTextStartWith(text: string, filterText: string): boolean {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
    }*/
}