import * as React from "react";

import { Stack } from "@fluentui/react";

import styles from "./PageHeader.less"

interface IPageHeaderProps {
    title: string;
    description: string;
}

const PageHeader: React.FC<IPageHeaderProps> = (props) => {
    return (
        <Stack>
            <h1>{props.title}</h1>
            <div className={styles.headerDescriptionAndLink}>
                 <span>{props.description}</span>  
            </div>
            <div style={{marginTop: "50px"}}></div>
            <div className={styles.separator}></div>
        </Stack>
    );
}

export default PageHeader;