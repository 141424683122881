import React from 'react';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react';
import { ICOGSCalculatorRequest } from '../../../../models/PCM';


interface IDeleteConfirmationModalProps {
    isModalOpen: boolean,
    hideModal: () => void,
    request: ICOGSCalculatorRequest,
    delete: (config: ICOGSCalculatorRequest) => void,
}

export const DeleteConfirmationModal: React.FunctionComponent<IDeleteConfirmationModalProps> = (props) => {
    const { isModalOpen, hideModal, request } = props;

    return (
      <Dialog hidden={!isModalOpen} onDismiss={hideModal} closeButtonAriaLabel="Close" dialogContentProps={{title: "Delete Request",subText: "Are you sure you want to delete this request?"}}>
        <DialogFooter>
          <PrimaryButton onClick={() => props.delete(request)} text="Yes" />
          <DefaultButton onClick={hideModal} text="No" />
        </DialogFooter>
      </Dialog>
    );
}
