import * as React from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { fetchDCNames } from '../../../../../services/dcSelectionService';

interface IDCNameFilterProp {
    defaultOptions: string[],
    //dcNamesOptions: IDropdownOption[]
    onEnvFilter: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void,
    dcCostSnapshotDate: Date,
    onSetParamsError: React.Dispatch<React.SetStateAction<string>>
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
};

const stackTokens: IStackTokens = { childrenGap: 20 };

const divider = { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider };



export const DCNameFilter: React.FunctionComponent<IDCNameFilterProp> = ({ defaultOptions, onEnvFilter, dcCostSnapshotDate, onSetParamsError }) => {
    const [dcNamesOptions, setDCNamesOptions] = React.useState<IDropdownOption[]>([{ key: "", text: "" }]);
    const [isLoadingDCNames, setIsLoadingDCNames] = React.useState<boolean>(true);

    function stringToDropdownOption(s: string) {
        return { key: s, text: s.toUpperCase() }
    }

    function getUniqueDCNames(names: string[]) {
        var namesSet: Set<string> = new Set();
        names.forEach(name => namesSet.add(name));

        var uniqueNames: string[] = [];
        namesSet.forEach(name => uniqueNames.push(name));

        uniqueNames.sort();
        return uniqueNames;
    }

    function getDCNames() {
        fetchDCNames(dcCostSnapshotDate).then(response => {
            var uniqueDCNames: string[] = getUniqueDCNames(response);
            var dcNamesOptions: IDropdownOption[] = uniqueDCNames.map(stringToDropdownOption);
            setDCNamesOptions(dcNamesOptions);
            setIsLoadingDCNames(false);
        },
        ex => {
            onSetParamsError("fetching data center names failed");
        }

        )
    }

    React.useEffect(() => {
        getDCNames();
    }, [])

    function makeCategoryHeaders(): IDropdownOption[] {
        let headerSet: Set<string> = new Set();
        for (var i = 0; i < dcNamesOptions.length; i++) {
            var currentOption = dcNamesOptions[i];
            var header = currentOption.text.replace(/[^a-z]/gi, "");
            headerSet.add(header);
        }

        let headerList: string[] = [];
        headerSet.forEach(h => headerList.push(h));
        headerList.sort();

        let dataCenterHeaders: IDropdownOption[] = [];
        headerList.forEach(h => {
            let headerOption: IDropdownOption = { key: h.toLowerCase(), text: h.toUpperCase(), itemType: DropdownMenuItemType.Header };
            dataCenterHeaders.push(headerOption);
        })

        return dataCenterHeaders;
    }

    function addCategoryHeaders() {
        let dataCenterHeaders = makeCategoryHeaders();
        var optionsWithHeaders = [];
        var headerIndex = 0;
        var currentHeader = dataCenterHeaders[headerIndex];
        optionsWithHeaders.push(currentHeader);
        for (var i = 0; i < dcNamesOptions.length; i++) {
            var currentOption = dcNamesOptions[i];
            if (!currentOption.text.startsWith(currentHeader.text)) {
                optionsWithHeaders.push(divider);
                headerIndex++;
                currentHeader = dataCenterHeaders[headerIndex];
                optionsWithHeaders.push(currentHeader);
            } else if (currentOption.text.charAt(currentHeader.text.length).match(/[A-Za-z]/)) { // forces an exact match in the start so that incorrecting category labeling does not happen with same letters ex AM vs AMS
                optionsWithHeaders.push(divider);
                headerIndex++;
                currentHeader = dataCenterHeaders[headerIndex];
                optionsWithHeaders.push(currentHeader);
            }
            optionsWithHeaders.push(currentOption);
        }
        return optionsWithHeaders;
    }

    return (
        !isLoadingDCNames && <Stack tokens={stackTokens}>
            <Dropdown
                placeholder="Select data centers"
                label="Data Centers"
                defaultSelectedKeys={defaultOptions}
                multiSelect
                options={addCategoryHeaders()}
                styles={dropdownStyles}
                onChange={onEnvFilter}
            />
        </Stack>
    );
};
