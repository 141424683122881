import { ComboBox, DefaultButton, DetailsList, DetailsRow, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IComboBox, IComboBoxOption, IconButton, IDetailsListProps, IDetailsRowStyles, IDropdownOption, IDropdownStyles, IGroup, ITooltipHostStyles, Label, PrimaryButton, Selection, SelectionMode, TextField, TooltipHost } from "@fluentui/react";
import * as React from "react";
import {Chart, registerables} from "chart.js";
import axios from "axios";
import { CapacitySellableEditor } from "./CapacitySellableEditor";
import { fetchSellableProfiles } from "../../../../services/demandPlanService";

interface IProfile {
    profileId: string,
    profileName: string,
    profileDescription: string,
    // Creator: string,
    // Timestamp: string
}

export const CapacitySellableIndexPage: React.FC = () => {
    const [profiles, setProfiles] = React.useState<IProfile[]>([]);
    const columns = [
        {key:"ProfileId", name: "Profile Id", fieldName: "profileId", minWidth: 5, maxWidth: 300},
        {key:"ProfileName", name: "Profile Name", fieldName: "profileName", minWidth: 5, maxWidth: 200},
        {key:"ProfileDescription", name: "Profile Description", fieldName: "profileDescription", minWidth: 5, maxWidth: 1000}
    ]

    const [edittingProfile, setEdittingProfile] = React.useState<string>();
    const capacityType = React.useState<string>("be");

    const _onProfileSelected = (item?: IProfile, index?: number, ev?: Event) => {
        setEdittingProfile(item?.profileId);
    }

    const _onNewClicked = () => {
        setEdittingProfile("new");
    }


    React.useEffect(() => {
        fetchSellableProfiles().then(response => {
            setProfiles(response);
        });
    },[]);
    
    return (
        <div>
        {!edittingProfile &&
            <div>
                <h1>CPG Sellable Profiles</h1>
                <Label style={{marginTop: "2rem"}}>Double click on the profile to edit</Label>
                <DetailsList
                    items={profiles}
                    columns={columns}
                    selectionMode = {SelectionMode.none}
                    onItemInvoked={_onProfileSelected}
                />

                <div className="ms-Grid-col ms-lg8 ms-md12 ms-sm12" style= {{marginTop:"2rem"}}>
                    <PrimaryButton 
                        style={{marginRight: "2rem"}}
                        text="New"
                        onClick={_onNewClicked}
                    />
                </div>
            </div>}
        {edittingProfile &&
            <div >
                <DefaultButton 
                    style={{margin: "1rem 5rem" , marginLeft: "6rem"}}
                    onClick={() => {setEdittingProfile(undefined);}}
                > 
                    Back 
                </DefaultButton> 
                <CapacitySellableEditor profileId={edittingProfile}/>
            </div>
        }
        </div>
    )
}