import * as React from "react";
import ReactEcharts from "echarts-for-react"; 

interface IGraphPlanInfluenceOnTtlProps {
    title?: string,
    forests: string[],
    dags: number[],
    extras: number[],
    ttls: number[],
    dagSize: number,
    historical? : boolean
}

const titleStyle = {
    fontFamily: 'Helvetica',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 'normal',
}

const createGraphOption = (title: string, forests: string[], dags: number[], extras: number[], ttls: number[], dagSize: number, historical?: boolean) => {
    console.log("title", title)
    console.log("forests", forests)
    console.log("dags", dags)
    console.log("extras", extras)
    console.log("ttls", ttls)
    console.log("dagSize", dagSize)
    console.log("historical", historical)
    const sizePerTtl: number[] = []
    for (var i = 0; i < forests.length; ++i) {
        if (extras[i] == -1) {
            sizePerTtl.push(0);
        } else if (extras[i] == 0) {
            sizePerTtl.push(dagSize);
        } else {
            sizePerTtl.push(ttls[i] / extras[i])
        }
    }

    const forestNewCapacity = []
    for (var i = 0; i < forests.length; ++i) {
        forestNewCapacity.push(dags[i] * dagSize);
    }

    const capacityToTtl = (capacitySeries: number[]) => {
        const ttlSeries = []
        for (var i = 0; i < forests.length; ++i) {
            if (sizePerTtl[i] == 0) {
                ttlSeries.push(0);
            } else {
                ttlSeries.push(capacitySeries[i] * sizePerTtl[i]);
            }
        }
        return ttlSeries; 
    }
    

    
    const option = {
        title: {
            text: title,
            textStyle: titleStyle
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            data: forests.filter(forest=>!forest.toLowerCase().includes("sparecapacity"))
        },
        series: [
        {
            name: historical? 'Current TTL' : 'TTL without this stamp',
            type: 'bar',
            stack: 'total',
            label: {
                show: true
            },
            emphasis: {
                focus: 'series'
            },
            data: capacityToTtl(extras).map(roundTwo)
        },
        (historical ? {}: 
        {
            name: 'TTL gain from this stamp',
            type: 'bar',
            stack: 'total',
            label: {
                show: true 
            },
            emphasis: {
                focus: 'series'
            },
            data: capacityToTtl(forestNewCapacity).map(roundTwo)
        }),
        ]
    };
    return option;
}
function roundTwo(x: number) {
    return Math.round(x*100) / 100;
}




export const GraphPlanInfluenceOnTtl : React.FC<IGraphPlanInfluenceOnTtlProps> = ({title, forests, dags, extras, ttls, dagSize, historical}) => {
    console.log(historical);
    return (
        <div>
            <ReactEcharts option={createGraphOption(title||"", forests, dags, extras, ttls, dagSize, historical)} />
        </div>
    )
}