import * as React from "react";
import axios from "axios";
import { fetchDcSelectionToken, fetchDecomPlans, fetchForestSnd, fetchRegionSnd, fetchRnf, generateDecomDemandPlans, runDcSelection } from "../../../services/demandPlanService";
import { setDefaultTarget } from "@fluentui/react/lib/components/Layer/Layer.notification";
import { DatePicker, DefaultButton, DetailsList, DetailsListLayoutMode, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IDropdownOption, PrimaryButton, TextField } from "@fluentui/react";
import { dataSanitizeString } from "@microsoft/applicationinsights-common";
import { Chart, registerables } from "chart.js";
import { chart } from "highcharts";
import { fetchForestCapacityMetrics } from "../../../services/allocationService";
import { merge } from "lodash";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { ApiId } from "@azure/msal-browser";
import { getDetailsColumnStyles } from "office-ui-fabric-react";
import { DemandPlanSimulationPage } from "./DemandPlanSimulationPage";
import { DemandPlanSimulationChart } from "./DemandPlanSimulationChart";
import { asComboBoxOption, convertDate, formatDate, intersect, parseDate } from "../../../utils/utils";
import { ICapacityOrder, IDatacenterMap, IDecomPlan, IDemandPlan, IDemandPlanEntry, IHardwareRoadMap, ITimeSeriesDataPoint } from "../../../models/DemandPlanGenerationModel";

export const DemandPlanGenerationPage: React.FC = () => {
    const [selectedRegion, setSelectedRegion] = React.useState<string>();
    const [selectedForest, setSelectedForest] = React.useState<string>("");
    const [selectedCapacityMetric, setSelectedCapacityMetric] = React.useState<string>("");
    const [regions, setRegions] = React.useState<string[]>([]);
    const [regionForests, setRegionForests] = React.useState<Map<string, string[]>>();

    const _targetTTL = React.useRef<number>(4.5);
    const _numShiftDays = React.useRef<number>(133);
    const _orderFrequency = React.useRef<number>(1);
    const _capacitySellable = React.useRef<number>(500);
    const _leadingTime = React.useRef<number>(6);
    const _supplyCutoffDate = React.useRef<Date>();
    const _supplyModifier = React.useRef<number>(1.0);
    const _demandModifier = React.useRef<number>(1.0);

    const _chartDateStrs = React.useRef<string[]>([]);
    const _chartSupplyData = React.useRef<number[]>([]);
    const _chartDemandData = React.useRef<number[]>([]);
    const _chartSafeSupplyData = React.useRef<number[]>([]);
    const _chartDemandPlanEntries = React.useRef<IDemandPlanEntry[]>([]);
    const _chartSupplyWithNewDemandData = React.useRef<number[]>([]);

    const [demandPlans, setDemandPlans] = React.useState<IDemandPlan[]>([]);
    const [version, setVersion] = React.useState<number>(Date.now());
    const [demandPlanOverviewColumns, setDemandPlanOverviewColumns] = React.useState<IColumn[]>([]);
    const [demandPlanDetailColumns, setDemandPlanDetailColumns] = React.useState<IColumn[]>([]);
    const [showingForests, setShowingForests] = React.useState<boolean>(true);
    const [showingDetail, setShowingDetail] = React.useState<boolean>(false);
    const [showingChart, setShowingChart] = React.useState<boolean>(false);
    const [demandPlanCalculationFinished, setDemandPlanCalculationFinished] =React.useState<boolean>(false);
    const [decomDemandPlanEntries, setDecomDemandPlanEntries] = React.useState<IDemandPlanEntry[]>([]);

    const [showStampGenerationDialog, setShowStampGenerationDialog] = React.useState<boolean>(false);
    const [capacityOrders, setCapacityOrders] = React.useState<ICapacityOrder[]>([]);

    const stampsForDcSelection = React.useRef<any[]>([]);

    const finishedPromises = React.useRef<number>(0);

    const CAPACITY_UNITS: any = {
        "HDD": "TB",
        "CPU": "GCycles",
        "IOPS": "IOPS",
        "SSD": "GB",
    }


    const _onSelectedRegionChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        setSelectedRegion(option?.text);
    }

    const getSelectedCapacitySellable = (capacityMetric: string) => {
        switch (capacityMetric) {
            case "HDD":
                return 700;
            case "IOPS":
                return 110000;
            case "CPU":
                return 5000;
            case "SSD":
                return 700000; 
            default:
                return 1;
        }
    }
    

    const _generateDemandPlans = () => {
        if (!selectedRegion || !regionForests) {
            return;
        }

        setShowingChart(false);
        setShowingDetail(false);
        setShowingForests(true);

        //growth
        const forests = regionForests.get(selectedRegion) || [];

        forests.sort();
        

        while (demandPlans.length > 0) demandPlans.pop();
        setDemandPlanCalculationFinished(false);
        for (let forest of forests) {
            // if (forest != 'namprd11') continue;
            //console.log("updating demand plan for " + forest);
            finishedPromises.current = 0;
            fetchForestSnd(forest).then(response => {

                finishedPromises.current ++;
                console.log(finishedPromises.current);
                const [hddDateStrs, hddSupply, hddDemand, hddSafeSupply] = extractAsArrays(response.hddSupply.map(convertDate), response.hddDemand.map(convertDate));
                const [cpuDateStrs, cpuSupply, cpuDemand, cpuSafeSupply] = extractAsArrays(response.cpuSupply.map(convertDate), response.cpuDemand.map(convertDate));
                const [iopsDateStrs, iopsSupply, iopsDemand, iopsSafeSupply] = extractAsArrays(response.iopsSupply.map(convertDate), response.iopsDemand.map(convertDate));
                const [ssdDateStrs, ssdSupply, ssdDemand, ssdSafeSupply] = extractAsArrays(response.ssdSupply.map(convertDate), response.ssdDemand.map(convertDate));
                const hddDemandPlanEntries = _calculateDemandPlans(hddDateStrs, hddSupply, hddSafeSupply, "HDD");
                const cpuDemandPlanEntries = _calculateDemandPlans(cpuDateStrs, cpuSupply, cpuSafeSupply, "CPU");
                const iopsDemandPlanEntries = _calculateDemandPlans(iopsDateStrs, iopsSupply, iopsSafeSupply, "IOPS");
                const ssdDemandPlanEntries = _calculateDemandPlans(ssdDateStrs, ssdSupply, ssdSafeSupply, "SSD");

                // console.log(hddDemandPlanEntries)
                // console.log(cpuDemandPlanEntries)
                // console.log(iopsDemandPlanEntries)
                // console.log(ssdDemandPlanEntries)

                const demandPlan = {
                    name: forest,
                    hdd: hddDemandPlanEntries,
                    cpu: cpuDemandPlanEntries,
                    iops: iopsDemandPlanEntries,
                    ssd: ssdDemandPlanEntries,
                    overall: _mergeDemandPlanEntries([hddDemandPlanEntries, cpuDemandPlanEntries, iopsDemandPlanEntries, ssdDemandPlanEntries])
                } as IDemandPlan;

                demandPlans.push(demandPlan);
                setVersion(Date.now());
                if (finishedPromises.current == forests.length) {
                    setDemandPlanCalculationFinished(true);
                    setShowingForests(false);

                }
            });

            setTimeout(() => {
                setDemandPlanCalculationFinished(true);
            }, 30000);
        }

        // decom
        fetchDecomPlans(selectedRegion).then(response => {
            const decomPlans = response.map(plan => ({
                region: plan.region,
                month: new Date(plan.month),
                sku: plan.sku,
                decomDags: plan.decomDags
            } as IDecomPlan));
            console.log(decomPlans);
            const decomEntries = decomPlans.map(plan => {
                return {
                    date: plan.month,
                    numUnits: Math.ceil(plan.decomDags / getUpSkuRatio(plan.sku))

                } as IDemandPlanEntry;
            });
            setDecomDemandPlanEntries(decomEntries)
        })
    }
    function getUpSkuRatio(sku: String) {
        return 1.5;
    }

    const renderDemandPlan = (demandPlan: IDemandPlan) => {
        return (
            <div>
                {demandPlan.name}
            </div>
        )
    }

    const _doShowDetail = (item: any) => {
        if (item.name.toLowerCase() == "total") {
            return;
        }
        setSelectedForest(item.name);
        setShowingDetail(true);
    }

    const _doShowChart = (item: any) => {
        setSelectedCapacityMetric(item.name.toUpperCase());
        fetchForestSnd(selectedForest).then(response => {

            const [hddDateStrs, hddSupply, hddDemand, hddSafeSupply] = extractAsArrays(response.hddSupply.map(convertDate), response.hddDemand.map(convertDate));
            const [cpuDateStrs, cpuSupply, cpuDemand, cpuSafeSupply] = extractAsArrays(response.cpuSupply.map(convertDate), response.cpuDemand.map(convertDate));
            const [iopsDateStrs, iopsSupply, iopsDemand, iopsSafeSupply] = extractAsArrays(response.iopsSupply.map(convertDate), response.iopsDemand.map(convertDate));
            const [ssdDateStrs, ssdSupply, ssdDemand, ssdSafeSupply] = extractAsArrays(response.ssdSupply.map(convertDate), response.ssdDemand.map(convertDate));

            const hddDemandPlanEntries = _calculateDemandPlans(hddDateStrs, hddSupply, hddSafeSupply, "HDD");
            const cpuDemandPlanEntries = _calculateDemandPlans(cpuDateStrs, cpuSupply, cpuSafeSupply, "CPU");
            const iopsDemandPlanEntries = _calculateDemandPlans(iopsDateStrs, iopsSupply, iopsSafeSupply, "IOPS");
            const ssdDemandPlanEntries = _calculateDemandPlans(ssdDateStrs, ssdSupply, ssdSafeSupply, "SSD");

            switch (item.name.toUpperCase()) {
                case "HDD":
                    _chartDateStrs.current = hddDateStrs
                    _chartSupplyData.current = hddSupply;
                    _chartDemandData.current = hddDemand;
                    _chartSafeSupplyData.current = hddSafeSupply
                    _chartDemandPlanEntries.current = hddDemandPlanEntries
                    break;
                case "CPU":
                    _chartDateStrs.current = cpuDateStrs
                    _chartSupplyData.current = cpuSupply;
                    _chartDemandData.current = cpuDemand;
                    _chartSafeSupplyData.current = cpuSafeSupply
                    _chartDemandPlanEntries.current = cpuDemandPlanEntries
                    break;
                case "IOPS":
                    _chartDateStrs.current = iopsDateStrs
                    _chartSupplyData.current = iopsSupply;
                    _chartDemandData.current = iopsDemand;
                    _chartSafeSupplyData.current = iopsSafeSupply
                    _chartDemandPlanEntries.current = iopsDemandPlanEntries
                    break;
                case "SSD":
                    _chartDateStrs.current = ssdDateStrs
                    _chartSupplyData.current = ssdSupply;
                    _chartDemandData.current = ssdDemand;
                    _chartSafeSupplyData.current = ssdSafeSupply
                    _chartDemandPlanEntries.current = ssdDemandPlanEntries
                    break;
                default:
            }
            _chartSupplyData.current = _chartSupplyData.current.map(p => p * _supplyModifier.current);
            _chartDemandData.current = _chartDemandData.current.map(p => p * _demandModifier.current);
            _chartSafeSupplyData.current = _chartSafeSupplyData.current.map(p => p * _demandModifier.current);
            _chartSupplyWithNewDemandData.current = _calculateSupplyWithDemandPlans(_chartDateStrs.current, _chartSupplyData.current, _chartDemandPlanEntries.current, item.name);
            setShowingChart(true);
            setVersion(Date.now());
        });
    }

    const _onTargetTtlChange = (event: React.FormEvent, newValue?: string) => {
        _numShiftDays.current = (parseFloat(newValue || "") * 30 + _orderFrequency.current * 30) || _numShiftDays.current;
        _targetTTL.current = parseFloat(newValue || "");
        // setData(_hddSupply.current, _hddDemand.current);
    }

    const _onOrderFrequencyChange = (event: React.FormEvent, newValue?: string) => {
        _orderFrequency.current = parseInt(newValue || "") || _orderFrequency.current;
    }

    const _onCapacitySellableChange = (event: React.FormEvent, newValue?: string) => {
        _capacitySellable.current = parseFloat(newValue || "") || _capacitySellable.current;
    }

    const _onLeadingTimeChange = (event: React.FormEvent, newValue?: string) => {
        _leadingTime.current = parseFloat(newValue || "") || _leadingTime.current;
    }

    const _onSupplyModifierChange = (event: React.FormEvent, newValue?: string) => {
        _supplyModifier.current = parseFloat(newValue || "") || _supplyModifier.current;
    }
    const _onDemandModifierChange = (event: React.FormEvent, newValue?: string) => {
        _demandModifier.current = parseFloat(newValue || "") || _demandModifier.current;
    }
    const _onSupplyCutoffDateChange = (event: React.FormEvent, newValue?: string) => {

    }

    const futureSeveralMonths = (numMonths: number) => {
        const date = new Date();

        const dates = []
        for (let i = 0; i < numMonths; ++i) {
            const d = new Date(date.getTime());
            d.setMonth(d.getMonth() + i);
            d.setDate(1);
            d.setHours(12);
            dates.push(d);
        }
        return dates;
    }

    // const sumDemandPlanEntries = (demandPlanEntries: IDemandPlanEntry[][]) => {
    //     return demandPlanEntries[0];
    // }

    // const withSummary = (demandPlans: IDemandPlan[]) => {
    //     if (demandPlans.length == 0) {
    //         return demandPlans;
    //     }

    //     let summary = {
    //         name: "Total",
    //         orderFrequency: demandPlans[0].orderFrequency,
    //         cpu: sumDemandPlanEntries(demandPlans.map(dp => dp.cpu || [])),
    //         iops: sumDemandPlanEntries(demandPlans.map(dp => dp.iops || [])),
    //         hdd: sumDemandPlanEntries(demandPlans.map(dp => dp.hdd || [])),
    //         ssd: sumDemandPlanEntries(demandPlans.map(dp => dp.ssd || [])),
    //         overall: sumDemandPlanEntries(demandPlans.map(dp => dp.overall))
    //    } as IDemandPlan
    // }

    const asOverviewItem = (demandPlan: IDemandPlan) => {
        const item: any = {}
        item["name"] = demandPlan.name;
        for (let entry of demandPlan.overall) {
            item[formatDate(entry.date)] = entry.numUnits
        }
        return item;
    }

    const createDecomOverviewItems = (demandPlanEntries: IDemandPlanEntry[]) => {
        const item: any = {}
        item["name"] = "Decom"
        for (let entry of demandPlanEntries) {
            item[formatDate(entry.date)] = entry.numUnits
        }
        return [item];
    }

    const createForestOverviewItems = (demandPlans: IDemandPlan[]) => {
        const overviewItems = demandPlans.map(asOverviewItem);
        const sumItem: any = {};
        sumItem["name"] = "Total Growth";
        for (let demandPlan of demandPlans) {
            for (let entry of demandPlan.overall) {
                sumItem[formatDate(entry.date)] = (sumItem[formatDate(entry.date)] || 0) + (entry.numUnits || 0);
            }
        }
        return [sumItem, ...overviewItems];
    }

    const createRegionOverviewItems = () => {
        const forestOverviewItems = createForestOverviewItems(demandPlans);

        console.log("creating region overview items");
        console.log(demandPlans);
        console.log(forestOverviewItems);
        console.log(forestOverviewItems[0]);
        const regionItem = forestOverviewItems[0];
        regionItem["name"] = selectedRegion;
        console.log(regionItem);
        return [regionItem];
    }
    const createDetailItems = (demandPlan: IDemandPlan) => {

        const hdd: any = {};
        const cpu: any = {};
        const iops: any = {};
        const ssd: any = {};
        hdd["name"] = "HDD";
        for (let entry of demandPlan.hdd || []) {
            hdd[formatDate(entry.date)] = entry.numUnits
        }
        cpu["name"] = "CPU";
        for (let entry of demandPlan.cpu || []) {
            hdd[formatDate(entry.date)] = entry.numUnits
        }
        iops["name"] = "IOPS";
        for (let entry of demandPlan.iops || []) {
            iops[formatDate(entry.date)] = entry.numUnits
        }
        ssd["name"] = "SSD";
        for (let entry of demandPlan.ssd || []) {
            ssd[formatDate(entry.date)] = entry.numUnits
        }

        return [hdd, cpu, iops, ssd];
    }

    const createCapaciyOrderItems = (capacityOrders: ICapacityOrder[]): Object[] => {
        return capacityOrders.map(
            co => {
                return {
                    DeploymentGroupIdName: co.deploymentGroupIdName,
                    PlanIntentTypeName: co.planIntentTypeName,
                    PlanRtegDate: `${co.planRtegDate.getFullYear()}-${co.planRtegDate.getMonth()+1}-${co.planRtegDate.getDate()}`,
                    DataCenter: co.dataCenter,
                    NumRacks: co.hardwareRoadmap.minimumOrderQuantity,
                    ServersPerRack: co.hardwareRoadmap.serversPerRack,
                    Sku: co.hardwareRoadmap.sku,
                    EstimatedDags: Math.floor(
                        (1 - co.hardwareRoadmap.spareRatio) * 
                            co.hardwareRoadmap.numLegs * 
                            co.hardwareRoadmap.minimumOrderQuantity * 
                            co.hardwareRoadmap.serversPerRack / 
                            co.hardwareRoadmap.serversPerDag)
                }
            }
        )
    }
    
    React.useEffect(() => {
        setDemandPlanOverviewColumns(["Forest", ...futureSeveralMonths(24).map(formatDate)]
            .map(val => {
                return {
                    key: val,
                    name: val,
                    fieldName: val == "Forest" ? "name" : val,
                    maxWidth: 200,
                    isResizable: true
                } as IColumn;
            }));
        setDemandPlanDetailColumns(["Date", ...futureSeveralMonths(24).map(formatDate)]
            .map(val => {
                return {
                    key: val,
                    name: val,
                    fieldName: val == "Date" ? "name" : val,
                    maxWidth: 200,
                    isResizable: true
                } as IColumn;
            }));
        fetchRnf().then(
            response => {
                console.log("fetch rnf then")
                response.json().then(body => {
                    let regionForests: Map<string, string[]> = new Map(Object.entries(body));
                    console.log(regionForests);

                    let regions = []
                    for (let o of regionForests.keys()) {
                        regions.push(o);
                    }
                    regions.sort();
                    setRegions(regions);
                    setRegionForests(regionForests);
                });
            }
        );
    }, []);
    
    const getHardwareRoadMap = (region: string,  year: number, month: number) => {
        return {
            numLegs: 6,
            numCopies: 3,
            sku: "WCS Gen7",
            serversPerRack: 18,
            serversPerDag: 24,
            minimumOrderQuantity: 20,
            spareRatio: 0.03,
            kwPerRack: 8.8
        } as IHardwareRoadMap
    }
    const getCapacityGeoCode = (region: string) => {
        const regionCapacityGeoCodeMapping = new Map(Object.entries({
            "nam": "UnitedStates",
            "eur": "Europe",
            "lam": "LATAM",
            "apc": "AsiaPacific",
            "jpn": "Japan",
            "ind": "India",
            "gbr": "UnitedKingdom"
        }));
        return regionCapacityGeoCodeMapping.get(region.toLowerCase());
    }
    const createStampName = (region: string, date: Date, seq:number) => {
        return `${getCapacityGeoCode(region)?.toUpperCase()}${date.getUTCFullYear()}${date.getUTCMonth()+1}-${seq}-EXO-MT`
    }

    function fillCapacityOrdersWithDataCenters(capacityOrders: ICapacityOrder[], stampToDcs: Record<string, string[]>) {
        const coWithDcs: ICapacityOrder[] = [];
        console.log(stampToDcs);
        let currentStampName = "";
        let index = 0;
        for (let order of capacityOrders) {
            if (currentStampName != order.deploymentGroupIdName) {
                currentStampName = order.deploymentGroupIdName;
                index = 0;
            }
            const datacenters = stampToDcs[order.deploymentGroupIdName];
            if (datacenters) {
                order.dataCenter = datacenters[index];
                index ++;
            }
            coWithDcs.push(order);
        }
        console.log("orders")
        console.log(coWithDcs);
        return coWithDcs;
    }

    const _doDcSelection = () => {
        fetchDcSelectionToken().then(response => {
            response.text().then(bearer => {
                console.log(bearer);
                const config = {
                    headers: {
                        Authorization: "Bearer " + bearer
                    }
                }
                
                const payload = {
                    TopN: 1,
                    EnableNetworkCalculation: true,
                    StampInfo: stampsForDcSelection.current
                }

                //const testlink = "https://intelligentplacement.azurewebsites.net/api/Hello"
                axios.post("https://intelligentplacement.azurewebsites.net/api/stamp/model/dcselection", payload, config).then((response) => {
                    console.log(response.data);
                    const stampToDcs: Record<string, string[]> = {};

                    for (let item of response.data) {
                        let dcs: string[] = []
                        stampToDcs[item['stampName']] = dcs
                        for (let dcRec of item.recommendation[0].dcs) {
                            dcs.push(dcRec.name);
                        }
                    }
                    console.log(stampToDcs);


                    const capacityOrdersWithDcs = fillCapacityOrdersWithDataCenters(capacityOrders, stampToDcs);
                    console.log(capacityOrdersWithDcs)
                    setCapacityOrders(capacityOrdersWithDcs);
                    //setVersion(Date.now());
                });

            })
        })

    }
    const addStampForDcSelection = (region: string, planRtegDate: Date, stampName: string, hardwareRoadMap: IHardwareRoadMap, intent: string) => {
        stampsForDcSelection.current.push(
            {
                StampName: stampName,
                Region: region.toUpperCase(),
                Layout: "MT-3-copy",
                DagCount: calculateDagsPerOrder(hardwareRoadMap),
                RackCntPerDC: hardwareRoadMap.minimumOrderQuantity,
                SKU: hardwareRoadMap.sku,
                kwPerRack: hardwareRoadMap.kwPerRack,
                PlanDockDate: formatDate(planRtegDate),
                ServerCntPerRack: hardwareRoadMap.serversPerRack,
                CapacityGeoCode: "",
                ReservationStatus: "",
                PlanIntent: intent,
                FiscalYear: 2024
            } 
        );
    }
            
        // })
        // axios.get(testlink, config).then((response) => {
        //     console.log(response.data);
        // })

    const createCapacityOrders = (region: string,  planRtegDate: Date, seq: number, hardwareRoadMap: IHardwareRoadMap, intent: string) => {

        const stampName = createStampName(region, planRtegDate, seq) + "-" + intent.toUpperCase() ;
        const orders: ICapacityOrder[] = [];

        // console.log("in create capacity orders")
        addStampForDcSelection(region, planRtegDate, stampName, hardwareRoadMap, intent);
        for (let i = 0; i < hardwareRoadMap.numLegs; ++i) {
            orders.push({
                planRtegDate: planRtegDate,
                planIntentTypeName: intent,
                deploymentGroupIdName: stampName,
                dataCenter: "DC" + i,
                hardwareRoadmap: hardwareRoadMap,
            })
        }
        return orders;
    }

    const calculateDagsPerOrder = (hardwareRoadMap: IHardwareRoadMap) => {
        return Math.trunc(
            hardwareRoadMap.minimumOrderQuantity * hardwareRoadMap.numLegs * hardwareRoadMap.serversPerRack * (1-hardwareRoadMap.spareRatio) / hardwareRoadMap.serversPerDag
        );
    }


    const generateStamps = () => {
        let region = selectedRegion || "unknown";
        let hardwareRoadMap = getHardwareRoadMap(region, 2023, 12);
        let plans = new Map<Date, number>();
        // for (let demandPlan of demandPlans) {
        //     for (let entry of demandPlan.overall) {
        //         plans.set(entry.date, (plans.get(entry.date) || 0) + (entry.numUnits || 0));
        //     }
        // }
        let growthDp: IDemandPlanEntry[] = []
        for (let plan of Object.entries(createRegionOverviewItems()[0])) {

            if (plan[0] == "name") continue;
            growthDp.push({
                date: new Date(plan[0]),
                numUnits: plan[1],
                unitCapacity: 0,
                notes: "Gen7"
            } as IDemandPlanEntry);
        }

        // console.log("in stamp generation");
        // console.log("======")
        growthDp.sort((a, b) => a.date.getTime() - b.date.getTime());
        // console.log(growthDp);

        let i = 0;
        let seq = 0;
        let dagsPerOrder = calculateDagsPerOrder(hardwareRoadMap);
        let pendingDags = dagsPerOrder
        // console.log("dags per order")
        // console.log(dagsPerOrder)
        const capacityOrders: ICapacityOrder[] = [];
        while (i < growthDp.length) {
            pendingDags += growthDp[i].numUnits;
            // console.log("pendingDags");
            // console.log(pendingDags);
            while (pendingDags >= dagsPerOrder) {
                pendingDags -= dagsPerOrder;
                seq ++;
                
                const stampCapacityOrders = createCapacityOrders(region, growthDp[i].date, seq, hardwareRoadMap, "Growth");
                for (let order of stampCapacityOrders) {
                    capacityOrders.push(order);
                }
            }
            seq = 0;
            i++;
        }
        //dcom
        let decomDp: IDemandPlanEntry[] = decomDemandPlanEntries;

        console.log("in stamp generation");
        console.log("======")
        decomDp.sort((a, b) => a.date.getTime() - b.date.getTime());
        console.log(decomDp);

        i = 0;
        seq = 0;
        pendingDags = dagsPerOrder
        while (i < decomDp.length) {
            pendingDags += decomDp[i].numUnits;
            console.log("pendingDags");
            console.log(pendingDags);
            while (pendingDags > dagsPerOrder) {
                pendingDags -= dagsPerOrder;
                seq ++;
                
                const stampCapacityOrders = createCapacityOrders(region, decomDp[i].date, seq, hardwareRoadMap, "DecomPair");
                for (let order of stampCapacityOrders) {
                    capacityOrders.push(order);
                }
            }
            seq = 0;
            i++;
        }
        console.log(capacityOrders);
        console.log(capacityOrders);
        setCapacityOrders(capacityOrders)
    }

    return (
        <div>
            <PageHeader
                title="Demand Plan Generation"
                description="The page is to generate demand plan based on multi-metrics demand forecasting and future supplies signals."
            />
        <div className="ms-Grid" dir="ltr"> 
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                    <Dropdown
                        label="Region"
                        selectedKey={selectedRegion}
                        options={regions.map(asComboBoxOption)}
                        onChange={_onSelectedRegionChange}
                    />
                </div>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                    <Dropdown
                        label="SKU"
                        selectedKey={"Gen7"}
                        options={["Gen7"].map(asComboBoxOption)}
                    />
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                    <TextField 
                        label="Target TTL (months)" 
                        defaultValue="4.5"
                        onChange={_onTargetTtlChange} 
                    />
                </div>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                    <TextField 
                        label="Order Frequency (every (x) months)" 
                        defaultValue="1"
                        onChange={_onOrderFrequencyChange} 
                    />
                </div>
            </div>
            {/* <div>
                <TextField 
                    label="Capacity Sellable" 
                    onChange={_onCapacitySellableChange} 
                />
            </div> */}
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                    <TextField 
                        label="Supply Chain Leading Time (months)" 
                        defaultValue="6"
                        onChange={_onLeadingTimeChange} 
                    />
                </div>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                    <DatePicker 
                        label="Supply Cut-off Date" 
                        onChange={_onSupplyCutoffDateChange} 
                    />
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                    <TextField 
                        label="Supply Modifier" 
                        defaultValue="1.0"
                        onChange={_onSupplyModifierChange} 
                    />
                </div>
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                    <TextField 
                        label="Demand Modifier" 
                        defaultValue="1.0"
                        onChange={_onDemandModifierChange} 
                    />
                </div>
            </div>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{marginTop:"1rem"}}>
                    <PrimaryButton onClick={_generateDemandPlans}>
                        Generate Demand Plan
                    </PrimaryButton>
                </div>
            </div>
            {showingChart && 
                <div>
                    <div>
                        <h2>
                            {`${selectedForest.toUpperCase()}: ${selectedCapacityMetric.toUpperCase()} (${CAPACITY_UNITS[selectedCapacityMetric.toUpperCase()]})`}
                        </h2>
                        <DemandPlanSimulationChart
                            key={version}
                            dateStrs={_chartDateStrs.current}
                            supplyData={_chartSupplyData.current}
                            demandData={_chartDemandData.current}
                            safeSupplyData={_chartSafeSupplyData.current}
                            supplyWithNewDemandData={_chartSupplyWithNewDemandData.current}
                        />

                    </div>
                    <div>
                        <PrimaryButton
                            onClick={()=>{setShowingChart(false)}}>
                            Back
                        </PrimaryButton>
                    </div>
                </div>
            }
            {showingDetail && !showingChart && 
                <div>
                    <div>
                        <h2>
                            {selectedForest.toUpperCase()}
                        </h2>
                        <DetailsList
                            items={createDetailItems(demandPlans.filter(demandPlan => demandPlan.name==selectedForest)[0])}
                            columns={demandPlanDetailColumns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            onItemInvoked={_doShowChart}
                        />

                    </div>
                    <div>
                        <PrimaryButton
                            onClick={()=>{setShowingDetail(false)}}>
                            Back
                        </PrimaryButton>
                    </div>
                </div>
            }
            {showingForests && !showingDetail && !showingChart &&
                <div key={version}>
                    <DetailsList
                        items={createForestOverviewItems(demandPlans)}
                        columns={demandPlanOverviewColumns}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        onItemInvoked={_doShowDetail}
                    />
                    {demandPlanCalculationFinished &&
                        <PrimaryButton
                            onClick={()=>{setShowingForests(false)}}>
                            Back
                        </PrimaryButton>}
                </div>
            }

            {!showingForests && !showingDetail && !showingChart &&
                <div key={version}>
                    <div>
                        <DetailsList
                            items={createRegionOverviewItems()}
                            columns={demandPlanOverviewColumns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            onItemInvoked={()=>setShowingForests(true)}
                        />
                    </div>
                    <div>
                        <DetailsList
                            items={createDecomOverviewItems(decomDemandPlanEntries)}
                            columns={demandPlanOverviewColumns}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                        />
                    </div>
                    <div>
                        <PrimaryButton 
                            onClick={() => setShowStampGenerationDialog(true)}
                            text="Generate Stamps" />
                        <DefaultButton 
                            onClick={_doDcSelection}>
                                Recommend DCs
                        </DefaultButton>
                    </div>
                        
                    <DetailsList
                        items={createCapaciyOrderItems(capacityOrders)}
                    />

                    <Dialog
                        hidden={!showStampGenerationDialog}
                        onDismiss={() => setShowStampGenerationDialog(false)}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: "Stamp Generation Parameters",
                            closeButtonAriaLabel: 'Close',
                            subText: "Stamps for the region will be generated using the following parameters",
                        }}
                    >
                        <TextField
                            label="SKU"
                        />
                        <TextField
                            label="Minimum Order Quantity"
                        />
                        <DatePicker
                            label="Order Start Time"
                        />
                        <DatePicker
                            label="Order End Time"
                        />
                        <DialogFooter>
                            <DefaultButton 
                                onClick={() => setShowStampGenerationDialog(false)} 
                                text="Cancel" />
                            <PrimaryButton 
                                onClick={() => {generateStamps(); setShowStampGenerationDialog(false)}} 
                                text="Generate" />
                        </DialogFooter>
                    </Dialog>
                </div>
            }
            </div>
        </div>);


    // ========================== utils========================================================

    // given supply data points and demand data points, extract the info and save the numeric values into 4 arrays
    // these arrays will be used in the chart.
    function extractAsArrays (supplyDataPoints: ITimeSeriesDataPoint[], demandDataPoints: ITimeSeriesDataPoint[]) {
        const dateStrs = intersect(
            supplyDataPoints.map(p => p.date.toISOString().slice(0, 10)), 
            demandDataPoints.map(p => p.date.toISOString().slice(0, 10)));

        const supply: number[] = supplyDataPoints
            .filter(d => dateStrs.includes(formatDate(d.date)))
            .map(d => d.value);

        const demand: number[] = demandDataPoints
            .filter(d => dateStrs.includes(formatDate(d.date)))
            .map(d => d.value);

        const safeSupply = demandDataPoints
            .filter(d => {
                let shiftedDate = new Date(d.date);
                shiftedDate.setDate(shiftedDate.getDate() - _numShiftDays.current);
                return dateStrs.includes(formatDate(shiftedDate))
            })
            .map(d => d.value)
        return [dateStrs, supply, demand, safeSupply];
    }

    // calculate the demand plans using dates, supply and safesupply, return as arrays of DemandPlan
    function _calculateDemandPlans(dateStrs: string[], supply: number[], safeSupply: number[], decidingFactor: string) {
        supply = supply.map(e => e * _supplyModifier.current);
        safeSupply = safeSupply.map(e => e * _demandModifier.current);
        let i = 0;
        while (dateStrs[i] < formatDate(new Date(Date.now()))) {
            i++;
        }
        while (i < dateStrs?.length &&  supply[i]! > safeSupply[i]!) {
            i++;
        }
        if (i >= dateStrs?.length || i >= supply.length || i > safeSupply.length) {
            return [];
        }
        let supplyNeededDate = parseDate(dateStrs[i-1])!;
        const lastDate = parseDate(dateStrs[dateStrs.length-1])!;

        supplyNeededDate.setDate(1);

        const demandPlans: IDemandPlanEntry[] = [];
        let purchasedDags = 0;
        while (supplyNeededDate < lastDate) {
            let nextSupplyDate = new Date(supplyNeededDate);
            nextSupplyDate.setMonth(nextSupplyDate.getMonth() + _orderFrequency.current);
            
            const targetCapacity = safeSupply[dateStrs.indexOf(formatDate(nextSupplyDate))];
            const currentCapcity = supply[dateStrs.indexOf(formatDate(nextSupplyDate))];
            const numDags = Math.ceil((targetCapacity - currentCapcity) / getSelectedCapacitySellable(decidingFactor));//_capacitySellable.current);
            console.log("===========");
            
            console.log(decidingFactor);
            console.log(dateStrs);
            console.log(supply);
            console.log(safeSupply);
             console.log(nextSupplyDate);
             console.log(currentCapcity);
             console.log(targetCapacity);
             console.log(numDags);
            console.log("===========")
            supplyNeededDate = nextSupplyDate;
            const orderNeededDate = new Date(supplyNeededDate.getTime());
            orderNeededDate.setMonth(orderNeededDate.getMonth());//- _leadingTime.current);
            if (numDags) {
                demandPlans.push({
                    date: orderNeededDate,
                    numUnits: numDags - purchasedDags,
                    unitCapacity: getSelectedCapacitySellable(decidingFactor),//_capacitySellable.current,
                    notes: decidingFactor
                });
                purchasedDags = numDags;
            }
            //monthlyDemandPlan.push()
        }
        
        return demandPlans.slice(0, demandPlans.length-2);
        //console.log(formatDate(supplyNeededDate));
    }

    // merge demand plan entries, demand plans from different metrics need to be put together as the final deman plan.
    // the merge will also align dates.
    function _mergeDemandPlanEntries(demandPlans: IDemandPlanEntry[][]) : IDemandPlanEntry[] {
        const merge = (dp1: IDemandPlanEntry[], dp2: IDemandPlanEntry[]): IDemandPlanEntry[] => {
            if (dp1.length == 0) {
                return dp2;
            }
            if (dp2.length == 0) {
                return dp1;
            }
            let i = 0;
            let j = 0;
            const mergedDemandPlans: IDemandPlanEntry[] = [];
            if (formatDate(dp1[i].date) < formatDate(dp2[j].date)) {
                mergedDemandPlans.push(dp1[i]);
                i++;
            }

            if (formatDate(dp2[j].date) < formatDate(dp1[i].date)) {
                mergedDemandPlans.push(dp2[j]);
                j++;
            }
            // console.log(dp1);
            // console.log(dp2);
            while (1) {
                i++; 
                j++;

                if (i >= dp1.length) {
                    dp2.slice(j, dp2.length).forEach(plan => mergedDemandPlans.push(plan));
                    break;
                }
                if (j >= dp2.length) {
                    dp1.slice(i, dp1.length).forEach(plan => mergedDemandPlans.push(plan));
                    break;
                }

                if (dp1[i].numUnits < dp2[j].numUnits) {
                    mergedDemandPlans.push(dp2[j]);
                } else {
                    mergedDemandPlans.push(dp1[i]);
                }
            }

            return mergedDemandPlans;
        }

        if (demandPlans.length == 1) {
            return demandPlans[0];
        }
        return _mergeDemandPlanEntries(
            [
                merge(demandPlans[0], demandPlans[1]), 
                ...demandPlans.slice(2, demandPlans.length)
            ]);
    }

    // calculate the supply after adding demand plans, the data is used to show the step-ish graph on the chart.
    function _calculateSupplyWithDemandPlans(dateStrs: string[], supplyData: number[], demandPlanEntries: IDemandPlanEntry[], selectedCapacityMetric: string) {
        const supplyWithDemandPlans: number[] = [];

        let j = -1;
        console.log("calculating new supply")
        console.log(demandPlanEntries)
        
        for (let i = 0; i < dateStrs.length; i++) {
            // const dateWithOffset = parseDate(dateStrs[i])!;
            // dateWithOffset.setMonth(dateWithOffset.getMonth() - _leadingTime.current);
            const date = parseDate(dateStrs[i])!;
            while (j < demandPlanEntries.length-1 && date >= demandPlanEntries[j+1].date) {
                j++;
            }
            if (j != -1) {
                supplyWithDemandPlans.push(supplyData[i] + demandPlanEntries.slice(0, j+2).map(e =>e.numUnits).reduce((a,b) => a+b) * getSelectedCapacitySellable(selectedCapacityMetric||""));//_demandPlans.current[j].unitCapacity);
            } else {
                supplyWithDemandPlans.push(supplyData[i]);
            }

        }
        return supplyWithDemandPlans;
    }
    // ========================================================
}

