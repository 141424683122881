import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import { TextField } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { SpinButton, ISpinButtonStyles } from '@fluentui/react/lib/SpinButton';
import { getConfigsNew, updateConfigsNew } from '../../../services/alertingservice';
import { AlertingConfig, AlertingConfigNew } from '../../../models/alerting';
import LoadingState from '../../common/LoadingState';
import { useBoolean } from '@fluentui/react-hooks';
import {
    MessageBar,
    MessageBarType,
    Spinner
} from '@fluentui/react';
import PageHeader from '../../common/pageheader/PageHeader';
import {
    DetailsList,
    DetailsListLayoutMode,
    IDetailsListStyles,
    IDetailsHeaderProps,
    ConstrainMode,
    IDetailsFooterProps,
    DetailsRow,
    IDetailsColumnStyles,
    IColumn,
    IDetailsRowProps,
    IDetailsRowStyles,
    IDetailsColumnRenderTooltipProps
} from '@fluentui/react/lib/DetailsList';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { TTLConfigTableModal } from './modules/TTLConfigTableModal';
import { IconButton } from '@fluentui/react/lib/Button';
import { IUserPermissionData, loadUserPermissionIfNeeded } from "../../../reducers/userPermissionReducer";
import { IAppState } from "../../../store";
import { useSelector } from "react-redux";
import { UserPermission } from "../../../models/permission"
import { checkUserPermission } from "../../../utils/utils"
import { ToolType } from "../../../utils/ToolConstants"


const headerStyle: Partial<IDetailsColumnStyles> = {
    cellTitle: {
        color: 'white',
        backgroundColor: 'black',
        marginLeft: '1px'
    }
}

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );
    return defaultRender!({
        ...props,
        styles: {
            root: {
                selectors: {
                    '.ms-DetailsHeader-cell': {
                        whiteSpace: 'pre-wrap',
                        textOverflow: 'clip',
                        lineHeight: 'normal',
                    },
                    '.ms-DetailsHeader-cellTitle': {
                        height: '100%',
                        alignItems: 'center',
                    },
                },
            },
        },
        onRenderColumnHeaderTooltip,
    });
};

const columns: IColumn[] = [{
    styles: headerStyle,
    key: 'geocode',
    name: 'Region',
    fieldName: 'geoCode',
    isResizable: true,
    minHeight: 20,
    minWidth: 130,
    maxWidth: 130,
    ariaLabel: "GeoCode"
},
{
    styles: headerStyle,
    key: 'env',
    name: 'Environment',
    fieldName: 'environment',
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    ariaLabel: "Environment"
},
{
    styles: headerStyle,
    key: 'red',
    name: 'Hot/Watch Limit',
    fieldName: 'sev1',
    isResizable: true,
    minWidth: 30,
    maxWidth: 100,
    ariaLabel: "Hot/Watch Limit"
},
{
    styles: headerStyle,
    key: 'green',
    name: 'Watch/Healthy Limit',
    fieldName: 'sev2',
    isResizable: true,
    minWidth: 30,
    maxWidth: 100,
    ariaLabel: "Watch/Healthy Limit"
},
{
    styles: headerStyle,
    key: 'blue',
    name: 'Healthy/Cold Limit',
    fieldName: 'sev3',
    isResizable: true,
    minWidth: 30,
    maxWidth: 100,
    ariaLabel: "Healthy/Cold Limit"
    },
    {
        styles: headerStyle,
        key: 'maxedb',
        name: 'MaxEDB',
        fieldName: 'maxEDB',
        isResizable: true,
        minWidth: 30,
        maxWidth: 100,
        ariaLabel: "MaxEDB"
    },
    {
        styles: headerStyle,
        key: 'edit',
        name: '',
        isResizable: true,
        minWidth: 30,
        maxWidth: 50,
    }

];

export const ThresholdConfig: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [saved, setSaved] = useState(false);
    const [messageBarType, setMessageBarType] = useState(MessageBarType.info);
    const [detailsItem, setDetailsItem] = useState<AlertingConfigNew[]>([]);
    const [detailColumn, setDetailColumn] = useState<IColumn[]>(columns);
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const [loadErrMsg, setLoadErrMsg] = useState("");
    const { userpermissions } = useSelector<IAppState, IUserPermissionData>(state => state.userPermissions);

    useEffect(() => {
        getConfigsNew("TTL")
            .then(configs => {
                setDetailsItem(configs);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setLoadErrMsg(loadErrMsg + err);
            })

    }, [])

    const gridStyles: Partial<IDetailsListStyles> = {
        root: {
            overflowX: 'scroll',
            selectors: {
                '& [role=grid]': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    background: 'none',
                    color: 'black'
                },
            },
        },
        headerWrapper: {
            flex: '0 0 auto',
        },
        contentWrapper: {
            flex: '1 1 auto',
            overflowY: 'auto',
            overflowX: 'hidden',
        },
    };

    const _renderItemColumn = (item: AlertingConfigNew, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName as keyof AlertingConfigNew] as string;

        switch (column.key) {
            case 'edit': {
                if (checkUserPermission(userpermissions, ToolType.CAPACITYHEALTH, 'edit'))
                    return <div><IconButton iconProps={{ iconName: 'Edit' }} title="Edit" ariaLabel="Edit" onClick={() => onRowSelection(item, index)} /></div>
                else
                    return <div></div>
            }
            default:
                return <div style={{ wordWrap: "break-word", fontSize: "18px", whiteSpace: "pre-wrap" }} >{fieldContent}</div>;
        }
    }   

    const _onRenderRow = (props: IDetailsRowProps, index: number) => {
        const rowStyles: Partial<IDetailsRowStyles> = {
            root: {
                background: props.itemIndex % 2 == 0 ? '#e3e3e3' : '#cacaca',
                color: 'black',
                marginBottom: '1px'
            },
        }
        return <DetailsRow {...props} styles={rowStyles} />;
    }

    const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns: IColumn[] = detailColumn.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(detailsItem, currColumn.fieldName!, currColumn.isSortedDescending);
        setDetailsItem(newItems);
        setDetailColumn(newColumns);
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const [isModalOpen, { setTrue: openModal, setFalse: hideModal }] = useBoolean(false);
    const [index, setIndex] = React.useState(-1);
    const [selectedItem, setSelectedItem] = React.useState<AlertingConfigNew>();


    const onRowSelection = (item: AlertingConfigNew, index: number) => {
        if (checkUserPermission(userpermissions, ToolType.CAPACITYHEALTH, 'edit')) {
            openModal();
            setSelectedItem(item);
            setIndex(index);
        }       
    }

    const onRowSave = (item?: AlertingConfigNew) => {
        hideModal();
        if (item != undefined) {
            const newDetails = [...detailsItem];
            newDetails[index].sev1 = item.sev1;
            newDetails[index].sev2 = item.sev2;
            newDetails[index].sev3 = item.sev3;
            newDetails[index].maxEDB = item.maxEDB;
            setDetailsItem(newDetails);

            item.version = new Date();
            item.changedBy = account?.username;
            updateConfigsNew(item)
                .then(res => {
                    if (res.status != 200) {
                        setLoadErrMsg("Save locally, but can't save to DB properly");
                    }                    
                });
        }
    }

    return (
        <div>
            <PageHeader
                title="TTL Threshold Configuration"
                description=" This page is to configure TTL alerting threshold in the capacity health dashboard."
            />
            <div>
                <div>
                    {loadErrMsg != "" && < MessageBar messageBarType={MessageBarType.error}>
                        ERROR -- {loadErrMsg}
                    </MessageBar>}
                    <h4>Capacity Threshold Config: </h4>
                    <MessageBar>
                        You could double click each row or click edit icon to update the config if you have permission
                    </MessageBar>
                    {loading && <Spinner label="Loading Configuration..." />}                   
                    {!loading && <div>
                        <DetailsList
                            compact={true}
                            items={detailsItem}
                            columns={detailColumn}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderDetailsHeader={onRenderDetailsHeader}
                            onRenderRow={_onRenderRow}
                            onColumnHeaderClick={_onColumnClick}
                            onRenderItemColumn={_renderItemColumn}
                            selectionPreservedOnEmptyClick
                            styles={gridStyles}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                            onItemInvoked={onRowSelection}
                            selectionMode={SelectionMode.none}
                        />
                        {isModalOpen && <TTLConfigTableModal isModalOpen={isModalOpen} hideModal={hideModal} item={selectedItem} save={onRowSave} />}
                    </div>}
                </div>
                <div></div>
            </div>
        </div>
    );
}
