import { AnyAction, Store, applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { ICakeData, cakeReducer } from "./reducers/cakeReducerExample";
import { ISideBarData, sideBarReducer } from "./reducers/sideBarReducer";
import { IUserPermissionData, userPermissionReducer } from "./reducers/userPermissionReducer";

export interface IAppState {
    cakes: ICakeData;
    sideBars: ISideBarData;
    userPermissions: IUserPermissionData;
}

export type GetStateFunction = () => IAppState;

const store: Store<IAppState, AnyAction> = createStore(
    combineReducers<IAppState>({
        cakes: cakeReducer,
        sideBars: sideBarReducer,
        userPermissions: userPermissionReducer
    }),
    applyMiddleware<IAppState>(thunkMiddleware)
);


export default store;