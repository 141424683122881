import * as React from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

interface IEnvFilterProp {
    defaultOptions: string[],
    onEnvFilter: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void,
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
};

const options: IDropdownOption[] = [
    { key: 'base', text: 'Base', itemType: DropdownMenuItemType.Header },
    { key: 'Go Local', text: 'Go Local' },
    { key: 'Multitenant', text: 'Multitenant' },
    { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'Gov', text: 'Government', itemType: DropdownMenuItemType.Header },
    { key: 'BlackForest', text: 'BlackForest' },
    { key: 'Gallatin', text: 'Gallatin' },
    { key: 'GCC Low', text: 'GCC Low' },
    { key: 'ITAR DoD', text: 'ITAR DoD' },
    { key: 'ITAR GCCH', text: 'ITAR GCCH' },
    { key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'microsoft', text: 'Microsoft', itemType: DropdownMenuItemType.Header },
    { key: 'MSIT', text: 'MSIT' },
    { key: 'SDF', text: 'SDF' },
    { key: 'TDF', text: 'TDF' },
    { key: 'Dedicated', text: 'Dedicated' },
];

const stackTokens: IStackTokens = { childrenGap: 20 };

export const EnvFilter: React.FunctionComponent<IEnvFilterProp> = (props) => {

    return (
        <Stack tokens={stackTokens}>
            <Dropdown
                placeholder="Select options"
                label="Select Environment"
                defaultSelectedKeys={props.defaultOptions}
                multiSelect
                options={options}
                styles={dropdownStyles}
                onChange={props.onEnvFilter}
            />
        </Stack>
    );
};
