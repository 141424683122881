import React from 'react';
import ReactECharts from 'echarts-for-react';
import { RegionStatusTrend } from '../../../../models/alerting';
import { EnvFilter } from './EnvFilter';
import { StatusTrendModeSelection } from './StatusTrendModeSelection';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

interface IStatusTrendProps {
    data: RegionStatusTrend[]
}

const _aggregateData = (data: RegionStatusTrend[]): RegionStatusTrend[] => {
    const dates = new Set(data.map(rst => rst.dataDate));
    let hotRegions = new Map();
    let watchRegions = new Map();
    let healthyRegions = new Map();
    let coldRegions = new Map();
    let res: RegionStatusTrend[] = [];
    dates.forEach(d => {
        hotRegions.set(d, 0);
        watchRegions.set(d, 0);
        healthyRegions.set(d, 0);
        coldRegions.set(d, 0);
    });

    data.forEach(d => {
        hotRegions.set(d.dataDate, hotRegions.get(d.dataDate) + d.hotRegion);
        watchRegions.set(d.dataDate, watchRegions.get(d.dataDate) + d.watchRegion);
        healthyRegions.set(d.dataDate, healthyRegions.get(d.dataDate) + d.healthyRegion);
        coldRegions.set(d.dataDate, coldRegions.get(d.dataDate) + d.coldRegion);
    });

    dates.forEach(d => {
        const rst: RegionStatusTrend = {
            dataDate: d,
            hotRegion: hotRegions.get(d),
            healthyRegion: healthyRegions.get(d),
            coldRegion: coldRegions.get(d),
            watchRegion: watchRegions.get(d),
            environment: "",
            statusDate: ""
        }
        res.push(rst);
    })

    return res;

}

export const StatusTrend: React.FC<IStatusTrendProps> = (props) => {
    const NEXTYEARMODE = "NEXT12MONTHMIN";
    const TODAYMODE = "TODAYMIN";
    const defaultSelectedKeys = ['Multitenant', 'Go Local', 'Gallatin'];
    const [data, setData] = React.useState<RegionStatusTrend[]>(_aggregateData(props.data.filter(rst => defaultSelectedKeys.includes(rst.environment) && rst.statusDate == TODAYMODE)));
    const [selectedKeys, setSelectedKeys] = React.useState(defaultSelectedKeys);
    const [mode, setMode] = React.useState(TODAYMODE);

    const _onEnvFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.selected == true) {
                selectedKeys.push(option.key.toString());
                setSelectedKeys(selectedKeys);
                setData(_aggregateData(props.data.filter(rst => selectedKeys.includes(rst.environment) && rst.statusDate == mode)));

            }
            if (option.selected == false) {
                const arr_index = selectedKeys.indexOf(option.key.toString());
                if (arr_index > -1) {
                    var new_arr = selectedKeys.filter(key => key != option.key.toString());
                    setSelectedKeys(new_arr);
                    setData(_aggregateData(props.data.filter(rst => new_arr.includes(rst.environment) && rst.statusDate == mode)));
                }
            }
        }
    }

    const _onModeSelection = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (option.key == NEXTYEARMODE) {
                setMode(NEXTYEARMODE);
                const data = props.data.filter(rst => selectedKeys.includes(rst.environment) && rst.statusDate == NEXTYEARMODE);
                setData(_aggregateData(data));
            } else {
                setMode(TODAYMODE);
                const data = props.data.filter(rst => selectedKeys.includes(rst.environment) && rst.statusDate == TODAYMODE);
                setData(_aggregateData(data));
            }
        }
    }

    const _getOption = (data: RegionStatusTrend[]) => {
        const option = {
            title: {
                text: 'Capacity Status Trend'
            },
            tooltip: {},
            legend: {
                data: ['Hot', 'Watch', 'Healthy', 'Cold']
            },
            xAxis: {
                data: (data != undefined && data.length != 0) ? data.map(x => new Date(x.dataDate).toLocaleDateString("en-US")) : []
            },
            yAxis: {},
            series: [
                {
                    name: 'Hot',
                    type: 'bar',
                    stack: "stackbar",
                    data: (data != undefined && data.length != 0) ? data.map(x => x.hotRegion) : [],
                    itemStyle: {
                        color: 'red'
                    }
                },
                {
                    name: 'Watch',
                    type: 'bar',
                    stack: "stackbar",
                    data: (data != undefined && data.length != 0) ? data.map(x => x.watchRegion) : [],
                    itemStyle: {
                        color: 'yellow'
                    }
                },
                {
                    name: 'Healthy',
                    type: 'bar',
                    stack: "stackbar",
                    data: (data != undefined && data.length != 0) ? data.map(x => x.healthyRegion) : [],
                    itemStyle: {
                        color: 'green'
                    }
                },
                {
                    name: 'Cold',
                    type: 'bar',
                    stack: "stackbar",
                    data: (data != undefined && data.length != 0) ? data.map(x => x.coldRegion) : [],
                    itemStyle: {
                        color: '#3483eb'
                    }
                },
            ]
        };
        return option;
    }

    

    return(
        <div>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"><StatusTrendModeSelection onModeSelection={_onModeSelection}></StatusTrendModeSelection></div>
                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"><EnvFilter onEnvFilter={_onEnvFilter} defaultOptions={defaultSelectedKeys} /></div> 
            </div>           
            <div className="ms-Grid" dir="ltr" style={{ marginTop: '20px' }}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8"><ReactECharts
                        option={_getOption(data)}
                        style={{ height: 400, width: 1500 }}
                        opts={{ renderer: 'svg' }}
                    /></div>
                                      
                </div>
            </div>
            
            
    </div>)
};