import * as React from "react"

import {
  IComboBox,
  IComboBoxOption,
  IComboBoxStyles,
} from '@fluentui/react';

import distinct from "../../../../utils/utils";
import { IAllocationOverrideEntry } from "../../../../models/AllocationOverride";
import request from "../../../../utils/api";
import { AllocationOverrideTable } from "./AllocationOverrideTable";
import { Stack, IStackTokens } from '@fluentui/react';
import { DefaultButton, IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { fetchAllocationOverrides, fetchAllocationPlans, fetchBeStamps } from "../../../../services/allocationService";
import PageHeader from '../../../common/pageheader/PageHeader';


export const AllocationOverridePage: React.FC = () => {
    const [allocationOverrideRows, setAllocationOverrideRows] = React.useState<IAllocationOverrideEntry[]>([]);
    const [selectedStamps, setSelectedStamps] = React.useState<String[]>([]);

    const [stamps, setStamps] = React.useState<IStamp[]>([]);

    const [version, setVersion] = React.useState(Date.now());

    const comboBoxRef = React.useRef<IComboBox>(null);
    const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 } };

    React.useEffect(() => {
        getAllocationOverrides();
        getStamps();
    }, [])

    const onStampsSelected = () => {
        const selection = comboBoxRef.current?.selectedOptions.map(option => option.key.toString());
        setSelectedStamps(selection ? selection : []);
    }

    const stackTokens: IStackTokens = { childrenGap: 40 };

    return (
        <div className="ms-Fabric" style={{margin: ""}}>
            <PageHeader
                title="Allocation Override"
                description="We can override the current allocation map"
            />

            {allocationOverrideRows
                .filter(row => selectedStamps.includes(row?.demandId?.toString() || ""))
                .map(row => {
                    return (
                        <div className="ms-font-m-plus"> {row.demandId || ""} </div>
                    );
                })
            }
            <AllocationOverrideTable 
                key={version}
                stamps = {stamps}
                data={allocationOverrideRows}//.filter(row => selectedStamps.includes(row.demandId.toString()))} 
                triggerRefresh={tableChange}
            />
            <IconButton iconProps={{ iconName: 'Add'}} title="Add" ariaLabel="Add" onClick={addAllocationOverrideRow}/>
            <Stack horizontal tokens={stackTokens}>
                <DefaultButton text="Discard" onClick={discardChanges}/> 
                <PrimaryButton text="Save" />
            </Stack>
        </div>
    );


    function tableChange() {
        console.log(allocationOverrideRows.length)
        setVersion(Date.now());
        console.log(allocationOverrideRows)
    }

    function getAllocationOverrides() {
        fetchAllocationOverrides().then(response => {
            setAllocationOverrideRows(response);
            setVersion(Date.now());
        })
    }


    function discardChanges() {
        getAllocationOverrides();
    }

    function addAllocationOverrideRow() {
        allocationOverrideRows.push({
            id: -1,
            forest: "",
        });
        tableChange();
    }
    
    function getStamps() {
        fetchBeStamps().then(response => {
            console.log(response);
            setStamps(response);
        })
    }
};