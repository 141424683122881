import { ComboBox, DefaultButton, DetailsList, DetailsRow, Dialog, DialogFooter, DialogType, Dropdown, IColumn, IComboBox, IComboBoxOption, IconButton, IDetailsListProps, IDetailsRowStyles, IDropdownOption, IDropdownStyles, IGroup, ITooltipHostStyles, Label, PrimaryButton, Selection, TextField, TooltipHost } from "@fluentui/react";
import * as React from "react";
import {Chart, registerables} from "chart.js";
import axios from "axios";
import { useId } from '@fluentui/react-hooks'
import { DatePicker } from "office-ui-fabric-react";
import { RegionParamEditor } from "./input/RegionParameterEditor";
import { CapacitySellableEditor } from "./input/CapacitySellableEditor";
interface ICapacityPlanGeneratorScenarioPageProps {
    id? : string
}
export const CapacityPlanGeneratorScenarioPage: React.FC<ICapacityPlanGeneratorScenarioPageProps> = ({id}) => {
    const [scenarioId, setScenarioId] = React.useState<string>(id || "");
    const [scenarioName, setScenarioName] = React.useState<string>("");
    const [scenarioDescription, setScenarioDescription] = React.useState<string>("");

    const onScenarioNameChange = (event: React.FormEvent, newValue?: string) => {
        setScenarioName(newValue || "");
    }

    const onScenarioDescriptionChange = (event: React.FormEvent, newValue?: string) => {
        setScenarioDescription(newValue || "");
    }
    return (
        <div className="ms-Grid ms-font-s" dir="ltr">
            <div className="ms-Grid-row"> 
                <div className="ms-Grid-col ms-lg5 ms-md5 ms-sm5">
                    <div style={{maxWidth: "55rem"}}>
                        <Dropdown label="Regions" options={[]}/>
                    </div>

                    <div>
                        <Label>Parameters</Label>
                        <RegionParamEditor regions={["nam", "eur", "apc"]} />
                    </div>

                    <div>
                        <Label>Sellables</Label>
                        <CapacitySellableEditor skus={["WCS Gen7", "WCS Gen8", "WCS Gen9"]} />
                    </div>
                    <div style={{display: "inline-block"}}>
                        <div>
                            <Dropdown 
                                label="HardwareRoadmap" 
                                defaultSelectedKey="latest"
                                options={[{key:"latest", text: "latest"}]}/>
                        </div>
                        <div>
                            <DefaultButton styles={{root: {marginBottom: "3rem"}}}>Inspect</DefaultButton>
                        </div>
                    </div>


                    <TextField label="parameters"/>
                </div>

                <div className="ms-Grid-col ms-lg3 ms-md3 ms-sm3">
                    <h2>Scenario Detail</h2>
                    <Label>Scenario ID</Label>
                    <TextField 
                        disabled 
                        value={scenarioId}/>
                    <Label >Name</Label>
                    <TextField 
                        value={scenarioName}
                        onChange={onScenarioNameChange}/>
                    <Label>Description</Label>
                    <TextField 
                        value={scenarioDescription}
                        onChange={onScenarioDescriptionChange}/>
                </div>
                <div className="ms-Grid-row"> 
                </div>
            </div>
            <div className="ms-Grid-row"> 
                <div className="ms-Grid-col ms-lg7 ms-md7 ms-sm7">
                    <div style={{maxWidth: "20rem"}}>
                        <DatePicker label="Supply Cut-off Date" />
                    </div>
                    <div style={{maxWidth: "20rem"}}>
                        <DatePicker label="Start Time" />
                    </div>
                    <div style={{maxWidth: "20rem"}}>
                        <DatePicker label="End Time" />
                    </div>
                </div>
            </div>
            <div className="ms-Grid-row"> 
                <div className="ms-Grid-col ms-lg7 ms-md7 ms-sm7">
                    <DefaultButton>Generate</DefaultButton>
                    <PrimaryButton>Save</PrimaryButton>
                </div>
            </div>
        </div>
    )
}