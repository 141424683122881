import * as React from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

interface IEnvFilterProp {
    options: IDropdownOption[],
    defaultOptions: string[],
    onColFilter: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => void,
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
};

const stackTokens: IStackTokens = { childrenGap: 20 };

export const ColumnSelection: React.FunctionComponent<IEnvFilterProp> = (props) => {

    return (
        <Stack tokens={stackTokens}>
            <Dropdown
                placeholder="Select options"
                label="Select Columns"
                defaultSelectedKeys={props.defaultOptions}
                multiSelect
                options={props.options}
                styles={dropdownStyles}
                onChange={props.onColFilter}
            />
        </Stack>
    );
};
