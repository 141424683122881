import { get, getJson, post, deleteItem } from "../utils/apiServiceBase";
import { ICOGSCalculatorRequest, EfficiencyTracker } from "../models/PCM"

export async function fetchCOGSCalculatorRequests(): Promise<ICOGSCalculatorRequest[]> {
    return await getJson<ICOGSCalculatorRequest[]>("pcm/get_requests");
}

export async function updateCOGSCalculatorRequest(request: ICOGSCalculatorRequest): Promise<Response> {
    return await post("pcm/update_request", request);
}

export async function deleteCOGSCalculatorRequest(requestId: string) {
    return await deleteItem("pcm/delete_request?requestid="+requestId);
}

export async function uploadEfficiencyProjects(projects: EfficiencyTracker[]): Promise<Response> {
    return await post("pcm/upload_efficiency", projects);
}
