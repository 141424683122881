import React from "react";
import PageHeader from '../../../common/pageheader/PageHeader';
import {
    MessageBar,
    MessageBarType,
    Link
} from '@fluentui/react';

export const DCSelectionComparisonPage: React.FC = () => {
    return (
        <div className="ms-Fabric ms-font-su" style={{margin: ""}}> 
            <PageHeader
                title="Comparison"
                description="Showing the difference in hosting cost results between default and model selection"
            />
            <div>
                <BodyPage />
            </div>
        </div>
    )
}

const BodyPage: React.FC = () => {
    return (
        <div>
            <MessageBar>
                Please go to
                <Link href="https://aka.ms/tesseract/m2cp/dcselection" inline>
                aka.ms/tesseract/m2cp/dcselection
                </Link>{' '}
                to check original report
            </MessageBar>

            <iframe title="DC Selection Comparison" 
            style = {{ width: "100%", height: "900px", marginTop: '20px' }}
            src="https://msit.powerbi.com/reportEmbed?reportId=e92a39ea-7124-47d3-928d-d5f1dbcf756b&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47" 
            frameBorder={0} 
            allowFullScreen={true}>
            </iframe>
        </div>
    );
}