import { IButtonStyles, IIconProps, ISpinnerStyles, IStackItemStyles, IStackStyles, IStackTokens, IconButton, Spinner, Stack, SpinnerSize } from "@fluentui/react";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBanner from "../banner/AppBanner";
import Navigation from "./Navigation";
import moment from "moment";
import styles from "./PageLayout.less";
import { useBoolean } from "@fluentui/react-hooks";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { ISideBarData, loadSideBarsIfNeeded } from "../../../reducers/sideBarReducer";
import { IUserPermissionData, loadUserPermissionIfNeeded } from "../../../reducers/userPermissionReducer";
import { IAppState } from "../../../store";
import { useMsal } from "@azure/msal-react";

const outerStackStyles: IStackStyles = {
    root: {
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden",
    }
};

const filterStackStyles: IStackStyles = {
    root: {
        width: "100%",
        height: 50,
        backgroundColor: "#E9E9E9",
        borderBottom: "1px solid #C8C8C8",
        alignItems: "center"
    }
};

const contentStackStyles: IStackStyles = {
    root: {
        height: "calc(100% - 98px)"
    }
};

const serviceTreeStyles: IStackStyles = {
    root: {
        width: 280,
        backgroundColor: "#E9E9E9",
        overflowY: "auto"
    }
};

const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
    },
};

const buttonStyles: IButtonStyles = {
    root: {
        width: 48,
        height: 48,
        color: "black"
    }
};

const spinnerStyles: ISpinnerStyles = {
    root: {
        height: "100%"
    }
};

const navIcon: IIconProps = { iconName: "GlobalNavButton" };

const filterStackTokens: IStackTokens = {
    childrenGap: 16
};

interface IPageLayoutProps {
    children?: ReactNode,
    active?: string
}

const PageLayout: React.FC<IPageLayoutProps> = (props) => {
    const dispatch = useDispatch();
    const scrollableContainerRef = React.useRef<HTMLDivElement>(null);
    const [open, { toggle }] = useBoolean(true);

    const { search } = useLocation();
    const query = React.useMemo(() => new URLSearchParams(search), [search]);
    const [hasViewId, setHasViewId] = useState(false);
    const history = useHistory();
    const { isLoading, sidebars } = useSelector<IAppState, ISideBarData>(state => state.sideBars);
    const { isLoadingPermission } = useSelector<IAppState, IUserPermissionData>(state => state.userPermissions);

    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    useEffect(() => {
        dispatch(loadSideBarsIfNeeded(account?.username));
        dispatch(loadUserPermissionIfNeeded(account?.username));
    }, [dispatch])


    useEffect(() => {
        if (!hasViewId) return;
        if (localStorage.getItem("hashRoutes")) {
            const hashRoutes = localStorage.getItem("hashRoutes");
            localStorage.removeItem("hashRoutes");
            hashRoutes && history.push(hashRoutes);

            if (hashRoutes?.includes("?")) return;
        }
    }, [dispatch, hasViewId, history]);


    useEffect(() => {
        window.dispatchEvent(new Event("resize"));
    }, [open]);


    return (
        <Stack styles={outerStackStyles} disableShrink>
            <AppBanner />

            {(isLoading || isLoadingPermission) && <Spinner label="Checking permission, please wait..." size={SpinnerSize.large} style={{ marginTop: '300px' }} />}
            {!isLoading && !isLoadingPermission && < Stack horizontal styles={contentStackStyles}>
                <Stack.Item shrink={false} styles={serviceTreeStyles}>
                    <Navigation links={sidebars} active={props.active}/>
                </Stack.Item>
                <div className={styles.pageContainer} ref={scrollableContainerRef}>
                    <div id="overviewUse" className={styles.page}>{props.children}</div>
                </div>
            </Stack>}
        </Stack>
    );
};

export default PageLayout;
