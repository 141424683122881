import * as React from "react";
import { useState, useEffect } from "react";
import { DefaultPalette, Stack, IStackStyles, IStackTokens, IStackItemStyles, Spinner, MessageBar, MessageBarType, Button, DefaultButton, PrimaryButton } from '@fluentui/react';
import { RegionTTL } from './RegionTTL'
import LoadingState from '../../common/LoadingState';
import { getConfigsNew, getRegionStatus, getCopsQC, getRegionStatusTrend } from '../../../services/alertingservice';
import { RegionStatus, AlertingConfigNew, CopsQC, RegionStatusTrend, RegionEnvironmentStatus } from "../../../models/alerting";
import PageHeader from '../../common/pageheader/PageHeader';
import { IUserPermissionData, loadUserPermissionIfNeeded } from "../../../reducers/userPermissionReducer";
import { IAppState } from "../../../store";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAllRegStatus, fetchRegionGeoMapping } from "../../../services/costsavingservice";
import { IRegionGeoMapping } from "../../../models/CostSaving";

// Styles definition
const stackStyles: IStackStyles = {
    root: {
        /*        background: DefaultPalette.themeTertiary,*/
        marginBottom: '25vh'
    },
};
const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        /*        background: DefaultPalette.themePrimary,
                color: DefaultPalette.white,*/
        display: 'flex',
        height: 30,
        justifyContent: 'center',
        width: '60vh'
    },
};

// Tokens definition
const stackTokens: IStackTokens = {
    childrenGap: 20,
    padding: 10,
};

export const AlertingReport: React.FC = () => {
    const history = useHistory();
    const [regionStatus, setRegionStatus] = useState<RegionStatus[]>([]);
    const [loadingConfig, setLoadingConfig] = useState(true);
    const [loadingRegionStatus, setLoadingRegionStatus] = useState(true);
    const [configs, setConfigs] = useState<AlertingConfigNew[]>([]);
    const [copsQC, setCopsQC] = useState<CopsQC>();
    const [regionStatusTrend, setRegionStatusTrend] = useState<RegionStatusTrend[]>([]);
    const [errMsg, setErrMsg] = useState("");
    const { userpermissions } = useSelector<IAppState, IUserPermissionData>(state => state.userPermissions);
    const [isColdRegionMapping, setIsColdRegionMapping] = useState<RegionEnvironmentStatus[]>([]);

    useEffect(() => {
        getRegionStatus("")
            .then(res => {
                setRegionStatus(res);
                setLoadingRegionStatus(false);
            })
            .catch(err => {
                setLoadingRegionStatus(false);
                setErrMsg(errMsg + err);
            })
        getConfigsNew("TTL")
            .then(configs => {
                setConfigs(configs);
                setLoadingConfig(false);
            })
            .catch(err => {
                setLoadingConfig(false);
                setErrMsg(errMsg + err);
            })
        getCopsQC()
            .then(res => {
                if (res != undefined) {
                    setCopsQC(res);
                }
            })
        getRegionStatusTrend()
            .then(res => {
                if (res != undefined) {
                    setRegionStatusTrend(res);
                }
            })
            .catch(err => {
                setErrMsg(errMsg + err);
            })
        getRegionStatusTrend()
            .then(res => {
                if (res != undefined) {
                    setRegionStatusTrend(res);
                }
            })
            .catch(err => {
                setErrMsg(errMsg + err);
            })
        fetchAllRegStatus()
            .then(res => {
                if (res != undefined) {
                    console.log("Map of cold reg env", res);
                    setIsColdRegionMapping(res);
                }
            })
    }, []);

    return (
        <div>
            <Stack >
                {errMsg != "" && < MessageBar messageBarType={MessageBarType.error}>
                    {errMsg}
                </MessageBar>}
                <PageHeader
                    title="Substrate Capacity Health"
                    description="Provide up-to-date capacity health status of Substrate BE."
                />

                {(loadingConfig || loadingRegionStatus) && <Spinner label="Loading Region Status..." />}
                {errMsg == "" && !loadingConfig && !loadingRegionStatus && < div style={{ display: "grid", gridTemplateColumns: "100%" }}>
                    <RegionTTL data={regionStatus} configs={configs} copsQC={copsQC} regionStatusTrend={regionStatusTrend} userPermissions={userpermissions} isColdRegionMapping={isColdRegionMapping} />
                </div>}
            </Stack>



        </div>
    )

}