import * as React from "react"

import {
  TextField,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import { Pagination } from "@uifabric/experiments"
import { SelectionGroup } from "../../../common/input/SelectionGroup";
import { IAllocationPlan } from "../../../../models/AllocationPlan";
import { AllocationPlanGroup } from "./AllocationPlanGroup";
import { AllocationPlanEditPage } from "./AllocationPlanEditPage";
import { fetchAllocationPlans, fetchBeStamps } from "../../../../services/allocationService";
import PageHeader from '../../../common/pageheader/PageHeader';
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { updateAllocationPlans } from "../../../../services/allocationService";
import { checkUserPermission } from "../../../../utils/utils"
import { IAppState } from "../../../../store";
import { IUserPermissionData } from "../../../../reducers/userPermissionReducer";
import { ToolType } from "../../../../utils/ToolConstants"

const IPaginationStyle = {
    pageNumber : {
        marginBottom: "0.6rem"
    }
}

export const AllocationOverviewPage: React.FC = () => {
    React.useEffect(() => {
        getStamps();
        getAllocationPlans();
    }, [])

    const [stamps, setStamps] = React.useState<IStamp[]>([]);
    const [allocationPlans, setAllocationPlans] = React.useState<IAllocationPlan[]>([]);

    const [keyword, setKeyword] = React.useState<string>("");
    const [edittingStamp, setEdittingStamp] = React.useState<string>("");
    const [selectedStatuses, setSelectedStatuses] = React.useState<string[]>([]);

    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const { userpermissions } = useSelector<IAppState, IUserPermissionData>(state => state.userPermissions);

    const [errMsg, setErrMsg] = React.useState<string>("");
    const [currentPage, setCurrentPage] = React.useState<number>(0);
    const plansPerPage = 10;  
    var totalPage = 0;
    
    const plansToShow = allocationPlans
        .filter(
            plan => !plan.demandId!.toString().startsWith("777")
        )
        .filter(
            plan => selectedStatuses.length == 0 || selectedStatuses.map(s => s.toLowerCase()).includes(plan.status?.toLowerCase() || "")
        )
        .filter(
            plan => plan.deploymentGroupIdName && includesIgnoreCase(plan.deploymentGroupIdName, keyword) ||
                    plan.region && includesIgnoreCase(plan.region, keyword) ||
                    plan.demandId && includesIgnoreCase(plan.demandId.toString(), keyword)
        );
    //console.log("number of plans to show: ", plansToShow.length)
    const statusOptions = ["Pending", "Created", "Completed", "History", "Excluded"];

    const planGroups = groupBy<IAllocationPlan, string>(plansToShow, plans => plans.deploymentGroupIdName || "")
    totalPage = Math.ceil(planGroups.size / plansPerPage)
    const stamp = stamps.filter(stamp => stamp.demandId.toString() + stamp.deploymentGroupIdName.toLowerCase() == edittingStamp.toLowerCase())[0];
    const isLoading = allocationPlans.length == 0 || stamps.length == 0;
    const onEditClicked = (stampClicked: string) => {
        //console.log("Edit clicked.")
        if (!stamps.filter(stamp => stamp.demandId.toString() + stamp.deploymentGroupIdName.toLowerCase() == stampClicked.toLowerCase())[0]) {
            setErrMsg("We cannot find this stamp in DERA. Please check if this stamp info has changed.")
        } else {
            //console.log(stampClicked);
            setEdittingStamp(stampClicked);
            setErrMsg("")

        }
    }

    const onSelectionChange = (selections: string[]) => {
        setSelectedStatuses(selections)
        setErrMsg("")
    }

    const _onPageChange = (index: number) => {
        setCurrentPage(index)
        setErrMsg("")
    }

    return (
        edittingStamp.toLocaleLowerCase() == "" ? 
            <div className="ms-Fabric ms-font-su" style={{margin: ""}}> 
                <PageHeader
                    title="Allocation Overview"
                    description="Showing the detail output of each stamp from allocation map generator."
                />
                <div style={{marginTop:"2rem"}}>
                    <SelectionGroup options={statusOptions} onChange={onSelectionChange} />
                </div>
                <div style={{marginTop:"2rem", marginBottom: "1rem"}}>
                    <TextField placeholder="You can search by demand id, stamp name and region." onChange={onKeywordInput} />
                </div>

                <div>
                    {errMsg && <MessageBar messageBarType={MessageBarType.error}> {errMsg}  </MessageBar>}
                </div>

                
                {isLoading ? 
                  <div style={{margin: "10rem"}}>
                     <Spinner label="Fetching stamp data..." size={SpinnerSize.large} />
                  </div> 
                :
                  <div className="ms-Grid ms-font-s" dir="ltr">
                    <div className="ms-Grid-row" style={{fontWeight: "bold", padding: "0.8rem 0.2rem", textAlign: "center"}}>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">DemandId</div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">DeploymentGroupIdName</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Region</div>
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">EstimatedGoLiveDate</div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">Status</div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">Actions</div>
                    </div>
                    {Array.from(planGroups).slice(currentPage * plansPerPage, (currentPage + 1) * plansPerPage).map((group, index) => {
                            const plans = group[1];
                            return <AllocationPlanGroup key={index} allocationPlans={plans} edit={onEditClicked} hasRerunPermission={checkUserPermission(userpermissions, ToolType.AllocationMap, 'rerun')}/>
                        }
                    )}
                    <div style={{marginTop:"1rem", textAlign: "right"}}>
                            <Pagination
                            selectedPageIndex={currentPage}
                            pageCount={totalPage}
                            onPageChange={_onPageChange}
                            format={"buttons"}
                            styles={IPaginationStyle}
                            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
                            previousPageIconProps={{ iconName: 'ChevronLeft' }}
                            nextPageIconProps={{ iconName: 'ChevronRight' }}
                            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
                            />
                    </div>
                  </div>
                }
            </div>
            :
            <AllocationPlanEditPage
                stamp = {stamp}
                allocationPlans = {allocationPlans.filter(plan => plan.demandId!.toString() + plan.deploymentGroupIdName!.toLowerCase() == edittingStamp.toLowerCase())}
                hasEditPermission = {checkUserPermission(userpermissions, ToolType.AllocationMap, 'edit')}
                onDiscard={discardChanges}
                onSave={(changedPlans) => saveAllocationPlans(changedPlans)}
            />
    );

    function onKeywordInput(event: React.FormEvent, newValue? : string) {
        //console.log(newValue);
        setKeyword(newValue ||  "");
        setErrMsg("")
    }

    function getStamps() {
        //console.log("getStamps called.")
        fetchBeStamps().then(response => {
            //console.log(response);
            setStamps(response);
        })
    }


    function getAllocationPlans() {
        fetchAllocationPlans().then(response => {
            //console.log(response);
            setAllocationPlans(response);
        })
    }


    function includesIgnoreCase(str: string, substr: string) {
        return str.toLowerCase().includes(substr.toLowerCase());
    }

    function groupBy<T, K extends keyof any>(alist: T[], getKey: (item: T) => K): Map<K, T[]> {
        const initialValue: Map<K, T[]> = new Map<K, T[]>();
        const groups = alist.reduce((carry, item) => {
            const group = getKey(item);
            if (!carry.get(group)) {
                carry.set(group, []);
            }
            carry.get(group)!.push(item);
            return carry;
        }, initialValue)

        return groups;
    }

    function discardChanges() {
        getAllocationPlans();
        setKeyword("");
        setEdittingStamp("");
    }

    function saveAllocationPlans(changedPlans: IAllocationPlan[]) {
        const updatedPlans = allocationPlans.filter(plan => plan.deploymentGroupIdName!.toLowerCase() != changedPlans[0].deploymentGroupIdName!.toLowerCase()).concat(changedPlans);
        setAllocationPlans(updatedPlans);
        updateAllocationPlans(changedPlans);

        setKeyword("");
        setEdittingStamp("");
    }
};