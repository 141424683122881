import * as React from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { TextField } from '@fluentui/react/lib/TextField';
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    ContextualMenu,
    Toggle,
    Modal,
    IDragOptions,
    IIconProps,
    Stack,
    IStackProps,
    IDropdownOption
} from '@fluentui/react';
import { DefaultButton, PrimaryButton, IconButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { repeat } from 'lodash';
import { AlertingConfigNew, RegionStatus } from '../../../../models/alerting';
import { useState } from 'react';
import { CustomerImpactChoices } from './CustomerImpactChoices';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { MaxEDBSelection } from './MaxEDBSelection'

interface ITableModalProps {
    isModalOpen: boolean,
    hideModal: () => void,
    item?: AlertingConfigNew,
    save: (config?: AlertingConfigNew) => void,
}

export const TTLConfigTableModal: React.FunctionComponent<ITableModalProps> = (props) => {
    const { isModalOpen, hideModal, item } = props;
    const [red, setRed] = useState(item == undefined ? "2.5" : item.sev1.toString());
    const [green, setGreen] = useState(item == undefined ? "4.5" : item.sev2.toString());
    const [blue, setBlue] = useState(item == undefined ? "12" : item.sev3.toString());

    const onRedChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
        setRed(value == undefined ? "" : value);
        if (item != undefined) {
            item.sev1 = Number(value);
        }
    };

    const onGreenChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
        setGreen(value == undefined ? "" : value);
        if (item != undefined) {
            item.sev2 = Number(value);
        }
    };

    const onBlueChange = (event: React.SyntheticEvent<HTMLElement>, value?: string): void => {
        setBlue(value == undefined ? "" : value);
        if (item != undefined) {
            item.sev3 = Number(value);
        }
    };

    const onMaxEDBFilter = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option != null) {
            if (item != undefined) {
                item.maxEDB = Number(option.text);
            }           
        }
    }

    // Use useId() to ensure that the IDs are unique on the page.
    // (It's also okay to use plain strings and manually ensure uniqueness.)
    const titleId = useId('title');

    return (
        <div>
            <Modal
                titleAriaId={titleId}
                isOpen={isModalOpen}
                onDismiss={hideModal}
                isBlocking={false}
                containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header}>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={hideModal}
                    />
                </div>
                <div>
                    <div style={{ width: "600px", height: "650px" }}>
                        <p style={{ marginLeft: "20px" }}><strong>Region:</strong> {item?.geoCode} </p>
                        <p style={{ marginLeft: "20px" }}><strong>Environment:</strong> {item?.environment} </p>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}><TextField label="Hot/Watch Limit:" value={red} onChange={onRedChange} underlined /></div>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}><TextField label="Watch/Healthy Limit:" value={green} onChange={onGreenChange} underlined /></div>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}><TextField label="Healthy/Cold Limit:" value={blue} onChange={onBlueChange} underlined /></div>
                        <div style={{ marginLeft: "10px", marginTop: "20px" }}><MaxEDBSelection defaultOptions={item == undefined ? '88' : item.maxEDB.toString()} onFilter={ onMaxEDBFilter } /></div>
                        <div style={{ marginLeft: "260px", marginTop: "20px" }}>   <PrimaryButton text="Submit" onClick={() => props.save(item)} /></div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const stackProps: Partial<IStackProps> = {
    horizontal: true,
    tokens: { childrenGap: 40 },
    styles: { root: { marginBottom: 20 } },
};
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};